import { AxiosResponse } from "axios";

import api from "../../../api/v1";
import { GetURLMetadataResponse } from "../types";

export default function getURLMetadata(params: {
  url: string;
}): Promise<AxiosResponse<GetURLMetadataResponse>> {
  return api.get<GetURLMetadataResponse>(`core/metadata-url/`, { params });
}
