/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {Array<Source>}
     * @memberof Meta
     */
    sources: Array<Source>;
}

/**
 * Check if a given object implements the Meta interface.
 */
export function instanceOfMeta(value: object): value is Meta {
    if (!('sources' in value) || value['sources'] === undefined) return false;
    return true;
}

export function MetaFromJSON(json: any): Meta {
    return MetaFromJSONTyped(json, false);
}

export function MetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Meta {
    if (json == null) {
        return json;
    }
    return {
        
        'sources': ((json['sources'] as Array<any>).map(SourceFromJSON)),
    };
}

export function MetaToJSON(value?: Meta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sources': ((value['sources'] as Array<any>).map(SourceToJSON)),
    };
}

