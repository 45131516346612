import { TodoRequest } from "openapi";

import Modal from "shared/componentsV2/common-modal";
import Button from "shared/componentsV2/form-controls/button";

import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useArticleTodos } from "modules/horizonScanning/hooks/useArticleTodos";

import TodoItem from "./TodoItem";
import { TodoItemIcon } from "./TodoItemIcon";

const ArticleToDosModal = () => {
  const {
    showToDosModal,
    setShowToDosModal,
    articleQuery: { data: article },
  } = useArticleContext();

  if (!article) return null;

  const {
    todos,
    totalTodosLength,
    completedTodosLength,
    updateArticleTodo,
    createArticleTodo,
    invalidateTodoQuery,
  } = useArticleTodos();

  const handleUpdateTodo = async (updatedTodo: TodoRequest & { id: number }) => {
    const feedTodos = todos.filter((t) => !t.feedArticle);

    if (feedTodos.length) {
      await Promise.all(
        feedTodos.map(async (t) => {
          if (t.id === updatedTodo.id) {
            await createArticleTodo.mutateAsync({
              ...t,
              isCompleted: updatedTodo.isCompleted,
              feed: null,
            });
          } else {
            await createArticleTodo.mutateAsync({
              ...t,
              feed: null,
            });
          }
        })
      );

      invalidateTodoQuery();
    }

    if (updatedTodo.feedArticle) {
      await updateArticleTodo.mutateAsync({
        id: updatedTodo.id,
        isCompleted: updatedTodo.isCompleted,
      });
    }
  };

  return (
    <Modal
      onClose={() => setShowToDosModal(false)}
      show={showToDosModal}
      size="4xl"
      dismissible={false}
    >
      <Modal.Header>
        <div className="flex items-center gap-2 text-sm font-medium text-richblack dark:text-gray-400">
          <TodoItemIcon
            completedTodosLength={completedTodosLength}
            totalTodosLength={totalTodosLength}
          />
          To-do
          <span>
            {completedTodosLength}/{totalTodosLength}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="!px-8 !py-6">
        <div className="grid gap-4">
          <span className="text-sm font-medium text-aurometalsaurus">
            The below tasks must be completed before the article status can be changed to complete.
          </span>
          <ul className="grid w-full list-none gap-2 text-sm font-medium">
            {todos.map((todo) => (
              <TodoItem
                key={todo.id}
                todo={todo}
                isEditable={false}
                canMarkAsDone={true}
                updateTodo={(updatedTodo) => handleUpdateTodo(updatedTodo)}
              />
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-end border-0 !px-8 !py-3">
        <Button
          btnTitle="Close"
          btnType="secondary"
          btnSize="sm"
          onClick={() => setShowToDosModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ArticleToDosModal;
