import { useEffect, useState } from "react";

import { LexicalEditor } from "lexical";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";

import { CAN_USE_DOM } from "../helpers/canUseDOM";
import AutoLinkPlugin from "../plugins/AutoLinkPlugin";
import ClickableLinkPlugin from "../plugins/ClickableLinkPlugin";
import TasksComponentPickerPlugin from "../plugins/ComponentPickerPlugin/TasksComponentPickerPlugin";
import DragDropPaste from "../plugins/DragDropPastePlugin";
import TaskDraggableBlockPlugin from "../plugins/DraggableBlockPlugin/TaskDraggableBlockPlugin";
import EmojiPickerPlugin from "../plugins/EmojiPickerPlugin";
import FloatingLinkEditorPlugin from "../plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "../plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "../plugins/ImagesPlugin";
import LinkPlugin from "../plugins/LinkPlugin";
import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin";
import MarkdownShortcutPlugin from "../plugins/MarkdownShortcutPlugin";
import TabFocusPlugin from "../plugins/TabFocusPlugin";
import TableCellActionMenuPlugin from "../plugins/TableActionMenuPlugin";
import TableCellResizer from "../plugins/TableCellResizer";
import WebBookmarkPlugin from "../plugins/WebBookmarkPlugin";
import Placeholder from "../ui/Placeholder";

export default function TaskCommentEditor({
  placeholder,
  children,
  className,
}: {
  placeholder: string | null;
  children?: (editor: LexicalEditor) => JSX.Element;
  className?: string;
}): JSX.Element {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };

    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const placeholderComponent = placeholder ? (
    <Placeholder className={className}>{placeholder}</Placeholder>
  ) : null;

  return (
    <>
      <div className={`editor-container`}>
        <DragDropPaste />
        {editor.isEditable() ? <TasksComponentPickerPlugin /> : null}
        <AutoLinkPlugin />
        <>
          <RichTextPlugin
            contentEditable={
              <>
                <div className="editor-scroller">
                  <div className="editor tasksEditor taskCommentEditor" ref={onRef}>
                    <ContentEditable
                      onKeyDown={(event: React.KeyboardEvent) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          event.stopPropagation();
                        }
                      }}
                    />
                  </div>
                </div>
                {children ? children(editor) : null}
              </>
            }
            placeholder={placeholderComponent}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <EmojiPickerPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <TablePlugin />
          <TableCellResizer />
          <ImagesPlugin />
          <LinkPlugin />
          <ClickableLinkPlugin />
          <HorizontalRulePlugin />
          <TabFocusPlugin />
          <TabIndentationPlugin />
          <WebBookmarkPlugin />
          <HistoryPlugin />
          <MarkdownShortcutPlugin />
          {floatingAnchorElem && !isSmallWidthViewport ? (
            <>
              <TaskDraggableBlockPlugin anchorElem={floatingAnchorElem} />
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
            </>
          ) : null}
        </>
      </div>
    </>
  );
}
