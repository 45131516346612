/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `open` - Open
 * * `resolved` - Resolved
 * @export
 */
export const FeedArticleCommentStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type FeedArticleCommentStatusEnum = typeof FeedArticleCommentStatusEnum[keyof typeof FeedArticleCommentStatusEnum];


export function instanceOfFeedArticleCommentStatusEnum(value: any): boolean {
    for (const key in FeedArticleCommentStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(FeedArticleCommentStatusEnum, key)) {
            if (FeedArticleCommentStatusEnum[key as keyof typeof FeedArticleCommentStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function FeedArticleCommentStatusEnumFromJSON(json: any): FeedArticleCommentStatusEnum {
    return FeedArticleCommentStatusEnumFromJSONTyped(json, false);
}

export function FeedArticleCommentStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedArticleCommentStatusEnum {
    return json as FeedArticleCommentStatusEnum;
}

export function FeedArticleCommentStatusEnumToJSON(value?: FeedArticleCommentStatusEnum | null): any {
    return value as any;
}

