import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamId } from "shared/types";

import { FilterResponse } from "../types";

export default function getFilters(
  permissions: { team: TeamId },
  requestParams?: { ordering?: string }
): Promise<
  AxiosResponse<{
    results: FilterResponse[];
  }>
> {
  return api.get<{ results: FilterResponse[] }>(`core/filters/`, {
    params: { ...permissions, ...hasNameOrdering(requestParams) },
  });
}
