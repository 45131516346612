import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { convertAgreementSubContractorData } from "../helpers";
import { AddSubContractorProps, SubContractorResultProps } from "../types";

export default async function addSubcontractor(
  data: AddSubContractorProps,
  permissions: { team: TeamId }
): Promise<AxiosResponse<SubContractorResultProps>> {
  let response = await api.post<SubContractorResultProps>(
    `agreements/subcontractors/`,
    { ...data },
    { params: { ...permissions } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertAgreementSubContractorData(response.data),
    };
  }

  return response;
}
