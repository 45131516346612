import { useInfiniteQuery } from "@tanstack/react-query";

import getPageFromNextPage from "shared/helpers/getPageFromNextPage";

import { getTagsList } from "../components/metadata/api";
import { CompanyId, TeamId } from "../types";

/**
 * Loads available tags for the given team
 */
export default function useTagsList(
  teamId?: TeamId,
  companyId?: CompanyId,
  isAllTeam?: boolean,
  searchData?: string
) {
  return useInfiniteQuery(
    ["getTagsList", teamId, companyId, isAllTeam, searchData],
    ({ pageParam = 1 }) => {
      if (!companyId) {
        return Promise.reject(new Error("Current company is not selected"));
      }
      if (!teamId) {
        return Promise.reject(new Error("Current team is not selected"));
      }

      const requestParams = {
        page: pageParam,
        name__icontains: searchData,
      };
      return getTagsList(searchData ? requestParams : { page: pageParam }, {
        team: { team: teamId, company: companyId, isAllTeam: isAllTeam },
      });
    },

    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data?.next != null) {
          return getPageFromNextPage(lastPage.data.next);
        }
      },
      enabled: !!teamId,
      refetchOnWindowFocus: false,
    }
  );
}
