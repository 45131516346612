import React from "react";

import clsx from "clsx";

const NotCompliant = ({ count, onClick }: { count: number; onClick?: () => void }) => {
  return (
    <div
      data-testid="complient_div"
      className={clsx(
        { "cursor-pointer": onClick },
        "ml-2.5 flex items-center gap-1 rounded-md border border-red-500 !bg-lavenderBlush px-2 text-xs font-bold text-red-600"
      )}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <div className="tabError">
        <span>{count}</span>
      </div>
      Not compliant
    </div>
  );
};

export default NotCompliant;
