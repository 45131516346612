// TODO: add auto refresh token
import { AssistantApi, Configuration, ConfigurationParameters, HorizonApi, RoiApi } from "openapi";

import { getToken } from "shared/helpers/util";

import { refreshTokenMiddleware } from "./middleware";

class ApiV1 {
  baseUrl!: string;

  roi!: RoiApi;
  assistant!: AssistantApi;
  horizonScanning!: HorizonApi;

  constructor(protected params: ConfigurationParameters) {
    const config = new Configuration({
      ...params,
      middleware: [refreshTokenMiddleware(params.basePath || "")],
    });
    this.baseUrl = config.basePath;

    this.roi = new RoiApi(config);
    this.assistant = new AssistantApi(config);
    this.horizonScanning = new HorizonApi(config);
  }
}

export const client = new ApiV1({
  basePath: process.env.REACT_APP_BACKEND_URL,
  apiKey() {
    return `Bearer ${getToken()}`;
  },
});

export const clientPublic = new ApiV1({
  basePath: process.env.REACT_APP_BACKEND_URL,
});
