import React from "react";

import clsx from "clsx";
import isEmpty from "lodash/isEmpty";

import styles from "assets/css/settings.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import { useUpdateCard } from "shared/store/settings";

import { PaymentMethodResponse } from "modules/settings/billing/types";

export default function BillingListing({
  billingDetails,
}: {
  billingDetails?: PaymentMethodResponse;
}) {
  const { currentCompany } = useCompany();

  const [
    setIsUpdateCardModalOpen,
    setIsUpdateEmailModalOpen,
    setIsUpdateAddressModalOpen,
    setIsUpdateVATorGSTModalOpen,
  ] = useUpdateCard((state) => [
    state.setIsUpdateCardModalOpen,
    state.setIsUpdateEmailModalOpen,
    state.setIsUpdateAddressModalOpen,
    state.setIsUpdateVATorGSTModalOpen,
  ]);

  return (
    <>
      <div>
        <table className={clsx(styles.billingTable, "w-full maxMd:min-w-[500px]")}>
          {billingDetails && !isEmpty(currentCompany.customer) ? (
            <tbody>
              <tr
                className={
                  "flex items-center border-b border-brightgray px-6 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm"
                }
              >
                <td>Payment Method</td>
                {billingDetails?.card && (
                  <td>
                    {`${billingDetails?.card.brand} ending with ${billingDetails?.card?.last4}`}
                  </td>
                )}
                <td>
                  <button onClick={() => setIsUpdateCardModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-6 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>Billing Email</td>
                <td>{billingDetails?.billing_details.email || "-"}</td>
                <td>
                  <button onClick={() => setIsUpdateEmailModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-6 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>Address</td>
                <td>
                  {!isEmpty(billingDetails?.billing_details)
                    ? `${billingDetails?.billing_details.address.line1 || ""}, ${
                        billingDetails?.billing_details.address.line2 || ""
                      } ${billingDetails?.billing_details.address.city || ""}, ${
                        billingDetails?.billing_details.address.state || ""
                      },${billingDetails?.billing_details.address.postal_code || ""}, ${
                        billingDetails?.billing_details.address.country || ""
                      }`
                    : "-"}
                </td>
                <td>
                  <button onClick={() => setIsUpdateAddressModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-6 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>VAT/GST Number</td>
                <td>{billingDetails?.billing_details.eu_vat || "-"}</td>
                <td>
                  <button onClick={() => setIsUpdateVATorGSTModalOpen(true)}>Update</button>
                </td>
              </tr>
            </tbody>
          ) : null}
          {!currentCompany.customer ? (
            <div>
              <div className="border-brightgray px-6 py-3 dark:border-thunders md:border-b maxMd:px-4 maxMd:text-sm">
                <div className="flex items-center dark:text-white">
                  Workspace Balance{" "}
                  <p className="ml-auto text-aurometalsaurus dark:text-white">$0</p>
                </div>
              </div>
              <p className="px-6  pt-4 text-aurometalsaurus dark:text-greychateau maxMd:px-4">
                This workspace has no payments yet.
              </p>
            </div>
          ) : null}
        </table>
      </div>
    </>
  );
}
