import React, { useState } from "react";

import clsx from "clsx";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";
import { DropdownItemProps, DropdownProps } from "shared/typesV2";

import Button from "../form-controls/button";

export type DropdownContentProps = {
  dropdownOptions: DropdownItemProps[];
  side: "bottom" | "top" | "right" | "left" | undefined;
  align: "center" | "start" | "end" | undefined;
  handleSelect?: (data?: string, option?: DropdownItemProps) => void;
  selectedValue?: string;
  className?: string;
};

const DropdownContent = ({
  dropdownOptions,
  side,
  align,
  className,
  handleSelect,
  selectedValue,
}: DropdownContentProps) => {
  return (
    <>
      <DropdownMenu.Content
        className={clsx("DropdownMenuContent", className)}
        sideOffset={5}
        side={side}
        align={align}
      >
        {dropdownOptions?.map((option) => {
          return (
            <DropdownMenu.Item
              key={option?.id}
              className="DropdownMenuItem"
              onClick={(e) => {
                e.stopPropagation();
                if (handleSelect) {
                  handleSelect(option?.title, option);
                }
                if (option?.onItemClick) {
                  option?.onItemClick(option?.title, option);
                }
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {option?.icon ? (
                    <Icon
                      type={option?.icon}
                      fill={option?.iconFill}
                      size="icon-sm"
                      className={option?.iconClassName}
                    />
                  ) : null}
                  <span className="font-inter-medium dark:text-silverpolish">{option?.title}</span>
                </div>
                {(option?.title || option?.value) === selectedValue ? (
                  <Icon type="checkmark" fill={true} size="icon-sm" />
                ) : null}
              </div>
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </>
  );
};

function Dropdown({
  dropdownOptions,
  isPortal = true,
  side = "bottom",
  align = "end",
  TriggerComponent,
  selectedValue,
  handleSelect,
  className,
  asChild = true,
  disabled = false,
}: DropdownProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger
        disabled={disabled}
        asChild={asChild}
        className={clsx("rounded-lg p-1 focus-within:outline-none", {
          "bg-antiflashwhite dark:bg-tricornblack": open,
        })}
      >
        {TriggerComponent ? (
          TriggerComponent
        ) : (
          <Button
            iconOnly={true}
            aria-label="Customise options"
            btnType="plain"
            btnSize="sm"
            className="focus:!border-transparent"
            icon={
              <Icon
                type="ellipsis-vertical"
                fill={true}
                size="icon-smd"
                className="three_dots_icon"
              />
            }
          />
        )}
      </DropdownMenu.Trigger>
      {isPortal ? (
        <DropdownMenu.Portal>
          {dropdownOptions && dropdownOptions?.length > 0 ? (
            <DropdownContent
              dropdownOptions={dropdownOptions}
              side={side}
              align={align}
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              className={className}
            />
          ) : null}
        </DropdownMenu.Portal>
      ) : (
        <>
          {dropdownOptions && dropdownOptions?.length > 0 ? (
            <DropdownContent
              dropdownOptions={dropdownOptions}
              side={side}
              align={align}
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              className={className}
            />
          ) : null}
        </>
      )}
    </DropdownMenu.Root>
  );
}

export default Dropdown;
