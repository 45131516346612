import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Modal, Spinner } from "flowbite-react";

import { useUser } from "shared/context/UserProvider";
import { general_access_options } from "shared/helpers/constant";

import { CompanyUserResponse } from "../../../modules/home/types";
import { CompanyGroupResponse } from "../../../modules/settingsV2/groups/types";
import CommonDropdownV2 from "../../componentsV2/common-dropdownV2";
import { useCompany } from "../../context/CompanyProvider";
import { useSharedObjectModal } from "../../store/sharedobject";
import { SharedObject } from "../../types";
import CustomDropdown from "../custom-dropdown";
import Icon from "../icon/Icon";
import CentralSpinner from "../spinner/CentralSpinner";
import {
  createSharedObjectForGroup,
  createSharedObjectForUser,
  getSharedObjectsFromBaseUrl,
} from "./api";
import Members from "./components/Members";
import TeamDetails from "./components/TeamDetails";

const ObjectPermissionModal = () => {
  const { currentCompany } = useCompany();
  const { user } = useUser();
  const {
    isSharedObjectModalOpen,
    setIsSharedObjectModalOpen,
    sharedObjectModalBaseUrl,
    name,
    objectOwner,
    transferOwnerEndpoint,
    refetchData,
    paramProps,
  } = useSharedObjectModal();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingTarget, setIsAddingTarget] = useState(false);
  const [selectedTarget, setSelectedTarget] = useState<{
    value: string | number;
    label: string;
    data: CompanyUserResponse | CompanyGroupResponse;
  } | null>(null);

  const [sharedObjects, setSharedObjects] = useState<SharedObject[]>([]);

  const addSharedObject = async () => {
    if (!selectedTarget) return;
    setIsAddingTarget(true);
    try {
      if ((selectedTarget?.data as CompanyUserResponse).role) {
        await createSharedObjectForUser(
          sharedObjectModalBaseUrl,
          (selectedTarget.data as CompanyUserResponse).user.id,
          "VIEWER",
          currentCompany.id,
          paramProps
        );
      } else {
        await createSharedObjectForGroup(
          sharedObjectModalBaseUrl,
          selectedTarget.data.id,
          "VIEWER",
          currentCompany.id,
          paramProps
        );
      }
    } catch (ex) {
      toast.error(
        (ex as AxiosError<{ error: string }>)?.response?.data?.error || "Something went wrong"
      );
    }
    setIsAddingTarget(false);
    setSelectedTarget(null);
    await reloadSharedObjects();
  };

  const reloadSharedObjects = async () => {
    setIsLoading(true);
    try {
      const res = await getSharedObjectsFromBaseUrl(
        sharedObjectModalBaseUrl,
        currentCompany.id,
        paramProps
      );
      setSharedObjects(res.data);
    } catch (ex) {
      toast.error(
        (ex as AxiosError<{ error: string }>)?.response?.data?.error || "Something went wrong"
      );
      setIsSharedObjectModalOpen(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSharedObjectModalOpen) {
      void reloadSharedObjects();
    }
  }, [sharedObjectModalBaseUrl, isSharedObjectModalOpen]);

  const handleDoneButton = () => {
    setIsSharedObjectModalOpen(false);
  };

  const roles = sharedObjects
    .filter(
      (so) =>
        so.shared_with_user?.id === user.id ||
        so.shared_with_group?.users.find((groupUser) => groupUser.user.id === user.id)
    )
    .map((_) => _.role);

  const currentUserOwner = objectOwner && objectOwner?.id === user?.id;

  const isEditAccess = () => {
    if (currentUserOwner || roles.includes("EDITOR")) return true;
    return false;
  };

  const excludeGroupsId = sharedObjects
    ?.filter((so) => so.shared_with_group !== null)
    .map((_) => _.shared_with_group?.id);

  return (
    <>
      <Modal
        show={isSharedObjectModalOpen}
        onClose={() => setIsSharedObjectModalOpen(false)}
        dismissible={true}
      >
        <Modal.Header className="dark:border-iridium">
          <p className="font-inter-semibold">Share {name}</p>
        </Modal.Header>
        <Modal.Body
          style={{
            minHeight: "400px",
          }}
        >
          <div className={clsx({ "!pointer-events-none !cursor-progress": isAddingTarget })}>
            <div
              className={clsx("relative flex w-full gap-2", {
                "!cursor-not-allowed": !isEditAccess(),
              })}
            >
              <CommonDropdownV2
                isDisabled={!isEditAccess()}
                isMultiSelect={false}
                selectedValue={selectedTarget ? [selectedTarget] : []}
                onChange={(data) => {
                  const [value] = data;
                  setSelectedTarget(value || null);
                }}
                key={"addType-user"}
                placeholder={"Enter user or team you wish to invite"}
                defaultOrderingField={"user__name,user__email"}
                apiDetails={[
                  { url: `companies/members/?company=${currentCompany.id}`, label: "Users" },
                  { url: `companies/groups/?company=${currentCompany.id}`, label: "Groups" },
                ]}
                optionField={{ label: "user.name,user.email", value: "user.id" }}
                searchKey={"search"}
                isClearable={true}
                className="searchable_dropdown"
                excludeOptionIds={
                  objectOwner &&
                  sharedObjects
                    .filter((so) => so.shared_with_user !== null)
                    .map((_) => _.shared_with_user.id)
                    .concat(user.id, excludeGroupsId, objectOwner.id)
                }
              />
              <div className="absolute right-3 top-[0.6rem]">
                <button
                  className={clsx(
                    "btn_primary flex items-center justify-evenly gap-1 rounded-lg px-[0.375rem] py-[0.25rem] pr-[0.5rem] text-xs",
                    { "!cursor-not-allowed !opacity-50 ": !isEditAccess() }
                  )}
                  onClick={() => {
                    if (!selectedTarget) {
                      toast.error("User or team must be selected");
                    }
                    addSharedObject();
                  }}
                  disabled={!isEditAccess()}
                >
                  {isAddingTarget ? (
                    <Spinner
                      hidden={!isAddingTarget}
                      size="sm"
                      className="ml-1 !fill-ghostwhite stroke-brightgray"
                    />
                  ) : (
                    <Icon type="plus" size="icon-xs" fill={true} className="themesvg_white" />
                  )}
                  Invite
                </button>
              </div>
            </div>
            {isLoading ? (
              <CentralSpinner />
            ) : (
              <>
                <div className="people_access_container">
                  <div>
                    <p className="font-inter-medium text-sm font-medium dark:text-white">
                      People with access
                    </p>
                  </div>
                  {objectOwner && (
                    <div className="memebers_container_owner_view">
                      <Members
                        role={"EDITOR"}
                        user={objectOwner}
                        key={objectOwner?.id}
                        isOptionsAvailable={true}
                        triggerUpdate={reloadSharedObjects}
                        ownerBadge={true}
                      />
                    </div>
                  )}
                  <div className="members_container_sub_view">
                    {sharedObjects
                      .filter(
                        (so) =>
                          so.shared_with_user !== null && so.shared_with_user.id !== objectOwner?.id
                      )
                      .map((so) => (
                        <Members
                          sharedObjectId={so.id}
                          role={so.role}
                          user={so.shared_with_user}
                          key={so.id}
                          isOptionsAvailable={true}
                          triggerUpdate={reloadSharedObjects}
                          isEditAccess={isEditAccess()}
                          ownerBadge={false}
                          transferOwnerUrl={transferOwnerEndpoint}
                          refetchData={refetchData}
                          isUsers={true}
                        />
                      ))}
                    {sharedObjects
                      .filter((so) => so.shared_with_group !== null)
                      .map((so) => (
                        <TeamDetails
                          sharedObject={so}
                          triggerUpdate={reloadSharedObjects}
                          key={so.id}
                          isEditAccess={isEditAccess()}
                        />
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-col items-start gap-4 dark:border-iridium">
          <div className="general-access-container">
            <div className="mb-4 text-sm">
              <h5 className="mb-1 font-inter-medium dark:text-white">General access</h5>
              <span className="text-aurometalsaurus dark:text-greychateau">
                Only people and teams with access can open with the link.
              </span>
            </div>
            <div className="!ml-0 w-1/2 cursor-not-allowed opacity-50">
              <CustomDropdown
                options={general_access_options}
                isGeneralAccess={true}
                value={general_access_options[0]?.value}
                onChange={() => {
                  toast.error(`This is a disbaled option as of now`);
                  return;
                }}
              />
            </div>
          </div>
          <div className="object_permission_footer_container !ml-0">
            <div className="object_permission_settings_container cursor-not-allowed opacity-50">
              <span>
                <Icon type="setting" size="icon-sm" fill={true} />
              </span>
              <p className="font-inter-medium text-sm font-medium dark:text-white">
                Advanced Settings
              </p>
            </div>
            <div className="object_permission_action_button">
              <button
                className="btn_secondary rounded-lg border-brightgray px-[0.75rem] py-[0.25rem]"
                onClick={() => setIsSharedObjectModalOpen(!isSharedObjectModalOpen)}
              >
                Cancel
              </button>
              <button
                className="btn_primary rounded-lg px-[0.75rem] py-[0.25rem]"
                onClick={handleDoneButton}
              >
                Done
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      ,
    </>
  );
};

export default ObjectPermissionModal;
