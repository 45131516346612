/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FeedView } from './FeedView';
import {
    FeedViewFromJSON,
    FeedViewFromJSONTyped,
    FeedViewToJSON,
} from './FeedView';

/**
 * 
 * @export
 * @interface PaginatedFeedViewList
 */
export interface PaginatedFeedViewList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedFeedViewList
     */
    nextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedFeedViewList
     */
    previousPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedFeedViewList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedFeedViewList
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFeedViewList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedFeedViewList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<FeedView>}
     * @memberof PaginatedFeedViewList
     */
    results: Array<FeedView>;
}

/**
 * Check if a given object implements the PaginatedFeedViewList interface.
 */
export function instanceOfPaginatedFeedViewList(value: object): value is PaginatedFeedViewList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedFeedViewListFromJSON(json: any): PaginatedFeedViewList {
    return PaginatedFeedViewListFromJSONTyped(json, false);
}

export function PaginatedFeedViewListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedFeedViewList {
    if (json == null) {
        return json;
    }
    return {
        
        'nextPage': json['next_page'] == null ? undefined : json['next_page'],
        'previousPage': json['previous_page'] == null ? undefined : json['previous_page'],
        'count': json['count'],
        'pageSize': json['page_size'] == null ? undefined : json['page_size'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(FeedViewFromJSON)),
    };
}

export function PaginatedFeedViewListToJSON(value?: PaginatedFeedViewList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next_page': value['nextPage'],
        'previous_page': value['previousPage'],
        'count': value['count'],
        'page_size': value['pageSize'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(FeedViewToJSON)),
    };
}

