import React, { memo, useMemo } from "react";

import clsx from "clsx";

import Icon from "shared/components/icon/Icon";
import { colorClassFromName } from "shared/helpers/util";

import { getNameForIcon } from "modules/settingsV2/helper";

function ChangeGroupImage({
  groupIcon,
  groupName,
  group_picture,
}: {
  groupIcon: File | null;
  groupName: string;
  group_picture: string | null;
}) {
  const { backgroundColor, color } = useMemo(
    () => colorClassFromName(getNameForIcon(groupName)),
    [groupName]
  );

  const getGroupIcon = useMemo(() => {
    if (groupIcon) return URL.createObjectURL(groupIcon);
    return group_picture || "";
  }, [groupIcon, group_picture]);

  return (
    <>
      {getGroupIcon ? (
        <img className="h-full w-full rounded" src={getGroupIcon} alt="group-icon" />
      ) : (
        <div
          className={clsx(
            "flex h-full w-full items-center justify-center rounded",
            {
              "border border-lightSilver bg-antiflashwhite dark:bg-iridium ": !groupName,
            },
            { [`${backgroundColor} ${color}`]: groupName }
          )}
        >
          {groupName ? groupName : <Icon type="gray_plus" />}
        </div>
      )}
    </>
  );
}
export default memo(ChangeGroupImage);
