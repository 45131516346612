import React, { KeyboardEventHandler, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";

import clsx from "clsx";
import { FormikProps } from "formik";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/settings.module.css";

import { selectControlStyleForCreate, selectErrorStyles } from "shared/helpers/selectStyle";

import { SSOProviderProps } from "../types";

const Domains = ({ providerForm }: { providerForm: FormikProps<SSOProviderProps> }) => {
  const components = {
    DropdownIndicator: null,
  };

  const { isDarkMode } = useDarkMode();

  const [inputValue, setInputValue] = useState("");

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (providerForm.values.domains.includes(inputValue)) {
          setInputValue("");
          return;
        }
        providerForm.setFieldValue("domains", [...providerForm.values.domains, inputValue]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const domains = useMemo(() => {
    return providerForm?.values?.domains?.map((domain) => {
      return { label: domain };
    });
  }, [providerForm.values.domains]);

  return (
    <div>
      <CreatableSelect
        classNamePrefix="multitag provider_modal"
        isClearable={false}
        components={components}
        inputValue={inputValue}
        className={clsx(
          { "!border-thunders": providerForm.errors.domains },
          styles.creatableSelect
        )}
        isMulti
        menuIsOpen={false}
        onChange={(_, prev) =>
          providerForm.setFieldValue("domains", [
            ...providerForm.values.domains.filter((domain) => domain !== prev.removedValue?.label),
          ])
        }
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={<div className="text-sm">Enter Domains and press enter...</div>}
        value={domains}
        styles={
          providerForm.errors.domains
            ? selectErrorStyles(isDarkMode)
            : selectControlStyleForCreate(isDarkMode)
        }
      />
    </div>
  );
};

export default Domains;
