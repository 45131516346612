import { ArticleImpactEnum, ArticleStatusEnum } from "openapi";

export const getImpactColor = (impact?: ArticleImpactEnum) => {
  switch (impact) {
    case ArticleImpactEnum.NotSet:
      return "#9CA3AF";
    case ArticleImpactEnum.Low:
      return "#0E9F6E";
    case ArticleImpactEnum.Medium:
      return "#E3A008";
    case ArticleImpactEnum.High:
      return "#E02424";
    default:
      return "";
  }
};

export const getImpactLabel = (impact: ArticleImpactEnum) => {
  switch (impact) {
    case ArticleImpactEnum.Low:
      return "Low Impact";
    case ArticleImpactEnum.Medium:
      return "Medium Impact";
    case ArticleImpactEnum.High:
      return "High Impact";
    default:
      return "";
  }
};

export const getStatusColor = (status: ArticleStatusEnum) => {
  switch (status) {
    case ArticleStatusEnum.Completed:
      return "bg-greenhaze";
    case ArticleStatusEnum.InProgress:
      return "bg-yellow-300";
    default:
      return "bg-santagrey";
  }
};

export const getStatusLabel = (status: ArticleStatusEnum) => {
  switch (status) {
    case ArticleStatusEnum.Completed:
      return "Completed";
    case ArticleStatusEnum.InProgress:
      return "In Progress";
    default:
      return "Pending";
  }
};
