import { useMemo, useState } from "react";

import { ColumnDef } from "@tanstack/react-table";

import { ReactComponent as TrashIcon } from "assets/images/icons/trash.svg";

import { ActionsType, FiltersProp, SearchFields } from "shared/components/common-table/types";
import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  DATETIME_FILTER,
  ICONTAINS_OPERATOR,
  TEXT_FILTER,
} from "shared/helpers/constant";
import { getStatusResultData } from "shared/helpers/getStatusResultData";
import { convertDate } from "shared/helpers/util";

import { StatusResultType } from "../types";

const useStatusCommonTable = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = useState<StatusResultType[]>([]);

  const deleteTask = (data: StatusResultType[]) => {
    setToBeDeleted([...data]);
    setIsDeleteModalOpen(true);
  };

  const actions: ActionsType<StatusResultType>[] = useMemo(
    () => [
      {
        key: "Delete",
        icon: <TrashIcon />,
        action: (data) => {
          deleteTask(getStatusResultData(data));
        },
      },
    ],
    []
  );

  const columns = useMemo<ColumnDef<StatusResultType, unknown>[]>(
    () => [
      {
        header: "Name",
        accessorKey: "name",
      },
      {
        header: "Description",
        accessorKey: "description",
        cell: (info) => info.getValue() || "N/A",
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        accessorFn: (row: StatusResultType) => {
          return convertDate(row?.created_at);
        },
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        accessorFn: (row: StatusResultType) => {
          return convertDate(row?.updated_at);
        },
      },
    ],
    []
  );

  const searchFields = useMemo<SearchFields[]>(
    () => [
      {
        label: "Name",
        value: "name",
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
    ],
    []
  );

  const filters: FiltersProp[] = useMemo(
    () => [
      {
        label: "Name",
        field: "name",
        type: TEXT_FILTER,
        operator: ICONTAINS_OPERATOR,
        single_option: true,
      },
      {
        label: "Created At",
        field: "created_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
      {
        label: "Updated At",
        field: "updated_at",
        type: DATETIME_FILTER,
        operators: [AFTER_OPERATOR, BEFORE_OPERATOR],
        single_option: true,
      },
    ],
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return {
    columns,
    filters,
    actions,
    searchFields,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    toBeDeleted,
  };
};

export default useStatusCommonTable;
