import {
  DESKTOP_DEVICE,
  LAPTOP_DEVICE,
  MOBILE_DEVICE,
  TABLET_DEVICE,
} from "shared/helpers/constant";
import { DeviceType } from "shared/types";

export default function findDeviceType(width: number): DeviceType {
  if (width >= 0 && width <= 767) {
    return MOBILE_DEVICE;
  } else if (width >= 768 && width <= 991) {
    return TABLET_DEVICE;
  } else if (width >= 992 && width <= 1366) {
    return LAPTOP_DEVICE;
  } else if (width >= 1366) {
    return DESKTOP_DEVICE;
  } else {
    return DESKTOP_DEVICE;
  }
}
