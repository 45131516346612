import React from "react";

import clsx from "clsx";

import { colorClassFromName } from "shared/helpers/util";

import { AvatarData } from "./types";

const Avatar = ({
  defaultName,
  name,
  url,
  avatarType,
  onClick,
  size = "regular",
  avatarFor,
  className,
}: AvatarData) => {
  const { backgroundColor, color } =
    avatarFor === "user"
      ? colorClassFromName(name || "")
      : { backgroundColor: "bg-brightgray", color: "text-mirage" };

  return (
    <button onClick={onClick} className={clsx(`avatar_v2_${size} border-none`)} type="button">
      {url ? (
        <img
          className={`h-full w-full avatar_v2_${avatarType} ${className}`}
          src={url}
          alt={name || "avatar"}
          loading="lazy"
        />
      ) : (
        <div
          className={`avatar_v2 avatar_v2_${avatarType} ${backgroundColor} ${color} ${className}`}
        >
          {name ? (defaultName ? defaultName : name[0].toUpperCase()) : ""}
        </div>
      )}
    </button>
  );
};

export default Avatar;
