/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourceRequest
 */
export interface SourceRequest {
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    form?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    pdfUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    celexNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof SourceRequest
     */
    objectType: string;
}

/**
 * Check if a given object implements the SourceRequest interface.
 */
export function instanceOfSourceRequest(value: object): value is SourceRequest {
    if (!('objectId' in value) || value['objectId'] === undefined) return false;
    if (!('objectType' in value) || value['objectType'] === undefined) return false;
    return true;
}

export function SourceRequestFromJSON(json: any): SourceRequest {
    return SourceRequestFromJSONTyped(json, false);
}

export function SourceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'form': json['form'] == null ? undefined : json['form'],
        'url': json['url'] == null ? undefined : json['url'],
        'pdfUrl': json['pdf_url'] == null ? undefined : json['pdf_url'],
        'author': json['author'] == null ? undefined : json['author'],
        'celexNumber': json['celex_number'] == null ? undefined : json['celex_number'],
        'objectId': json['object_id'],
        'objectType': json['object_type'],
    };
}

export function SourceRequestToJSON(value?: SourceRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'form': value['form'],
        'url': value['url'],
        'pdf_url': value['pdfUrl'],
        'author': value['author'],
        'celex_number': value['celexNumber'],
        'object_id': value['objectId'],
        'object_type': value['objectType'],
    };
}

