import React, { useEffect } from "react";

import { trackSignUp } from "shared/analytics";
import getUtmSource from "shared/helpers/getUtmSource";
import { getToken } from "shared/helpers/util";

import SignIn from "modules/auth/signIn/SignIn";

import { setUtmSourceForUser } from "./helpers";

/**
 * GoogleAuthSignIn component to track sign up on google auth signin.
 * If utm_source is passed, it will update the utm_source in the database for the user.
 * Assume that user is already signed up if access token and utm_source is present and
 * component will be unmounted(expecting redirect to home page).
 */
export default function GoogleAuthSignIn() {
  useEffect(() => {
    trackSignUp(window.location.search, "state");
    return () => {
      const token = getToken();
      const utmSource = getUtmSource(window.location.search, "state");
      if (token && utmSource) {
        setUtmSourceForUser(utmSource);
      }
    };
  }, []);

  return <SignIn />;
}
