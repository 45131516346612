import React from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { Modal, Spinner } from "flowbite-react";
import _ from "lodash";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useUpdateCard } from "shared/store/settings";

import { updatePaymentMethod } from "./api";
import { BillingDetailsModalProps, UpdateBillingData } from "./types";

const UpdateCard = ({ billingDetails, refetchBillingDetails }: BillingDetailsModalProps) => {
  const { currentCompany } = useCompany();

  const isUpdateCardModalOpen = useUpdateCard((state) => state.isUpdateCardModalOpen);

  const setIsUpdateCardModalOpen = useUpdateCard((state) => state.setIsUpdateCardModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const {
    isLoading,
    data: response,
    mutate: updatePaymentMethodFn,
  } = useMutation(
    (data: UpdateBillingData) =>
      updatePaymentMethod({ company: currentCompany?.id, data, id: billingDetails?.data.id }),
    {
      onSuccess: () => {
        refetchBillingDetails();
        setIsUpdateCardModalOpen(false);
        toast("Card updated successfully", { type: "success" });
      },
    }
  );

  const stripe = useStripe();

  const elements = useElements();

  const onClick = async () => {
    const cardElement = elements?.getElement("card");
    if (cardElement && stripe) {
      try {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            ..._.omit(billingDetails?.data?.billing_details, ["eu_vat"]),
          },
        });

        toast(error?.message, { type: "error" });

        if (paymentMethod) {
          await updatePaymentMethodFn({
            card: paymentMethod?.card,
          });
        }

        setTimeout(() => cardElement.clear(), 2000);

        const data = await response?.data;

        const confirm = await stripe?.confirmCardPayment(`${data?.clientSecret}`);

        if (confirm?.error) {
          toast("Payment Failed. Try again later.", { type: "error" });
        }
      } catch (error) {
        console.warn(error);
      }
    }
  };

  return (
    <Modal
      root={documentBodyRef}
      dismissible={true}
      show={isUpdateCardModalOpen}
      onClose={() => setIsUpdateCardModalOpen(false)}
      className={clsx(styles.upgrade_card_details, "modal_mobileview_center changepassword_modal")}
    >
      <Modal.Header className="text-lg dark:!border-thunders maxMd:p-4">
        Update Credit Card
      </Modal.Header>
      <Modal.Body className="maxMd:p-4">
        <CardElement
          id="card"
          className={clsx(
            "mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-thunders dark:bg-balticsea dark:text-greychateau mac13Inch:h-[38px] mac13Inch:py-2"
          )}
        />
      </Modal.Body>
      <Modal.Footer className="dark:!border-thunders maxMd:p-4">
        <button className="btn_primary ml-auto maxMd:w-full" onClick={() => onClick()}>
          <Spinner hidden={!isLoading} size="sm" className="mr-2 !fill-mirage stroke-mirage" />
          {isLoading ? "Updating" : "Update"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateCard;
