import React, { Suspense, useEffect, useRef } from "react";
import { Route, Routes } from "react-router-dom";

import CanAccess from "shared/components/can-access/CanAccess";
import CheckPermission from "shared/components/check-permission";
import NotFound from "shared/components/not-found";
import Sidebar from "shared/components/sidebar";
import Spinner from "shared/components/spinner/Spinner";
import { useCompany } from "shared/context/CompanyProvider";
import {
  AGREEMENT_MODULE,
  ARTICLE_MODULE,
  ASSISTANT_MODULE,
  CALENDAR_MODULE,
  DASHBOARD_MODULE,
  DOCUMENT_MODULE,
  NOTE_MODULE,
  POLICY_MODULE,
  TASK_MODULE,
  VIEW_ACTION,
} from "shared/helpers/ability";
import checkEnvVar from "shared/helpers/checkEnvVar";
import { useSidebar } from "shared/store/sidebar";

import EurLexDocument from "modules/articles/eur-lex-document/EurLexDocument";
import DashboardRoutes from "modules/dashboard/DashboardRoutes";
import DocumentViewer from "modules/documents/components/shared/DocumentViewer";
import StripeWrapper from "modules/settings/StripeWrapper";
import SettingsRoutes from "modules/settingsV2/SettingsRoutes";
import Tasks from "modules/tasks";

const Agreement = React.lazy(() => import("modules/agreements"));
const Articles = React.lazy(() => import("modules/articles"));
const Policy = React.lazy(() => import("modules/policies"));
const Assistant = React.lazy(() => import("modules/assistant"));
const Calendar = React.lazy(() => import("modules/calendar"));
const Documents = React.lazy(() => import("modules/documents"));
const NoteRoutes = React.lazy(() => import("modules/notes"));

export default function ModuleRoutes() {
  const mainContentRef = useRef<HTMLDivElement>(null);
  const { currentUserRole } = useCompany();
  const isGuest = currentUserRole === "GUEST";

  const isToggle = useSidebar((state) => state.isToggle);

  useEffect(() => {
    if (isToggle) {
      mainContentRef.current?.classList.add("main-content-toggle");
    } else {
      mainContentRef?.current?.classList.add("main-content-full");
    }
    return () => {
      mainContentRef.current?.classList.remove("main-content-full");
      mainContentRef?.current?.classList.remove("main-content-toggle");
    };
  }, [isToggle]);

  return (
    <>
      <div className="relative flex">
        <Sidebar />
        <div className="main-content" ref={mainContentRef}>
          <Suspense
            fallback={
              <div className="loader">
                <Spinner />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<CheckPermission />} />
              <Route
                path="/settings/*"
                element={
                  <StripeWrapper>
                    <SettingsRoutes />
                  </StripeWrapper>
                }
              />
              {!isGuest && (
                <>
                  <Route
                    path="/dashboard/*"
                    index
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={DASHBOARD_MODULE}>
                        <DashboardRoutes />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/calendar/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={CALENDAR_MODULE}>
                        <Calendar />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/articles/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={ARTICLE_MODULE}>
                        <Articles />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/agreements/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={AGREEMENT_MODULE}>
                        <Agreement />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/document/:id"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={DOCUMENT_MODULE}>
                        <DocumentViewer />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/documents/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={DOCUMENT_MODULE}>
                        <Documents />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/tasks/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={TASK_MODULE}>
                        <Tasks />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/policies/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={POLICY_MODULE}>
                        <Policy />
                      </CanAccess>
                    }
                  />
                  {checkEnvVar("REACT_APP_ENABLE_NEW_ASSISTANT_UI") ? (
                    <Route
                      path="/assistant/*"
                      element={
                        <CanAccess action={VIEW_ACTION} moduleName={ASSISTANT_MODULE}>
                          <Assistant />
                        </CanAccess>
                      }
                    />
                  ) : null}
                  <Route
                    path="/notes/*"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={NOTE_MODULE}>
                        <NoteRoutes />
                      </CanAccess>
                    }
                  />
                  <Route
                    path="/eur-lex/:url"
                    element={
                      <CanAccess action={VIEW_ACTION} moduleName={DOCUMENT_MODULE}>
                        <EurLexDocument />
                      </CanAccess>
                    }
                  />
                </>
              )}
              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  );
}
