import React from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import { Label, Modal, Spinner, TextInput } from "flowbite-react";
import _ from "lodash";

import { useMutation } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";
import { getServerErrors } from "shared/helpers/util";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useAuthProvider } from "shared/store/settings";

import { addFederatedGroup, updateFederatedGroup } from "../api";
import useGetGroupList from "../hooks/useGetGroupList";
import useGroupMappingForm from "../hooks/useGroupMappingForm";
import { GroupModalProps, SSOGroupMappingProps } from "../types";
import Permissions from "./Permissions";
import TeamPermissionGroupMapping from "./TeamPermissionGroupMapping";

const GroupModal = ({ sso, isGroupModalOpen, setIsGroupModalOpen }: GroupModalProps) => {
  const { currentCompany } = useCompany();

  const group = useAuthProvider((state) => state.group);

  const { refetch } = useGetGroupList(sso);

  const setGroup = useAuthProvider((state) => state.setGroup);

  const { documentBodyRef } = useDocumentBodyRef();

  const errorHandler = (error: AxiosError) => {
    return getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
  };

  const successHandler = (message: string) => {
    refetch();
    toast(`${message} Group Successfully.`, { type: "success" });
    setIsGroupModalOpen(false);
    groupMappingForm.resetForm();
  };

  const { isLoading, mutate: ssoGroupMappingMutationFn } = useMutation(
    (data: SSOGroupMappingProps) => addFederatedGroup(data, sso),
    {
      onSuccess: () => successHandler("Added"),
      onError: (error: AxiosError) => errorHandler(error),
    }
  );

  const { isLoading: isUpdating, mutate: ssoGroupManageMutationFn } = useMutation(
    (data: SSOGroupMappingProps) => {
      if (!group?.id) {
        return Promise.reject(new Error("SSO Group is not selected"));
      }
      return updateFederatedGroup({ data, sso, id: group?.id });
    },
    {
      onSuccess: () => {
        successHandler("Updated");
        setGroup(null);
      },
      onError: (error: AxiosError) => errorHandler(error),
    }
  );

  const onMutate = () => {
    const payload = {
      ...groupMappingForm.values,
      company: currentCompany.id,
    };
    if (payload.set_user_as_admin) {
      payload.teams = [];
      payload.permission = null;
    }
    if (_.isEmpty(group)) {
      ssoGroupMappingMutationFn(payload);
    } else {
      ssoGroupManageMutationFn(payload);
    }
  };

  const groupMappingForm = useGroupMappingForm(() => onMutate());

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
    setGroup(null);
    groupMappingForm.resetForm();
  };

  return (
    <div data-testid="group-modal">
      <Modal
        dismissible={true}
        show={isGroupModalOpen}
        onClose={onGroupModalClose}
        size="3xl"
        root={documentBodyRef}
        className="modal_mobileview_center provider-modal"
      >
        <Modal.Header className="dark:!border-thunders">
          <span className="text-lg font-semibold">Add Group Mapping</span>
        </Modal.Header>
        <Modal.Body className="overflow-y-auto md:max-h-[60vh] maxMd:px-4">
          <div className="mb-4">
            <Label value="Name*" className="mb-2 block font-inter-medium text-mirage" />
            <TextInput
              data-testid="group-name"
              placeholder="Enter group name"
              name="name"
              value={groupMappingForm.values.name}
              onChange={groupMappingForm.handleChange}
              color={groupMappingForm.errors.name ? "failure" : "gray"}
            />
          </div>
          <div className="mb-4">
            <Label value="Group ID*" className="mb-2 block font-inter-medium text-mirage" />
            <TextInput
              data-testid="group-id"
              placeholder="Enter group id"
              name="group_id"
              value={groupMappingForm.values.group_id}
              onChange={groupMappingForm.handleChange}
              color={groupMappingForm.errors.group_id ? "failure" : "gray"}
            />
          </div>
          <div className="mb-4">
            <Label value="Admin*" className="mb-2 block font-inter-medium text-mirage" />
            <input
              id="yes"
              type="radio"
              className="radioButtons"
              name="set_user_as_admin"
              onChange={() => groupMappingForm.setFieldValue("set_user_as_admin", true)}
              checked={groupMappingForm.values.set_user_as_admin === true}
            />
            <label
              htmlFor="yes"
              className="ml-2 text-sm font-medium text-mirage dark:text-gray-300"
            >
              Yes
            </label>
            <input
              id="no"
              type="radio"
              className="radioButtons ml-4"
              name="set_user_as_admin"
              onChange={() => groupMappingForm.setFieldValue("set_user_as_admin", false)}
              checked={groupMappingForm.values.set_user_as_admin === false}
            />
            <label htmlFor="no" className="ml-2 text-sm font-medium text-mirage dark:text-gray-300">
              No
            </label>
          </div>
          {!groupMappingForm.values.set_user_as_admin && (
            <div data-testid="perissions">
              <div className="mb-4">
                <TeamPermissionGroupMapping groupMappingForm={groupMappingForm} />
              </div>
              <div>
                <Permissions groupMappingForm={groupMappingForm} />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="dark:!border-thunders maxMd:flex-col maxMd:p-4">
          <button
            className="btn_secondary ml-auto maxMd:mb-4 maxMd:w-full"
            onClick={onGroupModalClose}
          >
            Cancel
          </button>
          <button
            className="btn_primary maxMd:!ml-0 maxMd:w-full"
            onClick={() => groupMappingForm.handleSubmit()}
          >
            <Spinner
              size="sm"
              light={true}
              hidden={!isLoading || !isUpdating}
              className="mr-3 !fill-mirage stroke-mirage"
            />
            {_.isEmpty(group) ? "Save" : "Update"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GroupModal;
