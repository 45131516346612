import { create } from "zustand";

import {
  FilterProps,
  FiltersProp,
  RefetchOptions,
  SearchOptions,
} from "shared/components/common-table/types";

import { AgreementTableColumnProps } from "modules/agreements/overview/types";
import { ArticleTableColumnProps } from "modules/articles/overview/types";
import { PolicyTableColumnProps } from "modules/policies/overview/types";
import { TaskTableColumnProps } from "modules/tasks/overview/types";

export type SelectedColumnModulesProps = {
  article: ArticleTableColumnProps;
  policies: PolicyTableColumnProps;
  task: TaskTableColumnProps;
  agreement: AgreementTableColumnProps;
};

export type TableColumnSelectionProps = {
  article: string;
  policies: string;
  task: string;
  agreement: string;
};

export type TableFilterTypeProps = {
  article?: FiltersProp[];
  policies?: FiltersProp[];
  task?: FiltersProp[];
  agreement?: FiltersProp[];
  agreement_category?: FiltersProp[];
  agreement_type?: FiltersProp[];
  supplier?: FiltersProp[];
  internal_entity?: FiltersProp[];
  document?: FiltersProp[];
  task_status?: FiltersProp[];
  note?: FiltersProp[];
  policy?: FiltersProp[];
  policy_template?: FiltersProp[];
  category?: FiltersProp[];
  community?: FiltersProp[];
};

export type QueryParamsProps = {
  article?: RefetchOptions[];
  policies?: RefetchOptions[];
  task?: RefetchOptions[];
  agreement?: RefetchOptions[];
  agreement_category?: RefetchOptions[];
  agreement_type?: RefetchOptions[];
  supplier?: RefetchOptions[];
  internal_entity?: RefetchOptions[];
  document?: RefetchOptions[];
  task_status?: RefetchOptions[];
  note?: RefetchOptions[];
  policy?: RefetchOptions[];
  policy_template?: RefetchOptions[];
  category?: RefetchOptions[];
  mitigation?: RefetchOptions[];
  risk?: RefetchOptions[];
  incident?: RefetchOptions[];
  community?: RefetchOptions[];
  agreement_enterprise_validation?: RefetchOptions[];
};

export type SearchDataProps = {
  article?: SearchOptions;
  policies?: SearchOptions;
  task?: SearchOptions;
  agreement?: SearchOptions;
  agreement_category?: SearchOptions;
  agreement_type?: SearchOptions;
  supplier?: SearchOptions;
  internal_entity?: SearchOptions;
  document?: SearchOptions;
  task_status?: SearchOptions;
  note?: SearchOptions;
  policy?: SearchOptions;
  policy_template?: SearchOptions;
  category?: SearchOptions;
  mitigation?: SearchOptions;
  risk?: SearchOptions;
  incident?: SearchOptions;
  community?: SearchOptions;
};

export type SuggestionDataProps = {
  article?: SearchOptions[];
  policies?: SearchOptions[];
  task?: SearchOptions[];
  agreement?: SearchOptions[];
  agreement_category?: SearchOptions[];
  agreement_type?: SearchOptions[];
  supplier?: SearchOptions[];
  internal_entity?: SearchOptions[];
  document?: SearchOptions[];
  task_status?: SearchOptions[];
  note?: SearchOptions[];
  policy?: SearchOptions[];
  policy_template?: SearchOptions[];
  category?: SearchOptions[];
  mitigation?: SearchOptions[];
  risk?: SearchOptions[];
  incident?: SearchOptions[];
  community?: SearchOptions[];
};

export type CompanyType = number;

export type CompanySuggestionsDataProps = {
  [companyId: CompanyType]: SuggestionDataProps[];
};

export type TableFirstFilterTypeProps = {
  article?: boolean;
  policies?: boolean;
  task?: boolean;
  agreement?: boolean;
  agreement_category?: boolean;
  agreement_type?: boolean;
  supplier?: FiltersProp[];
  internal_entity?: boolean;
  document?: boolean;
  task_status?: boolean;
  note?: boolean;
  policy?: boolean;
  policy_template?: boolean;
  category?: boolean;
  community?: boolean;
};

export type TableColumnSelection = {
  selectedColumnModules: SelectedColumnModulesProps | null;
  setSelectedColumnForModules: (payload: SelectedColumnModulesProps) => void;
};

export type TableViewType = {
  selectedViewModules: TableColumnSelectionProps | null;
  setSelectedViewForModules: (payload: TableColumnSelectionProps) => void;
};

export type TableFilterType = {
  selectedFilters: TableFilterTypeProps | null;
  setSelectedFiltersForModules: (payload: TableFilterTypeProps | null) => void;
  setSelectedFilters: (
    fn: (prev: TableFilterTypeProps | null) => TableFilterTypeProps | null
  ) => void;
  hasFirstFilter: TableFirstFilterTypeProps | null;
  setFirstFilterForModules: (policies: TableFirstFilterTypeProps | null) => void;
  resetFirstFilterForModules: (
    fn: (prev: TableFirstFilterTypeProps | null) => TableFirstFilterTypeProps | null
  ) => void;
};

export type TableFavoriteFilterType = {
  isModalOpen: boolean;
  selectedFilters: FilterProps;
  setSelectedFilters: (fn: (prev: FilterProps) => FilterProps) => void;
  setIsModalOpen: (fn: (prev: boolean) => boolean) => void;
};

export const useTableColumnSelection = create<TableColumnSelection>((set) => ({
  selectedColumnModules: null,
  setSelectedColumnForModules: (payload: SelectedColumnModulesProps) =>
    set((state) => ({
      selectedColumnModules: { ...state.selectedColumnModules, ...payload },
    })),
}));

export const useTableViewType = create<TableViewType>((set) => ({
  selectedViewModules: null,
  setSelectedViewForModules: (payload: TableColumnSelectionProps) =>
    set((state) => ({
      selectedViewModules: { ...state.selectedViewModules, ...payload },
    })),
}));

export const useTableFilter = create<TableFilterType>((set) => ({
  selectedFilters: null,
  setSelectedFiltersForModules: (selectedFilters: TableFilterTypeProps | null) =>
    set({ selectedFilters }),

  setSelectedFilters: (fn: (prev: TableFilterTypeProps | null) => TableFilterTypeProps | null) => {
    set((state) => ({ selectedFilters: fn(state.selectedFilters) }));
  },

  hasFirstFilter: null,
  setFirstFilterForModules: (hasFirstFilter: TableFirstFilterTypeProps | null) =>
    set({ hasFirstFilter }),

  resetFirstFilterForModules: (
    fn: (prev: TableFirstFilterTypeProps | null) => TableFirstFilterTypeProps | null
  ) => {
    set((state) => ({ hasFirstFilter: fn(state.hasFirstFilter) }));
  },
}));

export const useFavoriteFilter = create<TableFavoriteFilterType>((set) => ({
  isModalOpen: false,
  selectedFilters: {} as FilterProps,
  setSelectedFilters: (fn: (prev: FilterProps) => FilterProps) => {
    set((state) => ({ selectedFilters: fn(state.selectedFilters) }));
  },
  setIsModalOpen: (fn: (value: boolean) => boolean) => {
    set((state) => ({ isModalOpen: fn(state.isModalOpen) }));
  },
}));

type QueryParams = {
  selectedQueryParams: QueryParamsProps;
  setSelectedQueryParams: (payload: QueryParamsProps) => void;
  resetSelectedQueryParams: (payload: QueryParamsProps) => void;
};

export const useQueryParams = create<QueryParams>((set) => ({
  selectedQueryParams: {} as QueryParamsProps,
  setSelectedQueryParams: (payload: QueryParamsProps) =>
    set((state) => ({
      selectedQueryParams: { ...state.selectedQueryParams, ...payload },
    })),
  resetSelectedQueryParams: (payload: QueryParamsProps) => {
    set({ selectedQueryParams: payload });
  },
}));

type SearchSuggestionDataType = {
  searchData: SearchDataProps;
  setSearchData: (searchData: SearchDataProps) => void;
};

export const useSearchSuggestion = create<SearchSuggestionDataType>((set) => ({
  searchData: {},
  setSearchData: (searchData) => set({ searchData: searchData }),
}));
