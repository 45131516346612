import React from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "flowbite-react";
import moment from "moment";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";

import { getNameForIcon } from "modules/settingsV2/helper";

import { CompanyGroupResponse } from "../types";

export default function GroupListing({ groupsData }: { groupsData?: Array<CompanyGroupResponse> }) {
  const navigate = useNavigate();

  return (
    <div>
      <Table className="min-w-[1234px] md:min-w-[900px] ">
        <Table.Head className={styles.table_header}>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Date Created</Table.HeadCell>
        </Table.Head>
        {groupsData && groupsData?.length > 0 ? (
          <Table.Body className="divide-y pl-4">
            {groupsData?.map((item: CompanyGroupResponse, index: number) => {
              return (
                <Table.Row
                  className="cursor-pointer bg-white"
                  onClick={() => navigate(`${item.id}/details`)}
                  key={index}
                >
                  <Table.Cell>
                    <div className="flex items-center">
                      <Avatar
                        name={getNameForIcon(item.name || "")}
                        defaultName={getNameForIcon(item.name || "")}
                        avatarFor="user"
                        avatarType="squared"
                        size="md"
                        url={item.group_picture}
                        className="cursor-default"
                      />

                      <div className="grid pl-6">
                        <span className="truncate font-inter-semibold text-base text-mirage dark:text-white">
                          {item.name}
                        </span>
                        <span className="email_ellipsis text-davygrey dark:text-boulder">
                          {item.users.length} members
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <span className="text-aurometalsaurus dark:text-greychateau">
                      {moment(item.created_at).format("YYYY-MM-DD")}
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Table.Row className="border-b bg-white dark:border-thunders dark:bg-darkjunglegreenss">
            <Table.Cell
              className="items-baseline !px-0 py-6 text-center text-sm font-medium dark:border-thunders dark:text-greychateau"
              colSpan={4}
            >
              No Member Available
            </Table.Cell>
          </Table.Row>
        )}
      </Table>
    </div>
  );
}
