import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import { settingsAuthProviderData } from "shared/data/initialsdataForForm";
import { useAuthProvider } from "shared/store/settings";

import { SSOProviderProps } from "../types";
import { addProviderSchema } from "../validation";

const useAddProviderForm = (action: CallableFunction) => {
  const provider = useAuthProvider((state) => state.provider);

  return useFormik<SSOProviderProps>({
    initialValues: !isEmpty(provider) ? provider : settingsAuthProviderData,
    validateOnChange: false,
    validationSchema: addProviderSchema,
    enableReinitialize: true,
    onSubmit: () => action(),
  });
};

export default useAddProviderForm;
