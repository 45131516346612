import { useState } from "react";

import { useQuery } from "@tanstack/react-query";

import { GetTaskResultType } from "shared/types";
import { TeamParameter } from "shared/types";

import hasRelatedObject from "../helpers/hasRelatedObject";
import loadRelatedObject from "../helpers/loadRelatedObject";
import { RelatedObjectType } from "../types";

/**
 * Hook for loading related object for task if it has one and it is not loaded yet.
 */
export default function useLoadRelatedObject(
  task: GetTaskResultType | null,
  team: TeamParameter,
  relatedObject?: RelatedObjectType
): RelatedObjectType | undefined {
  const [loadedObject, setLoadedObject] = useState<RelatedObjectType | undefined>(relatedObject);

  useQuery(["relatedObject", task?.id], async () => loadRelatedObject(task, team), {
    enabled: hasRelatedObject(task) && !relatedObject,
    onSuccess: (data) => {
      setLoadedObject(data);
    },
  });

  return loadedObject;
}
