import { Dispatch, SetStateAction } from "react";

import clsx from "clsx";
import { Button, Modal } from "flowbite-react";

import styles from "assets/css/settings.module.css";

interface DeleteModalProp {
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  isDeleteModalOpen: boolean;
}

const DeleteModal = ({ setIsDeleteModalOpen, isDeleteModalOpen }: DeleteModalProp) => {
  return (
    <Modal
      show={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header className={clsx(styles.account_header, "dark:!border-thunders")}>
        Delete Group
      </Modal.Header>
      <Modal.Body className="maxMd:p-4">
        <div>
          <p className="text-base leading-relaxed text-gray-500 dark:text-greychateau mac13Inch:text-sm">
            Are you sure you want to delete this group? It cannot be reverted.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex-wrap-reverse justify-end dark:!border-thunders maxMd:p-4 mac13Inch:py-4">
        <button
          className="btn_secondary font-inter-medium maxMd:mt-[18px] maxMd:w-full"
          onClick={() => setIsDeleteModalOpen(false)}
        >
          No, Cancel
        </button>
        <Button className="font-xs-mac13 !rounded-full !bg-lavared font-inter-medium hover:!bg-red-800 focus:!ring-0 focus:!ring-transparent md:!ml-4 maxMd:!ml-0 maxMd:w-full">
          Yes, I’m sure
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
