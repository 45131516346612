import { cn } from "lib/utils";
import { useDarkMode } from "usehooks-ts";

import Icon from "shared/components/icon/Icon";
import Badge from "shared/componentsV2/form-controls/badge";

import { useArticleTodos } from "modules/horizonScanning/hooks/useArticleTodos";

import { useArticleContext } from "../../contexts/ArticleContext";
import { TodoItemIcon } from "./article-to-dos-modal/TodoItemIcon";

const ArticleHeader = () => {
  const {
    articleQuery: { data: article },
    isPublic,
    setShowToDosModal,
  } = useArticleContext();
  const { isDarkMode } = useDarkMode();

  if (!article) return null;

  const { totalTodosLength, completedTodosLength } = useArticleTodos();

  return (
    <div className="grid gap-3">
      {article.title && (
        <h1
          className={cn(
            "text-xl font-semibold text-richblack dark:text-lighthouse",
            isPublic ? "md:text-4xl" : "text-xl"
          )}
        >
          {article.title}
        </h1>
      )}
      {article.originalTitle && (
        <div className="flex gap-1 text-xs">
          <span className="font-semibold dark:text-gray-400">Original title:</span>
          <span className="font-normal dark:text-gray-500">{article.originalTitle}</span>
        </div>
      )}
      <div className="flex gap-2">
        {!isPublic && article.hasClassification && (
          <Badge
            badgeColor={isDarkMode ? "#9CA3AF" : "#4B5563"}
            icon={<Icon type="squares-check" size="icon-xxs" />}
            badgeTitle="Classified"
          />
        )}
        {!isPublic && (
          <Badge
            className="cursor-pointer !border-brightgray dark:border-thunders"
            icon={
              <TodoItemIcon
                completedTodosLength={completedTodosLength}
                totalTodosLength={totalTodosLength}
                size="icon-xxs"
              />
            }
            badgeColor={isDarkMode ? "#9CA3AF" : "#4B5563"}
            badgeBgColor="transparent"
            badgeTitle={
              <div className="flex cursor-pointer gap-1">
                To-do
                <span className="text-newcar">{`${completedTodosLength}/${totalTodosLength}`}</span>
              </div>
            }
            badgeType="secondary"
            onClick={() => {
              setShowToDosModal(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ArticleHeader;
