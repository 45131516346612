/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `eurlex` - Eurlex
 * * `copilot` - Copilot
 * * `roi` - ROI
 * * `agent` - Agent
 * @export
 */
export const ChatTypeEnum = {
    Eurlex: 'eurlex',
    Copilot: 'copilot',
    Roi: 'roi',
    Agent: 'agent'
} as const;
export type ChatTypeEnum = typeof ChatTypeEnum[keyof typeof ChatTypeEnum];


export function instanceOfChatTypeEnum(value: any): boolean {
    for (const key in ChatTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ChatTypeEnum, key)) {
            if (ChatTypeEnum[key as keyof typeof ChatTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChatTypeEnumFromJSON(json: any): ChatTypeEnum {
    return ChatTypeEnumFromJSONTyped(json, false);
}

export function ChatTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatTypeEnum {
    return json as ChatTypeEnum;
}

export function ChatTypeEnumToJSON(value?: ChatTypeEnum | null): any {
    return value as any;
}

