/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PatchedTaskRequestPriority } from './PatchedTaskRequestPriority';
import {
    PatchedTaskRequestPriorityFromJSON,
    PatchedTaskRequestPriorityFromJSONTyped,
    PatchedTaskRequestPriorityToJSON,
} from './PatchedTaskRequestPriority';

/**
 * 
 * @export
 * @interface PatchedTaskRequest
 */
export interface PatchedTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    feedArticle?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatchedTaskRequest
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {PatchedTaskRequestPriority}
     * @memberof PatchedTaskRequest
     */
    priority?: PatchedTaskRequestPriority | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedTaskRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTaskRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    createdBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    team?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    owner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    status?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    agreement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    policy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    article?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    directory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    document?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    note?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedTaskRequest
     */
    riskv2?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedTaskRequest
     */
    assignees?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedTaskRequest
     */
    mentions?: Array<number>;
}

/**
 * Check if a given object implements the PatchedTaskRequest interface.
 */
export function instanceOfPatchedTaskRequest(value: object): value is PatchedTaskRequest {
    return true;
}

export function PatchedTaskRequestFromJSON(json: any): PatchedTaskRequest {
    return PatchedTaskRequestFromJSONTyped(json, false);
}

export function PatchedTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTaskRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'feedArticle': json['feed_article'] == null ? undefined : json['feed_article'],
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'priority': json['priority'] == null ? undefined : PatchedTaskRequestPriorityFromJSON(json['priority']),
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'createdBy': json['created_by'] == null ? undefined : json['created_by'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'team': json['team'] == null ? undefined : json['team'],
        'owner': json['owner'] == null ? undefined : json['owner'],
        'status': json['status'] == null ? undefined : json['status'],
        'agreement': json['agreement'] == null ? undefined : json['agreement'],
        'policy': json['policy'] == null ? undefined : json['policy'],
        'article': json['article'] == null ? undefined : json['article'],
        'directory': json['directory'] == null ? undefined : json['directory'],
        'document': json['document'] == null ? undefined : json['document'],
        'note': json['note'] == null ? undefined : json['note'],
        'riskv2': json['riskv2'] == null ? undefined : json['riskv2'],
        'assignees': json['assignees'] == null ? undefined : json['assignees'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function PatchedTaskRequestToJSON(value?: PatchedTaskRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'feed_article': value['feedArticle'],
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'priority': PatchedTaskRequestPriorityToJSON(value['priority']),
        'name': value['name'],
        'description': value['description'],
        'created_by': value['createdBy'],
        'updated_by': value['updatedBy'],
        'team': value['team'],
        'owner': value['owner'],
        'status': value['status'],
        'agreement': value['agreement'],
        'policy': value['policy'],
        'article': value['article'],
        'directory': value['directory'],
        'document': value['document'],
        'note': value['note'],
        'riskv2': value['riskv2'],
        'assignees': value['assignees'],
        'mentions': value['mentions'],
    };
}

