import React, { PropsWithChildren } from "react";

import isUndefined from "lodash/isUndefined";

import { useQuery } from "@tanstack/react-query";

import me from "modules/home/api/me";
import { UserResponse } from "modules/home/types";

interface ProviderData {
  user: UserResponse;
}

export const UserContext = React.createContext({} as ProviderData);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { data: response } = useQuery(["getUser"], me);

  if (isUndefined(response)) {
    return null;
  }
  return <UserContext.Provider value={{ user: response.data }}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  return React.useContext(UserContext);
};
