import React, { useState } from "react";

import clsx from "clsx";

import { useQuery } from "@tanstack/react-query";

import headerStyles from "assets/css/common-header.module.css";

import ActivityLogList from "shared/components/activity-log";

import { getActivityLog } from "../api";
import { ActivityType, activityProps } from "../types";
import RevertVersionModal from "./ActivityRevertModel";

const ActivityLog = ({ id, type, teamId, companyId, isAllTeam }: activityProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [versionId, setVersionId] = useState<number>();

  const {
    data: activityLog,
    isLoading,
    isFetching,
  } = useQuery(
    ["getActivityLog", [], teamId],
    () => getActivityLog(type, id, { teamId: teamId, companyId: companyId, isAllTeam: isAllTeam }),
    {
      enabled: !!teamId,
    }
  );

  const onItemClick = (flag: boolean, activity: ActivityType) => {
    setVersionId(activity?.version_id);
    setIsModalOpen(flag);
  };

  return (
    <div className="metadata-tabs">
      <h1 className={clsx(headerStyles.subscriptionTitle, "metadata-tabs__header")}>
        Activity Log
      </h1>
      <ActivityLogList
        activityLog={activityLog?.data || []}
        isLoading={isLoading || isFetching}
        onItemClick={onItemClick}
      />

      <RevertVersionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        versionId={versionId || 0}
        id={`${id}`}
        permissions={{ teamId: teamId, companyId: companyId, isAllTeam: isAllTeam }}
        type={type}
      />
    </div>
  );
};

export default ActivityLog;
