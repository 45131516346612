import React, { FC, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import { Label, Spinner, TextInput } from "flowbite-react";
import { usePostHog } from "posthog-js/react";

import { useMutation } from "@tanstack/react-query";

import Icon from "shared/components/icon/Icon";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { useMenu } from "shared/store/menu";
import { DropdownOptionType } from "shared/types";

import CompanyInfo from "./CompanyInfo";
import { createCompany } from "./api";
import useCompanyForm from "./hooks/useCompanyForm";
import { CompanyRequestParams, CreateCompanyProps } from "./types";

const CreateCompany: FC<CreateCompanyProps> = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();

  const uploadImageRef = useRef<HTMLInputElement>(null);

  const [picturePath, setPicturePath] = useState<File | null>();

  const setIsMenuShown = useMenu((state) => state.setIsMenuShown);

  const [selectedIndustries, setSelectedIndustries] = useState<DropdownOptionType[]>([]);

  const [selectedCountries, setSelectedCountries] = useState<DropdownOptionType[]>([]);

  const { addCompanyList, companies } = useCompany();
  const {
    isLoading,
    mutate: companyFn,
    isError,
    error,
  } = useMutation((data: CompanyRequestParams) => createCompany(data, picturePath), {
    onSuccess: (res) => {
      posthog.capture("companyCreated");
      addCompanyList(res.data);
      setIsMenuShown(false);

      const params = new URLSearchParams({ company: `${res.data.id}` });
      navigate(`?${params.toString()}`);
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, errors, handleChange, setFieldValue } = useCompanyForm(() => {
    companyFn(values);
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file = target?.files ? target?.files[0] : null;
    setPicturePath(file);
  };

  return (
    <section className="auth-container">
      <div className="card card-auth">
        <h2 className="title-lg mb-3 font-sans-medium dark:text-white md:text-center maxMd:text-2xl mac13Inch:mb-0 mac13Inch:text-[24px]">
          Create a Company
        </h2>
        <p className="max-w-[22.813rem] text-aurometalsaurus dark:text-greychateau md:mx-auto md:block md:text-center mac13Inch:text-sm">
          Enter some details for your company
        </p>
        <form data-testid="create-company-form" onSubmit={handleSubmit}>
          <div>
            <input
              type="file"
              name="image"
              style={{ display: "none" }}
              ref={uploadImageRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChange(e)}
            />
          </div>
          {picturePath ? (
            <img
              alt="company-icon"
              className="mx-auto mt-4 flex h-[6.25rem] w-[6.25rem] items-center justify-center rounded md:mt-5 mac13Inch:h-[75px] mac13Inch:w-[75px]"
              src={URL.createObjectURL(picturePath)}
            />
          ) : null}
          {!picturePath ? (
            <div
              className={
                "mx-auto mt-4 flex h-[6.25rem] w-[6.25rem] cursor-pointer items-center justify-center rounded bg-antiflashwhite dark:bg-iridium md:mt-5 maxMd:h-[70px] maxMd:w-full mac13Inch:mt-4 mac13Inch:h-[75px] mac13Inch:w-[75px]"
              }
              onClick={() => uploadImageRef?.current?.click()}
            >
              <h2 className="text-[2.5rem] font-bold dark:text-white mac13Inch:text-[24px]">C</h2>
            </div>
          ) : null}
          <button
            type="button"
            className="mx-auto mt-4 block text-sm text-aurometalsaurus dark:text-greychateau mac13Inch:mt-3 mac13Inch:text-xs"
            onClick={() => uploadImageRef?.current?.click()}
          >
            Choose icon
          </button>
          <div className="mb-4 mt-[3.125rem] mac13Inch:mb-3 mac13Inch:mt-4">
            <Label value="Company Name*" className="mb-2 block font-inter-medium !text-mirage" />
            <TextInput
              placeholder="Enter company name"
              data-testid="company-name-input"
              name="name"
              value={values.name}
              onChange={handleChange}
              color={errors.name ? "failure" : "gray"}
            />
          </div>
          <CompanyInfo
            setFieldValue={setFieldValue}
            selectedIndustries={selectedIndustries}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            setSelectedIndustries={setSelectedIndustries}
          />
          {isError ? <ServerErrors className="mb-4" error={error} /> : null}
          <button
            data-testid="create-company-btn"
            type="submit"
            disabled={isLoading}
            className="btn_primary mt-4 w-full text-base md:py-3.5 mac13Inch:mt-3 mac13Inch:text-xs"
          >
            <Spinner
              size="md"
              light={true}
              hidden={!isLoading}
              className="mr-3 fill-crayolasblue stroke-crayolasblue"
            />
            Continue
          </button>
          {companies.length > 0 ? (
            <div
              data-testid={"back-to-dashboard"}
              className="mt-4 flex items-center justify-center mac13Inch:mt-3"
            >
              <Icon type="left" fill={false} size="icon-xs" className="themestroke_white mr-1" />
              <Link
                className="ml-1 font-inter-medium text-sm text-mirage underline hover:no-underline dark:text-white mac13Inch:text-xs"
                to="/"
              >
                Back to Dashboard
              </Link>
            </div>
          ) : null}
        </form>
      </div>
    </section>
  );
};

export default CreateCompany;
