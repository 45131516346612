import React from "react";

import clsx from "clsx";
import { Modal, Sidebar, Tooltip } from "flowbite-react";
import truncate from "lodash/truncate";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import {
  AppearanceIcon,
  BelllIcon,
  CreditCardIcon,
  InstitutionTypeIcon,
  MemberIcon,
  MyAccountIcon,
  SettingIcon,
  TeamsIcon,
  UpgradeIcon,
} from "shared/components/icons/Icons";
import { useCompany } from "shared/context/CompanyProvider";
import checkEnvVar from "shared/helpers/checkEnvVar";
import {
  APPEARANCE,
  AUTHENTICATION,
  BILLING,
  COMPANY,
  INSTITUTION_TYPES,
  MEMBERS,
  MY_ACCOUNT,
  NOTIFICATION,
  TEAMS,
  UPGRADE,
  hideSettingsModuleWithEnterprise,
  showSettingsModuleWithEnterprise,
} from "shared/helpers/constant";
import { getUsername } from "shared/helpers/util";
import { useHome } from "shared/store/home";
import { useSettings } from "shared/store/settings";

import Appearance from "./appearance";
import Authentication from "./authentication/Authentication";
import Billing from "./billing/Billing";
import SettingCustom from "./company/CompanySettings";
import Members from "./members/Members";
import { MyAccount } from "./my-account/MyAccount";
import { Notification } from "./notification/Notification";
import TeamsSettings from "./teams/TeamsSettings";
import Upgrade from "./upgrade/Upgrade";

export interface SettingProps {
  title: string;
  name: string;
  icon?: React.ReactElement;
  slug: string;
}

const ProfilePicture = () => {
  const profile = useHome((state) => state.profile);

  return (
    <div className="flex md:mr-2">
      <Avatar
        name={getUsername(profile)}
        avatarFor="user"
        avatarType="squared"
        size="sm"
        url={profile?.profile_picture}
        className="cursor-default"
      />
    </div>
  );
};

const settings = [
  {
    title: "My Account",
    name: "My Account",
    icon: <MyAccountIcon />,
    slug: MY_ACCOUNT,
    stripeIncluded: false,
  },
  {
    title: "My Settings",
    name: "My Settings",
    icon: <SettingIcon />,
    slug: COMPANY,
    stripeIncluded: false,
  },
  {
    title: "Notification Settings",
    name: "Notifications Settings",
    icon: <BelllIcon />,
    slug: NOTIFICATION,
    stripeIncluded: false,
  },
  {
    title: "Appearance",
    name: "Appearance",
    icon: <AppearanceIcon />,
    slug: APPEARANCE,
    stripeIncluded: false,
  },
  {
    title: "Upgrade",
    name: "Upgrade",
    icon: <UpgradeIcon />,
    slug: UPGRADE,
    stripeIncluded: true,
  },
  {
    title: "Billing",
    name: "Billing",
    icon: <CreditCardIcon />,
    slug: BILLING,
    stripeIncluded: true,
  },
];

const companySettings = [
  {
    title: "Teams",
    name: "Teams",
    icon: <TeamsIcon />,
    slug: TEAMS,
    stripeIncluded: false,
  },
  {
    title: "Members",
    name: "Members",
    icon: <MemberIcon />,
    slug: MEMBERS,
    stripeIncluded: false,
  },
  {
    title: "Institution Types",
    name: "Institution Types",
    icon: <InstitutionTypeIcon />,
    slug: INSTITUTION_TYPES,
    stripeIncluded: false,
  },
];
const Settings = () => {
  const profile = useHome((state) => state.profile);
  const sidebarTitle = useSettings((state) => state.sidebarTitle);
  const setSidebarTitle = useSettings((state) => state.setSidebarTitle);
  const isModalOpen = useSettings((state) => state.isModalOpen);
  const setIsModalOpen = useSettings((state) => state.setIsModalOpen);
  const screen = useSettings((state) => state.screen);
  const setScreen = useSettings((state) => state.setScreen);

  const { currentCompany } = useCompany();

  const BodyComponent = () => {
    switch (screen) {
      case MY_ACCOUNT:
        return <MyAccount />;
      case MEMBERS:
        return <Members />;
      case NOTIFICATION:
        return <Notification />;
      case APPEARANCE:
        return <Appearance />;
      case COMPANY:
        return <SettingCustom />;
      case TEAMS:
        return <TeamsSettings />;
      case UPGRADE:
        return <Upgrade />;
      case BILLING:
        return <Billing />;
      case AUTHENTICATION:
        return <Authentication />;
      default:
        return <MyAccount />;
    }
  };

  const onClick = (item: SettingProps) => {
    setScreen(item.slug);
    setSidebarTitle(item.title);
  };

  const { isAdmin } = useCompany();

  return (
    <Modal
      show={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className={styles.settingModal}
      dismissible={true}
    >
      <div className="flex overflow-hidden maxMd:h-full maxMd:flex-col">
        <div className="w-fit maxMd:h-[68px] maxMd:w-full">
          <Sidebar
            aria-label="Default sidebar example"
            className="sidebar_setting justify-end !space-y-0 dark:border-thunders maxMd:flex maxMd:w-full maxMd:border-b"
          >
            <h4 className="mb-6 pl-3.5 text-sm text-santagrey dark:text-boulder maxMd:hidden">
              Account
            </h4>
            <Sidebar.Items>
              <Sidebar.ItemGroup className={clsx(styles.sidebar_custom, "sidebar_customs")}>
                <Sidebar.Item className="custom_settings maxMd:hidden">
                  <div className="flex items-center">
                    <ProfilePicture />
                    <Tooltip
                      content={profile?.email}
                      className="custom_tooltip maxMd:hidden"
                      style="dark"
                    >
                      <h4 className="email_truncat w-[170px]">{truncate(profile?.email)}</h4>
                    </Tooltip>
                  </div>
                </Sidebar.Item>
                {settings
                  .slice(0, 2)
                  .filter((item) => {
                    if (!item.stripeIncluded) return item;

                    if (checkEnvVar("REACT_APP_STRIPE_ENABLE")) {
                      return item;
                    }
                    return null;
                  })
                  .map((item, index) => (
                    <Sidebar.Item
                      key={index}
                      className={clsx(
                        { active: screen === item.slug },
                        "custom_settings cursor-pointer !text-aurometalsaurus dark:!text-greychateau"
                      )}
                      onClick={() => onClick(item)}
                    >
                      {item.icon}
                      <span className="md:pr-4 maxMd:hidden">{item.name}</span>
                    </Sidebar.Item>
                  ))}

                {isAdmin ? (
                  <>
                    {settings
                      .slice(2)
                      ?.filter((item) => {
                        if (checkEnvVar("REACT_APP_STRIPE_ENABLE") || !item.stripeIncluded) {
                          return currentCompany?.is_enterprise
                            ? !hideSettingsModuleWithEnterprise?.includes(item.slug)
                            : !showSettingsModuleWithEnterprise?.includes(item.slug);
                        }
                      })
                      .map((item, index) => (
                        <Sidebar.Item
                          key={index}
                          className={clsx(
                            { active: screen === item.slug },
                            "custom_settings inline-block cursor-pointer !text-aurometalsaurus dark:!text-greychateau"
                          )}
                          onClick={() => onClick(item)}
                        >
                          {item.icon}
                          <span className="maxMd:hidden">{item.name}</span>
                        </Sidebar.Item>
                      ))}
                  </>
                ) : null}
                <Tooltip
                  content={currentCompany?.name}
                  className="custom_tooltip maxMd:hidden"
                  style="dark"
                >
                  <h4 className="mt-4 cursor-default p-2 text-sm text-santagrey dark:text-boulder maxMd:hidden">
                    Company({truncate(currentCompany?.name)})
                  </h4>
                </Tooltip>

                {companySettings.map((item, index) => (
                  <Sidebar.Item
                    key={index}
                    className={clsx(
                      { active: screen === item.slug },
                      "custom_settings cursor-pointer !text-aurometalsaurus dark:!text-greychateau"
                    )}
                    onClick={() => onClick(item)}
                  >
                    {item.icon}
                    <span className="md:pr-4 maxMd:hidden">{item.name}</span>
                  </Sidebar.Item>
                ))}
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </div>
        <div className="setting-modal-height-full w-full overflow-hidden overflow-y-auto dark:border-thunders md:max-h-[715px] md:border-l md:border-brightgray">
          <div className="seeting-mobile maxMd:overflow-y-auto">
            <Modal.Header className={clsx(styles.settingModalHeader, "dark:!border-thunders")}>
              {sidebarTitle}
            </Modal.Header>
            <Modal.Body className={styles.settingModalBody}>
              <BodyComponent />
            </Modal.Body>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
