import { AxiosError, isAxiosError } from "axios";
import { FormikProps, useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";

import { MetaTable, PermissionCreatedResponse } from "shared/components/metadata/types";
import { useTeam } from "shared/store/settings";

import { RelatedObjectType } from "modules/tasks/overview/types";

import { createPermission } from "../../api";
import { FULL_ACCESS } from "../../constants";
import isPermissionModule from "../../helpers/isPermissionModule";
import { PermissionFormValues } from "./types";
import { createPermissionSchema } from "./validation";

export default function usePermissionForm({
  type,
  relatedObject,
  onSuccess: handleSuccess,
  onError: handleError,
}: {
  type: MetaTable;
  relatedObject: RelatedObjectType;
  onSuccess?: (data: PermissionCreatedResponse) => void;
  onError?: (error: Error) => void;
}): FormikProps<PermissionFormValues> {
  const { currentTeam } = useTeam();
  const mutation = useMutation({
    mutationFn: (values: PermissionFormValues) => {
      const objectId = relatedObject.id;

      if (!isPermissionModule(type)) {
        return Promise.reject(new Error("Invalid permission module"));
      }

      if (!objectId) {
        return Promise.reject(new Error("Invalid object id"));
      }

      return createPermission(type, objectId, values, {
        team: currentTeam.id,
        isAllTeam: currentTeam.is_all_team,
        company: currentTeam.company,
      });
    },
  });

  const formik = useFormik<PermissionFormValues>({
    initialValues: {
      level: FULL_ACCESS,
    },
    validationSchema: createPermissionSchema,
    onSubmit: (values, formikHelpers) => {
      return mutation
        .mutateAsync(values)
        .then((response) => {
          formikHelpers.resetForm();
          handleSuccess?.(response.data);
        })
        .catch((error: AxiosError | Error) => {
          if (isAxiosError(error)) {
            formikHelpers.setStatus({
              serverError: error,
            });
            handleError?.(new Error("Server error"));
          } else {
            handleError?.(error);
          }
        });
    },
  });

  return formik;
}
