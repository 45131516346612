import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import { Button, Label, Modal, Spinner } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import { removeRefreshToken, removeToken } from "shared/helpers/util";
import { useHome } from "shared/store/home";

import { deleteAccount } from "../api";

const DeleteAccount = () => {
  const navigate = useNavigate();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const profile = useHome((state) => state.profile);

  const { isLoading, mutate: deleteAccountFn } = useMutation(() => deleteAccount(profile?.id), {
    onSuccess: () => {
      navigate("/signin", { replace: true });
      removeToken();
      removeRefreshToken();
    },
  });

  return (
    <>
      <div>
        <Label value="Danger Zone" className="font-inter-medium !text-mirage" />
        <br />
        <button
          type="button"
          className="mt-4 rounded-full border border-[#E02424] px-5 py-2.5 text-sm text-[#E02424] hover:bg-[#E02424] hover:text-white focus:outline-none focus:ring-0 mac13Inch:text-xs"
          onClick={() => setIsDeleteModalOpen(true)}
        >
          Delete Account
        </button>
      </div>
      <Modal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center"
      >
        <Modal.Header className={clsx(styles.account_header, "dark:!border-thunders")}>
          Delete Account
        </Modal.Header>
        <Modal.Body className="maxMd:p-4">
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-greychateau mac13Inch:text-sm">
              Are you sure you want to delete this account? It cannot be reverted.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-wrap-reverse justify-end dark:!border-thunders maxMd:p-4 mac13Inch:py-4">
          <button
            className="btn_secondary font-inter-medium maxMd:mt-[18px] maxMd:w-full"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No, Cancel
          </button>
          <Button
            className="font-xs-mac13 !rounded-full !bg-lavared font-inter-medium hover:!bg-red-800 focus:!ring-0 focus:!ring-transparent md:!ml-4 maxMd:!ml-0 maxMd:w-full"
            onClick={() => deleteAccountFn()}
          >
            <Spinner
              size="md"
              light={true}
              hidden={!isLoading}
              className="mr-3 !fill-red-500 !stroke-red-500"
            />
            Yes, I’m sure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAccount;
