import React from "react";

import Spinner from "shared/components/spinner/Spinner";
import { useCompany } from "shared/context/CompanyProvider";

import AddTeam from "./add-team/AddTeam";
import TeamsList from "./team-list/TeamsList";

const TeamsSettings = () => {
  const { isLoading, currentCompany, refetch } = useCompany();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700">
            The 'Teams' feature is deprecated and will be removed soon. Use this page only if you
            need to manage teams specifically; otherwise, please use the new 'Groups' feature.
          </div>
          <AddTeam />
          <TeamsList teamList={currentCompany?.teams} refetch={refetch} />
        </>
      )}
    </>
  );
};

export default TeamsSettings;
