import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import clsx from "clsx";

import { useQuery } from "@tanstack/react-query";

import BreadCrumb from "shared/components/bread-crumb/BreadCrumb";
import MetaData from "shared/components/metadata";
import MetaDataToggleButton from "shared/components/metadata-toggle-button";
import NotFound from "shared/components/not-found";
import Spinner from "shared/components/spinner/Spinner";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import { findNodeWithParents } from "shared/helpers/documents";
import useScreen from "shared/hooks/useScreen";
import { useDirectoryTree } from "shared/store/documents";
import { useTeam } from "shared/store/settings";

import { getDocumentFileType } from "modules/documents/helpers";

import { getSingleDocument } from "../../api";
import FileViewerButtonsContainer from "./FileViewerButtonsContainer";
import FileViewer from "./file-viewer";

const DocumentViewer = () => {
  const { id } = useParams();
  const deviceType = useScreen();
  const fileViewerButtonsContainerRef = useRef<HTMLDivElement>(null);
  const [isFileViewerButtonsContainerMounted, setIsFileViewerButtonsContainerMounted] =
    useState<boolean>(false);
  const handleMountedFileViewerButtonsContainer = useCallback(() => {
    setIsFileViewerButtonsContainerMounted(true);
  }, []);

  const currentTeam = useTeam((state) => state.currentTeam);

  const [isMetaDataOpen, setIsMetaDataOpen] = useState<boolean>(false);
  const directoryTree = useDirectoryTree((state) => state.directoryTree);

  const metaDataToggle = () => {
    setIsMetaDataOpen(!isMetaDataOpen);
    if (deviceType === MOBILE_DEVICE) {
      document.querySelector(".main-wrap")?.classList.add("overflow-y-hidden");
    }

    if (!isMetaDataOpen) {
      document.getElementsByClassName("main-content")[0]?.classList.remove("overflow-hidden");
    } else {
      document.getElementsByClassName("main-content")[0]?.classList.add("overflow-hidden");
    }
  };

  const {
    data: response,
    isError,
    isLoading,
    refetch: refetchDocument,
  } = useQuery(["single-document", currentTeam?.id, id], () => {
    if (!id) {
      return Promise.reject(new Error("Document id is not selected"));
    }
    return getSingleDocument(id, {
      team: currentTeam?.id,
      company: currentTeam?.company,
      isAllTeam: currentTeam.is_all_team,
    });
  });

  const handleDocumentUpdate = useCallback(() => {
    refetchDocument();
    toast.success("Document updated successfully");
  }, []);

  const breadCrumb = id
    ? findNodeWithParents(directoryTree.children || [], response?.data.directory)
    : [];
  breadCrumb.unshift({ id: null, name: "All Documents", link: "/documents" });
  breadCrumb.push({
    id: 0,
    name: response?.data.name,
  });

  useEffect(() => {
    document.getElementsByClassName("main-content")[0].classList.add("overflow-hidden");
    return () => {
      document.getElementsByClassName("main-content")[0]?.classList.remove("overflow-hidden");
    };
  }, []);

  if (isError) {
    return <NotFound />;
  }

  if (isLoading) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between bg-ghostwhite pr-5 dark:bg-balticsea">
        <div className="p-4 md:py-3.5 md:pl-5">
          <BreadCrumb breadCrumb={breadCrumb} />
        </div>

        <div className={clsx("ml-auto flex")}>
          {deviceType !== MOBILE_DEVICE && (
            <div>
              <FileViewerButtonsContainer
                ref={fileViewerButtonsContainerRef}
                onMounted={handleMountedFileViewerButtonsContainer}
              />
            </div>
          )}
          {!currentTeam.is_all_team && (
            <MetaDataToggleButton isMetaDataOpen={isMetaDataOpen} onClick={metaDataToggle} />
          )}
        </div>
      </div>
      {deviceType === MOBILE_DEVICE && (
        <div
          className={clsx("flex p-4 md:hidden", {
            hidden: getDocumentFileType(response.data) !== "spreadsheet",
          })}
        >
          <FileViewerButtonsContainer
            ref={fileViewerButtonsContainerRef}
            onMounted={handleMountedFileViewerButtonsContainer}
          />
        </div>
      )}

      <div className="document_height flex w-full ">
        {/* Ensure that the buttons container is mounted before rendering the file viewer */}
        {isFileViewerButtonsContainerMounted ? (
          <div
            className={clsx({
              excelsheet_toogal: isMetaDataOpen,
              "w-full": !isMetaDataOpen,
            })}
          >
            <FileViewer
              document={response.data}
              onUpdated={handleDocumentUpdate}
              buttonsContainer={fileViewerButtonsContainerRef.current}
            />
          </div>
        ) : null}

        {!currentTeam.is_all_team && isMetaDataOpen ? (
          <MetaData
            relatedObject={response.data}
            isOpen={isMetaDataOpen}
            setIsOpen={setIsMetaDataOpen}
            type="document"
          />
        ) : null}
      </div>
    </>
  );
};

export default DocumentViewer;
