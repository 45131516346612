import { useFormik } from "formik";
import { object, string } from "yup";

import { PredefinedFilterType } from "shared/helpers/constant";

const useAddPredefinedDateFilter = (action: CallableFunction) => {
  return useFormik<{ interval: PredefinedFilterType; from: string; to: string }>({
    initialValues: {
      interval: { key: "custom", value: "Custom" },
      from: "",
      to: "",
    },
    validateOnChange: false,
    validationSchema: addPredefinedDateSchema,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useAddPredefinedDateFilter;
export const addPredefinedDateSchema = object({
  from: string().required(),
  to: string().required(),
});
