import { memo, useRef } from "react";
import { useLocation } from "react-router-dom";

import { Dropdown } from "flowbite-react";
import { useOnClickOutside } from "usehooks-ts";

import { Table } from "@tanstack/react-table";

import headerStyles from "assets/css/common-header.module.css";
import styles from "assets/css/commonTable.module.css";
import { ReactComponent as Setting } from "assets/images/icons/setting.svg";

import { useDocuments } from "shared/store/documents";

import { ActionsType } from "./types";

const genericMemo: <T>(component: T) => T = memo;

function Actions<TData>({
  actions,
  table,
}: {
  actions: ActionsType<TData>[];
  table: Table<TData>;
}) {
  const { pathname } = useLocation();
  const selectedDocuments = useDocuments((state) => state.selectedDocuments);
  const actionRef = useRef<HTMLDivElement>(null);

  const onActionClicked = (item: ActionsType<TData>) => {
    if (selectedDocuments.length && pathname.includes("documents")) {
      if (item?.action) item?.action([]);
      return;
    }

    if (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) {
      if (item?.action)
        item?.action(
          table?.getSelectedRowModel()?.rows.map((val) => {
            return val?.original;
          })
        );
    }
  };

  const handleClickOutsideOfFavorite = () => {
    document.getElementsByClassName("favorites")[0].classList.add(styles.displayNone);
    const div = actionRef.current?.children[0];
    const attr = div?.getAttribute("aria-describedby");
    if (attr) {
      const button = div?.firstElementChild as HTMLButtonElement;
      button?.click();
    }

    const saveCurrentSearch = document.querySelector<HTMLButtonElement>(
      ".save_current_search > [aria-describedby] button"
    );
    saveCurrentSearch?.click();
  };

  useOnClickOutside(actionRef, handleClickOutsideOfFavorite);

  return (
    <div className={headerStyles.actionTag} ref={actionRef}>
      <Dropdown
        label={
          <>
            <Setting className="si fill h-5 w-5" />
            <span>Action</span>
          </>
        }
        className={headerStyles.actionDropDown}
        inline
        arrowIcon={false}
      >
        {actions?.map((item, index) => {
          return (
            <Dropdown.Item key={index} onClick={() => onActionClicked(item)}>
              <div className={headerStyles.items}>
                <span className="h-4 w-4">{item.icon}</span>
                <span className="text-aurometalsaurus dark:text-greychateau">{item.key}</span>
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    </div>
  );
}

export default genericMemo(Actions);
