import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Spinner, Table } from "flowbite-react";
import moment from "moment";

import { useQuery } from "@tanstack/react-query";

import { RefetchOptions } from "shared/components/common-table/types";
import TagChip from "shared/components/gridview-chip/TagChip";
import { IN_OPERATOR } from "shared/helpers/constant";
import getQueryParamsFromRefetchOptions from "shared/helpers/getQueryParamsFromRefetchOptions";
import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";
import { GetTaskResultType } from "shared/types";

import { getAllTasks } from "modules/tasks/overview/api";

import EditTaskModal from "../../overview/edit";
import CurrentTaskModal from "../../overview/view/CurrentTaskModal";

const TasksByType = () => {
  const { statusId } = useParams();

  const currentTeam = useTeam((state) => state.currentTeam);

  const permission = {
    team: currentTeam?.id,
    company: currentTeam?.company,
    isAllTeam: currentTeam?.is_all_team,
  };

  const searchFields = useMemo<RefetchOptions[]>(
    () => [
      {
        field: "status__" + IN_OPERATOR,
        value: `${statusId}`,
        type: "status",
      },
    ],
    [statusId]
  );

  const {
    data: response,
    isLoading,
    isFetching,
    refetch: refetchTasksData,
  } = useQuery(["getAllTasks", currentTeam?.id], () =>
    getAllTasks(getQueryParamsFromRefetchOptions(searchFields), { team: permission })
  );

  const currentTask = useTask((state) => state.currentTask);
  const setCurrentTask = useTask((state) => state.setCurrentTask);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [toBeEdited, setToBeEdited] = useState<GetTaskResultType | null>(null);

  return (
    <div className="data_table comman_table">
      <Table hoverable={true}>
        <Table.Head>
          <Table.HeadCell className="!pl-8">Task</Table.HeadCell>
          <Table.HeadCell>Status</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {!isLoading && !isFetching
            ? (response?.data || [])?.map((task: GetTaskResultType) => (
                <Table.Row
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  key={task.id}
                  onClick={() => {
                    setCurrentTask(task);
                    setIsViewModalOpen(true);
                  }}
                >
                  <Table.Cell className="!pl-8">{task.name}</Table.Cell>
                  <Table.Cell>{task.status ? <TagChip tag={task.status.name} /> : null}</Table.Cell>
                  <Table.Cell>{moment(task.created_at).format("YYYY-MM-DD")}</Table.Cell>
                </Table.Row>
              ))
            : null}
          {isFetching || isLoading ? (
            <Table.Row>
              <Table.Cell colSpan={3} className="text-center">
                <Spinner
                  size="lg"
                  light={true}
                  hidden={false}
                  className="custom_spinner !fill-mirage stroke-white"
                />
              </Table.Cell>
            </Table.Row>
          ) : null}
        </Table.Body>
      </Table>
      {isViewModalOpen ? (
        <CurrentTaskModal
          isViewModalOpen={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
          }}
          onEdit={() => {
            setIsViewModalOpen(false);
            if (currentTask) {
              setToBeEdited({
                ...currentTask,
                tags: currentTask?.tags_object?.map((tag) => tag.name) || currentTask?.tags,
              });
            } else {
              setToBeEdited(null);
            }
          }}
        />
      ) : null}
      {toBeEdited ? (
        <EditTaskModal
          isOpen={true}
          task={toBeEdited}
          teamId={currentTeam.id}
          onClose={() => {
            setIsViewModalOpen(false);
            setToBeEdited(null);
          }}
          onUpdated={() => {
            setToBeEdited(null);
            setIsViewModalOpen(false);
            refetchTasksData();
          }}
        />
      ) : null}
    </div>
  );
};

export default TasksByType;
