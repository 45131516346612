import { MultiValue } from "react-select";

import TeamOwner from "shared/components/metadata/team-actions/TeamOwner";
import { TeamMemberOptionProps, optionType } from "shared/components/metadata/types";

import { UserResponse } from "modules/home/types";

import TaskLabel from "../shared/TaskLabel";

export const TaskOwner = ({
  onFieldChanged,
  owner_object,
}: {
  onFieldChanged: (fieldName: string, data: number) => void;
  owner_object: UserResponse | null;
}) => {
  return (
    <div>
      <TaskLabel title="Owner" />
      <TeamOwner
        placeholder="Select owner"
        user_object={owner_object}
        onChange={(
          id?:
            | string
            | number
            | string[]
            | MultiValue<optionType>
            | MultiValue<TeamMemberOptionProps>
            | null
        ) => {
          onFieldChanged("owner", Number(id));
        }}
      />
    </div>
  );
};
