/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Todo
 */
export interface Todo {
    /**
     * 
     * @type {number}
     * @memberof Todo
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof Todo
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Todo
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof Todo
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Todo
     */
    readonly isDeleted: boolean;
    /**
     * 
     * @type {number}
     * @memberof Todo
     */
    feed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Todo
     */
    feedArticle?: number | null;
}

/**
 * Check if a given object implements the Todo interface.
 */
export function instanceOfTodo(value: object): value is Todo {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('isDeleted' in value) || value['isDeleted'] === undefined) return false;
    return true;
}

export function TodoFromJSON(json: any): Todo {
    return TodoFromJSONTyped(json, false);
}

export function TodoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Todo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'description': json['description'],
        'isCompleted': json['is_completed'] == null ? undefined : json['is_completed'],
        'isDeleted': json['is_deleted'],
        'feed': json['feed'] == null ? undefined : json['feed'],
        'feedArticle': json['feed_article'] == null ? undefined : json['feed_article'],
    };
}

export function TodoToJSON(value?: Omit<Todo, 'id'|'created_at'|'updated_at'|'is_deleted'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'is_completed': value['isCompleted'],
        'feed': value['feed'],
        'feed_article': value['feedArticle'],
    };
}

