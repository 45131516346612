import { useEffect, useRef, useState } from "react";

import { Label } from "flowbite-react";
import { cn } from "lib/utils";
import { Todo, TodoRequest } from "openapi";

import styles from "assets/css/articlesV2.module.css";

import Icon from "shared/components/icon/Icon";
import Dropdown from "shared/componentsV2/common-table/DropDown";
import Checkbox from "shared/componentsV2/form-controls/checkbox";
import { DropdownItemProps } from "shared/typesV2";

interface TodoItemProps {
  todo: Todo;
  isEditable?: boolean;
  canMarkAsDone?: boolean;
  updateTodo?: (todo: TodoRequest & { id: number }) => void;
  deleteTodo?: (id: number) => void;
}

const TodoItem = ({
  todo,
  isEditable = true,
  updateTodo,
  deleteTodo,
  canMarkAsDone = true,
}: TodoItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(todo.isCompleted);
  const inputRef = useRef<HTMLInputElement>(null);

  const data: DropdownItemProps[] = [
    {
      id: 0,
      title: "Delete",
      icon: "trash-new",
      iconClassName: "theme_icon",
      iconFill: true,
    },
  ];

  const handleUpdate = (value: string) => {
    if (!updateTodo) return;

    if (value.trim() === "") {
      setIsEditing(false);
      return;
    }
    updateTodo({ ...todo, description: value } as Todo);
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleUpdate(e.currentTarget.value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleUpdate(e.currentTarget.value);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.select();
      }, 100);
    }
  }, [isEditing]);

  return (
    <li
      className={cn(
        styles.article_to_do,
        "flex items-center rounded-lg bg-ghostwhite px-3 py-2 dark:border dark:border-jet dark:bg-balticsea dark:text-aurometalsaurus"
      )}
    >
      <div
        className={cn(
          "flex w-full items-center gap-2",
          isCompleted && !isEditing && "text-aurometalsaurus line-through"
        )}
      >
        {canMarkAsDone && (
          <Checkbox
            id={todo.id.toString()}
            checked={isCompleted}
            wrapperClassName="!p-0"
            className="h-3.5 w-3.5"
            onChange={() => {
              setIsCompleted(!isCompleted);
              updateTodo && updateTodo({ ...todo, isCompleted: !todo.isCompleted });
            }}
          />
        )}
        {!isEditable ? (
          <Label
            htmlFor={todo.id.toString()}
            value={todo.description}
            className={cn(
              "grow !px-0 !py-1 text-sm font-medium text-richblack dark:text-lighthouse",
              isCompleted ? "opacity-50" : "opacity-100"
            )}
            disabled={isCompleted}
          />
        ) : (
          <input
            ref={inputRef}
            disabled={isCompleted || !isEditable}
            defaultValue={todo.description}
            className="w-full grow !bg-transparent !px-0 !py-1 text-sm font-medium"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        )}
      </div>
      {isEditable && (
        <Dropdown
          isPortal={false}
          className="!min-w-[11.5rem]"
          dropdownOptions={data}
          TriggerComponent={
            <button className="ml-auto rounded-sm p-0">
              <Icon
                type="ellipsis-verticals"
                fill={true}
                size="icon-xs"
                className="fill_current_icon cursor-pointer rounded-sm p-0 text-davygrey dark:text-silvers"
              />
            </button>
          }
          handleSelect={() => {
            if (deleteTodo) {
              deleteTodo(todo.id);
            }
          }}
        />
      )}
    </li>
  );
};

export default TodoItem;

interface CreateTodoItemProps {
  createTodo: (todo: TodoRequest) => void;
}

export const CreateTodoItem = ({ createTodo }: CreateTodoItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = (value: string) => {
    if (value.trim() === "") return;
    createTodo({ description: value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEdit(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleEdit(e.currentTarget.value);
    e.currentTarget.value = "";
  };

  return (
    <li
      className={cn(
        styles.article_to_do,
        "grid grid-cols-[auto_1fr_auto] items-center gap-2 rounded-lg bg-ghostwhite px-3 py-2 dark:bg-balticsea"
      )}
    >
      <Checkbox checked={false} disabled wrapperClassName="!p-0" className="h-3.5 w-3.5" />
      <input
        ref={inputRef}
        defaultValue={""}
        className="w-full grow !bg-transparent !px-0 !py-1 text-sm font-medium"
        placeholder="Type a new todo"
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </li>
  );
};
