import { useFormik } from "formik";

import { CompanyGroupInputData, CompanyGroupResponse } from "../types";
import { createGroupSchema } from "../validation";

const useUpdateGroup = (data?: CompanyGroupResponse | null, action?: CallableFunction) => {
  return useFormik<CompanyGroupInputData>({
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      group_picture: data?.group_picture || null,
    },
    validateOnChange: true,
    validationSchema: createGroupSchema,
    enableReinitialize: true,
    onSubmit: () => {
      if (action) {
        action();
      }
    },
  });
};

export default useUpdateGroup;
