import React, { useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Label, Modal, Spinner, TextInput } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import ServerErrors from "shared/components/server-errors";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useHome } from "shared/store/home";

import { changeEmail } from "./api";
import useChangeEmail from "./hooks/useChangeEmail";
import { ChangeEmailProps } from "./types";

const ChangeEmail = () => {
  const profile = useHome((state) => state.profile);

  const [isEmailModalOpen, setIsEmailModalOpen] = useState<boolean>(false);

  const { documentBodyRef } = useDocumentBodyRef();

  const {
    isLoading,
    mutate: changeEmailFn,
    isError,
    error,
  } = useMutation((data: ChangeEmailProps) => changeEmail(data), {
    onSuccess: (res) => {
      toast(res.data.detail, { type: "success" });
      setIsEmailModalOpen(false);
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, errors, handleChange } = useChangeEmail(() =>
    changeEmailFn(values)
  );

  return (
    <>
      <div>
        <Label value="Email Address" className="!text-mirage dark:!text-white" />
        <br />
        <button
          type="button"
          className="btn_secondary mt-4"
          onClick={() => setIsEmailModalOpen(true)}
        >
          Change Email Address
        </button>
      </div>
      <div data-testid="change-email">
        <Modal
          root={documentBodyRef}
          show={isEmailModalOpen}
          popup={true}
          onClose={() => setIsEmailModalOpen(false)}
          dismissible={true}
          className="modal_mobileview_center"
        >
          <form className="!mt-0" onSubmit={handleSubmit}>
            <Modal.Header className={clsx("font-sans-medium maxMd:p-4", styles.modalHeader)}>
              <span>Change Email Address</span>
            </Modal.Header>
            <Modal.Body className="border-y border-brightgray !px-8 !pt-6 dark:!border-thunders maxMd:!px-4 maxMd:py-4">
              <div className="space-y-6">
                <h5
                  className="whitespace-nowrap font-medium dark:text-white mac13Inch:text-sm"
                  data-testid="change-email-header"
                >
                  Your current email is <span className="font-inter-medium"> {profile?.email}</span>
                </h5>
                <div className="relative">
                  <div className="mb-2 block">
                    <Label
                      className="!text-mirage dark:!text-white"
                      htmlFor="password"
                      value="Please enter your password*"
                    />
                  </div>
                  <TextInput
                    data-testid="change-email-password"
                    type="password"
                    name="password"
                    className="custom_input"
                    value={values.password}
                    onChange={handleChange}
                    placeholder="Password"
                    color={errors.password ? "failure" : "gray"}
                  />
                </div>
                <div className="relative !mt-4">
                  <div className="mb-2 block">
                    <Label htmlFor="email" value="New Email Address*" className="!text-mirage" />
                  </div>
                  <TextInput
                    data-testid="change-email-text"
                    type="email"
                    name="email"
                    className="custom_input"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="New Email"
                    color={errors.email ? "failure" : "gray"}
                  />
                </div>
                {isError ? <ServerErrors className="mt-4" error={error} /> : null}
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-end md:!pr-6 maxMd:p-4 mac13Inch:py-4">
              <button className="btn_primary py-3 maxMd:w-full">
                <Spinner
                  size="md"
                  light={true}
                  hidden={!isLoading}
                  className="mr-3 fill-crayolasblue stroke-crayolasblue"
                />
                Change Email
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ChangeEmail;
