import { AGREEMENT_TYPE, NOTE_TYPE, POLICY_TYPE } from "shared/helpers/constant";

import { getAgreementFileById } from "modules/agreements/overview/api";
import { getNoteFile } from "modules/notes/api";
import { getPolicyFile } from "modules/policies/overview/api";
import { TeamResponse } from "modules/settings/teams/types";

import { FileDownloader } from "../types";
import isUploadableModule from "./isUploadableModule";

const defaultFileDownloader = () => Promise.resolve(null);

const getPolicyFileDownloader = (team: TeamResponse) => (id: string) => {
  return getPolicyFile(id, {
    team: { team: team.id, company: team.company, isAllTeam: team.is_all_team },
  });
};

const getAgreementFileDownloader = (team: TeamResponse) => (id: string) => {
  return getAgreementFileById({ team: team.id }, +id);
};

const getNoteFileDownloader = (team: TeamResponse) => (id: string) => {
  return getNoteFile(+id, {
    team: { team: team.id, company: team.company, isAllTeam: team.is_all_team },
  });
};

export default function getFileDownloader(type: string, team: TeamResponse): FileDownloader {
  if (!isUploadableModule(type)) {
    return defaultFileDownloader;
  }
  switch (type) {
    case POLICY_TYPE: {
      return getPolicyFileDownloader(team);
    }
    case AGREEMENT_TYPE: {
      return getAgreementFileDownloader(team);
    }
    case NOTE_TYPE: {
      return getNoteFileDownloader(team);
    }
    default: {
      const _exhaustiveCheck: never = type;
      console.warn("Exhaustive check failed: ", _exhaustiveCheck);
      return defaultFileDownloader;
    }
  }
}
