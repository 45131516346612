import { number, object, string } from "yup";

export const changeVATorGSTSchema = object({
  eu_vat: string().required(),
});

export const changeEmailSchema = object({
  email: string().email().required(),
});

export const changeAddressSchema = object({
  name: string().required(),
  address: object().shape({
    line1: string().required(),
    city: string().required(),
    country: string().required(),
    postal_code: number().positive().required(),
    state: string().required(),
  }),
});
