import {
  AGREEMENT_TABLE,
  ARTICLE_TABLE,
  DIRECTORIES,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  MITIGATION_TABLE,
  NOTE_TABLE,
  POLICY_TABLE,
  RISK_TABLE,
} from "shared/helpers/constant";
import { HORIZON_SCANNING, RISKV2 } from "shared/helpersV2/constant";
import {
  BaseModelMixin,
  CommonTableColumnProps,
  PaginatedResponse,
  TaskOrderChangeParams,
  TimeStampedModelMixin,
} from "shared/types";
import {
  AuditQueryParams,
  GetTaskResultType,
  PageQueryParam,
  TeamActionQueryParams,
  TimeStampedQueryParams,
} from "shared/types";

import { AgreementResultsType } from "modules/agreements/overview/types";
import { ArticlesResponseProps } from "modules/articles/overview/types";
import { Document, DocumentResponse } from "modules/documents/type";
import { UserResponse } from "modules/home/types";
import { NoteResponse } from "modules/notes/types";
import { PolicyResponse } from "modules/policies/overview/types";
import { StatusResultType } from "modules/tasks/statuses/types";

import { RiskRelatedTaskType } from "../../../risksV2/types";

export type TasksType = {
  id: number;
  description: string;
  name: string;
  attachment?: string;
  members?: MemberType[];
  completed: boolean;
  daysLeft: number;
};
export type MemberType = {
  id: number;
  name: string;
  avatar: string;
};
export type KanbanListType = {
  id: number;
  team: number;
  name: string;
  tasks: GetTaskResultType[];
};

export type PaginatedTaskListResponse = PaginatedResponse<GetTaskResultType>;

export type TaskResultType = TimeStampedModelMixin &
  BaseModelMixin & {
    id?: number;
    name: string;
    description: string;
    due_date: string | null;
    company: number;
    status: number;
    status_object: StatusResultType | null;
    agreement?: number | null;
    policy?: number | null;
    article?: number | null;
    document?: number | null;
    note?: number | null;
    riskv2?: number | null;
    priority: string;
    tags: string[];
    assignees: number[];
    mentions: number[];
    owner: string | number;
    owner_object: UserResponse | null;
  };

export type OrderResultType = {
  id: number;
  created_at: Date;
  updated_at: Date;
  order: Array<number>;
  team: number;
};

export type KanbanOrderResultType = OrderResultType & {
  status: number;
};

export type TaskLabelType = {
  className?: string;
  title?: string;
  onClick?: () => void;
};

export type TaskTableColumnProps = {
  description: boolean;
} & CommonTableColumnProps;

export type GetAllTaskRequestParams = {
  name?: string;
  description?: string;
  name__icontains?: string;
  description__icontains?: string;
  status__in?: string;
  status?: string;
  ordering?: string;
} & TimeStampedQueryParams &
  AuditQueryParams &
  TeamActionQueryParams &
  PageQueryParam;

export type GetAllTaskByStatusRequestParams = {
  status__in?: string;
};

export type OrderUpdateTaskStatusRequestParams = TaskOrderChangeParams & {
  statusFrom: number | string;
  statusTo: number | string;
  requestFrom?: KanbanOrderResultType;
  requestTo?: KanbanOrderResultType;
};

export type RelatedObjectTypeName =
  | typeof AGREEMENT_TABLE
  | typeof ARTICLE_TABLE
  | typeof DOCUMENT_TABLE
  | typeof INCIDENTS_TABLE
  | typeof MITIGATION_TABLE
  | typeof NOTE_TABLE
  | typeof POLICY_TABLE
  | typeof RISK_TABLE
  | typeof DIRECTORIES
  | typeof RISKV2
  | typeof HORIZON_SCANNING;

/**
 * Related object type for task
 */
export type RelatedObjectType =
  | PolicyResponse
  | AgreementResultsType
  | ArticlesResponseProps
  | Document
  | DocumentResponse
  | NoteResponse
  | RiskRelatedTaskType;

export function isRiskRelatedTaskType(object: RelatedObjectType): object is RiskRelatedTaskType {
  return (object as RiskRelatedTaskType).register !== undefined;
}

export type TaskAttachmentResponse = {
  id: number;
  name: string;
  attachment: string;
  team: number;
  task: number;
} & TimeStampedModelMixin;

export type TaskAttachmentRequest = {
  file: File;
  taskId: number;
};
