import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamParameter } from "shared/types";

import { PolicyFileResponse } from "../types";

export default function getPolicyFile(
  id: string,
  permissions: { team: TeamParameter },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<PolicyFileResponse>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<PolicyFileResponse>(`policies/files/${id}/`, {
    params: {
      ...{
        team: teamParameter,
        company: permissions.team?.company,
        ...hasNameOrdering(requestParams),
      },
    },
  });
}
