import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { convertNoteData } from "../helpers";
import { NoteResponse } from "../types";

export default async function getNoteById(
  noteId: number,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<NoteResponse>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<NoteResponse>(`notes/${noteId}/`, {
    params: { team: teamParameter, company: permissions.team?.company },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertNoteData(response.data),
    };
  }

  return response;
}
