/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `low` - Low
 * * `medium` - Medium
 * * `high` - High
 * @export
 */
export const PriorityEnum = {
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;
export type PriorityEnum = typeof PriorityEnum[keyof typeof PriorityEnum];


export function instanceOfPriorityEnum(value: any): boolean {
    for (const key in PriorityEnum) {
        if (Object.prototype.hasOwnProperty.call(PriorityEnum, key)) {
            if (PriorityEnum[key as keyof typeof PriorityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PriorityEnumFromJSON(json: any): PriorityEnum {
    return PriorityEnumFromJSONTyped(json, false);
}

export function PriorityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriorityEnum {
    return json as PriorityEnum;
}

export function PriorityEnumToJSON(value?: PriorityEnum | null): any {
    return value as any;
}

