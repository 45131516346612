import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { convertArticleData } from "../helpers";
import { ArticlesResponseProps } from "../types";

export default async function getArticleById(
  articleId: number
): Promise<AxiosResponse<ArticlesResponseProps>> {
  let response = await api.get<ArticlesResponseProps>(`articles/${articleId}/`, {});

  if (response && response.data) {
    response = {
      ...response,
      data: convertArticleData(response.data),
    };
  }

  return response;
}
