import React from "react";
import { Link } from "react-router-dom";

import { AxiosError } from "axios";
import { Label, Spinner, TextInput } from "flowbite-react";
import { FormikProps, useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";

import { AuthHeader } from "shared/components/header";
import ServerErrors from "shared/components/server-errors";

import { forgotPassword } from "./api";
import { ForgotPasswordRequestParams } from "./type";
import { forgotPasswordSchema } from "./validation";

const ForgotPassword = () => {
  const {
    isLoading,
    mutate: forgotPasswordFn,
    isError,
    isSuccess,
    error,
  } = useMutation((email: ForgotPasswordRequestParams) => forgotPassword(email), {
    onError: (e: AxiosError): AxiosError => e,
  });

  const formik: FormikProps<ForgotPasswordRequestParams> = useFormik<ForgotPasswordRequestParams>({
    initialValues: { email: "" },
    onSubmit: (values) => forgotPasswordFn(values),
    validationSchema: forgotPasswordSchema,
    validateOnChange: false,
  });

  return (
    <>
      <AuthHeader />
      <section className="auth-container">
        {!isSuccess ? (
          <div className="card card-auth">
            <h2 className="md:title-lg mb-3 font-sans-medium text-3xl dark:text-white mac13Inch:text-[24px]">
              Forgot Password
            </h2>
            <p className="max-w-[464px] text-greychateau mac13Inch:text-sm">
              Don&apos;t fret! Just type in your email and we will send you a code to reset your
              password!
            </p>
            <form className="mt-5 mac13Inch:mt-3" onSubmit={formik.handleSubmit}>
              <div className="mb-4 mac13Inch:mb-3">
                <Label value="Your Email" className="mb-2 block font-inter-medium !text-mirage" />
                <TextInput
                  placeholder="name@example.com"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  color={formik.errors.email ? "failure" : "gray"}
                />
              </div>
              {isError ? <ServerErrors className="mb-4" error={error} /> : null}
              <button className="btn_primary w-full font-inter-medium text-base md:py-3.5 mac13Inch:text-xs">
                <Spinner
                  size="md"
                  light={true}
                  hidden={!isLoading}
                  className="mr-3 fill-crayolasblue stroke-crayolasblue"
                />
                Recover Password
              </button>
              <div className="mt-4 mac13Inch:mt-3">
                <Label
                  value="Back to"
                  className="!text-aurometalsaurus dark:!text-greychateau mac13Inch:text-xs"
                />
                <Link
                  className="ml-1 font-inter-medium text-sm text-mirage underline hover:no-underline dark:text-white mac13Inch:text-xs"
                  to="/signin"
                >
                  Sign in now
                </Link>
              </div>
            </form>
          </div>
        ) : null}
        {isSuccess ? (
          <div className="flex h-[calc(100vh-13rem)] items-center">
            <div className="title-md -mt-10 dark:text-white">
              An email has been sent to reset your password. Please check your email.
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default ForgotPassword;
