import Icon, { IconSize } from "shared/components/icon/Icon";

export const TodoItemIcon = ({
  totalTodosLength,
  completedTodosLength,
  size = "icon-xs",
}: {
  totalTodosLength: number;
  completedTodosLength: number;
  size?: string;
}) => {
  const allCompleted = totalTodosLength === completedTodosLength;
  const iconType = allCompleted
    ? "check-circle"
    : completedTodosLength > 0
    ? "in-progress"
    : "to-do";

  return (
    <Icon type={iconType} size={size as IconSize} className={allCompleted ? "stroke-none" : ""} />
  );
};
