import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { CompanyGroupResponse, UpdateCompanGroupData } from "../types";

export const updateGroup = async (
  data: Partial<UpdateCompanGroupData>,
  permissions: TeamParameter
): Promise<AxiosResponse<CompanyGroupResponse>> => {
  const formData = new FormData();
  if (data.name) {
    formData.append("name", data.name);
  }
  if (data.description) {
    formData.append("description", data.description);
  }
  if (data.group_picture) {
    formData.append("group_picture", data.group_picture);
  }

  return api.patch<CompanyGroupResponse>(`companies/groups/${data.id}/`, formData, {
    params: { company: permissions?.company },
  });
};
