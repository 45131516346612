import React from "react";

import clsx from "clsx";
import { Modal, ModalFooterProps } from "flowbite-react";

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  return (
    <Modal.Footer {...props} className={clsx("common_modal_footer", props.className)}>
      {props.children}
    </Modal.Footer>
  );
};
export default ModalFooter;
