import React from "react";

import moment from "moment";

import { InvoiceResponseProps } from "../types";

export default function BillingInvoice({ billings }: { billings?: Array<InvoiceResponseProps> }) {
  return (
    <div>
      <h4 className="px-4 py-3 font-inter-medium text-base dark:text-white md:pl-6">Invoices</h4>
      <div className="min-w-[310px] overflow-x-auto md:min-w-[500px]">
        {billings?.map((invoice: InvoiceResponseProps, index: number) => (
          <div
            className="flex overflow-x-auto px-4 py-3 text-sm md:px-6 maxMd:min-w-[460px] mac13Inch:px-7"
            key={index}
          >
            <span className="w-[33.5%] text-davygrey dark:text-greychateau">
              {moment(invoice.created).format("MMMM DD, YYYY")}
            </span>
            <a href={invoice.invoice_pdf} className="w-[26%] text-davygrey dark:text-greychateau">
              View invoice
            </a>
            <span className="w-[20%] text-mirage dark:text-greychateau">{invoice.amount_paid}</span>
            <span className="w-[20%] text-center font-inter-medium text-mirage dark:text-greychateau">
              {invoice.paid ? "Paid" : "Pending"}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
