import React, { useEffect, useState } from "react";

import headerStyles from "assets/css/common-header.module.css";

import { FILTER_TYPE } from "shared/helpers/constant";
import getBooleanValue from "shared/helpers/getBooleanValue";

import BooleanInput from "./BooleanInput";
import { FilterOptionType } from "./types";

export default function BooleanFilter({
  field,
  label,
  operator,
  onClick,
  single_option,
}: {
  field: string;
  label: string;
  operator?: string;
  onClick: (e: FilterOptionType) => void;
  single_option: boolean;
}) {
  const [isChecked, setIsChecked] = useState<boolean | null>(null);

  useEffect(() => {
    return () => {
      setIsChecked(null);
    };
  }, [field]);

  return (
    <div className="mt-2">
      <BooleanInput
        fieldName={field}
        isChecked={isChecked}
        handleChnage={(state) => setIsChecked(state)}
      />
      <div className="mt-4 !w-fit maxMd:!w-full">
        <button
          type="submit"
          className={headerStyles.btn_primary__imp}
          data-testid="filter_submit"
          onClick={() => {
            onClick({
              key: getBooleanValue(isChecked),
              label: (
                <span>
                  {label} {isChecked ? "YES" : "NO"}
                </span>
              ),
              value: `${getBooleanValue(isChecked)}`,
              field: field,
              type: FILTER_TYPE,
              operator: operator,
              conditions: [
                {
                  field: field,
                  operator: operator,
                  value: `${getBooleanValue(isChecked)}`,
                },
              ],
              single_option: single_option,
            });
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
