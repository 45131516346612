/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Meta } from './Meta';
import {
    MetaFromJSON,
    MetaFromJSONTyped,
    MetaToJSON,
} from './Meta';
import type { ChatMessageTypeEnum } from './ChatMessageTypeEnum';
import {
    ChatMessageTypeEnumFromJSON,
    ChatMessageTypeEnumFromJSONTyped,
    ChatMessageTypeEnumToJSON,
} from './ChatMessageTypeEnum';

/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    message: string;
    /**
     * 
     * @type {ChatMessageTypeEnum}
     * @memberof ChatMessage
     */
    readonly type: ChatMessageTypeEnum;
    /**
     * 
     * @type {Meta}
     * @memberof ChatMessage
     */
    meta?: Meta | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    readonly createdAt: Date;
}



/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value: object): value is ChatMessage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'message': json['message'],
        'type': ChatMessageTypeEnumFromJSON(json['type']),
        'meta': json['meta'] == null ? undefined : MetaFromJSON(json['meta']),
        'createdAt': (new Date(json['created_at'])),
    };
}

export function ChatMessageToJSON(value?: Omit<ChatMessage, 'id'|'type'|'created_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'meta': MetaToJSON(value['meta']),
    };
}

