/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedTagRequest
 */
export interface PatchedTagRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedTagRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the PatchedTagRequest interface.
 */
export function instanceOfPatchedTagRequest(value: object): value is PatchedTagRequest {
    return true;
}

export function PatchedTagRequestFromJSON(json: any): PatchedTagRequest {
    return PatchedTagRequestFromJSONTyped(json, false);
}

export function PatchedTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTagRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function PatchedTagRequestToJSON(value?: PatchedTagRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

