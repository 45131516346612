import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Checkbox, Label, Spinner, TextInput } from "flowbite-react";
import { usePostHog } from "posthog-js/react";

import { useMutation } from "@tanstack/react-query";

import { trackSignUp } from "shared/analytics";
import { AuthHeader } from "shared/components/header";
import Icon from "shared/components/icon/Icon";
import ServerErrors from "shared/components/server-errors";
import getUtmSource from "shared/helpers/getUtmSource";
import { hasError } from "shared/helpers/util";

import GoogleAuthButton from "modules/auth/google-auth-button/GoogleAuthButton";

import { signup } from "./api";
import useSignupForm from "./hooks/useSignupForm";
import { SignupRequestParams } from "./types";

const SignUp = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();

  const {
    isLoading,
    mutate: signupAction,
    error,
    isError,
  } = useMutation((data: SignupRequestParams) => signup(data), {
    onSuccess: () => {
      posthog.capture("newUserSignedUp");
      trackSignUp(window.location.search);
      navigate("/signup/thanks");
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, handleChange, errors, setFieldValue, isValid } = useSignupForm(
    getUtmSource(window.location.search),
    () => {
      delete values.agreeTerms;
      signupAction(values);
    }
  );

  const resetFiledValue = (filed: string) => {
    setFieldValue(filed, "");
  };
  if (error) {
    console.log(error?.response?.status);
  }

  return (
    <section className="relative overflow-x-hidden">
      <AuthHeader />
      <div className="auth-container">
        <div className="card card-auth">
          <div className="md:title-lg mb-4 font-sans-medium text-3xl text-mirage dark:text-white md:mb-10 mac13Inch:mb-8 mac13Inch:text-[24px]">
            Sign Up
          </div>
          <GoogleAuthButton authType="signup" />
          <form onSubmit={handleSubmit}>
            <div className="mb-4 mac13Inch:mb-3">
              <Label value="Your Email*" className="mb-2 block font-inter-medium !text-mirage" />
              <TextInput
                placeholder="name@example.com"
                data-testid="email"
                type="email"
                onChange={handleChange}
                value={values.email}
                name="email"
                className={clsx({
                  "is-error": errors.email || (error && hasError("email", error)),
                  " gray": !(errors.email || (error && hasError("email", error))),
                })}
              />
            </div>
            <div className="mb-4 mac13Inch:mb-3">
              <Label value="Password*" className="mb-2 block font-inter-medium !text-mirage" />
              <div className="form-input">
                <TextInput
                  type={"password"}
                  placeholder="••••••••••"
                  data-testid="password1"
                  name="password1"
                  onChange={handleChange}
                  value={values.password1}
                  className={clsx({
                    "is-error": errors.password1 || (error && hasError("password1", error)),
                    " gray": !(errors.password1 || (error && hasError("password1", error))),
                  })}
                />
                {errors.password1 ? (
                  <button className="p-3" onClick={() => resetFiledValue("password1")}>
                    <Icon type="close-round" fill={true} size="icon-xs" className="close-icon" />
                  </button>
                ) : null}
                {values.password1 && !errors.password1 ? (
                  <div className="check p-3">
                    <Icon type="check" fill={true} size="icon-xs" className="check-icon" />
                  </div>
                ) : null}
              </div>
              <div
                data-testid="password1-error"
                className={clsx(
                  { "text-lavared": errors.password1 },
                  "mt-2 text-xs leading-[18px] text-aurometalsaurus"
                )}
              >
                {errors.password1
                  ? "Use a combination of uppercase letters and lowercase letters Include at least one number, and special characters."
                  : null}
                {!values.password1 && !errors.password1
                  ? "At least 8 characters long. A combination of uppercase letters, lowercase letters, numbers, and special characters."
                  : null}
              </div>
            </div>
            <div className="mb-4 mac13Inch:mb-3">
              <Label
                value="Confirm Password*"
                className="mb-2 block font-inter-medium !text-mirage"
              />
              <div className="form-input">
                <TextInput
                  type={"password"}
                  placeholder="••••••••••"
                  data-testid="password2"
                  name="password2"
                  onChange={handleChange}
                  value={values.password2}
                  className={clsx({
                    "is-error": errors.password2 || (error && hasError("password2", error)),
                    " gray": !(errors.password2 || (error && hasError("password2", error))),
                  })}
                />
                {errors.password2 ? (
                  <button className="p-3" onClick={() => resetFiledValue("password2")}>
                    <Icon type="close-round" fill={true} size="icon-xs" className="close-icon" />
                  </button>
                ) : null}
                {values.password2 && !errors.password2 ? (
                  <div className="check p-3">
                    <Icon type="check" fill={true} size="icon-xs" className="check-icon" />
                  </div>
                ) : null}
              </div>
            </div>
            <div
              className={clsx("mb-4 flex items-center gap-2 mac13Inch:mb-3", {
                "custom-checkbox": errors.agreeTerms,
              })}
            >
              <Checkbox
                id="agree"
                data-testid="agree-terms"
                className="custom-checkbox rounded"
                checked={values.agreeTerms}
                onChange={() => setFieldValue("agreeTerms", !values.agreeTerms)}
              />
              <Label htmlFor="agree" className="text-xs text-davygrey dark:!text-greychateau">
                I Accept the{" "}
                <a
                  href="https://grand.io/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs text-mirage underline dark:text-white"
                >
                  Terms and Conditions
                </a>
              </Label>
            </div>
            {isError ? (
              <ServerErrors
                className="mb-4"
                skipError={error?.response?.status === 500}
                error={error}
              />
            ) : null}
            <button
              className="btn_primary w-full text-base md:py-3.5 mac13Inch:text-xs"
              type="submit"
              disabled={isLoading || !isValid}
            >
              <Spinner
                size="md"
                light={true}
                hidden={!isLoading}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Create Account
            </button>
            <div className="mt-4 mac13Inch:mt-3">
              <Label
                value="Already have an account?"
                className="!text-aurometalsaurus dark:!text-greychateau"
              />
              <Link
                className="ml-1 font-inter-medium text-sm text-mirage underline hover:no-underline dark:text-white mac13Inch:text-xs"
                to="/signin"
              >
                Login here
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
