import { toast } from "react-toastify";

import { isAxiosError } from "axios";
import { Modal, Spinner } from "flowbite-react";
import get from "lodash/get";

import { useMutation } from "@tanstack/react-query";

import modalStyle from "assets/css/customModal.module.css";

import { revertVersion } from "../api";
import { CreateTypesModalProps, RevertVersionType } from "../types";

const RevertVersionModal = ({
  isModalOpen,
  setIsModalOpen,
  versionId,
  id,
  permissions,
  type,
}: CreateTypesModalProps) => {
  const { isLoading, mutate: revertVersionFn } = useMutation(
    (data: RevertVersionType) => revertVersion(data),
    {
      onSuccess: () => {
        toast(`Activity reverted to ${versionId} Successfully.`, {
          type: "success",
        });
        setIsModalOpen(false);
      },
      onError: (error: unknown) => {
        if (isAxiosError(error) && get(error, ["response", "status"], 0) === 403) {
          toast("You are not authorized to perform this action.", {
            type: "error",
          });
        } else {
          toast("Something went wrong.", {
            type: "error",
          });
        }
      },
    }
  );

  return (
    <Modal
      size="lg"
      show={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header
        className={
          modalStyle.modalHeader +
          " !border-b !border-solid border-brightgray p-4 md:!p-6 maxMd:!py-4 "
        }
      >
        Revert version
      </Modal.Header>
      <form>
        <Modal.Body className="maxMd:p-4">
          <label>Do you want to revert to {versionId} version ?</label>
        </Modal.Body>
        <Modal.Footer className="dark:!border-thunders maxMd:flex-col maxMd:p-4">
          <button
            className="btn_secondary ml-auto maxMd:mb-4 maxMd:w-full"
            type="button"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="btn_primary maxMd:w-full"
            type="button"
            data-testid="revert"
            onClick={() =>
              revertVersionFn({
                type: type,
                typeId: id,
                versionId: versionId,
                permissions: permissions,
              })
            }
          >
            Revert
            <Spinner hidden={!isLoading} size="sm" className="ml-1 !fill-mirage stroke-mirage" />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RevertVersionModal;
