import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { PolicyVersionProps } from "../types";

export default function getPolicyVersions(
  policyId: number,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PolicyVersionProps[]>> {
  const teamParameter = permissions.team.isAllTeam ? "all" : permissions.team.team;
  return api.get<PolicyVersionProps[]>(`policies/${policyId}/versions/`, {
    params: { ...{ team: teamParameter, company: permissions.team?.company } },
  });
}
