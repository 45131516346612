import { createContext, useContext, useState } from "react";

import { Article } from "openapi";

import { UseQueryResult } from "@tanstack/react-query";

import { useArticle, useUpdateArticle } from "../hooks/useArticles";

type ArticleContextProps = {
  feedId: number;
  articleId: number;
  companyId: number;
  isPublic: boolean;
};

type ArticleContextType = {
  articleQuery: UseQueryResult<Article, unknown>;
  shareModalArticle: Article | undefined;
  setShareModalArticle: React.Dispatch<React.SetStateAction<Article | undefined>>;
  showToDosModal: boolean;
  setShowToDosModal: React.Dispatch<React.SetStateAction<boolean>>;
} & ArticleContextProps &
  Omit<ReturnType<typeof useUpdateArticle>, "isLoading" | "isSuccess" | "isError">;

const ArticleContext = createContext<ArticleContextType>({} as ArticleContextType);

export const ArticleProvider = ({
  children,
  ...props
}: { children: React.ReactNode } & ArticleContextProps) => {
  const articleQuery = useArticle({ ...props, id: props.articleId });
  const articleActions = useUpdateArticle({ ...props, id: props.articleId });

  const [shareModalArticle, setShareModalArticle] = useState<Article>();
  const [showToDosModal, setShowToDosModal] = useState(false);

  return (
    <ArticleContext.Provider
      value={{
        ...props,
        ...articleActions,
        articleQuery,
        shareModalArticle,
        setShareModalArticle,
        showToDosModal,
        setShowToDosModal,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export const useArticleContext = () => useContext(ArticleContext);
