/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Todo } from './Todo';
import {
    TodoFromJSON,
    TodoFromJSONTyped,
    TodoToJSON,
} from './Todo';

/**
 * 
 * @export
 * @interface PaginatedTodoList
 */
export interface PaginatedTodoList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTodoList
     */
    nextPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTodoList
     */
    previousPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTodoList
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedTodoList
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTodoList
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTodoList
     */
    previous?: string | null;
    /**
     * 
     * @type {Array<Todo>}
     * @memberof PaginatedTodoList
     */
    results: Array<Todo>;
}

/**
 * Check if a given object implements the PaginatedTodoList interface.
 */
export function instanceOfPaginatedTodoList(value: object): value is PaginatedTodoList {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('results' in value) || value['results'] === undefined) return false;
    return true;
}

export function PaginatedTodoListFromJSON(json: any): PaginatedTodoList {
    return PaginatedTodoListFromJSONTyped(json, false);
}

export function PaginatedTodoListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedTodoList {
    if (json == null) {
        return json;
    }
    return {
        
        'nextPage': json['next_page'] == null ? undefined : json['next_page'],
        'previousPage': json['previous_page'] == null ? undefined : json['previous_page'],
        'count': json['count'],
        'pageSize': json['page_size'] == null ? undefined : json['page_size'],
        'next': json['next'] == null ? undefined : json['next'],
        'previous': json['previous'] == null ? undefined : json['previous'],
        'results': ((json['results'] as Array<any>).map(TodoFromJSON)),
    };
}

export function PaginatedTodoListToJSON(value?: PaginatedTodoList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'next_page': value['nextPage'],
        'previous_page': value['previousPage'],
        'count': value['count'],
        'page_size': value['pageSize'],
        'next': value['next'],
        'previous': value['previous'],
        'results': ((value['results'] as Array<any>).map(TodoToJSON)),
    };
}

