import { useCallback, useEffect, useMemo, useRef } from "react";
import Select, { SelectInstance, SingleValue } from "react-select";

import isUndefined from "lodash/isUndefined";
import { useDarkMode } from "usehooks-ts";

import { selectControlStyle, selectErrorStyles } from "shared/helpers/selectStyle";
import { getUsername } from "shared/helpers/util";

import { UserResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

import TeamUserSelectOption from "./TeamUserSelectOption";
import TeamUserSingleValue from "./TeamUserSingleValue";
import { fromSelectId, toSelectId } from "./helpers";
import { TeamUserOption, Value } from "./types";

export type TeamUserSelectProps = {
  name: string;
  /**
   * Array of teams from company
   */
  teams: TeamResponse[];
  /**
   * Array of users from company
   */
  users: UserResponse[];
  defaultValue?: Value;
  value?: Value;
  onChange?: (value: Value) => void;
  hasError?: boolean;
  errorText?: string;
};

export default function TeamUserSelect({
  name,
  teams,
  users,
  defaultValue,
  value,
  onChange: handleChange,
  hasError,
  errorText,
}: TeamUserSelectProps) {
  const selectRef = useRef<SelectInstance<TeamUserOption, false>>(null);
  const { isDarkMode } = useDarkMode(false);

  const teamOptions = useMemo(
    () =>
      teams.map((team) => ({
        label: team.name,
        value: toSelectId({ team: team.id }),
        team,
      })),
    [teams]
  );

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        label: getUsername(user),
        value: toSelectId({ user: user.id }),
        user,
      })),
    [users]
  );

  const options = useMemo(() => [...userOptions, ...teamOptions], [teamOptions, userOptions]);

  const defaultOption = useMemo(() => {
    if (defaultValue) {
      return options.find((option) => option.value === toSelectId(defaultValue));
    }
    return undefined;
  }, [defaultValue, options]);

  const selectedOption = useMemo(() => {
    if (value) {
      return options.find((option) => option.value === toSelectId(value));
    }
    return undefined;
  }, [value, options]);

  useEffect(() => {
    if (selectRef.current && isUndefined(selectedOption)) {
      selectRef.current.clearValue();
    }
  }, [selectedOption]);

  const change = useCallback((newValue: SingleValue<{ value: string; label: string }>) => {
    if (newValue) {
      handleChange?.(fromSelectId(newValue.value));
    }
  }, []);

  return (
    <div className="teamuserSelectOption" data-testid="team-user-select-container">
      <Select<TeamUserOption, false>
        ref={selectRef}
        name={name}
        id={name}
        data-testid="team-by-user-id"
        options={options}
        onChange={change}
        defaultValue={defaultOption}
        value={selectedOption}
        placeholder={"Select team or user"}
        styles={{
          ...selectControlStyle(isDarkMode),
          ...(hasError ? selectErrorStyles(isDarkMode) : {}),
        }}
        components={{
          Option: TeamUserSelectOption,
          SingleValue: TeamUserSingleValue,
        }}
      />
      {hasError ? (
        <div className="text-xs text-red-500" data-testid="team-user-select-error">
          {errorText}
        </div>
      ) : null}
    </div>
  );
}
