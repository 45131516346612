import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { useMutation, useQuery } from "@tanstack/react-query";

import CommonTable from "shared/components/common-table";
import SimpleTable from "shared/components/common-table/SimpleTable";
import { RefetchOptions } from "shared/components/common-table/types";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { TASK_STATUS_TABLE } from "shared/helpers/constant";
import getQueryParamsFromRefetchOptions from "shared/helpers/getQueryParamsFromRefetchOptions";
import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";

import { deleteStatusById, getStatus } from "./api";
import AddStatus from "./components/AddStatus";
import StatusTitle from "./components/StatusTitle";
import useStatusCommonTable from "./hooks/useStatusCommonTable";
import { StatusResultType } from "./types";

const Status = () => {
  const navigate = useNavigate();
  const { currentCompany } = useCompany();
  const currentTeam = useTeam((state) => state.currentTeam);
  const [filterOptions, setFilterOptions] = useState<RefetchOptions[]>([]);

  const [setStatuses, statuses] = useTask((state) => [state.setStatuses, state.statuses]);

  const {
    columns,
    filters,
    actions,
    searchFields,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    toBeDeleted,
  } = useStatusCommonTable();

  const {
    refetch,
    isLoading: statusLoading,
    isFetching: statusFetching,
  } = useQuery(
    ["getStatus", filterOptions, currentCompany?.id, currentTeam?.id],
    () =>
      getStatus(getQueryParamsFromRefetchOptions(filterOptions), {
        team: {
          team: currentTeam?.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        },
      }),
    {
      onSuccess: (response) => setStatuses(response.data),
    }
  );

  const {
    isLoading: isDeleting,
    mutateAsync: deleteStatuses,
    isError: hasDeleteError,
    error: deleteError,
  } = useMutation(
    ({ statusId, teamId }: { statusId: number; teamId: number }) =>
      deleteStatusById({ statusId, teamId: teamId }),
    {
      onError: (err: AxiosError): AxiosError => {
        toast(err.message || "", { type: "error" });
        return err;
      },
    }
  );
  const refetchTasks = useCallback((options: RefetchOptions[]) => {
    setFilterOptions(options);
  }, []);

  return (
    <div>
      {hasDeleteError ? <ServerErrors className="mb-4" error={deleteError as AxiosError} /> : null}

      {!currentTeam.is_all_team ? <StatusTitle setIsModalOpen={setIsModalOpen} /> : null}

      <CommonTable<StatusResultType>
        columns={columns}
        data={statuses || null}
        filters={filters}
        actions={actions}
        displayPagination={true}
        name={TASK_STATUS_TABLE}
        searchFields={searchFields}
        refetch={refetchTasks}
        displayToggleButton={false}
        displayActions={!currentTeam.is_all_team}
        displayFavorites={!currentTeam.is_all_team}
      >
        {(table) => (
          <>
            <SimpleTable
              table={table}
              onRowClick={(id) => navigate(`/tasks/statuses/${id}/view`)}
              isLoading={statusLoading || isDeleting || statusFetching}
            />

            <ConfirmModal
              isOpen={isDeleteModalOpen}
              setIsOpen={setIsDeleteModalOpen}
              action={async () => {
                const promises = toBeDeleted.map((status) => {
                  return deleteStatuses({
                    statusId: status?.id || 0,
                    teamId: parseInt(`${status.team}`),
                  });
                });

                table.toggleAllRowsSelected(false);
                setIsDeleteModalOpen(false);
                await Promise.all(promises).then(() => {
                  refetch();
                });
              }}
            />
          </>
        )}
      </CommonTable>
      <AddStatus
        refetchStatus={refetch}
        isStatusModalOpen={isModalOpen}
        setIsStatusModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Status;
