/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyMini
 */
export interface CompanyMini {
    /**
     * 
     * @type {string}
     * @memberof CompanyMini
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyMini
     */
    readonly slug: string;
}

/**
 * Check if a given object implements the CompanyMini interface.
 */
export function instanceOfCompanyMini(value: object): value is CompanyMini {
    if (!('slug' in value) || value['slug'] === undefined) return false;
    return true;
}

export function CompanyMiniFromJSON(json: any): CompanyMini {
    return CompanyMiniFromJSONTyped(json, false);
}

export function CompanyMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyMini {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'] == null ? undefined : json['image'],
        'slug': json['slug'],
    };
}

export function CompanyMiniToJSON(value?: Omit<CompanyMini, 'slug'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'image': value['image'],
    };
}

