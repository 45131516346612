import React, { useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Table } from "flowbite-react";
import get from "lodash/get";
import toString from "lodash/toString";
import moment from "moment";

import { useMutation, useQuery } from "@tanstack/react-query";

import styles from "assets/css/members.module.css";

import Avatar from "shared/components/avatar";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import Icon from "shared/components/icon/Icon";
import { removeFromCompany } from "shared/components/permission/api";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyMember } from "shared/context/types/company";
import { getCompanyUserResponseData } from "shared/helpers/getCompanyUserResponseData";
import { getServerErrors, getUsername } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import deleteInvitationById from "../../auth/on-boarding/api/deleteInvitationById";
import MembersDropDown from "./MembersDropDown";
import PendingRequest from "./PendingRequest";
import PermissionModal from "./PermissionModal";
import TeamsSpace from "./TeamsSpace";
import AddMember from "./add-member/AddMember";
import { updateMember } from "./api";
import { UpdateMemberPermissionProps } from "./types";

function Members() {
  const { currentCompany, refetch } = useCompany();
  const { currentTeam } = useTeam();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState<CompanyMember[]>([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [invitationToBeDeleted, setInvitationToBeDeleted] = useState<number>();

  const [memberDetails, setMemberDetails] = useState<CompanyMember | undefined>();

  const {
    data: companyMemberList,
    isLoading,
    isFetching,
    refetch: refetchMember,
  } = useQuery(
    ["getMemberByCompany", "settingMembers", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const memberList: CompanyMember[] = [
    ...(companyMemberList?.data?.results || []),
    ...(currentCompany?.organization_invites ? [...currentCompany.organization_invites] : []),
  ];

  const membersList = isFiltered ? filteredMembers : memberList;

  const { mutate: updateMemberFn } = useMutation(
    (data: UpdateMemberPermissionProps) => updateMember(data),
    {
      onSuccess: () => {
        refetch();
        toast("Members Updated Successfully.", { type: "success" });
      },
      onError: (error: AxiosError) => {
        getServerErrors(error).forEach((err: string) => toast(err, { type: "error" }));
      },
    }
  );

  const { mutate: deleteFromCompany } = useMutation(
    (member: CompanyMember) => removeFromCompany(member.id, { company: currentCompany.id }),
    {
      onSuccess: () => {
        refetch();
        toast("Removed from Company.", { type: "success" });
      },
      onError: (error: AxiosError) => {
        getServerErrors(error).forEach((err: string) => toast(err, { type: "error" }));
      },
    }
  );

  const { mutate: deleteInvitationFn } = useMutation(
    (invitationId?: number) => {
      if (!invitationToBeDeleted) {
        Promise.reject(new Error("Member is not selected"));
      }

      return deleteInvitationById({
        invitationId,
        companyId: currentCompany.id,
      });
    },
    {
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        toast("Invitation deleted successfully.", { type: "success" });
        refetch();
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const onClick = (member: CompanyMember, key: string) => {
    updateMemberFn({
      is_admin: key === "owner" ? true : false,
      team: currentTeam.id,
      id: member.id,
      company: currentCompany.id,
    });
  };

  return (
    <div className="seeting_modal">
      <AddMember
        setIsFiltered={setIsFiltered}
        setFilteredMembers={setFilteredMembers}
        memberList={memberList}
        memberCount={
          (companyMemberList?.data.count || 0) + currentCompany.organization_invites.length
        }
      />
      <div className={clsx(styles.member_list)}>
        <Table className="min-w-[1234px] md:min-w-[900px] ">
          <Table.Head className={styles.table_header}>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Email Address</Table.HeadCell>
            <Table.HeadCell>Access Level</Table.HeadCell>
            <Table.HeadCell>Team Space</Table.HeadCell>
            <Table.HeadCell>Created At</Table.HeadCell>
            <Table.HeadCell>Updated At</Table.HeadCell>
            <Table.HeadCell className="pr-3">
              <span className="sr-only"></span>
            </Table.HeadCell>
          </Table.Head>
          {isLoading || isFetching ? <CentralSpinner /> : null}
          {membersList.length > 0 ? (
            <Table.Body className="divide-y pl-4">
              {membersList?.map((item: CompanyMember, index: number) => (
                <Table.Row className="bg-white" key={index}>
                  <Table.Cell className={`whitespace-nowrap !pl-6 font-medium`}>
                    <div className="flex items-center">
                      <Avatar
                        name={toString(getUsername(item?.user) || get(item, "invitee_identifier"))}
                        avatarFor="user"
                        avatarType="squared"
                        size="sm"
                        url={get(item, "user.profile_picture")}
                        className="cursor-default"
                      />
                      <span className="ml-3 w-[8.438rem] truncate">
                        {get(item, "user.name") ||
                          (get(item, "invitee_identifier") as unknown as string)?.split("@")[0] ||
                          (get(item, "user.email") as unknown as string)?.split("@")[0] ||
                          "N/A"}
                      </span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <span className="email_ellipsis">
                      {get(item, "user.email") || get(item, "invitee_identifier") || ""}
                    </span>
                  </Table.Cell>
                  <Table.Cell>
                    {currentCompany.organization_invites?.find(
                      (invites) => item.id === invites.id
                    ) ? (
                      <PendingRequest />
                    ) : (
                      <MembersDropDown
                        onClick={onClick}
                        member={item}
                        index={index}
                        onDelete={deleteFromCompany}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <TeamsSpace member={item} />
                  </Table.Cell>
                  <Table.Cell>{moment(item.created).format("YYYY-MM-DD")}</Table.Cell>
                  <Table.Cell className="text-center">-</Table.Cell>
                  <Table.Cell>
                    {get(item, "user") ? (
                      <button
                        className="dots_hover"
                        onClick={() => {
                          setMemberDetails(item);
                          setIsModalOpen(true);
                        }}
                      >
                        <Icon type="dots-horizontal" fill={false} size="icon-md" />
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                          setInvitationToBeDeleted(item.id);
                        }}
                      >
                        <Icon type="trash" fill={true} size="icon-sm" />
                      </button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          ) : (
            <Table.Row className="bg-white opacity-50">
              <Table.Cell
                className={`items-baseline !px-0 py-6 text-center text-sm font-medium`}
                colSpan={5}
              >
                No Member Available
              </Table.Cell>
            </Table.Row>
          )}
        </Table>
      </div>
      <PermissionModal
        refetchMember={refetchMember}
        show={isModalOpen}
        memberDetails={memberDetails ? getCompanyUserResponseData({ ...memberDetails }) : null}
        setMemberDetails={setMemberDetails}
        onClose={() => setIsModalOpen(false)}
      />
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={() => {
          deleteInvitationFn(invitationToBeDeleted);
        }}
      />
    </div>
  );
}

export default Members;
