import { useState } from "react";

import isEmpty from "lodash/isEmpty";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";
import { DropdownItemProps } from "shared/typesV2";

import Button from "../form-controls/button";

export type CommonTableV2AddButtonProps = {
  btnDetails?: {
    icon?: string;
    title: string;
    handleClickBtn?: () => void;
    dropdownOptions?: DropdownItemProps[];
    isBtnDisable?: boolean;
  };
};

const CommonTableV2AddButton = ({ btnDetails }: CommonTableV2AddButtonProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="ml-2">
      {!isEmpty(btnDetails) && (
        <DropdownMenu.Root
          open={open}
          onOpenChange={btnDetails.isBtnDisable ? () => setOpen(false) : setOpen}
        >
          <DropdownMenu.Trigger asChild>
            <Button
              onClick={btnDetails.handleClickBtn}
              btnTitle={btnDetails.title}
              btnSize="sm"
              disabled={btnDetails.isBtnDisable}
              icon={
                btnDetails.icon ? (
                  <Icon
                    type={btnDetails.icon}
                    fill={true}
                    size="icon-smd"
                    className="themesvg_white"
                  />
                ) : null
              }
            />
          </DropdownMenu.Trigger>
          {btnDetails.dropdownOptions && btnDetails.dropdownOptions?.length ? (
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className="DropdownMenuContent"
                sideOffset={5}
                side="bottom"
                align="end"
              >
                {btnDetails.dropdownOptions?.map((option) => {
                  const {
                    id,
                    icon = "",
                    iconFill = true,
                    title,
                    iconClassName = "theme_icon",
                    onItemClick,
                    disabled,
                    node,
                  } = option;

                  return node ? (
                    node
                  ) : (
                    <DropdownMenu.Item
                      key={id}
                      className="DropdownMenuItem"
                      onClick={() => {
                        if (onItemClick) {
                          onItemClick(title);
                        }
                      }}
                      disabled={disabled}
                    >
                      <div className="flex items-center gap-2">
                        <Icon
                          type={icon}
                          fill={iconFill}
                          size="icon-sm"
                          className={iconClassName}
                        />
                        <span>{title}</span>
                      </div>
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          ) : null}
        </DropdownMenu.Root>
      )}
    </div>
  );
};

export default CommonTableV2AddButton;
