import { Link } from "react-router-dom";

/**
 * CsntLogin component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement}
 */

const CantLogin = () => {
  return (
    <section
      className="flex h-screen items-center bg-white dark:bg-darkjunglegreenss"
      data-testid="cant-login"
    >
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <p
            className="mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl"
            data-testid="cant-login-text"
          >
            Something went wrong!
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            Sorry, we can&apos;t logged you in.
          </p>
          <Link to="/" className="btn_primary my-4" data-testid="cant-login-link">
            Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CantLogin;
