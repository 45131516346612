import React from "react";

import Icon from "../icon/Icon";

/**
 *
 * @returns display disable Label
 */
const OwnerLabel = () => {
  return (
    <div
      className="dowun_arrow flex cursor-not-allowed items-center justify-between"
      data-testid="owner_label"
    >
      <p className="text-sm !text-gray-500 dark:!text-greychateau">Full Access</p>
      <Icon type="down" fill={false} size="icon-sm" className="ml-2" />
    </div>
  );
};

export default OwnerLabel;
