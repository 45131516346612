import { cn } from "shared/componentsV2/utils";

type IconNames =
  | "document-star"
  | "logoWhite"
  | "logo"
  | "star"
  | "document-text"
  | "facebook"
  | "linkedin";

type IconProps = {
  name: IconNames;
  className?: string;

  /**
   * @deprecated
   */
  size?:
    | "icon-2xxs"
    | "icon-xxs"
    | "icon-sxs"
    | "icon-xs"
    | "icon-sm"
    | "icon-smd"
    | "icon-md"
    | "icon-lg"
    | "icon-xl"
    | "icon-2xl";
};

export const Icon = ({ name, className, size }: IconProps) => {
  const getSize = () => {
    switch (size) {
      case "icon-2xxs":
        return "w-2 h-2";
      case "icon-xxs":
        return "w-4 h-4";
      case "icon-sxs":
        return "w-4 h-4";
      case "icon-sm":
        return "w-4 h-4";
      case "icon-smd":
        return "w-4 h-4";
      case "icon-md":
        return "w-4 h-4";
      case "icon-lg":
        return "w-4 h-4";
      case "icon-xl":
        return "w-4 h-4";
      case "icon-2xl":
        return "w-8 h-8";
      default:
        return "w-4 h-4";
    }
  };
  return (
    <img
      src={`/images/icons/${name.toLocaleLowerCase()}.svg`}
      alt={name}
      className={cn(className, getSize())}
    />
  );
};
export default Icon;
