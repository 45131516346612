import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { close as closeIntercom } from "intercom";
import find from "lodash/find";
import isObject from "lodash/isObject";
import omit from "lodash/omit";

import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import { AppAbility, Permissions, buildAbilityFor } from "shared/helpers/ability";
import { SEARCH_SUGGESTION } from "shared/helpers/constant";
import { getId, getItemFromLocalStorage, setId, setItemToLocalStorage } from "shared/helpers/util";
import { defaultSideBarItems as defaultSideBarItemsV2 } from "shared/helpersV2/sidebar";
import { useMultiYearPlanner } from "shared/store/fiveYearPlanner";
import { useTeam } from "shared/store/settings";
import { useWorkspaceModal } from "shared/store/trial";
import { useSidebar as useSidebarV2 } from "shared/storeV2/sidebar";

import getAllCompanies from "modules/home/helpers/getAllCompanies";
import { UserResponse, UserRole } from "modules/home/types";
import { getTeamMemberPermission } from "modules/settings/teams/api";
import { TeamResponse } from "modules/settings/teams/types";

import {
  CompanySuggestionsDataProps,
  TableFilterTypeProps,
  useSearchSuggestion,
  useTableFilter,
} from "../store/table-column-selection";
import { useUser } from "./UserProvider";
import { CompanyProps } from "./types/company";

export interface CompanyProviderData {
  is_enterprise?: boolean;
  companies: CompanyProps[];
  isLoading: boolean;
  currentCompany: CompanyProps;
  updateCurrentCompany: (data: CompanyProps) => void;
  removeCompany: (data: CompanyProps, isLoggedInUser: boolean) => void;
  refetch: () => void;
  addCompanyList: (data: CompanyProps) => void;
  ability: AppAbility;
  setAbility: (ability: AppAbility) => void;
  isAbilityLoaded: boolean;
  isAdmin: boolean;
  usersList: { memberId: number; user: UserResponse }[];
  currentUserRole: UserRole | undefined;
}
export const CompanyContext = React.createContext({} as CompanyProviderData);

export const CompanyProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const setTeams = useTeam((state) => state.setTeams);
  const setCurrentTeam = useTeam((state) => state.setCurrentTeam);
  const currentTeam = useTeam((state) => state.currentTeam);

  const [setSidebarItemsV2] = useSidebarV2((state) => [state.setSidebarItems]);
  const [usersList, setUsersList] = useState<{ memberId: number; user: UserResponse }[]>([]);

  const setSearchData = useSearchSuggestion((state) => state.setSearchData);

  const setTrialExpired = useWorkspaceModal((state) => state.setIsModalOpen);

  const [setSelectedFilters, setFirstFilterForModules, setSelectedFiltersForModules] =
    useTableFilter((state) => [
      state.setSelectedFilters,
      state.setFirstFilterForModules,
      state.setSelectedFiltersForModules,
    ]);

  const { setDefaultYears } = useMultiYearPlanner();

  const { user: loggedInuser } = useUser();
  const [isAdmin, setIsAdmin] = useState(false);
  const { isLoading, refetch } = useQuery(["getAllCompanies"], getAllCompanies, {
    refetchOnWindowFocus: false,
    enabled: !!loggedInuser,
    onSuccess: (results) => {
      if (results.length === 0) {
        navigate("/on-boarding");
        return;
      }
      if (results.length > 0) {
        setCompanyList(results);
        const companyId = getId("companyID");

        const company =
          (companyId ? results.find((item) => item?.id?.toString() === companyId) : results[0]) ||
          results[0];
        setCurrentCompany(company);

        setUsersList(
          company.organization_users.map((member) => ({ memberId: member.id, user: member.user }))
        );
      }
    },
  });

  const [companyList, setCompanyList] = useState<CompanyProps[]>([]);
  const [currentUserRole, setCurrentUserRole] = useState<UserRole | undefined>();
  const [ability, setAbility] = useState<AppAbility>({} as AppAbility);

  const [isAbilityLoaded, setIsAbilityLoaded] = useState<boolean>(false);

  const [currentCompany, _setCurrentCompany] = useState<CompanyProps>({} as CompanyProps);

  const { data: res } = useQuery(
    ["fetch-team-member-permission", currentTeam?.id, currentCompany.id, loggedInuser?.id],
    () => getTeamMemberPermission(currentTeam?.id, currentCompany.id, Number(loggedInuser?.id)),
    {
      enabled: !!currentTeam?.id && !!loggedInuser?.id && !!currentCompany?.id,
    }
  );

  const setCurrentCompany = useCallback(
    (value: CompanyProps) => {
      // set company id and team id in cookies.
      setId("companyID", value?.id?.toString());

      setSidebarItemsV2(defaultSideBarItemsV2(value?.id));

      _setCurrentCompany(value);
      setUsersList(
        value.organization_users.map((member) => ({ memberId: member.id, user: member.user }))
      );

      setDefaultYears();

      const user = find(value?.organization_users, (_org) => _org.user?.id === loggedInuser.id);

      setIsAdmin(user?.is_admin || false);
      const teams =
        value.teams.filter((_team) => {
          if (_team.members && _team.members?.length === 0) {
            return true;
          } else {
            return _team.members?.includes(loggedInuser.id);
          }
        }) || [];
      setTeams(teams);
      const teamId = getId("teamID");

      const team =
        teams?.filter((team) => team?.name?.toLowerCase() === "general")[0] ||
        teams?.filter((team) => team?.name?.toLowerCase() !== "common")[0] ||
        teams[0];

      const selectedTeam = teamId
        ? teams?.find((item: TeamResponse) => item?.id?.toString() === teamId) || team
        : team;
      setCurrentTeam(selectedTeam);

      // update permission here
      setAbility(
        buildAbilityFor(
          isObject(res?.data)
            ? (res?.data as Permissions)
            : ((selectedTeam?.custom_permissions || {}) as Permissions),
          user?.is_admin
        )
      );
      setIsAbilityLoaded(true);

      const currentUser = value?.organization_users?.find((x) => x.user.id === loggedInuser?.id);
      setCurrentUserRole(currentUser?.role);

      // add the company trial expiration state
      if (value?.trial_expired) {
        setTrialExpired(true);
        return;
      }
    },
    [_setCurrentCompany, loggedInuser]
  );

  const addCompany = (newCompany: CompanyProps) => {
    if (companyList[0] === undefined) {
      setCompanyList([newCompany]);
    } else {
      setCompanyList([newCompany, ...companyList]);
    }
    setSelectedFilters(() => {
      return {} as TableFilterTypeProps;
    });
    setFirstFilterForModules({});

    setSearchData({});

    setCurrentCompany(newCompany);
  };

  const setCurrentCompanyData = (data: CompanyProps) => {
    if (data?.id) {
      closeIntercom();
      setCurrentCompany(data);
      setSelectedFilters(() => {
        return {} as TableFilterTypeProps;
      });
      setFirstFilterForModules({});

      setSearchData({});

      const index = value?.companies?.findIndex((item: CompanyProps) => item.id === data?.id);
      companyList.splice(index, 1);
      companyList.unshift(data);
      setCompanyList([...companyList]);
    }
  };

  const removeCurrentCompanyData = (data: CompanyProps, isLoggedInUser: boolean) => {
    const index = value?.companies?.findIndex((item: CompanyProps) => item.id === data?.id);
    if (isLoggedInUser) {
      const suggestions: CompanySuggestionsDataProps = getItemFromLocalStorage(SEARCH_SUGGESTION);
      const updatedSuggestions = omit(suggestions, [data?.id]);
      setItemToLocalStorage(SEARCH_SUGGESTION, JSON.stringify(updatedSuggestions));

      companyList.splice(index, 1);
      setCompanyList([...companyList]);
      setCurrentCompany(companyList[0]);
      if (companyList.length < 1) {
        navigate("/on-boarding", { state: { isValid: true } });
      }
    } else {
      setCurrentCompanyData(data);
    }
  };

  useEffect(() => {
    try {
      if (currentCompany?.id) {
        Sentry.setContext("company", {
          id: currentCompany.id,
          createdAt: currentCompany.created,
          betaFeatures: currentCompany.beta_features,
          isActive: currentCompany.is_active,
          isEnterprise: currentCompany.is_enterprise,
        });
      } else {
        Sentry.setContext("company", null);
      }
    } catch (error) {
      /* empty */
    }
  }, [currentCompany]);

  useEffect(() => {
    setSelectedFiltersForModules(null);
    setFirstFilterForModules(null);
  }, [currentTeam, currentCompany]);

  const value = {
    companies: companyList,
    isLoading: isLoading,
    currentCompany: currentCompany,
    updateCurrentCompany: setCurrentCompanyData,
    removeCompany: removeCurrentCompanyData,
    refetch,
    addCompanyList: addCompany,
    ability,
    setAbility,
    isAdmin,
    isAbilityLoaded,
    usersList,
    currentUserRole,
  };

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export const useCompany = () => {
  return React.useContext(CompanyContext);
};
