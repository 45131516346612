import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import teamToQueryParams from "shared/helpers/teamToQueryParams";
import { TeamParameter } from "shared/types";

import { getUrlName } from "../helpers";
import getParamName from "../helpers/getParamName";
import { PermissionCreatedResponse, PermissionInputData, PermissionModule } from "../types";

/**
 * Api call to create permission for object assume that object is already created
 * data is valid
 */
export default function createPermission(
  module: PermissionModule,
  objectId: number,
  data: PermissionInputData,
  team: TeamParameter
): Promise<AxiosResponse<PermissionCreatedResponse>> {
  return api.post<PermissionCreatedResponse>(`/${getUrlName(module)}/permissions/`, data, {
    params: {
      [getParamName(module)]: objectId,
      ...teamToQueryParams(team),
    },
  });
}
