import React, { useEffect, useState } from "react";

import clsx from "clsx";
import get from "lodash/get";

import CommonDropdownV2 from "shared/componentsV2/common-dropdownV2";
import { useCompany } from "shared/context/CompanyProvider";
import { DropdownOptionType } from "shared/types";

import { SelectOptionProps } from "modules/settings/teams/types";

import { GroupInvitationType } from "../types";

export default function EmailWithRole({
  data,
  handleChange,
  selectedMember,
  className,
  hasError,
}: {
  data: GroupInvitationType;
  handleChange: (data: GroupInvitationType) => void;
  selectedMember: Array<number>;
  className: string;
  hasError?: boolean;
}) {
  const { currentCompany } = useCompany();

  const [selectedMembers, setSelectedMembers] = useState<SelectOptionProps[]>([]);

  const onEmailChange = (selectedOptions: DropdownOptionType[]) => {
    const [selectedOption] = selectedOptions;

    if (!selectedOption) {
      handleChange({
        email: { label: ``, value: 0 },
      });

      setSelectedMembers([]);
      return;
    }

    setSelectedMembers([
      {
        value: Number(selectedOption.value),
        label: selectedOption.label,
      },
    ]);

    handleChange({
      email: {
        label: get(selectedOption, "label", ""),
        value: Number(get(selectedOption, "value", "")),
        email: get(selectedOption, "data.user.email", ""),
      },
    });
  };

  useEffect(() => {
    setSelectedMembers(
      data.email.label ? [{ label: data.email.label, value: data.email.value }] : []
    );
  }, [data]);

  return (
    <div className={clsx(className)}>
      <CommonDropdownV2
        hasError={hasError}
        isClearable={true}
        selectedValue={selectedMembers.map((data) => ({ ...data, data, label: `${data.label}` }))}
        onChange={onEmailChange}
        apiDetails={{
          url: `companies/members/?exclude_user__in=${selectedMember}`,
          props: { company: currentCompany?.id },
        }}
        optionField={{ label: "user.name,user.email", value: "user.id" }}
        defaultOrderingField="user__name,user__email"
        searchKey="search"
        placeholder="name@example.com"
        excludeOptionIds={selectedMember}
      />
    </div>
  );
}
