import React from "react";

import clsx from "clsx";
import { Modal, Tabs } from "flowbite-react";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import { useCompany } from "shared/context/CompanyProvider";
import { getMembersList } from "shared/helpers/getMemersList";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import useKeyPress from "shared/hooks/useKeyPress";
import { useTeam } from "shared/store/settings";

import General from "../members/General";
import Members from "../members/Members";
import Permission from "../members/Permission";
import { ManageTeamProps } from "../types";

const ManageTeams = ({ refetch }: ManageTeamProps) => {
  const { currentCompany, updateCurrentCompany } = useCompany();

  const [selectedTeam, isManageModalOpen, setIsManageModalOpen, activeTab, setActiveTab] = useTeam(
    (store) => [
      store.selectedTeam,
      store.isManageModalOpen,
      store.setIsManageModalOpen,
      store.activeTab,
      store.setActiveTab,
    ]
  );

  const { documentBodyRef } = useDocumentBodyRef();

  useKeyPress(() => setIsManageModalOpen(false));

  const onCloseModal = () => {
    setIsManageModalOpen(false);
    if (selectedTeam) {
      updateCurrentCompany({
        ...currentCompany,
        teams: currentCompany.teams.map((team) => {
          if (team.id !== selectedTeam?.id) return team;
          else return selectedTeam;
        }),
      });
    }
    setActiveTab(0);
  };

  return (
    <Modal
      root={documentBodyRef}
      show={isManageModalOpen}
      size="xl"
      onClose={onCloseModal}
      className="provider-modal"
    >
      <Modal.Header className={clsx(styles.manage_teams_header, "maxMd:px-4 maxMd:py-3")}>
        <Avatar
          name={selectedTeam?.name || ""}
          avatarFor="team"
          avatarType="squared"
          size="sm"
          url={selectedTeam?.team_picture}
        />
        <span className="ml-3 text-lg text-mirage dark:text-white mac13Inch:text-base">
          {selectedTeam?.name}
        </span>
      </Modal.Header>
      <Modal.Body className="overflow-x-hidden maxMd:px-4 maxMd:py-5 mac13Inch:pb-0">
        <Tabs.Group
          aria-label=""
          style="underline"
          defaultValue="permission"
          onActiveTabChange={(tab) => setActiveTab(tab)}
          className={styles.manage_teams_tabs}
        >
          <Tabs.Item active={activeTab === 0} title="General" id="general">
            <div>
              <General />
            </div>
          </Tabs.Item>
          <Tabs.Item
            id="member"
            active={activeTab === 1}
            title={
              <div>
                Members{" "}
                <span className="text-aurometalsaurus dark:text-boulder">
                  {getMembersList(currentCompany, selectedTeam)?.length}
                </span>
              </div>
            }
          >
            <div>
              <Members refetch={refetch} />
            </div>
          </Tabs.Item>
          <Tabs.Item active={activeTab === 2} title="Permissions" id="permission">
            <div className="-mt-4">
              <Permission />
            </div>
          </Tabs.Item>
        </Tabs.Group>
      </Modal.Body>
    </Modal>
  );
};

export default ManageTeams;
