import React, { useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { useMutation, useQuery } from "@tanstack/react-query";

import style from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import { CompanyMember } from "shared/context/types/company";
import { CREATE_ACCESS_ROLE_LEVEL, INITIAL_ROLE_LEVEL, ROLES_LIST } from "shared/helpers/constant";
import { useWorkspaceModal } from "shared/store/trial";

import { getMemberByEmail } from "modules/home/api";
import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import MemberListing from "./components/MemberListing";
import MembersHeader from "./components/MembersHeader";

export default function Members() {
  const { currentCompany } = useCompany();
  const { user } = useUser();

  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const [filterKey, setFilterKey] = useState<string | null>(null);

  const [filteredMembers, setFilteredMembers] = useState<CompanyMember[]>([]);

  const { isLoading: isMemberSearchLoading, mutateAsync: searchMembers } = useMutation(
    ["getMemberByEmail", "settingCurrentMember", currentCompany?.id],
    ({ searchKey }: { searchKey: string }) =>
      getMemberByEmail(currentCompany?.id, { search: searchKey }),
    {
      onSuccess: (response) => {
        setFilteredMembers(response.data.results);
      },
    }
  );

  const handleChangeFilter = useCallback((searchKey: string | null) => {
    setNewMembers([]);
    setFilterKey(searchKey);
    if (searchKey) {
      searchMembers({ searchKey });
    }
  }, []);

  const [newMembers, setNewMembers] = useState<Array<{ email: string; role: string }>>([]);
  const handleChangeNewMembers = useCallback(
    (
      data: React.SetStateAction<
        {
          email: string;
          role: string;
        }[]
      >
    ) => setNewMembers(data),
    []
  );

  const {
    data: companyMemberList,
    refetch: refetchMemberList,
    isLoading,
    isFetching,
  } = useQuery(
    ["getMemberByCompany", "settingMember", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const {
    data: currentMember,
    isLoading: isMemberLoading,
    isFetching: isMemberFetching,
  } = useQuery(
    ["getMemberByEmail", "settingCurrentMember", currentCompany?.id],
    () => getMemberByEmail(currentCompany?.id, { search: user.email }),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const memberList: CompanyMember[] = [
    ...(companyMemberList?.data?.results || []),
    ...(currentCompany?.organization_invites ? [...currentCompany.organization_invites] : []),
  ];

  const membersList = filterKey ? filteredMembers : memberList;

  const areSeatsOver =
    (companyMemberList?.data?.count || 0) + newMembers.length >= currentCompany.seats;

  const loggedInUserRole = useMemo(() => {
    const loggedInUser = currentMember?.data?.results?.find((data) => data.user.id === user.id);
    return ROLES_LIST.find((roleDetail) => roleDetail.value === loggedInUser?.role);
  }, [user, currentMember]);

  const canEdit = (loggedInUserRole?.level || INITIAL_ROLE_LEVEL) >= CREATE_ACCESS_ROLE_LEVEL;

  return (
    <>
      {isLoading || isFetching || isMemberLoading || isMemberFetching || isMemberSearchLoading ? (
        <CentralSpinner />
      ) : null}

      <div className={style.setting_layout}>
        {areSeatsOver ? (
          <div className="m-4 flex gap-2 rounded-md border border-valentinered p-4 ">
            <span className="rounded-lg bg-mistyrose p-[6px] ">
              <Icon type="bell-warning" className={style.warningIcons} size="icon-smd" />
            </span>
            <div className="border-valentinered font-inter-medium text-xs text-lavared ">
              You have reached the maximum number of users. Please contact our support team at{" "}
              <a className={"underline"} href={"mailto:help@grand.io"}>
                help@grand.io
              </a>{" "}
              to increase the number of available seats in your account.
            </div>
          </div>
        ) : null}
        <div className={style.layout_card_info}>
          <MembersHeader
            setFilterKey={handleChangeFilter}
            memberCount={
              (companyMemberList?.data?.count || 0) + currentCompany?.organization_invites?.length
            }
          />
        </div>
        <MemberListing
          loggedInUserRole={loggedInUserRole}
          isEdit={canEdit}
          membersList={membersList}
          newMembers={newMembers}
          setNewMembers={handleChangeNewMembers}
          refetchMemberList={() => {
            if (filterKey) {
              searchMembers({ searchKey: filterKey });
            } else {
              refetchMemberList();
            }
          }}
        />
      </div>

      {canEdit && !filterKey ? (
        <div
          className={clsx(
            style.layout_card_info,
            "sticky bottom-0 z-[1] flex w-full cursor-pointer items-center justify-center gap-2  border-b border-t border-brightgray bg-white py-4 dark:border-thunders dark:bg-darkjunglegreenss dark:text-white",
            { "cursor-not-allowed opacity-50": areSeatsOver }
          )}
          onClick={() => {
            if (areSeatsOver) {
              return;
            }
            if (currentCompany?.trial_expired) {
              setWorkspaceModalOpen(true);
            } else {
              const defaultMember = { email: "", role: "" };
              setNewMembers((state) => [...state, defaultMember]);
            }
          }}
        >
          <>
            <Icon type="plus" className={style.create_icon} />
            Invite member
          </>
        </div>
      ) : null}
    </>
  );
}
