import Lottie from "lottie-react";

import grandSpinner from "./grand-spinner.json";

const Spinner = ({ height = 120, width = 120 }: { height?: number; width?: number }) => {
  return (
    <div className="flex w-full flex-col items-center justify-center" data-testid="spinner">
      <Lottie animationData={grandSpinner} style={{ height, width }} />
    </div>
  );
};

export default Spinner;
