import React from "react";

import Icon from "shared/components/icon/Icon";

import { FileViewerProps } from "./types";

export default function DefaultFileViewer({ document }: FileViewerProps) {
  return (
    <div
      data-testid="default-file-viewer"
      className="doc_preview flex w-full flex-col items-center justify-center maxMd:p-4"
    >
      <p className="mb-4 text-aurometalsaurus">No preview available</p>
      <a
        href={document.file}
        className="btn_primary  flex h-10 items-center gap-x-1 maxMd:justify-center"
        target={"_blank"}
        download
        rel="noopener noreferrer"
      >
        <Icon type="download" fill={false} size="icon-smd" className="notification_icon" />
        Download
      </a>
    </div>
  );
}
