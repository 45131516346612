import React from "react";
import { Link, useLocation } from "react-router-dom";

import clsx from "clsx";

import { useCompany } from "shared/context/CompanyProvider";
import { subMenuOverview } from "shared/helpers/constant";
import { defaultSideBarItems } from "shared/helpers/sidebar";

import { itemTyps } from "../sidebar/types";

const HeaderMenu = () => {
  const { pathname } = useLocation();

  const { currentCompany } = useCompany();
  const activePath = defaultSideBarItems(currentCompany?.id).find((sidebarItem) => {
    const path =
      pathname[pathname.length - 1] === "/dashboard/overview"
        ? pathname.slice(0, pathname.length - 1)
        : pathname;

    if (path === sidebarItem.path && !sidebarItem?.items) {
      return sidebarItem;
    }

    return (
      sidebarItem.items?.find((child) => pathname?.includes(child.path.toLowerCase())) ||
      pathname?.includes(sidebarItem.label.toLowerCase()) ||
      (pathname === "/dashboard/overview" && sidebarItem.label === "Dashboard")
    );
  });

  return (
    <div data-testid="HeaderMenu_comp">
      {activePath?.items ? (
        (activePath?.items || []).map((submenu: itemTyps, index: number) => {
          const condition =
            pathname?.includes(submenu.path?.toLowerCase()) ||
            (subMenuOverview?.includes(pathname?.split("/")[2]?.toLowerCase()) &&
              subMenuOverview?.includes(submenu?.path?.split("/")[2]?.toLowerCase()) &&
              pathname?.includes(submenu?.path?.split("/")[1]?.toLowerCase()));

          return (
            <Link
              to={submenu.path || "/"}
              className={clsx(
                {
                  header_link_active: condition,
                },
                "mr-8 text-aurometalsaurus hover:!text-mirage dark:text-boulder dark:hover:!text-white "
              )}
              key={index}
            >
              {submenu.label}
            </Link>
          );
        })
      ) : (
        <Link
          to={activePath?.path || "/"}
          className={clsx(
            {
              header_link_active: pathname?.includes((activePath?.path || "")?.toLowerCase()),
            },
            "mr-8 font-sans-medium  text-aurometalsaurus hover:!text-mirage dark:!text-white"
          )}
        >
          {activePath?.label}
        </Link>
      )}
    </div>
  );
};

export default HeaderMenu;
