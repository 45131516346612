import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";

import { useUser } from "shared/context/UserProvider";
import { options, ownerOptions } from "shared/helpers/constant";
import { useSharedObjectModal } from "shared/store/sharedobject";
import { SharedObjectRole } from "shared/types";

import { useCompany } from "../../../context/CompanyProvider";
import { User } from "../../../context/types/company";
import Avatar from "../../avatar";
import CustomDropdown from "../../custom-dropdown";
import { deleteSharedObject, updateSharedObjectOwner, updateSharedObjectRole } from "../api";

type MembersProp = {
  isTeamDetailView?: boolean;
  user: User;
  isEditAccess?: boolean;
  ownerBadge?: boolean;
  transferOwnerUrl?: string;
  refetchData?: CallableFunction;
  isUsers?: boolean;
} & (
  | {
      isOptionsAvailable: true;
      sharedObjectId?: number;
      role: SharedObjectRole;
      triggerUpdate: () => Promise<void> | null;
    }
  | {
      isOptionsAvailable: false;
      role?: never;
      sharedObjectId?: never;
      triggerUpdate?: never;
    }
);

const Members = ({
  isOptionsAvailable,
  isTeamDetailView,
  sharedObjectId,
  role,
  user,
  triggerUpdate,
  isEditAccess,
  ownerBadge,
  transferOwnerUrl,
  refetchData,
  isUsers,
}: MembersProp) => {
  const { currentCompany } = useCompany();
  const { user: currentUser } = useUser();
  const { objectOwner, setObjectOwner } = useSharedObjectModal();
  const [selectedOptionBuffer, setSelectedOptionBuffer] = useState<string>(role || "");
  useEffect(() => {
    setSelectedOptionBuffer(role || "");
  }, [role]);

  if (!user) {
    return null;
  }

  const isTransferable = objectOwner && currentUser.id === objectOwner.id;

  return (
    <>
      <div className="flex !w-full items-center justify-between gap-2 rounded-lg px-2 py-1">
        <div className="flex items-center gap-2">
          <Avatar
            name={user?.name}
            avatarFor="user"
            avatarType="rounded"
            size="sm"
            url={user.profile_picture}
            className="cursor-default"
          />
          <div className="member_name_container cursor-default">
            <div className="flex items-center gap-2">
              <p className="text-xs font-medium text-richblack dark:text-white">{user?.name}</p>
              {user.name ? (
                <div className={clsx({ hidden: !(currentUser?.id === user?.id) })}>
                  <p className="rounded-[0.25rem] bg-lavender p-1 py-[0.13rem] text-xs text-crayolasblue">
                    You
                  </p>
                </div>
              ) : null}
            </div>
            {!isTeamDetailView && (
              <div className="flex items-center gap-2">
                <p className="text-sm font-normal text-santagrey dark:text-greychateau">
                  {user?.email}
                </p>
                <div className={clsx({ hidden: !(currentUser?.id === user?.id && !user.name) })}>
                  <p className="rounded-[0.25rem] bg-lavender p-1  py-[0.13rem] text-xs text-crayolasblue">
                    You
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {isOptionsAvailable ? (
          <div className={"access_select_container"}>
            <div
              className={clsx("custom_select cursor-pointer", {
                "cursor-not-allowed opacity-50": !isEditAccess || ownerBadge,
              })}
            >
              <CustomDropdown
                options={(ownerBadge ? ownerOptions : options).filter(
                  (_) => !!transferOwnerUrl || _.value !== "TRANSFER_OWNER"
                )}
                isGeneralAccess={false}
                value={selectedOptionBuffer}
                isEditAccess={isEditAccess}
                isTransferable={isTransferable}
                isOwner={ownerBadge}
                isUsers={isUsers}
                onChange={async (value: string) => {
                  if (!isEditAccess) {
                    toast.error("You don't have permissions to edit the shared object");
                    return;
                  }
                  if (value === "TRANSFER_OWNER") {
                    try {
                      if (objectOwner && currentUser.id !== objectOwner.id) return;
                      if (!sharedObjectId) return;
                      const owner = { object_owner: user?.id };
                      const res = await updateSharedObjectOwner(
                        transferOwnerUrl as string,
                        currentCompany?.id,
                        owner
                      );
                      if (res.status === 200) {
                        await triggerUpdate();
                        setObjectOwner(user);
                      } else {
                        await triggerUpdate();
                      }
                      refetchData && refetchData();
                    } catch (ex) {
                      toast.error(
                        (ex as AxiosError<{ error: string }>)?.response?.data?.error ||
                          "Something went wrong"
                      );
                    }
                  } else if (value === "REMOVE_ACCESS") {
                    try {
                      if (!sharedObjectId) return;
                      await deleteSharedObject(currentCompany.id, sharedObjectId);
                      toast.success("Access removed");
                      await triggerUpdate();
                      refetchData && refetchData();
                    } catch (ex) {
                      toast.error(
                        (ex as AxiosError<{ error: string }>)?.response?.data?.error ||
                          "Something went wrong"
                      );
                    }
                  } else {
                    try {
                      if (!sharedObjectId) return;
                      setSelectedOptionBuffer(value);
                      await updateSharedObjectRole(
                        currentCompany.id,
                        sharedObjectId,
                        value as SharedObjectRole
                      );
                      toast.success("Access updated");
                      await triggerUpdate();
                    } catch (ex) {
                      setSelectedOptionBuffer(role);
                      toast.error(
                        (ex as AxiosError<{ error: string }>)?.response?.data?.error ||
                          "Something went wrong"
                      );
                    }
                  }
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Members;
