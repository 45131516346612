import { AxiosResponse } from "axios";
import clsx from "clsx";

import { useQuery } from "@tanstack/react-query";

import headerStyles from "assets/css/common-header.module.css";

import CommentsList from "shared/components/comments-list";
import LexicalEditor from "shared/components/lexical-editor";
import MetadataEditor from "shared/components/lexical-editor/metadata-editor/MetadataEditor";
import { initialDataForList } from "shared/helpers/util";

import { getComments } from "../api";
import { commentProps } from "../types";
import SubmitContent from "./SubmitContent";

const Comments = ({ type = "default", id, teamId, companyId, isAllTeam }: commentProps) => {
  const { data, refetch, isLoading } = useQuery(
    ["getComments", [], teamId, id],
    () => {
      if (!companyId) {
        return Promise.reject("Company is not selected");
      }

      if (!teamId) {
        return Promise.reject("Team is not selected");
      }
      return getComments(type, id, { teamId, companyId, isAllTeam });
    },
    {
      initialData: () => ({ data: { ...initialDataForList } } as AxiosResponse),
      enabled: !!teamId,
    }
  );
  const commentList = data?.data?.results || [];

  return (
    <>
      <div className="metadata-tabs">
        <h1 className={clsx(headerStyles.subscriptionTitle, "metadata-tabs__header")}>Comments</h1>
        <div className="metadata-editor-container">
          <LexicalEditor>
            {() => (
              <MetadataEditor>
                {(editor) => (
                  <SubmitContent
                    editor={editor}
                    refetch={refetch}
                    type={type}
                    id={id}
                    teamId={teamId}
                    companyId={companyId}
                  />
                )}
              </MetadataEditor>
            )}
          </LexicalEditor>
        </div>
        {commentList?.length > 0 ? (
          <CommentsList comments={commentList} isLoading={isLoading} />
        ) : null}
      </div>
    </>
  );
};

export default Comments;
