import { useMemo } from "react";

import clsx from "clsx";
import get from "lodash/get";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Avatar from "shared/components/avatar";
import Icon from "shared/components/icon/Icon";
import CommonDropdownV2 from "shared/componentsV2/common-dropdownV2";
import { useCompany } from "shared/context/CompanyProvider";
import { GUEST_ROLE } from "shared/helpers/constant";
import { getUsername } from "shared/helpers/util";
import { tsAwareBooleanFilter } from "shared/helpersV2/tsAwareBooleanFilter";
import { useTeam } from "shared/store/settings";
import { SharedObject } from "shared/types";

import { CompanyUserResponse, UserRole } from "modules/home/types";
import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useFeedMembers } from "modules/horizonScanning/hooks/useFeedMembers";
import useFeed from "modules/horizonScanning/shared/hooks/useFeed";

const ArticleAssigneesDropdown = ({ disabled }: { disabled?: boolean }) => {
  const currentTeam = useTeam((state) => state.currentTeam);
  const { usersList, currentCompany } = useCompany();
  const {
    feedId,
    articleQuery: { data: article },
    updateAssignees,
  } = useArticleContext();
  const { data: feed } = useFeed({ feedId });
  const { mentionableUsers } = useFeedMembers({ feedId });

  const apiDetails = useMemo(() => {
    switch (feed?.visibility) {
      case "public":
        return {
          url: "companies/members/",
          props: {
            company: currentTeam.company,
          },
        };
      default:
        return undefined;
    }
  }, [feed?.visibility]);

  const accessKey = useMemo(() => {
    switch (feed?.visibility) {
      case "public":
        return "user";
      case "private":
        return "user";
      case "shared":
        return "shared_with_user";
      default:
        return "user";
    }
  }, [feed?.visibility]);

  if (!article) return null;

  const feedOwner = usersList.find(({ user }) => user.id === feed?.objectOwner);

  const defaultOptions = feedOwner?.user
    ? [
        {
          value: feedOwner?.user.id,
          name: feedOwner?.user.name || feedOwner?.user.email,
          label: feedOwner?.user.name || feedOwner?.user.email,
          user: feedOwner?.user,
          shared_with_user: feedOwner?.user,
          data: {
            id: feedOwner?.user.id,
            user: feedOwner?.user,
            shared_with_user: feedOwner?.user,
            created: "",
            modified: "",
            is_admin: false,
            custom_permissions: feedOwner?.user.custom_permissions ?? {},
            organization: currentCompany.id,
            role: GUEST_ROLE as UserRole,
          },
        },
      ]
    : undefined;

  const constantOptions = [
    ...mentionableUsers.map((user) => ({
      value: String(user.id),
      name: user.name || user.email,
      label: user.name || user.email,
      user: user,
      shared_with_user: user,
      data: {
        id: user.id,
        user: user,
        shared_with_user: user,
        created: "",
        modified: "",
        is_admin: false,
        custom_permissions: user.custom_permissions ?? {},
        organization: currentCompany.id,
        role: GUEST_ROLE as UserRole,
      },
    })),
  ];

  const usersListHashMap = Object.fromEntries(
    usersList.map((user) => [String(user.user.id), user.user])
  );

  const selectedAssignees =
    article.assignees
      ?.map((userId) => {
        const user = usersListHashMap[String(userId)];

        return user
          ? {
              value: String(userId),
              label: user.name || user.email,
              data: {
                id: userId,
                user: user,
                shared_with_user: user,
                created: "",
                modified: "",
                is_admin: false,
                custom_permissions: user.custom_permissions ?? {},
                organization: currentCompany.id,
                role: GUEST_ROLE as UserRole,
              },
            }
          : null;
      })
      .filter(tsAwareBooleanFilter) ?? [];

  return (
    <>
      <div className="flex min-h-[1.5rem] flex-wrap gap-4">
        {selectedAssignees.map((assignee) => {
          const profilePicture = assignee.data.user.profile_picture || "";
          return (
            <div key={assignee.label} className="flex items-center gap-1.5">
              {profilePicture ? (
                <img
                  className={clsx("h-5 w-5 rounded-full", { "opacity-70": disabled })}
                  src={profilePicture}
                  alt="avatar"
                />
              ) : (
                <Avatar
                  name={getUsername(assignee.data.user)}
                  size="smd"
                  avatarType="rounded"
                  avatarFor="user"
                  className={clsx({ "cursor-default opacity-70": disabled })}
                />
              )}
              <span
                className={clsx("text-sm", {
                  "text-richblack dark:text-lighthouse": !disabled,
                  "text-gray-400 dark:text-gray_400": disabled,
                })}
              >
                {assignee.label}
              </span>
              {!disabled ? (
                <button
                  onClick={() => {
                    const filteredAssignees = selectedAssignees.filter(
                      (item) => item.value !== assignee.value
                    );

                    updateAssignees(filteredAssignees.map((assignee) => Number(assignee.value)));
                  }}
                >
                  <Icon type="close-small" fill={true} size="icon-xs" />
                </button>
              ) : null}
            </div>
          );
        })}
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild disabled={disabled}>
            <button
              aria-label="Customise options"
              title="Change View"
              disabled={disabled}
              className="focus-visible:outline-none"
            >
              {selectedAssignees?.length > 0 ? (
                <span className="add_btn block rounded border border-gray-200 p-0.5 shadow-sm">
                  {!disabled ? <Icon type="plus-btn" fill={true} size="icon-xs" /> : null}
                </span>
              ) : (
                <div className="flex items-center gap-1">
                  <span className="font-inter-medium text-sm text-santagrey dark:text-gray_400">
                    Select assignees
                  </span>
                  <Icon type="chevron-down" size="icon-sm" fill={true} />
                </div>
              )}
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            className="member_search_dropdown"
            align="start"
            sideOffset={5}
            side="bottom"
          >
            <CommonDropdownV2<CompanyUserResponse | SharedObject>
              isMultiSelect={true}
              className="common_dropdown_v2"
              classNamePrefix="combobox_dropdown"
              placeholder="Search Collaborators..."
              isDisabled={disabled}
              selectedValue={[]}
              onChange={(data) => {
                const assignees = selectedAssignees.find(
                  (assignee) => Number(assignee.value) === Number(data[0].value)
                )
                  ? selectedAssignees
                  : [...selectedAssignees, ...data];

                updateAssignees(assignees.map((assignee) => Number(assignee.value)));
              }}
              menuIsOpen={true}
              constantOptions={feed?.visibility === "private" ? defaultOptions : constantOptions}
              defaultOptions={feed?.visibility === "shared" ? defaultOptions : undefined}
              apiDetails={apiDetails}
              searchKey="search"
              defaultOrderingField={
                feed?.visibility === "public" ? "user__name,user__email" : "name,description"
              }
              optionField={{
                label: `${accessKey}.name,${accessKey}.email`,
                value: `${accessKey}.id`,
              }}
              OptionComponent={(data) => {
                const profilePicture = get(data, `data.data.${accessKey}.profile_picture`, "");
                return (
                  <div className="flex w-full items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      {profilePicture ? (
                        <img className="h-4 w-4 rounded-full" src={profilePicture} alt="avatar" />
                      ) : (
                        <Avatar
                          name={getUsername(get(data, `data.data.${accessKey}`))}
                          size="smd"
                          avatarType="rounded"
                          avatarFor="user"
                        />
                      )}
                      <span className="whitespace-nowrap text-sm text-gray-700 dark:text-uniquegrey">
                        {get(data, "data.label", "")}
                      </span>
                    </div>
                    {selectedAssignees.find(
                      (assignee) => Number(assignee.value) === Number(get(data, "data.value", null))
                    ) ? (
                      <Icon
                        type="checkmark"
                        fill={true}
                        size="icon-xs"
                        className="checkmark_icon"
                      />
                    ) : null}
                  </div>
                );
              }}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </>
  );
};

export default ArticleAssigneesDropdown;
