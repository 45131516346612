/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `pending` - Pending
 * * `in_progress` - In Progress
 * * `completed` - Completed
 * @export
 */
export const ArticleStatusEnum = {
    Pending: 'pending',
    InProgress: 'in_progress',
    Completed: 'completed'
} as const;
export type ArticleStatusEnum = typeof ArticleStatusEnum[keyof typeof ArticleStatusEnum];


export function instanceOfArticleStatusEnum(value: any): boolean {
    for (const key in ArticleStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(ArticleStatusEnum, key)) {
            if (ArticleStatusEnum[key as keyof typeof ArticleStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ArticleStatusEnumFromJSON(json: any): ArticleStatusEnum {
    return ArticleStatusEnumFromJSONTyped(json, false);
}

export function ArticleStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleStatusEnum {
    return json as ArticleStatusEnum;
}

export function ArticleStatusEnumToJSON(value?: ArticleStatusEnum | null): any {
    return value as any;
}

