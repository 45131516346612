/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChatTypeEnum } from './ChatTypeEnum';
import {
    ChatTypeEnumFromJSON,
    ChatTypeEnumFromJSONTyped,
    ChatTypeEnumToJSON,
} from './ChatTypeEnum';
import type { SourceRequest } from './SourceRequest';
import {
    SourceRequestFromJSON,
    SourceRequestFromJSONTyped,
    SourceRequestToJSON,
} from './SourceRequest';

/**
 * 
 * @export
 * @interface ChatCreateRequest
 */
export interface ChatCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ChatCreateRequest
     */
    title?: string | null;
    /**
     * 
     * @type {ChatTypeEnum}
     * @memberof ChatCreateRequest
     */
    type?: ChatTypeEnum;
    /**
     * 
     * @type {Array<SourceRequest>}
     * @memberof ChatCreateRequest
     */
    documents: Array<SourceRequest> | null;
    /**
     * 
     * @type {number}
     * @memberof ChatCreateRequest
     */
    teamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChatCreateRequest
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChatCreateRequest
     */
    assistantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChatCreateRequest
     */
    initialMessage?: string | null;
}



/**
 * Check if a given object implements the ChatCreateRequest interface.
 */
export function instanceOfChatCreateRequest(value: object): value is ChatCreateRequest {
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function ChatCreateRequestFromJSON(json: any): ChatCreateRequest {
    return ChatCreateRequestFromJSONTyped(json, false);
}

export function ChatCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatCreateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'type': json['type'] == null ? undefined : ChatTypeEnumFromJSON(json['type']),
        'documents': (json['documents'] == null ? null : (json['documents'] as Array<any>).map(SourceRequestFromJSON)),
        'teamId': json['team_id'] == null ? undefined : json['team_id'],
        'companyId': json['company_id'] == null ? undefined : json['company_id'],
        'assistantId': json['assistant_id'] == null ? undefined : json['assistant_id'],
        'initialMessage': json['initial_message'] == null ? undefined : json['initial_message'],
    };
}

export function ChatCreateRequestToJSON(value?: ChatCreateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'type': ChatTypeEnumToJSON(value['type']),
        'documents': (value['documents'] == null ? null : (value['documents'] as Array<any>).map(SourceRequestToJSON)),
        'team_id': value['teamId'],
        'company_id': value['companyId'],
        'assistant_id': value['assistantId'],
        'initial_message': value['initialMessage'],
    };
}

