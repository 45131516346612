/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Todo } from './Todo';
import {
    TodoFromJSON,
    TodoFromJSONTyped,
    TodoToJSON,
} from './Todo';
import type { ArticleImpactEnum } from './ArticleImpactEnum';
import {
    ArticleImpactEnumFromJSON,
    ArticleImpactEnumFromJSONTyped,
    ArticleImpactEnumToJSON,
} from './ArticleImpactEnum';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';
import type { Publisher } from './Publisher';
import {
    PublisherFromJSON,
    PublisherFromJSONTyped,
    PublisherToJSON,
} from './Publisher';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { ArticleStatusEnum } from './ArticleStatusEnum';
import {
    ArticleStatusEnumFromJSON,
    ArticleStatusEnumFromJSONTyped,
    ArticleStatusEnumToJSON,
} from './ArticleStatusEnum';
import type { Section } from './Section';
import {
    SectionFromJSON,
    SectionFromJSONTyped,
    SectionToJSON,
} from './Section';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    originalTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    hasClassification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    readonly isRead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    readonly isSaved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Article
     */
    readonly isArchived: boolean;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof Article
     */
    readonly status: ArticleStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    publicationDate?: Date | null;
    /**
     * 
     * @type {Publisher}
     * @memberof Article
     */
    publisher: Publisher;
    /**
     * 
     * @type {Array<number>}
     * @memberof Article
     */
    readonly assignees: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    readonly commentsCount: number;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Article
     */
    readonly tags: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    mitigatingEfforts?: string | null;
    /**
     * 
     * @type {ArticleImpactEnum}
     * @memberof Article
     */
    readonly impact: ArticleImpactEnum;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    timeline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    institutionTypes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    potentialImpact?: string | null;
    /**
     * 
     * @type {Array<Region>}
     * @memberof Article
     */
    regions: Array<Region>;
    /**
     * 
     * @type {Array<Section>}
     * @memberof Article
     */
    sections: Array<Section>;
    /**
     * 
     * @type {Array<Todo>}
     * @memberof Article
     */
    readonly todos: Array<Todo>;
}



/**
 * Check if a given object implements the Article interface.
 */
export function instanceOfArticle(value: object): value is Article {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('hasClassification' in value) || value['hasClassification'] === undefined) return false;
    if (!('isRead' in value) || value['isRead'] === undefined) return false;
    if (!('isSaved' in value) || value['isSaved'] === undefined) return false;
    if (!('isArchived' in value) || value['isArchived'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('publisher' in value) || value['publisher'] === undefined) return false;
    if (!('assignees' in value) || value['assignees'] === undefined) return false;
    if (!('commentsCount' in value) || value['commentsCount'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('impact' in value) || value['impact'] === undefined) return false;
    if (!('regions' in value) || value['regions'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    if (!('todos' in value) || value['todos'] === undefined) return false;
    return true;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'originalTitle': json['original_title'] == null ? undefined : json['original_title'],
        'description': json['description'] == null ? undefined : json['description'],
        'hasClassification': json['has_classification'],
        'isRead': json['is_read'],
        'isSaved': json['is_saved'],
        'isArchived': json['is_archived'],
        'status': ArticleStatusEnumFromJSON(json['status']),
        'publicationDate': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'publisher': PublisherFromJSON(json['publisher']),
        'assignees': json['assignees'],
        'commentsCount': json['comments_count'],
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'url': json['url'],
        'mitigatingEfforts': json['mitigating_efforts'] == null ? undefined : json['mitigating_efforts'],
        'impact': ArticleImpactEnumFromJSON(json['impact']),
        'timeline': json['timeline'] == null ? undefined : json['timeline'],
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'potentialImpact': json['potential_impact'] == null ? undefined : json['potential_impact'],
        'regions': ((json['regions'] as Array<any>).map(RegionFromJSON)),
        'sections': ((json['sections'] as Array<any>).map(SectionFromJSON)),
        'todos': ((json['todos'] as Array<any>).map(TodoFromJSON)),
    };
}

export function ArticleToJSON(value?: Omit<Article, 'id'|'is_read'|'is_saved'|'is_archived'|'status'|'assignees'|'comments_count'|'tags'|'impact'|'todos'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'original_title': value['originalTitle'],
        'description': value['description'],
        'has_classification': value['hasClassification'],
        'publication_date': value['publicationDate'] == null ? undefined : ((value['publicationDate'] as any).toISOString().substring(0,10)),
        'publisher': PublisherToJSON(value['publisher']),
        'url': value['url'],
        'mitigating_efforts': value['mitigatingEfforts'],
        'timeline': value['timeline'],
        'institution_types': value['institutionTypes'],
        'potential_impact': value['potentialImpact'],
        'regions': ((value['regions'] as Array<any>).map(RegionToJSON)),
        'sections': ((value['sections'] as Array<any>).map(SectionToJSON)),
    };
}
