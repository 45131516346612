/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextArea
 */
export interface TextArea {
    /**
     * 
     * @type {number}
     * @memberof TextArea
     */
    x0: number;
    /**
     * 
     * @type {number}
     * @memberof TextArea
     */
    y0: number;
    /**
     * 
     * @type {number}
     * @memberof TextArea
     */
    x1: number;
    /**
     * 
     * @type {number}
     * @memberof TextArea
     */
    y1: number;
    /**
     * 
     * @type {number}
     * @memberof TextArea
     */
    pageIndex: number;
}

/**
 * Check if a given object implements the TextArea interface.
 */
export function instanceOfTextArea(value: object): value is TextArea {
    if (!('x0' in value) || value['x0'] === undefined) return false;
    if (!('y0' in value) || value['y0'] === undefined) return false;
    if (!('x1' in value) || value['x1'] === undefined) return false;
    if (!('y1' in value) || value['y1'] === undefined) return false;
    if (!('pageIndex' in value) || value['pageIndex'] === undefined) return false;
    return true;
}

export function TextAreaFromJSON(json: any): TextArea {
    return TextAreaFromJSONTyped(json, false);
}

export function TextAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextArea {
    if (json == null) {
        return json;
    }
    return {
        
        'x0': json['x0'],
        'y0': json['y0'],
        'x1': json['x1'],
        'y1': json['y1'],
        'pageIndex': json['page_index'],
    };
}

export function TextAreaToJSON(value?: TextArea | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'x0': value['x0'],
        'y0': value['y0'],
        'x1': value['x1'],
        'y1': value['y1'],
        'page_index': value['pageIndex'],
    };
}

