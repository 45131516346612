import api from "shared/api/v1";

import { UserResponse } from "../../../home/types";

type GetFeedSharedUsersParams = {
  feedId: number;
  company: number;
};

type SharedWithGroup = {
  company: number;
  created_at: string;
  description: string;
  group_picture: null | string;
  id: number;
  name: string;
  updated_at: string;
  users: { user: UserResponse }[];
};

const getFeedSharedUsers = async (params: GetFeedSharedUsersParams) => {
  const response = await api.get<
    { shared_with_user: UserResponse | null; shared_with_group: SharedWithGroup | null }[]
  >(`/horizon/${params.company}/feeds/${params.feedId}/sharing/?company=${params.company}`);

  return response.data;
};

export default getFeedSharedUsers;
