import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";

import { Spinner } from "flowbite-react";

import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";

import { ReactComponent as DownloadIcon } from "assets/images/articles/download.svg";

import { useTeam } from "shared/store/settings";

import { UpdateDocumentProps, updateDocument } from "../../../api";
import { SPREADSHEET_OPEN_URL } from "../../../constants";
import { convertToExcelFile, fetchSpreadsheet } from "../../../helpers";
import { FileViewerProps } from "./types";

export default function SpreadsheetFileViewer({
  document: grandDocument,
  onUpdated: handleUpdate,
  buttonsContainer,
}: FileViewerProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const spreadsheetRef = useRef<SpreadsheetComponent | null>(null);
  const currentTeam = useTeam((state) => state.currentTeam);

  useEffect(() => {
    fetchSpreadsheet(grandDocument).then((file) => {
      if (spreadsheetRef.current) {
        spreadsheetRef.current.open({ file });
      }
    });
  }, []);

  useEffect(() => {
    document.getElementsByClassName("document_height")[0]?.classList.add("spreadsheet");
    return () => {
      document.getElementsByClassName("document_height")[0]?.classList.remove("spreadsheet");
    };
  }, []);

  const saveFile = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const spreadsheet = spreadsheetRef.current;
      if (!spreadsheet) {
        console.warn("Spreadsheet component not initialized");
        return;
      }

      setIsSaving(true);
      spreadsheet
        .saveAsJson()
        .then((response: unknown) => convertToExcelFile(grandDocument.filename, response))
        .then((file) => {
          const updateDocumentProps: UpdateDocumentProps = {
            id: grandDocument.id,
            file: file,
            name: grandDocument.name,
            team: grandDocument.team,
            directory: grandDocument.directory,
          };

          return updateDocument(updateDocumentProps);
        })
        .then((response) => {
          if (handleUpdate) {
            handleUpdate(response.data);
          }
        })
        .catch(() => {
          toast.error("Failed to update document");
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [spreadsheetRef, grandDocument]
  );

  return (
    <>
      {/* Use create portal to set buttons on the top sidebar */}
      {buttonsContainer
        ? createPortal(
            <>
              <a
                data-testid="download-button"
                href={grandDocument.file}
                className="btn_secondary flex h-10 items-center md:mr-4 maxMd:w-full maxMd:justify-center"
                target={"_blank"}
                rel="noopener noreferrer"
                download
              >
                <DownloadIcon className="mr-2" />
                Download
              </a>
              {!currentTeam.is_all_team && (
                <button
                  data-testid="save-file-button"
                  type="button"
                  className="btn_primary mt-auto flex h-10 items-center justify-center gap-x-1 maxMd:mt-2.5 maxMd:w-full maxMd:justify-center"
                  onClick={saveFile}
                >
                  <Spinner
                    hidden={!isSaving}
                    size="sm"
                    className="mr-2 !fill-mirage !stroke-mirage"
                  />
                  Save File
                </button>
              )}
            </>,
            buttonsContainer
          )
        : null}
      <div
        data-testid="spreadsheet-file-viewer"
        className="spreadsheet-file-viewer flex w-full flex-col items-center justify-center overflow-hidden"
      >
        <SpreadsheetComponent ref={spreadsheetRef} openUrl={SPREADSHEET_OPEN_URL} />
      </div>
    </>
  );
}
