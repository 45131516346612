import React from "react";
import { Link, useNavigate } from "react-router-dom";

import clsx from "clsx";

import { MOBILE_DEVICE } from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";
import { useRouteBlock } from "shared/storeV2/routeBlock";

import { BreadCrumbTreeProps } from "../bread-crumb/types";
import Icon from "../icon/Icon";

export default function BreadCrumbWrapper({ breadCrumb }: { breadCrumb: BreadCrumbTreeProps[] }) {
  const deviceType = useScreen();

  const isBlocked = useRouteBlock((state) => state.isBlocking);
  const navigate = useNavigate();

  return (
    <>
      <ol className="bread_crumb_list inline-flex w-full items-center space-x-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {deviceType !== MOBILE_DEVICE ? (
          (breadCrumb || []).map((breadCrumbItem, index) => (
            <li
              key={index}
              data-testid="any-device"
              className={clsx({
                "max-w-[50%] overflow-hidden": index > 0,
              })}
            >
              <Link
                to={breadCrumbItem?.link || ""}
                data-testid={"bread_crumb" + index}
                onClick={(e) => {
                  if (isBlocked) {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(breadCrumbItem?.link || "");
                  }
                }}
                className="flex w-auto items-center text-ellipsis"
              >
                <p
                  className={clsx(
                    "overflow-hidden text-ellipsis whitespace-nowrap text-left font-inter-medium text-sm text-aurometalsaurus hover:text-mirage dark:text-battleshipgray dark:hover:text-white",
                    {
                      "w-full overflow-hidden text-ellipsis whitespace-nowrap !text-brightgrey hover:!text-mirage dark:!text-shiningSliver dark:hover:!text-white":
                        index === breadCrumb.length - 1,
                    }
                  )}
                >
                  {breadCrumbItem.name || ""}
                </p>
                {breadCrumb[index + 1] && breadCrumb[index + 1].id !== null ? (
                  <div className="min-w-fit">
                    <Icon
                      type="rightarrow"
                      fill={true}
                      size="icon-smd"
                      className="bread_crumb_icon"
                    />
                  </div>
                ) : null}
              </Link>
            </li>
          ))
        ) : (
          <li data-testid="mobile-device" className={"max-w-[calc(100%_-_2rem)]"}>
            <Link
              to={breadCrumb[breadCrumb?.length - 1]?.link || ""}
              data-testid={"bread_crumb" + (breadCrumb?.length - 1)}
              className="flex w-auto items-center text-ellipsis"
            >
              <div className="w-fit">
                <Icon type="leftarrow" fill={true} size="icon-md" />
              </div>
              <p className="text_ellipsis whitespace-nowrap text-left text-sm font-medium text-aurometalsaurus hover:text-mirage dark:text-shiningSliver dark:hover:text-white">
                {breadCrumb[breadCrumb?.length - 1]?.name || ""}
              </p>
            </Link>
          </li>
        )}
      </ol>
    </>
  );
}
