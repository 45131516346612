import { ReactNode, Ref } from "react";

const Popover = ({
  children,
  innerref,
}: {
  children?: ReactNode;
  innerref: Ref<HTMLDivElement>;
}) => {
  return (
    <div className="relative flex flex-col" ref={innerref}>
      {children}
    </div>
  );
};

const PopoverTrigger = ({ children, onClick }: { children?: ReactNode; onClick?: () => void }) => {
  return (
    <div onClick={onClick} className="cursor-pointer">
      {children}
    </div>
  );
};

const PopoverContent = ({ children, className }: { children?: ReactNode; className?: string }) => {
  return <div className={`absolute left-0 right-0 z-50 mt-2 ${className} `}>{children}</div>;
};

export { Popover, PopoverTrigger, PopoverContent };
