import api from "shared/api/v1";
import { AGREEMENT_TABLE } from "shared/helpers/constant";
import { getUrlFromType } from "shared/helpers/getUrlFromType";
import { show_all_objects } from "shared/helpersV2/constant";
import { CompanyId, TeamId } from "shared/types";

import { ActivityType, MetaTable } from "../types";

// Get Activity log API
const getActivityLog = (
  type: MetaTable,
  typeId: string,
  { teamId, companyId, isAllTeam }: { teamId: TeamId; companyId: CompanyId; isAllTeam: boolean }
) => {
  const typeUrl = getUrlFromType(type);
  if (typeUrl === "default") {
    return Promise.reject(new Error("Module is not selected"));
  }

  const teamPermissionPayload = { team: teamId, company: companyId, isAllTeam: isAllTeam };

  const teamParameter = teamPermissionPayload?.isAllTeam ? "all" : teamPermissionPayload?.team;

  return api.get<ActivityType[]>(`${typeUrl}/${typeId}/versions/`, {
    params: {
      ...{ team: teamParameter, company: teamPermissionPayload.company },
      show_all_objects: type === AGREEMENT_TABLE ? show_all_objects : undefined,
    },
  });
};
export default getActivityLog;
