/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Chat,
  ChatCreate,
  ChatCreateRequest,
  ChatMessage,
  ChatMessageRequest,
  ChatRequest,
  ChatSetDocuments,
  ChatSetDocumentsRequest,
  OpenAIAssistant,
  PaginatedChatList,
  PaginatedChatMessageList,
  PaginatedOpenAIAssistantList,
  PatchedChatRequest,
} from '../models/index';
import {
    ChatFromJSON,
    ChatToJSON,
    ChatCreateFromJSON,
    ChatCreateToJSON,
    ChatCreateRequestFromJSON,
    ChatCreateRequestToJSON,
    ChatMessageFromJSON,
    ChatMessageToJSON,
    ChatMessageRequestFromJSON,
    ChatMessageRequestToJSON,
    ChatRequestFromJSON,
    ChatRequestToJSON,
    ChatSetDocumentsFromJSON,
    ChatSetDocumentsToJSON,
    ChatSetDocumentsRequestFromJSON,
    ChatSetDocumentsRequestToJSON,
    OpenAIAssistantFromJSON,
    OpenAIAssistantToJSON,
    PaginatedChatListFromJSON,
    PaginatedChatListToJSON,
    PaginatedChatMessageListFromJSON,
    PaginatedChatMessageListToJSON,
    PaginatedOpenAIAssistantListFromJSON,
    PaginatedOpenAIAssistantListToJSON,
    PatchedChatRequestFromJSON,
    PatchedChatRequestToJSON,
} from '../models/index';

export interface AssistantAgentsListRequest {
    page?: number;
    pageSize?: number;
}

export interface AssistantAgentsRetrieveRequest {
    id: number;
}

export interface AssistantChatCreateRequest {
    chatCreateRequest: ChatCreateRequest;
}

export interface AssistantChatDestroyRequest {
    id: number;
}

export interface AssistantChatListRequest {
    assistantId?: number;
    companyId?: number;
    page?: number;
    pageSize?: number;
    teamId?: number;
    type?: AssistantChatListTypeEnum;
}

export interface AssistantChatMessagesCreateRequest {
    chatId: string;
    chatMessageRequest: ChatMessageRequest;
}

export interface AssistantChatMessagesListRequest {
    chatId: string;
    page?: number;
    pageSize?: number;
}

export interface AssistantChatMessagesRetrieveRequest {
    chatId: string;
    id: number;
}

export interface AssistantChatPartialUpdateRequest {
    id: number;
    patchedChatRequest?: PatchedChatRequest;
}

export interface AssistantChatRetrieveRequest {
    id: number;
}

export interface AssistantChatSetDocumentsCreateRequest {
    id: number;
    chatSetDocumentsRequest: ChatSetDocumentsRequest;
}

export interface AssistantChatUpdateRequest {
    id: number;
    chatRequest: ChatRequest;
}

/**
 * 
 */
export class AssistantApi extends runtime.BaseAPI {

    /**
     * A simple ViewSet for viewing OpenAIAssistants.
     */
    async assistantAgentsListRaw(requestParameters: AssistantAgentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOpenAIAssistantList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/agents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOpenAIAssistantListFromJSON(jsonValue));
    }

    /**
     * A simple ViewSet for viewing OpenAIAssistants.
     */
    async assistantAgentsList(requestParameters: AssistantAgentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOpenAIAssistantList> {
        const response = await this.assistantAgentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A simple ViewSet for viewing OpenAIAssistants.
     */
    async assistantAgentsRetrieveRaw(requestParameters: AssistantAgentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenAIAssistant>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantAgentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/agents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpenAIAssistantFromJSON(jsonValue));
    }

    /**
     * A simple ViewSet for viewing OpenAIAssistants.
     */
    async assistantAgentsRetrieve(requestParameters: AssistantAgentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenAIAssistant> {
        const response = await this.assistantAgentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatCreateRaw(requestParameters: AssistantChatCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatCreate>> {
        if (requestParameters['chatCreateRequest'] == null) {
            throw new runtime.RequiredError(
                'chatCreateRequest',
                'Required parameter "chatCreateRequest" was null or undefined when calling assistantChatCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatCreateRequestToJSON(requestParameters['chatCreateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatCreateFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatCreate(requestParameters: AssistantChatCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatCreate> {
        const response = await this.assistantChatCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatDestroyRaw(requestParameters: AssistantChatDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatDestroy(requestParameters: AssistantChatDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assistantChatDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatListRaw(requestParameters: AssistantChatListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedChatList>> {
        const queryParameters: any = {};

        if (requestParameters['assistantId'] != null) {
            queryParameters['assistant_id'] = requestParameters['assistantId'];
        }

        if (requestParameters['companyId'] != null) {
            queryParameters['company_id'] = requestParameters['companyId'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['teamId'] != null) {
            queryParameters['team_id'] = requestParameters['teamId'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedChatListFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatList(requestParameters: AssistantChatListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedChatList> {
        const response = await this.assistantChatListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assistantChatMessagesCreateRaw(requestParameters: AssistantChatMessagesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatMessage>> {
        if (requestParameters['chatId'] == null) {
            throw new runtime.RequiredError(
                'chatId',
                'Required parameter "chatId" was null or undefined when calling assistantChatMessagesCreate().'
            );
        }

        if (requestParameters['chatMessageRequest'] == null) {
            throw new runtime.RequiredError(
                'chatMessageRequest',
                'Required parameter "chatMessageRequest" was null or undefined when calling assistantChatMessagesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{chat_id}/messages/`.replace(`{${"chat_id"}}`, encodeURIComponent(String(requestParameters['chatId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageRequestToJSON(requestParameters['chatMessageRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageFromJSON(jsonValue));
    }

    /**
     */
    async assistantChatMessagesCreate(requestParameters: AssistantChatMessagesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatMessage> {
        const response = await this.assistantChatMessagesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assistantChatMessagesListRaw(requestParameters: AssistantChatMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedChatMessageList>> {
        if (requestParameters['chatId'] == null) {
            throw new runtime.RequiredError(
                'chatId',
                'Required parameter "chatId" was null or undefined when calling assistantChatMessagesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{chat_id}/messages/`.replace(`{${"chat_id"}}`, encodeURIComponent(String(requestParameters['chatId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedChatMessageListFromJSON(jsonValue));
    }

    /**
     */
    async assistantChatMessagesList(requestParameters: AssistantChatMessagesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedChatMessageList> {
        const response = await this.assistantChatMessagesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assistantChatMessagesRetrieveRaw(requestParameters: AssistantChatMessagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatMessage>> {
        if (requestParameters['chatId'] == null) {
            throw new runtime.RequiredError(
                'chatId',
                'Required parameter "chatId" was null or undefined when calling assistantChatMessagesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatMessagesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{chat_id}/messages/{id}/`.replace(`{${"chat_id"}}`, encodeURIComponent(String(requestParameters['chatId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageFromJSON(jsonValue));
    }

    /**
     */
    async assistantChatMessagesRetrieve(requestParameters: AssistantChatMessagesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatMessage> {
        const response = await this.assistantChatMessagesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatPartialUpdateRaw(requestParameters: AssistantChatPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chat>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedChatRequestToJSON(requestParameters['patchedChatRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatPartialUpdate(requestParameters: AssistantChatPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chat> {
        const response = await this.assistantChatPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatRetrieveRaw(requestParameters: AssistantChatRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chat>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatRetrieve(requestParameters: AssistantChatRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chat> {
        const response = await this.assistantChatRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatSetDocumentsCreateRaw(requestParameters: AssistantChatSetDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatSetDocuments>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatSetDocumentsCreate().'
            );
        }

        if (requestParameters['chatSetDocumentsRequest'] == null) {
            throw new runtime.RequiredError(
                'chatSetDocumentsRequest',
                'Required parameter "chatSetDocumentsRequest" was null or undefined when calling assistantChatSetDocumentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{id}/set_documents/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatSetDocumentsRequestToJSON(requestParameters['chatSetDocumentsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatSetDocumentsFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatSetDocumentsCreate(requestParameters: AssistantChatSetDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatSetDocuments> {
        const response = await this.assistantChatSetDocumentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatUpdateRaw(requestParameters: AssistantChatUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chat>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling assistantChatUpdate().'
            );
        }

        if (requestParameters['chatRequest'] == null) {
            throw new runtime.RequiredError(
                'chatRequest',
                'Required parameter "chatRequest" was null or undefined when calling assistantChatUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/assistant/chat/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChatRequestToJSON(requestParameters['chatRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatFromJSON(jsonValue));
    }

    /**
     * ChatViewset is used to create a chat object which is used to store the messages between the user and the copilot It also stores the documents the user is currently chatting about
     */
    async assistantChatUpdate(requestParameters: AssistantChatUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chat> {
        const response = await this.assistantChatUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const AssistantChatListTypeEnum = {
    Agent: 'agent',
    Copilot: 'copilot',
    Eurlex: 'eurlex',
    Roi: 'roi'
} as const;
export type AssistantChatListTypeEnum = typeof AssistantChatListTypeEnum[keyof typeof AssistantChatListTypeEnum];
