import clsx from "clsx";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";
import { TagsField } from "shared/components/tags-field/TagsField";

import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";

type TArticleTagsDropdownProps = {
  readonly disabled?: boolean;
};

const ArticleTagsDropdown = ({ disabled }: TArticleTagsDropdownProps) => {
  const {
    addTags,
    articleQuery: { data: article },
  } = useArticleContext();

  if (!article) return null;

  const selectedTags = article.tags?.map(({ name }) => ({ value: name, label: name, data: name }));

  return (
    <>
      <div className="flex flex-wrap gap-4">
        {selectedTags
          ?.map((tag) => {
            return (
              <div
                key={tag.value}
                className="flex items-center justify-between gap-1 rounded-md bg-[#E1EFFE] px-1.5 py-0.5"
              >
                <div className={clsx("text-sm font-medium text-crayolasblue")}>{tag.label}</div>
                {!disabled ? (
                  <button
                    onClick={() => {
                      const filteredTags = selectedTags.filter((item) => item.value !== tag.value);
                      addTags(filteredTags.map((tag) => tag.value));
                    }}
                  >
                    <Icon type="close-small" fill={true} size="icon-xxs" badgeColor="#1C64F2" />
                  </button>
                ) : null}
              </div>
            );
          })
          .concat([
            <DropdownMenu.Root key={article.id}>
              <DropdownMenu.Trigger asChild disabled={disabled}>
                <button
                  aria-label="Customise options"
                  title="Change View"
                  disabled={disabled}
                  className="focus-visible:outline-none"
                >
                  {selectedTags?.length > 0 ? (
                    <span className="add_btn block rounded border border-brightgray p-0.5 shadow-sm">
                      {!disabled ? <Icon type="plus-btn" fill={true} size="icon-xs" /> : null}
                    </span>
                  ) : (
                    <div className="flex items-center gap-1">
                      <span className="font-inter-medium text-sm text-santagrey dark:text-gray_400">
                        Select tags
                      </span>
                      <Icon type="chevron-down" size="icon-sm" fill={true} />
                    </div>
                  )}
                </button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content
                className="relative z-10 min-w-[15rem]"
                align="start"
                sideOffset={5}
                side="bottom"
              >
                <TagsField
                  teamId={undefined}
                  controlShouldRenderValue={false}
                  isDisabled={disabled}
                  values={selectedTags.map((tag) => ({ label: tag.label, value: tag.value }))}
                  onChange={(data) => {
                    addTags(data.map((tag) => String(tag.value)));
                  }}
                />
              </DropdownMenu.Content>
            </DropdownMenu.Root>,
          ])}
      </div>
    </>
  );
};

export default ArticleTagsDropdown;
