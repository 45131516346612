import { ReactComponent as CloseIcon } from "assets/images/cross.svg";

const ImageCard = ({
  url,
  download_url,
  filename,
  onClose,
}: {
  filename: string;
  download_url?: string;
  url: string;
  onClose?: () => void;
}) => {
  const Card = (
    <div className="my-2 overflow-x-auto">
      <div className="metadata_card">
        <div className="metadata_file">
          <img src={url} alt="file" className="mr-4 h-8 w-8 object-contain md:h-10 md:w-10" />
          <div className="meta_content">
            <p className="break-all dark:text-white">{filename}</p>
            <p className="text-sm text-aurometalsaurus dark:text-white">Image</p>
          </div>
        </div>
        {onClose ? (
          <div className="closeIcon">
            <CloseIcon onClick={onClose} data-testid="close_icon" />
          </div>
        ) : null}
      </div>
    </div>
  );

  if (download_url) {
    return (
      <a href={download_url} target="_blank" rel="noopener noreferrer">
        {Card}
      </a>
    );
  }
  return Card;
};

export default ImageCard;
