/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChatTypeEnum } from './ChatTypeEnum';
import {
    ChatTypeEnumFromJSON,
    ChatTypeEnumFromJSONTyped,
    ChatTypeEnumToJSON,
} from './ChatTypeEnum';
import type { SourceRequest } from './SourceRequest';
import {
    SourceRequestFromJSON,
    SourceRequestFromJSONTyped,
    SourceRequestToJSON,
} from './SourceRequest';

/**
 * 
 * @export
 * @interface PatchedChatRequest
 */
export interface PatchedChatRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedChatRequest
     */
    title?: string | null;
    /**
     * 
     * @type {ChatTypeEnum}
     * @memberof PatchedChatRequest
     */
    type?: ChatTypeEnum;
    /**
     * 
     * @type {Array<SourceRequest>}
     * @memberof PatchedChatRequest
     */
    documents?: Array<SourceRequest> | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedChatRequest
     */
    teamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedChatRequest
     */
    companyId?: number | null;
}



/**
 * Check if a given object implements the PatchedChatRequest interface.
 */
export function instanceOfPatchedChatRequest(value: object): value is PatchedChatRequest {
    return true;
}

export function PatchedChatRequestFromJSON(json: any): PatchedChatRequest {
    return PatchedChatRequestFromJSONTyped(json, false);
}

export function PatchedChatRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedChatRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'type': json['type'] == null ? undefined : ChatTypeEnumFromJSON(json['type']),
        'documents': json['documents'] == null ? undefined : ((json['documents'] as Array<any>).map(SourceRequestFromJSON)),
        'teamId': json['team_id'] == null ? undefined : json['team_id'],
        'companyId': json['company_id'] == null ? undefined : json['company_id'],
    };
}

export function PatchedChatRequestToJSON(value?: PatchedChatRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'type': ChatTypeEnumToJSON(value['type']),
        'documents': value['documents'] == null ? undefined : ((value['documents'] as Array<any>).map(SourceRequestToJSON)),
        'team_id': value['teamId'],
        'company_id': value['companyId'],
    };
}

