import { useEffect, useState } from "react";

import { $getRoot, COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND, LexicalEditor } from "lexical";

import isImageType from "../../helpers/isImageType";
import isTypeAheadPopoverOpen from "../../helpers/isTypeAheadPopoverOpen";
import { useTeam } from "../../store/settings";
import { $isMentionNode } from "../lexical-editor/nodes/MentionNode";
import ImageCard from "../metadata/comments/ImageCard";
import { CreateCommentType } from "../metadata/types";

const SubmitTaskComment = ({
  onCommentAdd,
  editor,
  type,
  id,
}: {
  onCommentAdd: (data: CreateCommentType) => void;
  editor: LexicalEditor;
  type: string;
  id: string;
}) => {
  const [files, setFiles] = useState<File[]>([]);

  const currentTeam = useTeam((state) => state.currentTeam);

  const onSubmit = () => {
    editor?.update(() => {
      const root = $getRoot();
      const textSize = root.getTextContentSize();
      if (textSize === 0 && files.length === 0) {
        return;
      }

      const mentions: string[] = [];
      editor?.getEditorState()._nodeMap.forEach((node) => {
        if ($isMentionNode(node)) {
          mentions.push(`${node.__id}`);
        }
      });

      onCommentAdd({
        team: currentTeam?.id,
        comment: textSize === 0 ? "" : JSON.stringify(editor?.getEditorState().toJSON()),
        type: type,
        id: id || "",
        attachments: files,
        mentions: mentions,
        company: currentTeam?.company,
      });

      root.clear();
      setFiles([]);
    });
  };

  // on enter submit comment
  useEffect(() => {
    return editor?.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        if (event !== null && !event.shiftKey) {
          event.preventDefault();
          // bypass typeahead context menu
          if (isTypeAheadPopoverOpen()) {
            return false;
          }
          // submit on enter
          onSubmit();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <>
      <div className="relative">
        <div className="editor-footer flex-col">
          <div className="">
            {files.map((file, index) => {
              if (isImageType(file)) {
                return (
                  <ImageCard
                    key={index}
                    filename={file.name}
                    url={URL.createObjectURL(file)}
                    onClose={() => removeFile(index)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitTaskComment;
