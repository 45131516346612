import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import CantLogin from "shared/components/cant-login/CantLogin";
import Spinner from "shared/components/spinner/Spinner";
import { setRefreshToken, setToken } from "shared/helpers/util";

const SsoAuth = () => {
  const [params] = useSearchParams();
  const accessToken = params.get("access_token");
  const refreshToken = params.get("refresh");
  const navigate = useNavigate();

  const isBothTokenAvailable = accessToken && refreshToken;

  useEffect(() => {
    if (!isBothTokenAvailable) return;
    setToken(accessToken);
    setRefreshToken(refreshToken);
    navigate("/");
  }, [isBothTokenAvailable]);

  if (!isBothTokenAvailable) {
    return <CantLogin />;
  }

  return <Spinner />;
};

export default SsoAuth;
