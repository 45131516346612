import React, { ReactElement, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import clsx from "clsx";
import get from "lodash/get";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/agreement.module.css";
import headerStyles from "assets/css/common-header.module.css";

import { optionType } from "shared/components/metadata/types";
import { selectControlStyle, selectErrorStyles } from "shared/helpers/selectStyle";
import { AgreementFormValues } from "shared/types";

export default function StaticDropdown({
  mainClassname,
  isLoading,
  placeholder,
  hasError,
  value,
  handleChange,
  data,
  isClearable = true,
}: {
  mainClassname?: string;
  isLoading?: boolean;
  placeholder?: string;
  hasError?: boolean;
  value: string;
  handleChange: (data: string | null) => void;
  data: AgreementFormValues<
    Pick<{ id: number | string; name: string | ReactElement }, "id" | "name">
  >;
  isClearable?: boolean;
}) {
  const { isDarkMode } = useDarkMode();

  const [options, setOptions] = useState<Array<optionType>>([]);

  const values = useMemo(
    () =>
      options?.filter((option) => {
        return value == option.value;
      }),
    [options, value]
  );

  useEffect(() => {
    setOptions(
      data?.map((item: Pick<{ id: number | string; name: string }, "id">) => {
        return {
          id: Number(item.id || "0"),
          label: `${get(item, "name")}`,
          value: `${item.id}`,
          data: item,
        };
      })
    );
  }, [data]);

  return (
    <>
      <Select
        isLoading={isLoading}
        menuPlacement="bottom"
        isSearchable
        isClearable={isClearable}
        options={options}
        placeholder={placeholder}
        classNamePrefix="multitag multitag_spacing searchable_dropdown"
        className={clsx(headerStyles?.searchField, styles.multiDropdownSelect, mainClassname)}
        value={values}
        styles={hasError ? selectErrorStyles(isDarkMode) : selectControlStyle(isDarkMode)}
        onChange={(valueOption) => {
          if (valueOption && !Array.isArray(valueOption)) {
            handleChange(valueOption.value || null);
            return;
          }
          if (valueOption === null) {
            handleChange(null);
          }
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#E5E7EB",
          },
        })}
      />
    </>
  );
}
