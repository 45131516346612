import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useTeam } from "shared/store/settings";

import { RelatedObjectType } from "modules/tasks/overview/types";

import { deletePermission } from "../api";
import isPermissionModule from "../helpers/isPermissionModule";
import { MetaTable, Permission } from "../types";

export default function usePermissionDeleteMutation({
  type,
  relatedObject,
}: {
  type: MetaTable;
  relatedObject: RelatedObjectType;
}) {
  const queryClient = useQueryClient();

  const { currentTeam } = useTeam();

  return useMutation<void, AxiosError, Permission, { previousPermissionList: Permission[] }>({
    mutationFn: async (permission) => {
      if (!isPermissionModule(type)) {
        throw new Error("Invalid permission module");
      }

      if (!relatedObject.id) {
        throw new Error("Invalid related object id");
      }

      await deletePermission(type, relatedObject.id, permission.id, {
        team: currentTeam?.id,
        isAllTeam: currentTeam.is_all_team,
        company: currentTeam?.company,
      }).then((response) => response.data);
    },
    onMutate: async (permission) => {
      await queryClient.cancelQueries(["permissions", type, relatedObject.id]);
      const permissionListQueryKey = ["permissions", type, relatedObject.id];
      const previousPermissionList =
        queryClient.getQueryData<Permission[]>(permissionListQueryKey) || [];
      queryClient.setQueryData<Permission[]>(
        permissionListQueryKey,
        previousPermissionList.filter((item) => item.id !== permission.id)
      );

      return { previousPermissionList };
    },
    onError: (err, permission, context) => {
      queryClient.setQueryData(
        ["permissions", type, relatedObject.id],
        context?.previousPermissionList
      );
      toast.error("Something went wrong! Please try again.");
    },
    onSuccess: () => {
      toast.success("Permission deleted successfully!");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["permissions", type, relatedObject.id]);
    },
  });
}
