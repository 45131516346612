import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { show_all_objects } from "shared/helpersV2/constant";
import { TeamParameter } from "shared/types";

import convertAgreementData from "../helpers/convertAgreementData";
import { AgreementResultsType } from "../types";

export default async function getAggrementById(
  { agreementId }: { agreementId: number },
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<AgreementResultsType>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<AgreementResultsType>(`agreements/${agreementId}/`, {
    params: {
      ...{
        team: teamParameter,
        company: permissions.team.company,
        show_all_objects,
      },
    },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertAgreementData(response.data),
    };
  }

  return response;
}
