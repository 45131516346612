import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";

import { useCompany } from "shared/context/CompanyProvider";
import { settingsGroupMappingData } from "shared/data/initialsdataForForm";
import { useAuthProvider } from "shared/store/settings";

import { SSOGroupMappingProps } from "../types";
import { addGroupMappingSchema } from "../validation";

const useGroupMappingForm = (action: CallableFunction) => {
  const group = useAuthProvider((state) => state.group);

  const { currentCompany } = useCompany();

  const teams = currentCompany.teams.filter((team) => group?.teams?.includes(team.id));

  return useFormik<SSOGroupMappingProps>({
    initialValues: !isEmpty(group)
      ? { ...group, teams: teams || [] }
      : { ...settingsGroupMappingData, teams: teams || [] },
    validateOnChange: false,
    validationSchema: addGroupMappingSchema,
    enableReinitialize: true,

    onSubmit: () => action(),
  });
};

export default useGroupMappingForm;
