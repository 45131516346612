/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstitutionType
 */
export interface InstitutionType {
    /**
     * 
     * @type {number}
     * @memberof InstitutionType
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof InstitutionType
     */
    name: string;
}

/**
 * Check if a given object implements the InstitutionType interface.
 */
export function instanceOfInstitutionType(value: object): value is InstitutionType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function InstitutionTypeFromJSON(json: any): InstitutionType {
    return InstitutionTypeFromJSONTyped(json, false);
}

export function InstitutionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstitutionType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

export function InstitutionTypeToJSON(value?: Omit<InstitutionType, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

