/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SourceRequest } from './SourceRequest';
import {
    SourceRequestFromJSON,
    SourceRequestFromJSONTyped,
    SourceRequestToJSON,
} from './SourceRequest';

/**
 * 
 * @export
 * @interface ChatSetDocumentsRequest
 */
export interface ChatSetDocumentsRequest {
    /**
     * 
     * @type {Array<SourceRequest>}
     * @memberof ChatSetDocumentsRequest
     */
    documents: Array<SourceRequest>;
}

/**
 * Check if a given object implements the ChatSetDocumentsRequest interface.
 */
export function instanceOfChatSetDocumentsRequest(value: object): value is ChatSetDocumentsRequest {
    if (!('documents' in value) || value['documents'] === undefined) return false;
    return true;
}

export function ChatSetDocumentsRequestFromJSON(json: any): ChatSetDocumentsRequest {
    return ChatSetDocumentsRequestFromJSONTyped(json, false);
}

export function ChatSetDocumentsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatSetDocumentsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'documents': ((json['documents'] as Array<any>).map(SourceRequestFromJSON)),
    };
}

export function ChatSetDocumentsRequestToJSON(value?: ChatSetDocumentsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'documents': ((value['documents'] as Array<any>).map(SourceRequestToJSON)),
    };
}

