/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorityDocumentUpdateRequest,
  AuthorityDocumentView,
  Obligation,
  ObligationRequest,
  PaginatedAuthorityDocumentViewList,
  PaginatedObligationList,
  PatchedAuthorityDocumentUpdateRequest,
  PatchedObligationRequest,
  SimilarObligation,
} from '../models/index';
import {
    AuthorityDocumentUpdateRequestFromJSON,
    AuthorityDocumentUpdateRequestToJSON,
    AuthorityDocumentViewFromJSON,
    AuthorityDocumentViewToJSON,
    ObligationFromJSON,
    ObligationToJSON,
    ObligationRequestFromJSON,
    ObligationRequestToJSON,
    PaginatedAuthorityDocumentViewListFromJSON,
    PaginatedAuthorityDocumentViewListToJSON,
    PaginatedObligationListFromJSON,
    PaginatedObligationListToJSON,
    PatchedAuthorityDocumentUpdateRequestFromJSON,
    PatchedAuthorityDocumentUpdateRequestToJSON,
    PatchedObligationRequestFromJSON,
    PatchedObligationRequestToJSON,
    SimilarObligationFromJSON,
    SimilarObligationToJSON,
} from '../models/index';

export interface RoiAuthorityDocumentsCreateRequest {
    file: Blob;
    name: string;
    company?: number | null;
}

export interface RoiAuthorityDocumentsDestroyRequest {
    id: number;
}

export interface RoiAuthorityDocumentsListRequest {
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    institutionTypes?: Array<string>;
    jurisdictions?: Array<string>;
    origin?: RoiAuthorityDocumentsListOriginEnum;
    page?: number;
    pageSize?: number;
    search?: string;
    tags?: Array<string>;
    upcomingReviewAfter?: Date;
    upcomingReviewBefore?: Date;
}

export interface RoiAuthorityDocumentsPartialUpdateRequest {
    id: number;
    patchedAuthorityDocumentUpdateRequest?: PatchedAuthorityDocumentUpdateRequest;
}

export interface RoiAuthorityDocumentsRetrieveRequest {
    id: number;
}

export interface RoiAuthorityDocumentsUpdateRequest {
    id: number;
    authorityDocumentUpdateRequest: AuthorityDocumentUpdateRequest;
}

export interface RoiObligationsCreateRequest {
    obligationRequest: ObligationRequest;
}

export interface RoiObligationsDestroyRequest {
    id: number;
}

export interface RoiObligationsListRequest {
    authorityDocument?: number;
    categories?: Array<string>;
    controls?: boolean;
    page?: number;
    pageSize?: number;
    policyCoverage?: boolean;
    search?: string;
}

export interface RoiObligationsPartialUpdateRequest {
    id: number;
    patchedObligationRequest?: PatchedObligationRequest;
}

export interface RoiObligationsRetrieveRequest {
    id: number;
}

export interface RoiObligationsSimilarListRequest {
    id: number;
    authorityDocument?: number;
    categories?: Array<string>;
    controls?: boolean;
    policyCoverage?: boolean;
    search?: string;
}

export interface RoiObligationsUpdateRequest {
    id: number;
    obligationRequest: ObligationRequest;
}

/**
 * 
 */
export class RoiApi extends runtime.BaseAPI {

    /**
     */
    async roiAuthorityDocumentsCreateRaw(requestParameters: RoiAuthorityDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling roiAuthorityDocumentsCreate().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling roiAuthorityDocumentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['company'] != null) {
            formParams.append('company', requestParameters['company'] as any);
        }

        if (requestParameters['name'] != null) {
            formParams.append('name', requestParameters['name'] as any);
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsCreate(requestParameters: RoiAuthorityDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsDestroyRaw(requestParameters: RoiAuthorityDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roiAuthorityDocumentsDestroy(requestParameters: RoiAuthorityDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiAuthorityDocumentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiAuthorityDocumentsListRaw(requestParameters: RoiAuthorityDocumentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAuthorityDocumentViewList>> {
        const queryParameters: any = {};

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString();
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString();
        }

        if (requestParameters['institutionTypes'] != null) {
            queryParameters['institution_types'] = requestParameters['institutionTypes'];
        }

        if (requestParameters['jurisdictions'] != null) {
            queryParameters['jurisdictions'] = requestParameters['jurisdictions'];
        }

        if (requestParameters['origin'] != null) {
            queryParameters['origin'] = requestParameters['origin'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags'];
        }

        if (requestParameters['upcomingReviewAfter'] != null) {
            queryParameters['upcoming_review_after'] = (requestParameters['upcomingReviewAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['upcomingReviewBefore'] != null) {
            queryParameters['upcoming_review_before'] = (requestParameters['upcomingReviewBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAuthorityDocumentViewListFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsList(requestParameters: RoiAuthorityDocumentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAuthorityDocumentViewList> {
        const response = await this.roiAuthorityDocumentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsPartialUpdateRaw(requestParameters: RoiAuthorityDocumentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedAuthorityDocumentUpdateRequestToJSON(requestParameters['patchedAuthorityDocumentUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsPartialUpdate(requestParameters: RoiAuthorityDocumentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsRetrieveRaw(requestParameters: RoiAuthorityDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsRetrieve(requestParameters: RoiAuthorityDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsUpdateRaw(requestParameters: RoiAuthorityDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsUpdate().'
            );
        }

        if (requestParameters['authorityDocumentUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'authorityDocumentUpdateRequest',
                'Required parameter "authorityDocumentUpdateRequest" was null or undefined when calling roiAuthorityDocumentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorityDocumentUpdateRequestToJSON(requestParameters['authorityDocumentUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsUpdate(requestParameters: RoiAuthorityDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsCreateRaw(requestParameters: RoiObligationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['obligationRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationRequest',
                'Required parameter "obligationRequest" was null or undefined when calling roiObligationsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationRequestToJSON(requestParameters['obligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsCreate(requestParameters: RoiObligationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsDestroyRaw(requestParameters: RoiObligationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roiObligationsDestroy(requestParameters: RoiObligationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiObligationsListRaw(requestParameters: RoiObligationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedObligationList>> {
        const queryParameters: any = {};

        if (requestParameters['authorityDocument'] != null) {
            queryParameters['authority_document'] = requestParameters['authorityDocument'];
        }

        if (requestParameters['categories'] != null) {
            queryParameters['categories'] = requestParameters['categories'];
        }

        if (requestParameters['controls'] != null) {
            queryParameters['controls'] = requestParameters['controls'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['policyCoverage'] != null) {
            queryParameters['policy_coverage'] = requestParameters['policyCoverage'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedObligationListFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsList(requestParameters: RoiObligationsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedObligationList> {
        const response = await this.roiObligationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsPartialUpdateRaw(requestParameters: RoiObligationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedObligationRequestToJSON(requestParameters['patchedObligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsPartialUpdate(requestParameters: RoiObligationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsRetrieveRaw(requestParameters: RoiObligationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsRetrieve(requestParameters: RoiObligationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve obligations similar to a given obligation ID.
     */
    async roiObligationsSimilarListRaw(requestParameters: RoiObligationsSimilarListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimilarObligation>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsSimilarList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['authorityDocument'] != null) {
            queryParameters['authority_document'] = requestParameters['authorityDocument'];
        }

        if (requestParameters['categories'] != null) {
            queryParameters['categories'] = requestParameters['categories'];
        }

        if (requestParameters['controls'] != null) {
            queryParameters['controls'] = requestParameters['controls'];
        }

        if (requestParameters['policyCoverage'] != null) {
            queryParameters['policy_coverage'] = requestParameters['policyCoverage'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/similar/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimilarObligationFromJSON));
    }

    /**
     * Retrieve obligations similar to a given obligation ID.
     */
    async roiObligationsSimilarList(requestParameters: RoiObligationsSimilarListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimilarObligation>> {
        const response = await this.roiObligationsSimilarListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsUpdateRaw(requestParameters: RoiObligationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsUpdate().'
            );
        }

        if (requestParameters['obligationRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationRequest',
                'Required parameter "obligationRequest" was null or undefined when calling roiObligationsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationRequestToJSON(requestParameters['obligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsUpdate(requestParameters: RoiObligationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const RoiAuthorityDocumentsListOriginEnum = {
    External: 'EXTERNAL',
    Internal: 'INTERNAL'
} as const;
export type RoiAuthorityDocumentsListOriginEnum = typeof RoiAuthorityDocumentsListOriginEnum[keyof typeof RoiAuthorityDocumentsListOriginEnum];
