import truncate from "lodash/truncate";
import { FeedView } from "openapi";

import style from "assets/css/articlesV2.module.css";

import Header from "shared/components/main-content/Header";
import NotFound from "shared/components/not-found/NotFound";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { cn } from "shared/componentsV2/utils";
import { useHorizonScanningMenuBar } from "shared/storeV2/horizonScanningMenubar";
import { useHorizonScanningViewMode } from "shared/storeV2/horizonScanningViewMode";

import { useArticleContext } from "../contexts/ArticleContext";
import ShareArticleModal from "../shared/components/ShareArticleModal";
import ActionBar from "./components/ActionBar";
import ArticleClassifications from "./components/ArticleClassifications";
import ArticleDetailsMetadata from "./components/ArticleDetailsMetadata";
import ArticleHeader from "./components/ArticleHeader";
import ArticleSimilarArticles from "./components/ArticleSimilarArticles";
import ArticleSummary from "./components/ArticleSummary";
import ArticleFormMetadata from "./components/article-form-metadata/ArticleFormMetadata";
import ArticleToDosModal from "./components/article-to-dos-modal/ArticleToDosModal";

const ArticleDetails = ({ feed }: { feed?: FeedView }) => {
  const {
    feedId,
    companyId,
    isPublic,
    shareModalArticle,
    articleQuery: { data: article, isLoading },
    ...rest
  } = useArticleContext();

  const { setToggleMenuBar, toggleMenuBar } = useHorizonScanningMenuBar();
  const { setActiveTab } = useHorizonScanningViewMode();

  if (!article && !isLoading) {
    return <NotFound />;
  } else if (isLoading) {
    return <CentralSpinner />;
  }

  const breadCrumb = [
    {
      id: null,
      name: "Horizon Scanning",
      link: `/workspace/${companyId}/horizon-scanning/feeds`,
    },
    {
      id: feed?.id ?? null,
      name: feed?.name ?? "",
      link: `/workspace/${companyId}/horizon-scanning/feeds/${feed?.id}`,
    },
    ...(article
      ? [
          {
            id: article.id,
            name: truncate(article.title, { length: 67 }),
            link: `/workspace/${companyId}/horizon-scanning/feeds/${feedId}/article/${article.id}`,
          },
        ]
      : []),
  ];

  return (
    <div
      className={cn(
        "relative flex w-full flex-1 flex-col rounded-lg bg-white dark:bg-mirage",
        !isPublic && "border border-gray-200 dark:border-jet"
      )}
    >
      {!isPublic && (
        <Header
          moduleName="horizon-scanning"
          breadCrumb={breadCrumb}
          displaySidebarBtn={!toggleMenuBar}
          onSidebarBtnClick={() => {
            setActiveTab("comments");
            setToggleMenuBar(true);
          }}
        />
      )}

      <div className={cn(style.article_details_grid, isPublic ? "!pt-0" : "")}>
        {!isPublic && <ActionBar />}

        <ArticleHeader />

        <div className="grid gap-2">
          {!isPublic && <ArticleFormMetadata />}
          <ArticleDetailsMetadata />
        </div>

        <div className="h-px bg-antiflashwhite dark:bg-jet" />

        <ArticleSummary />

        <div className="h-px bg-antiflashwhite dark:bg-jet" />

        <ArticleClassifications />

        {!isPublic && <ArticleSimilarArticles />}
      </div>

      {!isPublic && <ArticleToDosModal />}
      {shareModalArticle && (
        <ShareArticleModal
          article={shareModalArticle}
          open={!!shareModalArticle}
          onClose={() => rest.setShareModalArticle(undefined)}
        />
      )}
    </div>
  );
};

export default ArticleDetails;
