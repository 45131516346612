import React from "react";

import clsx from "clsx";
import { Modal, ModalHeaderProps } from "flowbite-react";

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  return (
    <Modal.Header {...props} className={clsx("common_modal_header", props.className)}>
      {props.children}
    </Modal.Header>
  );
};
export default ModalHeader;
