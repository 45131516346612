/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ChatTypeEnum } from './ChatTypeEnum';
import {
    ChatTypeEnumFromJSON,
    ChatTypeEnumFromJSONTyped,
    ChatTypeEnumToJSON,
} from './ChatTypeEnum';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * 
 * @export
 * @interface ChatCreate
 */
export interface ChatCreate {
    /**
     * 
     * @type {number}
     * @memberof ChatCreate
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ChatCreate
     */
    title?: string | null;
    /**
     * 
     * @type {ChatTypeEnum}
     * @memberof ChatCreate
     */
    type?: ChatTypeEnum;
    /**
     * 
     * @type {Array<Source>}
     * @memberof ChatCreate
     */
    documents: Array<Source> | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatCreate
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ChatCreate
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ChatCreate
     */
    teamId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChatCreate
     */
    companyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChatCreate
     */
    assistantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChatCreate
     */
    initialMessage?: string | null;
}



/**
 * Check if a given object implements the ChatCreate interface.
 */
export function instanceOfChatCreate(value: object): value is ChatCreate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('documents' in value) || value['documents'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ChatCreateFromJSON(json: any): ChatCreate {
    return ChatCreateFromJSONTyped(json, false);
}

export function ChatCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'type': json['type'] == null ? undefined : ChatTypeEnumFromJSON(json['type']),
        'documents': (json['documents'] == null ? null : (json['documents'] as Array<any>).map(SourceFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'teamId': json['team_id'] == null ? undefined : json['team_id'],
        'companyId': json['company_id'] == null ? undefined : json['company_id'],
        'assistantId': json['assistant_id'] == null ? undefined : json['assistant_id'],
        'initialMessage': json['initial_message'] == null ? undefined : json['initial_message'],
    };
}

export function ChatCreateToJSON(value?: Omit<ChatCreate, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'type': ChatTypeEnumToJSON(value['type']),
        'documents': (value['documents'] == null ? null : (value['documents'] as Array<any>).map(SourceToJSON)),
        'team_id': value['teamId'],
        'company_id': value['companyId'],
        'assistant_id': value['assistantId'],
        'initial_message': value['initialMessage'],
    };
}

