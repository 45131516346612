import { PropsWithChildren } from "react";

import Icon from "shared/components/icon/Icon";

const ArticleSection = ({
  title,
  children,
  icon,
}: PropsWithChildren<{ title?: string; icon?: string }>) => (
  <div className="grid gap-4">
    {title && (
      <div className="flex items-center gap-2 text-sm font-medium text-richblack dark:text-gray-400">
        {icon ? <Icon type={icon} fill size="icon-xs" /> : null}
        {title}
      </div>
    )}
    {children}
  </div>
);

export default ArticleSection;
