import { useEffect, useMemo, useState } from "react";

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";

import { useCompany } from "../../../context/CompanyProvider";
import { $isMentionNode } from "../../lexical-editor/nodes/MentionNode";
import { CAN_USE_DOM } from "../helpers/canUseDOM";
import AutoLinkPlugin from "../plugins/AutoLinkPlugin";
import ClickableLinkPlugin from "../plugins/ClickableLinkPlugin";
import TasksComponentPickerPlugin from "../plugins/ComponentPickerPlugin/TasksComponentPickerPlugin";
import DragDropPaste from "../plugins/DragDropPastePlugin";
import TaskDraggableBlockPlugin from "../plugins/DraggableBlockPlugin/TaskDraggableBlockPlugin";
import EmojiPickerPlugin from "../plugins/EmojiPickerPlugin";
import FloatingLinkEditorPlugin from "../plugins/FloatingLinkEditorPlugin";
import FloatingTextFormatToolbarPlugin from "../plugins/FloatingTextFormatToolbarPlugin";
import ImagesPlugin from "../plugins/ImagesPlugin";
import LinkPlugin from "../plugins/LinkPlugin";
import ListMaxIndentLevelPlugin from "../plugins/ListMaxIndentLevelPlugin";
import MarkdownShortcutPlugin from "../plugins/MarkdownShortcutPlugin";
import MentionsPlugin from "../plugins/MentionsPlugin";
import TabFocusPlugin from "../plugins/TabFocusPlugin";
import TableCellActionMenuPlugin from "../plugins/TableActionMenuPlugin";
import TableCellResizer from "../plugins/TableCellResizer";
import WebBookmarkPlugin from "../plugins/WebBookmarkPlugin";
import { MentionType } from "../types";
import ContentEditable from "../ui/ContentEditable";
import Placeholder from "../ui/Placeholder";

export default function TaskEditor({
  placeholder,
  onMentionChange,
}: {
  placeholder: string | null;
  onMentionChange: (data: string[]) => void;
}): JSX.Element {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const { currentCompany } = useCompany();
  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport =
        CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };

    window.addEventListener("resize", updateViewPortWidth);

    return () => {
      window.removeEventListener("resize", updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const placeholderComponent = placeholder ? <Placeholder>{placeholder}</Placeholder> : null;

  const mentionUsers = useMemo(() => {
    return currentCompany?.organization_users?.map((organization_user) => ({
      id: organization_user.user?.id,
      name: organization_user.user?.name || organization_user.user?.email,
    }));
  }, [currentCompany]);

  useEffect(() => {
    const mentions: string[] = [];
    editor?.getEditorState()._nodeMap.forEach((node) => {
      if ($isMentionNode(node)) {
        mentions.push(`${node.__id}`);
      }
    });
    onMentionChange(mentions);
  }, [editor?.getEditorState()]);

  return (
    <>
      <div className={`editor-container`}>
        <DragDropPaste />
        {editor.isEditable() && <TasksComponentPickerPlugin />}
        <AutoLinkPlugin />
        <>
          <RichTextPlugin
            contentEditable={
              <div className="editor-scroller">
                <div className="editor tasksEditor" ref={onRef}>
                  <ContentEditable />
                </div>
              </div>
            }
            placeholder={placeholderComponent}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <MentionsPlugin mentions={mentionUsers as MentionType[]} />
          <ListPlugin />
          <EmojiPickerPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <TablePlugin />
          <TableCellResizer />
          <ImagesPlugin />
          <LinkPlugin />
          <ClickableLinkPlugin />
          <HorizontalRulePlugin />
          <TabFocusPlugin />
          <TabIndentationPlugin />
          <WebBookmarkPlugin />
          <HistoryPlugin />
          <MarkdownShortcutPlugin />
          {floatingAnchorElem && !isSmallWidthViewport ? (
            <>
              <TaskDraggableBlockPlugin anchorElem={floatingAnchorElem} />
              <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
              <TableCellActionMenuPlugin anchorElem={floatingAnchorElem} />
              <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
            </>
          ) : null}
        </>
      </div>
    </>
  );
}
