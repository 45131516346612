import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { useMutation } from "@tanstack/react-query";

import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { useCompany } from "shared/context/CompanyProvider";
import { User } from "shared/context/types/company";
import { getMembersList } from "shared/helpers/getMemersList";
import { getServerErrors } from "shared/helpers/util";
import { useTeam, useTeamMemberPermission } from "shared/store/settings";

import { removeTeamMember } from "../api";
import useGetMembers from "../hooks/useGetMembers";
import TeamMemberPermission from "../team-member-permission/TeamMemberPermission";
import { ManageTeamProps } from "../types";
import MemberListItem from "./MemberListItem";

const MemberList = ({ refetch, memberList }: ManageTeamProps) => {
  const [selectedTeam, setSelectedTeam] = useTeam((store) => [
    store.selectedTeam,
    store.setSelectedTeam,
  ]);

  const { currentCompany } = useCompany();

  const { setTeamMemberPermissionModal } = useTeamMemberPermission();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [memberforDelete, setMemberforDelete] = useState<User | null>(null);

  const currentMember = useRef<User | null>(null);

  const { isLoading: isMemberRemoving, mutate: removeTeamMemberFn } = useMutation(
    () => {
      if (!selectedTeam?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }

      return removeTeamMember(
        selectedTeam?.id,
        currentCompany.id,
        memberforDelete?.id ? [memberforDelete?.id] : []
      );
    },
    {
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        if (selectedTeam) {
          setSelectedTeam({
            ...selectedTeam,
            members: selectedTeam.members?.filter((member) => member !== memberforDelete?.id) || [],
          });
        }
        toast(`${memberforDelete?.name || "Member"} removed successfully`, {
          type: "success",
        });
        setMemberforDelete(null);
        refetch();
      },
      onError: (error: AxiosError) => {
        toast(getServerErrors(error)[0], {
          type: "error",
        });
        setIsDeleteModalOpen(false);
      },
    }
  );

  const { isLoading, fetchMemberPermissionFn } = useGetMembers(() =>
    setTeamMemberPermissionModal(true)
  );

  const onMemberClick = (member?: User) => {
    if (member && selectedTeam) {
      currentMember.current = member;
      fetchMemberPermissionFn({ memberId: member.id, teamId: selectedTeam?.id });
    }
  };

  const onRemoveClick = (member?: User) => {
    if (member) {
      setMemberforDelete(member);
      setIsDeleteModalOpen(true);
    }
  };

  return (
    <>
      <ul className="divide-y divide-gray-200">
        {getMembersList(currentCompany, selectedTeam)?.map((member, index) => (
          <MemberListItem
            memberList={memberList || []}
            member={member}
            key={index}
            onRemoveClick={onRemoveClick}
            onMemberClick={onMemberClick}
          />
        ))}
      </ul>
      <TeamMemberPermission
        isLoading={isLoading}
        member={currentMember?.current}
        memberList={memberList || []}
      />
      <ConfirmModal
        isLoading={isMemberRemoving}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={() => removeTeamMemberFn()}
        text={`Are you sure you want to delete ${memberforDelete?.name || ""}`}
      />
    </>
  );
};

export default MemberList;
