import React from "react";

import { Modal } from "flowbite-react";

import styles from "assets/css/sidebar.module.css";

import { SwitchCompanyModalProps } from "./types";

const SwitchCompanyModal = ({
  isSwitchCompanyModalVisible,
  setIsSwitchCompanyModalVisible,
  selectedCompany,
  onSwitchMoreClick,
}: SwitchCompanyModalProps) => {
  return (
    <Modal
      show={isSwitchCompanyModalVisible}
      size="2xl"
      popup={true}
      onClose={() => setIsSwitchCompanyModalVisible(false)}
      dismissible={true}
      className={styles.switchCompanyModal}
      data-testid="switch-company-modal"
    >
      {/* <Modal.Header /> */}
      <Modal.Body className="pt-6">
        <div className="mt-4">
          <h2 className="font-inter-semibold text-mirage dark:!text-white">Oh snap!</h2>
          <h3 className="mt-3 text-aurometalsaurus dark:text-greychateau ">
            You are not a member of any team within {selectedCompany.name}, you may not <br />
            able to access some of the features. Please contact the company <br />
            administrator.
          </h3>
          <div className="mt-6 flex justify-center gap-2">
            <button
              data-testid="dismiss-button"
              className="btn_secondary ml-auto"
              onClick={() => setIsSwitchCompanyModalVisible(false)}
            >
              Dismiss
            </button>
            <button data-testid="switch-button" className="btn_primary" onClick={onSwitchMoreClick}>
              Switch Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchCompanyModal;
