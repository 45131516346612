import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { show_all_objects } from "shared/helpersV2/constant";
import { TeamId } from "shared/types";

import { AgreementFilesType, UploadAgreementFilesProps } from "../types";

export default function uploadAgreementFiles(
  data: UploadAgreementFilesProps,
  permissions: { team: TeamId }
): Promise<AxiosResponse<AgreementFilesType>> {
  const formData = new FormData();
  if (data.file) {
    formData.append("file", data?.file);
    formData.append("name", data?.file.name);
  }
  if (data.teamId) {
    formData.append("team", `${data.teamId}`);
  }
  return api.post<AgreementFilesType>(`agreements/files/`, formData, {
    params: {
      ...permissions,
      show_all_objects,
    },
  });
}
