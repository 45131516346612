/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMini
 */
export interface UserMini {
    /**
     * 
     * @type {string}
     * @memberof UserMini
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UserMini
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof UserMini
     */
    profilePicture?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMini
     */
    email: string;
}

/**
 * Check if a given object implements the UserMini interface.
 */
export function instanceOfUserMini(value: object): value is UserMini {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function UserMiniFromJSON(json: any): UserMini {
    return UserMiniFromJSONTyped(json, false);
}

export function UserMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMini {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'id': json['id'],
        'profilePicture': json['profile_picture'] == null ? undefined : json['profile_picture'],
        'email': json['email'],
    };
}

export function UserMiniToJSON(value?: Omit<UserMini, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'profile_picture': value['profilePicture'],
        'email': value['email'],
    };
}

