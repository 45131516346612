import React, { useState } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { Button, Modal, Spinner, TextInput } from "flowbite-react";
import { FormikProps, useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useSettings } from "shared/store/settings";

import { deleteCompany } from "../api";
import { CompanyDeleteProps, CompanyProps } from "../types";

const DeleteCompany = () => {
  const { currentCompany, refetch } = useCompany();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const setIsModalOpen = useSettings((state) => state.setIsModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading, mutate: deleteCompanyFn } = useMutation(
    () => deleteCompany(currentCompany?.id),
    {
      onSuccess: () => {
        setIsDeleteModalOpen(false);
        setIsModalOpen(false);
        refetch();
        toast("Company Deleted Successfully.", { type: "success" });
      },
    }
  );

  const validate = (values: CompanyProps) => {
    const errors: CompanyDeleteProps = {};

    if (!values.name) {
      errors.name = "invalid name";
    } else if (values.name !== currentCompany?.name) {
      errors.name = "invalid name";
    }
    return errors;
  };

  const formik: FormikProps<CompanyProps> = useFormik<CompanyProps>({
    initialValues: { name: "" },
    onSubmit: () => deleteCompanyFn(),
    validateOnChange: false,
    validate,
  });

  return (
    <>
      <div className={clsx(styles.account_content, "dark:border-thunders")}>
        <div className="mb-2 text-sm font-medium capitalize dark:text-white">Danger zone</div>
        <button
          type="button"
          onClick={() => setIsDeleteModalOpen(true)}
          className=" rounded-full border border-lavared px-5 py-2.5 text-sm text-lavared hover:bg-lavared hover:text-white mac13Inch:text-xs"
        >
          Delete Company
        </button>
      </div>
      <Modal
        root={documentBodyRef}
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center"
      >
        <Modal.Header className="header-font-xs-mac13 font-sans-medium dark:!border-thunders maxMd:px-4 maxMd:py-2.5 mac13Inch:items-center mac13Inch:px-6 mac13Inch:py-4">
          <span className="text-mirage dark:text-white">Please confirm company deletion</span>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body className="maxMd:px-4 maxMd:py-5">
            <div className="space-y-6">
              <p className="mb-3 text-aurometalsaurus dark:text-greychateau mac13Inch:text-sm">
                Are you sure you want to delete this company? It cannot be reverted.
              </p>
              <TextInput
                data-testid="delete-company-name"
                type="text"
                placeholder="Enter company name"
                value={formik.values.name}
                name="name"
                onChange={formik.handleChange}
                color={formik.errors.name ? "failure" : "gray"}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="flex-wrap justify-end !space-x-0 !p-4 dark:!border-thunders md:!px-6">
            <button
              type="button"
              className="btn_secondary md:mr-3 maxMd:mb-[18px] maxMd:w-full"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </button>
            <Button type="submit" color="failure" pill={true} className="maxMd:w-full">
              <Spinner
                size="sm"
                hidden={!isLoading}
                className="mr-3 !fill-red-700 !stroke-red-700"
              />
              Continue
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DeleteCompany;
