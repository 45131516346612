import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useWizard } from "react-use-wizard";

import { AxiosError } from "axios";
import { Label, Spinner, TextInput, Textarea } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import style from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { getServerErrors } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";

import { getNameForIcon } from "modules/settingsV2/helper";

import { createGroup } from "../api";
import { updateGroup } from "../api/updateGroup";
import ChangeGroupImage from "../components/ChangeGroupImage";
import useCreateGroup from "../hooks/useCreateGroup";
import { CompanyGroupInputData, CompanyGroupResponse, UpdateCompanGroupData } from "../types";

const BasicInfoStepOne = ({
  data,
  handleChangeData,
}: {
  data: CompanyGroupResponse | null;
  handleChangeData: React.Dispatch<React.SetStateAction<CompanyGroupResponse | null>>;
}) => {
  const { nextStep } = useWizard();

  const { currentCompany } = useCompany();

  const currentTeam = useTeam((store) => store.currentTeam);

  const [groupIcon, setGroupIcon] = useState<File | null>(null);

  const { values, handleChange, errors, handleSubmit, setFieldValue } = useCreateGroup(data, () => {
    if (data) {
      if (values.group_picture === null || typeof values.group_picture !== "string") {
        updateGroupFn({
          id: data.id,
          name: values.name,
          description: values.description,
          group_picture: values.group_picture,
        });
        return;
      }
      updateGroupFn({
        id: data.id,
        name: values.name,
        description: values.description,
      });
      return;
    }
    createGroupFn(values);
  });

  const groupIconRef = useRef<HTMLInputElement>(null);

  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target.files ? target.files[0] : null;
    if (file) {
      setFieldValue("group_picture", file);
      setGroupIcon(file);
    }
  };

  const { isLoading: isUpdating, mutate: createGroupFn } = useMutation(
    (data: CompanyGroupInputData) =>
      createGroup(
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      ),
    {
      onSuccess: (response) => {
        toast("Group Created Successfully", { type: "success" });
        handleChangeData(response.data);
        nextStep();
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const { isLoading, mutate: updateGroupFn } = useMutation(
    (data: Partial<UpdateCompanGroupData>) =>
      updateGroup(
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      ),
    {
      onSuccess: (response) => {
        toast("Group Updated Successfully", { type: "success" });
        handleChangeData(response.data);
        nextStep();
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  return (
    <div className={style.create_step_one}>
      <h3 className="dark:text-white">Icon</h3>
      <div className="mt-2 h-20 w-20 cursor-pointer" onClick={() => groupIconRef?.current?.click()}>
        <ChangeGroupImage
          groupIcon={groupIcon}
          groupName={data?.group_picture || groupIcon ? "" : getNameForIcon(values.name || "")}
          group_picture={data?.group_picture || null}
        />
      </div>
      <h3 className="mb-4 mt-2 text-sm text-aurometalsaurus dark:text-greychateau">
        Upload an image or pick an emoji. It will show up in your sidebar and notifications.
      </h3>
      <div>
        <input
          data-testid="add-group-file"
          type="file"
          className="hidden"
          ref={groupIconRef}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onIconChange(e)}
        />
        <button className={style.group_changeIcon} onClick={() => groupIconRef?.current?.click()}>
          <Icon type="cloud-upload" />
          Change picture
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="my-4">
          <Label value="Group Name*" className="mb-2 block font-inter-medium !text-mirage" />
          <TextInput
            data-testid="add-group-name"
            placeholder="Enter group name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            color={errors.name ? "failure" : "gray"}
          />
        </div>
        <div className="mb-4">
          <Label value="Description" className="mb-2 block font-inter-medium !text-mirage" />
          <Textarea
            data-testid="add-group-description"
            rows={4}
            name="description"
            value={values.description}
            onChange={handleChange}
            color={errors.description ? "failure" : "gray"}
            placeholder="Enter group description"
          />
        </div>
        <div className={style.create_step_one_footer}>
          <Link
            to={`/settings/${currentCompany.id}/groups/`}
            className="flex items-center gap-2 text-sm dark:text-greychateau"
          >
            <Icon type="chevron-left" className={style.back_to_setting_icon} />
            Go back to Settings
          </Link>
          <button className="btn_primary" type="submit" disabled={isLoading || isUpdating}>
            <Spinner
              size="md"
              light={true}
              hidden={!(isLoading || isUpdating)}
              className="mr-3 fill-crayolasblue stroke-crayolasblue"
            />
            {data ? "Update" : "Continue"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BasicInfoStepOne;
