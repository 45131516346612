import React, { useState } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { Modal } from "flowbite-react";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import { getUsername } from "shared/helpers/util";
import { useHome } from "shared/store/home";

import { ProfilePictureProps } from "../types";

const ChangePicture = ({ profilePicture, setProfilePicture }: ProfilePictureProps) => {
  const [isImageModalopen, setIsImageModalOpen] = useState<boolean>(false);

  const profile = useHome((state) => state.profile);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target?.files ? target?.files[0] : null;
    setIsImageModalOpen(false);
    if (file && file["type"].split("/")[0] !== "image") {
      toast("Upload a valid image", { type: "error" });
      return;
    }
    setProfilePicture(file);
  };

  const getProfilePicture = () => {
    if (profilePicture) return URL.createObjectURL(profilePicture);
    if (profile.profile_picture) return profile?.profile_picture;
  };

  return (
    <>
      <Modal
        show={isImageModalopen}
        onClose={() => setIsImageModalOpen(false)}
        className="custom_choose_file modal_mobileview_center"
        dismissible={true}
      >
        <Modal.Header className="font-sans-medium dark:!border-thunders maxMd:px-4 maxMd:py-3">
          <span className="dark:text-white">Upload icon</span>
        </Modal.Header>
        <Modal.Body className="maxMd:p-4">
          <form method="post" id="revue-form" name="revue-form" className="max-w-[493px]">
            <input
              className="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400"
              id="file_input"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onImageChange(e)}
            />
          </form>
        </Modal.Body>
      </Modal>
      <div className={clsx(styles.account_content, "dark:border-thunders")}>
        <div className="mb-2 font-inter-medium capitalize dark:text-white mac13Inch:text-sm">
          Photo
        </div>
        <div className={styles.account_details}>
          <div className="mr-4 cursor-pointer">
            <Avatar
              name={getUsername(profile)}
              avatarFor="user"
              avatarType="squared"
              size="lg"
              url={getProfilePicture()}
              onClick={() => setIsImageModalOpen(true)}
            />
          </div>
          <div className="my-auto block w-full md:w-[calc(100%-96px)]">
            <p className="!mt-0 dark:text-greychateau maxMd:!mt-4">
              Upload an image. It will show up in your sidebar and notifications.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePicture;
