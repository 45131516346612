import React from "react";

import toString from "lodash/toString";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyProps } from "shared/context/types/company";

import Avatar from "../avatar";

type CompaniesListProps = { onCompanyClick: (company: CompanyProps) => void };

const ComapniesList = ({ onCompanyClick }: CompaniesListProps) => {
  const { currentCompany, companies } = useCompany();
  return (
    <>
      {companies?.length >= 1 &&
        companies?.map((company) => (
          <div
            key={company.id}
            className="flex cursor-pointer items-center gap-2 rounded-lg px-2 py-1.5 hover:bg-antiflashwhite hover:dark:bg-the_end"
            onClick={() => onCompanyClick(company)}
          >
            <Avatar
              className="rounded-lg"
              name={company?.name}
              url={toString(company?.image)}
              avatarFor="company"
              avatarType="squared"
              size="regular"
            />
            <div className="flex w-[calc(100%_-_66px)] flex-wrap break-words">
              <span className="font-inter-medium text-sm text-brightgrey dark:text-silverpolish">
                {company?.name}
              </span>
            </div>
            <div className="flex items-center">
              {company?.id === currentCompany?.id ? (
                <Icon type="checkmark" fill={true} size="icon-sm" className="sidebar_icon" />
              ) : null}
            </div>
          </div>
        ))}
    </>
  );
};

export default ComapniesList;
