/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedAuthorityDocumentUpdateRequest
 */
export interface PatchedAuthorityDocumentUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    company?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    jurisdictions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    institutionTypes?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    authorityBody?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    lastReviewedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    upcomingReviewAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedAuthorityDocumentUpdateRequest
     */
    name?: string;
}

/**
 * Check if a given object implements the PatchedAuthorityDocumentUpdateRequest interface.
 */
export function instanceOfPatchedAuthorityDocumentUpdateRequest(value: object): value is PatchedAuthorityDocumentUpdateRequest {
    return true;
}

export function PatchedAuthorityDocumentUpdateRequestFromJSON(json: any): PatchedAuthorityDocumentUpdateRequest {
    return PatchedAuthorityDocumentUpdateRequestFromJSONTyped(json, false);
}

export function PatchedAuthorityDocumentUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedAuthorityDocumentUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tags': json['tags'] == null ? undefined : json['tags'],
        'company': json['company'] == null ? undefined : json['company'],
        'jurisdictions': json['jurisdictions'] == null ? undefined : json['jurisdictions'],
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'authorityBody': json['authority_body'] == null ? undefined : json['authority_body'],
        'lastReviewedAt': json['last_reviewed_at'] == null ? undefined : (new Date(json['last_reviewed_at'])),
        'upcomingReviewAt': json['upcoming_review_at'] == null ? undefined : (new Date(json['upcoming_review_at'])),
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function PatchedAuthorityDocumentUpdateRequestToJSON(value?: PatchedAuthorityDocumentUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'company': value['company'],
        'jurisdictions': value['jurisdictions'],
        'institution_types': value['institutionTypes'],
        'authority_body': value['authorityBody'],
        'last_reviewed_at': value['lastReviewedAt'] == null ? undefined : ((value['lastReviewedAt'] as any).toISOString()),
        'upcoming_review_at': value['upcomingReviewAt'] == null ? undefined : ((value['upcomingReviewAt'] as any).toISOString()),
        'name': value['name'],
    };
}

