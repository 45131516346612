import { ReactElement } from "react";

import { useCompany } from "shared/context/CompanyProvider";
import { Actions, Modules } from "shared/helpers/ability";
import useModuleEnabled from "shared/hooks/useModuleEnabled";
import { useHome } from "shared/store/home";
import { useTeam } from "shared/store/settings";

import NotFound from "../not-found";

interface CanAccessProps {
  action: Actions;
  moduleName: Modules;
  children: ReactElement;
}

const CanAccess = ({ action, moduleName, children }: CanAccessProps) => {
  /**
   * @type {import("shared/context/CompanyProvider").<AppAbility>(buildAbilityFor({} as Permissions))}
   **/

  /**
   * @param useCompany hook that sores infor related for comnpany
   * @param useCompany.ability property that used for ability that checks user can access module or not
   * @param useCompany.isAbilityLoaded boolean that
   */

  const { ability, isAbilityLoaded } = useCompany();

  const { checkEnabled } = useModuleEnabled();

  const currentTeam = useTeam((state) => state.currentTeam);

  const profile = useHome((state) => state.profile);

  if (!checkEnabled(moduleName)) {
    return <NotFound />;
  }

  if (!isAbilityLoaded) {
    return null;
  }

  if (ability.can(action, moduleName)) {
    return children;
  }

  if (
    !currentTeam?.members?.includes(profile?.id) &&
    !currentTeam?.is_common_team &&
    !currentTeam?.is_general_team &&
    !currentTeam?.is_all_team
  ) {
    return <NotFound />;
  }

  /**
   * NotFound component.
   *
   * @type {React.FC<Props>}
   * @returns {React.ReactElement} The NotFound.
   */

  return <NotFound />;
};

export default CanAccess;
