import React, { useState } from "react";
import { Wizard } from "react-use-wizard";

import { useQuery } from "@tanstack/react-query";

import style from "assets/css/settings.module.css";

import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { DEFAULT_GROUP_MEMBER } from "shared/helpers/constant";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import { CompanyGroupResponse, GroupInvitationType } from "../types";
import BasicInfoStepOne from "./BasicInfoStepOne";
import MembersInvitation from "./MembersInvitation";
import StepFormHeader from "./StepFormHeader";

export default function CreateGroup() {
  const { currentCompany } = useCompany();

  const [data, setData] = useState<CompanyGroupResponse | null>(null);

  const [groupMember, setGroupMember] = useState<Array<GroupInvitationType>>(
    Array.from({ length: 3 }, () => DEFAULT_GROUP_MEMBER)
  );

  const { data: memberList, isLoading } = useQuery(
    ["getMemberByCompany", "settingGroup", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  return (
    <div>
      {isLoading ? <CentralSpinner /> : null}
      <div className={style.create_groupContainer}>
        <Wizard header={<StepFormHeader headerTitles={["Group Details", "Add Users"]} />}>
          <BasicInfoStepOne data={data} handleChangeData={setData} />
          <MembersInvitation
            group={data}
            groupMember={groupMember}
            memberListing={memberList?.data}
            handleChangeMemberEmail={setGroupMember}
          />
        </Wizard>
      </div>
    </div>
  );
}
