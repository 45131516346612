/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `not_set` - Not Set
 * * `low` - Low Impact
 * * `medium` - Medium Impact
 * * `high` - High Impact
 * @export
 */
export const ArticleImpactEnum = {
    NotSet: 'not_set',
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;
export type ArticleImpactEnum = typeof ArticleImpactEnum[keyof typeof ArticleImpactEnum];


export function instanceOfArticleImpactEnum(value: any): boolean {
    for (const key in ArticleImpactEnum) {
        if (Object.prototype.hasOwnProperty.call(ArticleImpactEnum, key)) {
            if (ArticleImpactEnum[key as keyof typeof ArticleImpactEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ArticleImpactEnumFromJSON(json: any): ArticleImpactEnum {
    return ArticleImpactEnumFromJSONTyped(json, false);
}

export function ArticleImpactEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleImpactEnum {
    return json as ArticleImpactEnum;
}

export function ArticleImpactEnumToJSON(value?: ArticleImpactEnum | null): any {
    return value as any;
}

