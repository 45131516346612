import React from "react";

import { Table } from "flowbite-react";
import get from "lodash/get";
import moment from "moment";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import Icon from "shared/components/icon/Icon";
import { CompanyMember } from "shared/context/types/company";
import { INITIAL_ROLE_LEVEL, ROLES_LIST } from "shared/helpers/constant";
import { getUsername } from "shared/helpers/util";

import { UserRole } from "modules/home/types";
import RoleDropdown from "modules/settingsV2/members/components/RoleDropdown";

export default function MemberListingTable({
  loggedInUserRole,
  handleChangeRole,
  membersList,
  handleDelete,
  isEdit,
  children,
}: {
  loggedInUserRole?: {
    level: number;
    name: string;
    value: UserRole;
  };
  handleChangeRole?: ({
    role,
    memberId,
    item,
  }: {
    role: UserRole;
    memberId: number;
    item: CompanyMember;
  }) => void;
  membersList: CompanyMember[];
  handleDelete?: (item: CompanyMember) => void;
  isEdit: boolean;
  children?: JSX.Element;
}) {
  return (
    <>
      <Table className="min-w-[1234px] md:min-w-[900px] ">
        <Table.Head className={styles.table_header}>
          <Table.HeadCell>Name</Table.HeadCell>
          <Table.HeadCell>Role</Table.HeadCell>
          <Table.HeadCell>Last Activity</Table.HeadCell>
          <Table.HeadCell>Date Added</Table.HeadCell>
          {isEdit ? (
            <Table.HeadCell className="pr-3">
              <span className="sr-only"></span>
            </Table.HeadCell>
          ) : null}
        </Table.Head>
        {membersList.length > 0 ? (
          <Table.Body className="divide-y pl-4">
            {membersList?.map((item: CompanyMember, index: number) => {
              return (
                <Table.Row className="bg-white" key={index}>
                  <Table.Cell>
                    <div className="flex items-center">
                      <Avatar
                        name={getUsername(item.user)}
                        avatarFor="user"
                        avatarType="rounded"
                        size="md"
                        url={get(item, "user.profile_picture")}
                        className="cursor-default"
                      />
                      <div className="grid pl-6">
                        <span className="truncate font-inter-semibold text-base text-mirage dark:text-white">
                          {get(item, "user.name")}
                        </span>
                        <span className="email_ellipsis text-davygrey dark:text-boulder">
                          {get(item, "user.email") || get(item, "invitee_identifier") || ""}
                        </span>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <RoleDropdown
                      loggedInUserRole={loggedInUserRole}
                      isEdit={isEdit}
                      handleChangeRole={({ role }) => {
                        if (handleChangeRole) {
                          handleChangeRole({ role, memberId: item.id, item });
                        }
                      }}
                      isDisable={(get(item, "role") || "Select Role") == "Select Role"}
                      role={get(item, "role") || "Select Role"}
                    />
                  </Table.Cell>
                  <Table.Cell>{moment(item.modified).format("YYYY-MM-DD")}</Table.Cell>
                  <Table.Cell>{moment(item.created).format("YYYY-MM-DD")}</Table.Cell>
                  {isEdit ? (
                    <Table.Cell>
                      {(loggedInUserRole?.level || INITIAL_ROLE_LEVEL) >
                      (ROLES_LIST.find((roleDetail) => roleDetail.value === get(item, "role"))
                        ?.level || 0) ? (
                        <button
                          onClick={() => {
                            if (handleDelete) handleDelete(item);
                          }}
                        >
                          <Icon type="trash" fill={true} size="icon-sm" />
                        </button>
                      ) : null}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              );
            })}
          </Table.Body>
        ) : (
          <Table.Row className="border-b bg-white dark:border-thunders dark:bg-darkjunglegreenss">
            <Table.Cell
              className="items-baseline !px-0 py-6 text-center text-sm font-medium dark:border-thunders dark:text-greychateau"
              colSpan={4}
            >
              No Member Available
            </Table.Cell>
          </Table.Row>
        )}
        {children}
      </Table>
    </>
  );
}
