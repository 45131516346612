import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { Route, Routes } from "react-router-dom";

import { Spinner } from "flowbite-react";
import { createBrowserHistory } from "history";
import { boot as bootIntercom, load as loadIntercom, update as updateIntercom } from "intercom";

import { useQuery } from "@tanstack/react-query";

import { AbilityContext } from "shared/components/can/Can";
import ObjectPermissionModal from "shared/components/object-permission";
import { useCompany } from "shared/context/CompanyProvider";
import { useHome } from "shared/store/home";
import { useSharedObjectModal } from "shared/store/sharedobject";

import { getProfile } from "modules/settings/my-account/api";

import ModuleRoutes from "./ModuleRoutes";

const Home = () => {
  const { ability, currentCompany, isAbilityLoaded } = useCompany();

  const isSharedObjectModalOpen = useSharedObjectModal((state) => [state.isSharedObjectModalOpen]);

  const [profile, setProfile] = useHome((state) => [state.profile, state.setProfile]);

  useQuery(["getProfile"], getProfile, {
    onSuccess: (res) => setProfile(res.data),
  });

  useEffect(() => {
    loadIntercom();
    bootIntercom({
      alignment: "right",
      horizontal_padding: 80,
      hide_default_launcher: false,
      user_id: profile?.id,
      name: profile?.name,
      email: profile?.email,
    });

    const history = createBrowserHistory();
    return history.listen(() => {
      updateIntercom();
    });
  }, [profile]);

  if (!currentCompany?.id) {
    return <Spinner />;
  }

  if (!isAbilityLoaded) {
    return <Spinner />;
  }

  return (
    <>
      <main>
        <AbilityContext.Provider value={ability}>
          <Routes>
            <Route path="/*" element={<ModuleRoutes />} />
          </Routes>
        </AbilityContext.Provider>
        {isSharedObjectModalOpen ? createPortal(<ObjectPermissionModal />, document.body) : null}
      </main>
    </>
  );
};

export default Home;
