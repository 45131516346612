import React, { ReactElement, useEffect, useState } from "react";

import get from "lodash/get";

import Avatar from "shared/components/avatar";
import CommonDropdownWrapper from "shared/components/common-dropdown";
import { useCompany } from "shared/context/CompanyProvider";
import { User } from "shared/context/types/company";
import { getUsername } from "shared/helpers/util";
import useOwnerFilter from "shared/hooks/useOwnerFilter";
import { useDropdownPaginationSearch } from "shared/store/dropdownPaginationSearch";

import { TeamOwnerProps } from "../types";

type SelectOptionProps = {
  name: string | ReactElement;
  id: string | number;
};

const TeamOwner = ({ onChange, user_object, placeholder = "" }: TeamOwnerProps) => {
  const { currentCompany } = useCompany();
  const [selectedOwner, setSelectedOwner] = useState<SelectOptionProps | null>(null);

  const { paginationSearchData } = useDropdownPaginationSearch();

  const {
    isLoading: isLoading,
    data: membersData,
    hasNextPage: hasMemberNextPage,
    isFetchingNextPage: isFetchingMemberNextPage,
    fetchNextPage: fetchMemberNextPage,
    isFetching: isMemberFetching,
  } = useOwnerFilter(
    "teamOwnerList",
    currentCompany?.id,
    "owner",
    paginationSearchData?.metaDataOwnerForm?.search
  );

  const getLabel = (label: User) => {
    return (
      <div className="flex items-center">
        <div className="mr-2 flex">
          <Avatar
            size="sm"
            name={getUsername(label)}
            avatarFor="user"
            avatarType="rounded"
            url={label?.profile_picture}
          />
        </div>
        <span className="email_truncat">{getUsername(label)}</span>
      </div>
    );
  };

  const companyOwners: SelectOptionProps[] =
    membersData?.pages
      ?.map(
        (users) =>
          users.data?.results?.map((item) => {
            return {
              name: item.user && getLabel({ ...item.user }),
              id: item.user && item.user.id,
            };
          }) || []
      )
      ?.flat() || [];

  useEffect(() => {
    if (user_object) {
      setSelectedOwner({
        name: (
          <div className="flex items-center text-sm text-mirage dark:text-white">
            <div className="mr-2 flex">
              <Avatar
                size="sm"
                name={getUsername(user_object)}
                avatarFor="user"
                avatarType="rounded"
                url={user_object?.profile_picture}
              />
            </div>
            {getUsername(user_object)}
          </div>
        ),
        id: user_object?.id || 0,
      });
    }
  }, [user_object]);

  return (
    <>
      <div className="select-member relative items-start" data-testid="team_owner">
        <CommonDropdownWrapper
          hasPagination={true}
          title=""
          isLoading={isLoading || isMemberFetching || isFetchingMemberNextPage}
          fieldName="search"
          placeholder={placeholder}
          handleChangeCallback={(data, option) => {
            onChange(data);
            setSelectedOwner(get(option, "data", null));
          }}
          formName="metaDataOwnerForm"
          data={companyOwners}
          selectedObject={selectedOwner}
          hasNextPage={hasMemberNextPage}
          fetchNextPage={fetchMemberNextPage}
        />
      </div>
    </>
  );
};

export default TeamOwner;
