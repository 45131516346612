import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { show_all_objects } from "shared/helpersV2/constant";
import { PaginationDropdownQueryParams } from "shared/types";

import { GeographiesResultType } from "../types";

export default function getGeographies(
  requestParams?: PaginationDropdownQueryParams
): Promise<AxiosResponse<GeographiesResultType[]>> {
  return api.get<GeographiesResultType[]>("agreements/geographies/", {
    params: {
      ...hasNameOrdering(requestParams),
      show_all_objects,
    },
  });
}
