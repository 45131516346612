import React from "react";

import isEmpty from "lodash/isEmpty";

import { useQuery } from "@tanstack/react-query";

import style from "assets/css/settings.module.css";

import { useCompany } from "shared/context/CompanyProvider";

import { getPaymentMethod } from "modules/settings/billing/api";

import { getInvoices } from "./api";
import BillingDetails from "./components/BillingDetails";
import BillingInvoice from "./components/BillingInvoice";
import BillingListing from "./components/BillingListing";
import UpdateAddress from "./components/UpdateAddress";
import UpdateCard from "./components/UpdateCard";
import UpdateEmail from "./components/UpdateEmail";
import UpdateVATorGST from "./components/UpdateVATorGST";

export default function Billing() {
  const { currentCompany } = useCompany();

  const { data: billingDetails, refetch: refetchBillingDetails } = useQuery(
    ["getPaymentMethod"],
    () => getPaymentMethod(currentCompany.id)
  );

  const { data: response } = useQuery(["getInvoices"], () => getInvoices(currentCompany.id));

  const billings = !isEmpty(response?.data) ? response?.data : [];

  return (
    <div className={style.setting_layout}>
      {billingDetails ? (
        <>
          {!isEmpty(currentCompany.customer) ? (
            <BillingDetails billingDetails={billingDetails?.data} />
          ) : null}
        </>
      ) : null}

      <BillingListing billingDetails={billingDetails?.data} />
      <BillingInvoice billings={billings} />
      {billingDetails?.data ? (
        <>
          <UpdateCard
            billingDetails={billingDetails?.data}
            refetchBillingDetails={refetchBillingDetails}
          />
          <UpdateEmail
            billingDetails={billingDetails?.data}
            refetchBillingDetails={refetchBillingDetails}
          />
          <UpdateAddress
            billingDetails={billingDetails?.data}
            refetchBillingDetails={refetchBillingDetails}
          />
          <UpdateVATorGST
            billingDetails={billingDetails?.data}
            refetchBillingDetails={refetchBillingDetails}
          />
        </>
      ) : null}
    </div>
  );
}
