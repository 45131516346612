import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import toString from "lodash/toString";
import { useOnClickOutside } from "usehooks-ts";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/sidebar.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import { SEARCH_SUGGESTION } from "shared/helpers/constant";
import { removeItemFromLocalStorage, removeRefreshToken, removeToken } from "shared/helpers/util";
import { useVendor } from "shared/storeV2/vendors";

import { signout } from "modules/auth/signIn/api";

import { CompanyProps } from "../../context/types/company";
import { useHome } from "../../store/home";
import { useMenu } from "../../store/menu";
import { useQueryParams, useSearchSuggestion } from "../../store/table-column-selection";
import Icon from "../icon/Icon";
import CompaniesList from "./CompaniesList";
import SwitchCompanyModal from "./SwitchCompanyModal";

type MenuProps = {
  companies: CompanyProps[];
};

const Menu = ({ companies }: MenuProps) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyProps | null>(null);

  const { updateCurrentCompany } = useCompany();
  const setSearchData = useSearchSuggestion((state) => state.setSearchData);
  const setCurrentVendorList = useVendor((state) => state.setCurrentVendorList);

  const profile = useHome((state) => state.profile);

  const navigate = useNavigate();

  const menuRef = useRef<HTMLDivElement>(null);

  const isMenuShown = useMenu((state) => state.isMenuShown);

  const setIsMenuShown = useMenu((state) => state.setIsMenuShown);

  const [isSwitchCompanyModalVisible, setIsSwitchCompanyModalVisible] = useState<boolean>(false);

  const [resetSelectedQueryParams] = useQueryParams((state) => [state.resetSelectedQueryParams]);

  useOnClickOutside(menuRef, () => {
    if (!isSwitchCompanyModalVisible) {
      setIsMenuShown(!isMenuShown);
    }
  });

  const { mutate: signoutFn } = useMutation(() => signout(), {
    onSuccess: () => {
      navigate("/signin", { replace: true });
      setIsMenuShown(false);
      removeItemFromLocalStorage(SEARCH_SUGGESTION);
      setSearchData({});
      removeToken();
      removeRefreshToken();
      setCurrentVendorList(null);
    },
  });

  const onCompanyClick = (company: CompanyProps) => {
    setSelectedCompany(company);

    const userisMemberinCompanies = company.teams.filter((team) =>
      toString(team.members)?.includes(toString(profile?.id))
    );

    if (company.teams.length === 0 || userisMemberinCompanies.length === 0) {
      setIsSwitchCompanyModalVisible(true);
      return;
    }

    navigate("/");
    updateCurrentCompany(company);
    setCurrentVendorList(null);
    setIsMenuShown(false);
    resetSelectedQueryParams({});
  };

  const onSwitchMoreClick = () => {
    selectedCompany && updateCurrentCompany(selectedCompany);
    setIsSwitchCompanyModalVisible(false);
    setIsMenuShown(false);
  };

  return (
    <>
      <div className={styles.menu} ref={menuRef} data-testid="menu">
        <div className={styles.menuItem}>
          <CompaniesList companies={companies} onCompanyClick={onCompanyClick} />
        </div>
        <button
          className={styles.menuOptions}
          data-testid="navigate-button"
          onClick={() => navigate("/on-boarding")}
        >
          <Icon type="bank" fill={true} size="icon-sm" className="mr-3" />
          <span className="title-sm text-aurometalsaurus dark:text-greychateau">Add Company</span>
        </button>
        <button
          className={styles.menuOptions}
          data-testid="signout-button"
          onClick={() => signoutFn()}
        >
          <Icon type="closeround" fill={false} size="icon-sm" className="mr-3" />
          <span className="title-sm text-aurometalsaurus dark:text-greychateau">Logout All</span>
        </button>
      </div>
      {selectedCompany ? (
        <SwitchCompanyModal
          isSwitchCompanyModalVisible={isSwitchCompanyModalVisible}
          setIsSwitchCompanyModalVisible={setIsSwitchCompanyModalVisible}
          selectedCompany={selectedCompany}
          onSwitchMoreClick={onSwitchMoreClick}
        />
      ) : null}
    </>
  );
};

export default Menu;
