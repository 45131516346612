/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof Section
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Section
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
}

/**
 * Check if a given object implements the Section interface.
 */
export function instanceOfSection(value: object): value is Section {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function SectionFromJSON(json: any): Section {
    return SectionFromJSONTyped(json, false);
}

export function SectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Section {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'name': json['name'],
    };
}

export function SectionToJSON(value?: Omit<Section, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

