import React from "react";

import { Button, Modal, Spinner } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import { unsubscribe } from "./api";

interface DowngradePlansProps {
  isDowngradePlans: boolean;
  setIsDowngradePlans: (setIsDowngradePlans: boolean) => void;
}

const DowngradePlan = ({ isDowngradePlans, setIsDowngradePlans }: DowngradePlansProps) => {
  const { currentCompany, updateCurrentCompany } = useCompany();

  const { isLoading, mutate: unsubscribeFn } = useMutation(() => unsubscribe(currentCompany?.id), {
    onSuccess: (res) => {
      updateCurrentCompany(res.data);
      setIsDowngradePlans(false);
    },
    onError: () => {
      setIsDowngradePlans(false);
    },
  });

  return (
    <>
      <Modal
        show={isDowngradePlans}
        onClose={() => setIsDowngradePlans(false)}
        dismissible={true}
        className="modal_mobileview_center"
      >
        <Modal.Header className="!font-semibold dark:!border-thunders maxMd:p-4">
          <span className="text-mirage">Downgrade plan</span>
        </Modal.Header>
        <Modal.Body className="maxMd:p-4">
          <div className="space-y-6">
            <p className="text-base leading-relaxed text-gray-500 dark:text-greychateau">
              With less than a month to go before the European Union enacts new consumer privacy
              laws for its citizens, companies around the world are updating their terms of service
              agreements to comply.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-greychateau">
              The European Union&apos;s General Data Protection Regulation (G.D.P.R.) goes into
              effect on May 25 and is meant to ensure a common set of data rights in the European
              Union. It requires organizations to notify users as soon as possible of high-risk data
              breaches that could personally affect them.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end dark:!border-thunders maxMd:flex-wrap maxMd:p-4  ">
          <button
            className="btn_secondary maxMd:mb-2 maxMd:w-full"
            onClick={() => setIsDowngradePlans(false)}
          >
            No, cancel
          </button>
          <Button
            className="!rounded-full !bg-valentinered hover:!bg-red-600 dark:focus:!ring-0 maxMd:!ml-0 maxMd:w-full"
            onClick={() => unsubscribeFn()}
          >
            <Spinner size="md" hidden={!isLoading} className="mr-3 !fill-red-500 !stroke-red-500" />
            Downgrade
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DowngradePlan;
