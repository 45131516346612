import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { show_all_objects } from "shared/helpersV2/constant";
import { TeamId } from "shared/types";

import convertAgreementData from "../helpers/convertAgreementData";
import { AgreementResultsType } from "../types";

export default async function updateAgreementFiles(
  data: { [key: string]: number[] },
  agreementId: string | number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<AgreementResultsType>> {
  let response = await api.patch<AgreementResultsType>(
    `agreements/${agreementId}/`,
    { ...data },
    {
      params: {
        ...permissions,
        show_all_objects,
      },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertAgreementData(response.data),
    };
  }

  return response;
}
