import React from "react";

import gridStyles from "assets/css/gridview.module.css";

export type GridCardTitleProps = {
  title: string;
  selected?: boolean;
  selectable?: boolean;
  onSelect?: (selected: boolean) => void;
};

const GridCardTitle: React.FC<GridCardTitleProps> = ({
  title,
  selected,
  selectable,
  onSelect: select,
}: GridCardTitleProps) => {
  return (
    <div className={gridStyles.titleContainer}>
      <div className={gridStyles.gridTitle}>
        <div className="min-w-0 items-start gap-2">
          <div className={gridStyles.gridTitleContent}>{title}</div>
        </div>
        {selectable ? (
          <input
            type="checkbox"
            data-testid="grid-card-select"
            readOnly
            className="h-4 w-4 cursor-pointer rounded-full border-lightSilver bg-ghostwhite"
            checked={selected}
            onClick={(e) => {
              e.stopPropagation();
              if (select) {
                select(!selected);
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GridCardTitle;
