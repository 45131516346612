import { useState } from "react";
import { toast } from "react-toastify";

import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";
import { ACTIVE } from "shared/helpers/constant";
import { useSubscriptionActive } from "shared/store/subscription";

import getCurrentCompany from "modules/home/api/getCurrentCompany";

const usePolling = (isSuccess: boolean, setIsUpgradePaymentModal: (state: boolean) => void) => {
  const { currentCompany } = useCompany();

  const setSubscriptionActive = useSubscriptionActive((state) => state.setSubscriptionActive);

  const [isPolling, setIsPolling] = useState<boolean>(true);

  useQuery(["fetch-current-company"], () => getCurrentCompany(currentCompany?.id), {
    onSuccess: (res) => {
      if (res?.data.subscription && res?.data?.subscription?.status === ACTIVE) {
        setSubscriptionActive(true);
        setTimeout(() => {
          setIsPolling(false);
          setIsUpgradePaymentModal(false);
          toast("Subscription Activated Successfully.", { type: "success" });
        }, 2000);
      }
    },
    enabled: isSuccess,
    refetchOnWindowFocus: false,
    refetchInterval: (res) => (res?.data?.subscription?.status !== ACTIVE ? 1500 : false),
  });

  return { isPolling, setIsPolling };
};

export default usePolling;
