import React from "react";

import { Button, Modal, Spinner } from "flowbite-react";

type ConfirmModalProp = {
  action: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isLoading?: boolean;
  text?: string;
};

const ConfirmModal = ({ action, isOpen, setIsOpen, isLoading, text }: ConfirmModalProp) => {
  const Icon = () => {
    return (
      <svg
        aria-hidden="true"
        className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
    );
  };

  return (
    <Modal
      show={isOpen}
      size="md"
      popup={true}
      onClose={() => setIsOpen(false)}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header className="!p-2" />
      <Modal.Body>
        <div className="text-center">
          <Icon />
          <h3
            data-testid="confirm-text"
            className="mb-5 text-lg font-normal text-gray-500 dark:text-greychateau mac13Inch:text-sm"
          >
            {text ? text : "Are you sure you want to delete"}?
          </h3>
          <div className="flex justify-center gap-4">
            <Button
              color="failure"
              onClick={action}
              className="btn-danger !rounded-full focus:!ring-transparent"
              disabled={isLoading}
              data-testid="confirm"
            >
              <Spinner
                size="sm"
                hidden={!isLoading}
                className="mr-2 !fill-red-700 !stroke-red-700"
              />
              Yes, I&apos;m sure
            </Button>
            <button className="btn_secondary" onClick={() => setIsOpen(false)}>
              No, cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
