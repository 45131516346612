import { useState } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { ArticleStatusEnum } from "openapi";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import Icon from "shared/components/icon/Icon";

import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useArticleTodos } from "modules/horizonScanning/hooks/useArticleTodos";
import { getStatusColor, getStatusLabel } from "modules/horizonScanning/utils";

const ArticleStatusDropdown = ({ disabled }: { disabled?: boolean }) => {
  const [openComboBox, setOpenComboBox] = useState(false);
  const {
    addStatus,
    articleQuery: { data: article },
  } = useArticleContext();
  const { totalTodosLength, completedTodosLength } = useArticleTodos();
  const hasUncompletedTodos = totalTodosLength > completedTodosLength;

  if (!article) return null;

  return (
    <DropdownMenu.Root open={openComboBox} onOpenChange={setOpenComboBox}>
      <DropdownMenu.Trigger asChild disabled={disabled} className="rounded-lg">
        <button
          disabled={disabled}
          className="flex w-fit items-center justify-between gap-2 rounded-lg border-none px-0 py-0.5 disabled:text-santagrey"
        >
          {article.status ? (
            <div className="flex items-center gap-2">
              <span
                className={clsx(
                  "h-2 w-2 rounded-full",
                  { "opacity-70": disabled },
                  getStatusColor(article.status)
                )}
              ></span>
              <span
                className={clsx("text-sm font-medium", {
                  "text-richblack dark:text-lighthouse": !disabled,
                  "text-santagrey dark:text-gray_400": disabled,
                })}
              >
                {getStatusLabel(article.status)}
              </span>
            </div>
          ) : (
            <span className="font-inter-medium text-sm text-santagrey dark:text-gray_400">
              Select
            </span>
          )}
          <Icon type="chevron-down" size="icon-sm" fill={true} />
        </button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="DropdownMenuContent w-fit min-w-[10rem]"
        sideOffset={5}
        side={"bottom"}
        align={"start"}
      >
        {Object.values(ArticleStatusEnum).map((option) => {
          return (
            <DropdownMenu.Item
              key={option}
              className="DropdownMenuItem"
              onClick={() => {
                if (hasUncompletedTodos && option === ArticleStatusEnum.Completed) {
                  toast("Complete all to-dos before marking this article as completed.", {
                    type: "error",
                  });
                  return;
                }
                addStatus(option);
              }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className={clsx("h-2 w-2 rounded-full", getStatusColor(option))}></span>
                  <span>{getStatusLabel(option)}</span>
                </div>
                {option === article.status && <Icon type="checkmark" fill={true} size="icon-sm" />}
              </div>
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ArticleStatusDropdown;
