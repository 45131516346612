import React from "react";

import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import { getProviders } from "./api";
import AddProviders from "./authentication-providers/AddProviders";
import ProvidersList from "./authentication-providers/ProvidersList";
import ProvidersModal from "./authentication-providers/ProvidersModal";

const Authentication = () => {
  const { currentCompany } = useCompany();

  const { data: response, refetch } = useQuery(["get-providers"], () =>
    getProviders(currentCompany.id)
  );

  return (
    <>
      <AddProviders>
        <ProvidersModal refetch={refetch} />
      </AddProviders>
      <ProvidersList response={response} refetch={refetch} />
    </>
  );
};

export default Authentication;
