/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenAIAssistant
 */
export interface OpenAIAssistant {
    /**
     * 
     * @type {number}
     * @memberof OpenAIAssistant
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof OpenAIAssistant
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpenAIAssistant
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof OpenAIAssistant
     */
    assistantId: string;
    /**
     * 
     * @type {string}
     * @memberof OpenAIAssistant
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpenAIAssistant
     */
    isActive?: boolean;
}

/**
 * Check if a given object implements the OpenAIAssistant interface.
 */
export function instanceOfOpenAIAssistant(value: object): value is OpenAIAssistant {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('assistantId' in value) || value['assistantId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function OpenAIAssistantFromJSON(json: any): OpenAIAssistant {
    return OpenAIAssistantFromJSONTyped(json, false);
}

export function OpenAIAssistantFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenAIAssistant {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'assistantId': json['assistant_id'],
        'name': json['name'],
        'isActive': json['is_active'] == null ? undefined : json['is_active'],
    };
}

export function OpenAIAssistantToJSON(value?: Omit<OpenAIAssistant, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'assistant_id': value['assistantId'],
        'name': value['name'],
        'is_active': value['isActive'],
    };
}

