import React, { useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Modal, Tabs } from "flowbite-react";
import get from "lodash/get";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/customModal.module.css";

import Avatar from "shared/components/avatar";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import PermissionListing from "shared/components/permission";
import { removeFromCompany, updatePermission } from "shared/components/permission/api";
import { permissionProps } from "shared/components/permission/types";
import ServerErrors from "shared/components/server-errors";
import Spinner from "shared/components/spinner/Spinner";
import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import { getCompanyUserResponseData } from "shared/helpers/getCompanyUserResponseData";
import { getUsername } from "shared/helpers/util";
import { useSettings } from "shared/store/settings";

import { CompanyUserResponse } from "modules/home/types";

import { TeamResponse } from "../teams/types";

const PermissionModal = ({
  show,
  onClose,
  memberDetails,
  setMemberDetails,
  refetchMember,
}: permissionProps) => {
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);

  const { user } = useUser();
  const { removeCompany, currentCompany, refetch } = useCompany();
  const setIsSettingModalOpen = useSettings((state) => state.setIsModalOpen);

  const {
    isLoading: isDeleting,
    mutate: deleteFromCompany,
    isError: isDeleteError,
    error: deleteError,
  } = useMutation((id: number) => removeFromCompany(id, { company: currentCompany.id }), {
    onSuccess: () => {
      if (get(memberDetails, "user.id") === user.id) {
        onClose();
        setIsSettingModalOpen(false);
        removeCompany(currentCompany, true);
        toast("Removed from Company.", { type: "success" });
      } else {
        refetchMember();

        removeCompany(currentCompany, false);
        onClose();
        toast("Member Removed Successfully.", { type: "success" });
      }
    },
    onError: (e: AxiosError): AxiosError => {
      setIsConfirmDialog(false);
      return e;
    },
  });

  const removePermission = () => {
    if (memberDetails) {
      deleteFromCompany(memberDetails?.id);
    }
  };
  const {
    isLoading,
    mutate: updatePermissionList,
    isError,
    error,
  } = useMutation({
    mutationFn: (data: CompanyUserResponse) => {
      return updatePermission(data, { company: currentCompany.id });
    },
    onSuccess: (response) => {
      setMemberDetails(response.data);
      refetch();
      refetchMember();
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const updateMemberPermission = (data: CompanyUserResponse) => {
    updatePermissionList(data);
  };

  return (
    <div className={styles.permissionModal}>
      <Modal show={show} onClose={onClose} dismissible={true} className="modal_mobileview_center">
        <Modal.Header className={styles.modalHeader}>
          <div className="flex items-center">
            <div className=" mr-3 flex  h-6 w-6 items-center justify-center rounded text-[16px] text-mirage dark:text-white">
              <Avatar
                name={getUsername(memberDetails?.user)}
                avatarFor="user"
                avatarType="squared"
                size="sm"
                url={get(memberDetails, "user.profile_picture")}
              />
            </div>
            <span className="font-inter-regular text-mirage dark:text-white maxMd:text-sm ">
              {getUsername(memberDetails?.user)}
            </span>
          </div>
        </Modal.Header>
        <Tabs.Group className={styles.tabs_details}>
          <Tabs.Item title="Permissions" active={true}>
            <Modal.Body className={clsx(styles.permissions, "maxMd:p-4")}>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <div className={styles.permissionsList}>
                    {memberDetails ? (
                      <PermissionListing
                        data={{ ...memberDetails }}
                        updatePermissionFn={(data: CompanyUserResponse | TeamResponse) => {
                          const companyUserData = getCompanyUserResponseData(data);
                          if (companyUserData && typeof companyUserData !== null) {
                            updateMemberPermission(companyUserData);
                          }
                        }}
                        isOwner={memberDetails?.is_admin}
                      />
                    ) : null}
                  </div>
                </>
              )}
              {(isError || isDeleteError) && (
                <ServerErrors className="m-4" error={error || deleteError} />
              )}
            </Modal.Body>
            <Modal.Footer className="mx-[-24px] justify-end dark:!border-thunders maxMd:px-4 mac13Inch:pb-0">
              <button
                type="button"
                className="rounded-full border border-[#E02424] px-5 py-2.5 text-sm text-[#E02424] hover:bg-[#E02424] hover:text-white focus:outline-none focus:ring-0 maxMd:w-full mac13Inch:text-xs"
                onClick={() => {
                  setIsConfirmDialog(!isConfirmDialog);
                }}
              >
                Remove From Company
              </button>
              <ConfirmModal
                isLoading={isDeleting}
                isOpen={isConfirmDialog}
                setIsOpen={setIsConfirmDialog}
                action={() => removePermission()}
              />
            </Modal.Footer>
          </Tabs.Item>
        </Tabs.Group>
      </Modal>
    </div>
  );
};

export default PermissionModal;
