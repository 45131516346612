import React from "react";
import { useWizard } from "react-use-wizard";

import clsx from "clsx";

import style from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";

export default function StepFormHeader({ headerTitles }: { headerTitles: Array<string> }) {
  const { activeStep } = useWizard();

  return (
    <div className={style.create_groupHeader}>
      {headerTitles.map((title, index) => (
        <div className="flex items-center gap-4 rounded-lg py-4 pl-5 text-lg text-brightgrey dark:text-brightgray">
          <div
            className={clsx(
              "flex h-12 w-12 items-center justify-center rounded-full border border-lightSilver bg-antiflashwhite text-mirage dark:bg-white dark:text-mirage",
              {
                "border-mirage bg-mirage text-white": activeStep === index,
                "border-greenhaze bg-greenhaze dark:bg-greenhaze": activeStep > index,
              }
            )}
          >
            {activeStep > index ? (
              <Icon type="right" size="icon-sm" className={style.successIcon} />
            ) : (
              index + 1
            )}
          </div>
          {title}
          {headerTitles.length !== index + 1 ? (
            <Icon type="chevron-right-thin" size="icon-lg" />
          ) : null}
        </div>
      ))}
    </div>
  );
}
