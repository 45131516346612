import get from "lodash/get";
import omit from "lodash/omit";

import { PaginationSearchModuleProps } from "shared/store/commonTable";

export default function getPaginatedSearchDataResult(
  currentData: PaginationSearchModuleProps,
  moduleName: string,
  field: string,
  searchData: string
): PaginationSearchModuleProps {
  if (searchData && searchData?.trim()?.length > 0) {
    return {
      ...currentData,
      [moduleName]: {
        ...get(currentData, moduleName, {}),
        [field]: searchData,
      },
    };
  }
  return {
    ...currentData,
    [moduleName]: omit(get(currentData, moduleName), [field]),
  };
}
