import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { CompanyId, TeamId } from "shared/types";

import { GetTagsRequestParams, TagsResponseType } from "../types";

type CustomTeamParameter = {
  team: TeamId | undefined;
  company: CompanyId;
  isAllTeam?: boolean;
};

export default function getTagsList(
  requestParams: GetTagsRequestParams,
  permissions: { team: CustomTeamParameter }
): Promise<AxiosResponse<TagsResponseType>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get(`core/tags/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{
        ...(teamParameter !== undefined ? { team: teamParameter } : {}),
        company: permissions.team?.company,
      },
    },
  });
}
