/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `public` - Public
 * * `shared` - Shared
 * * `private` - Private
 * @export
 */
export const VisibilityEnum = {
    Public: 'public',
    Shared: 'shared',
    Private: 'private'
} as const;
export type VisibilityEnum = typeof VisibilityEnum[keyof typeof VisibilityEnum];


export function instanceOfVisibilityEnum(value: any): boolean {
    for (const key in VisibilityEnum) {
        if (Object.prototype.hasOwnProperty.call(VisibilityEnum, key)) {
            if (VisibilityEnum[key as keyof typeof VisibilityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VisibilityEnumFromJSON(json: any): VisibilityEnum {
    return VisibilityEnumFromJSONTyped(json, false);
}

export function VisibilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisibilityEnum {
    return json as VisibilityEnum;
}

export function VisibilityEnumToJSON(value?: VisibilityEnum | null): any {
    return value as any;
}

