import { useFormik } from "formik";

import { CompanyGroupInputData, CompanyGroupResponse } from "../types";
import { createGroupSchema } from "../validation";

const useCreateGroup = (data: CompanyGroupResponse | null, action: CallableFunction) => {
  return useFormik<CompanyGroupInputData>({
    initialValues: {
      name: data?.name || "",
      description: data?.description || "",
      group_picture: data?.group_picture || null,
    },
    validateOnChange: false,
    validationSchema: createGroupSchema,
    onSubmit: () => action(),
  });
};

export default useCreateGroup;
