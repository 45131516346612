import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";

import { convertArticleData } from "../helpers";
import { ArticlesResponseProps } from "../types";

export default async function getPublicArticle(
  articleId: number,
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<ArticlesResponseProps>> {
  let response = await api.get<ArticlesResponseProps>(`articles/${articleId}/public/`, {
    params: { ...hasNameOrdering(requestParams, "title") },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertArticleData(response.data),
    };
  }

  return response;
}
