import { AxiosResponse } from "axios";

import { UseInfiniteQueryResult, useInfiniteQuery } from "@tanstack/react-query";

import getPageFromNextPage from "shared/helpers/getPageFromNextPage";
import { CompanyId } from "shared/types";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";
import { PaginatedCompanyMember } from "modules/home/types";

export type OwnerFilter = "owner" | "assignees" | "commented_by";

export default function useOwnerFilter(
  queryKey: string,
  companyId: CompanyId,
  filter: OwnerFilter,
  searchString?: string
): UseInfiniteQueryResult<AxiosResponse<PaginatedCompanyMember>> {
  return useInfiniteQuery(
    [queryKey, companyId, searchString, filter],
    ({ pageParam = 1 }) => {
      const requestParams = {
        page: pageParam,
        search: searchString,
      };
      return getMembersByCompanyId(companyId, searchString ? requestParams : { page: pageParam });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.next != null) {
          return getPageFromNextPage(lastPage.data.next);
        }
      },
      enabled: !!companyId,
      refetchOnWindowFocus: false,
    }
  );
}
