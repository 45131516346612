import React, { useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Modal, Select, Spinner, TextInput } from "flowbite-react";
import get from "lodash/get";
import { usePostHog } from "posthog-js/react";

import { useMutation } from "@tanstack/react-query";

import modalStyle from "assets/css/customModal.module.css";
import styles from "assets/css/members.module.css";
import { ReactComponent as SearchIcon } from "assets/images/icon/search.svg";

import StaticDropdown from "shared/components/common-dropdown/static-dropdown";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyMember, CompanyProps } from "shared/context/types/company";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import { inviteTeammates } from "modules/auth/on-boarding/api";
import getCompanyById from "modules/home/api/getCompanyById";
import useCheckCompanyPlan from "modules/settings/hooks/useCheckCompanyPlan";

import plural from "../../../../shared/helpers/plural";
import { useWorkspaceModal } from "../../../../shared/store/trial";
import useMemberForm from "../hooks/useMemberForm";

const AddMember = ({
  setIsFiltered,
  setFilteredMembers,
  memberList,
  memberCount,
}: {
  setIsFiltered: (data: boolean) => void;
  setFilteredMembers: (data: CompanyMember[]) => void;
  memberList: CompanyMember[];
  memberCount: number;
}) => {
  const { currentCompany, updateCurrentCompany } = useCompany();
  const posthog = usePostHog();

  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const { handleSubmit, values, errors, handleChange, setFieldError, setFieldValue } =
    useMemberForm(() => {
      onSubmit(values.email);
    });

  const { mutate: getCompanyByIdFn } = useMutation(() => getCompanyById(currentCompany?.id), {
    onSuccess: (res) => {
      const filteredCompany = res?.data.results.find(
        (company: CompanyProps) => company.id === currentCompany?.id
      );
      if (filteredCompany) {
        updateCurrentCompany(filteredCompany);
      }
    },
  });

  const getMemberPermission = () => {
    if (
      (currentCompany?.subscription?.cancel_at_period_end ||
        currentCompany?.subscription === null) &&
      !currentCompany?.is_enterprise
    ) {
      return {
        is_admin: true,
      };
    } else {
      return { is_admin: values.is_admin === "Owner" };
    }
  };

  const {
    isLoading,
    mutate: onSubmit,
    isError,
    error,
  } = useMutation(
    (data: string) => {
      return inviteTeammates({
        invitee_identifier: data,
        organization: currentCompany?.id,
        ...getMemberPermission(),
      });
    },
    {
      onSuccess: () => {
        getCompanyByIdFn();
        setIsModalOpen(false);
        posthog.capture("memberAdded");
        toast("Invitation sent successfully!", { type: "success" });
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSearchMember = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setIsFiltered(false);
      return;
    }
    setIsFiltered(true);
    const members = memberList?.filter((item) =>
      get(item, "user.name", "").toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setFilteredMembers(members);
  };

  const shouldDisableSelectMemberRole = () => {
    if (
      (currentCompany?.subscription?.cancel_at_period_end ||
        currentCompany?.subscription === null) &&
      !currentCompany?.is_enterprise
    ) {
      return true;
    }
  };

  const { hasDowngradedPlan } = useCheckCompanyPlan();

  if (!currentCompany) {
    return null;
  }

  const areSeatsOver = memberCount >= currentCompany.seats;
  const remainingSeats = Math.max(currentCompany.seats - memberCount, 0);

  return (
    <>
      <div className={styles?.account_content}>
        <h6 className="font-inter-medium text-base dark:text-white mac13Inch:text-sm">
          {memberCount} current {plural(memberCount, "member", "members")}
        </h6>
        <h4 className="font-inter-regular text-sm text-aurometalsaurus dark:text-greychateau mac13Inch:text-xs">
          {remainingSeats} available {plural(remainingSeats, "seat", "seats")}
        </h4>
      </div>
      <div className={styles?.account_content}>
        <h6 className="font-inter-medium text-base dark:text-white mac13Inch:text-sm">
          Manage Members
        </h6>
        <div className={clsx(styles?.search_block, "maxMd:flex-col")}>
          <TextInput
            className={styles?.search_field}
            placeholder="Search"
            onChange={onSearchMember}
          />
          <SearchIcon className={clsx(styles.search_img, "seeting_icon")} />
          <button
            onClick={() =>
              currentCompany?.trial_expired
                ? setWorkspaceModalOpen(true)
                : setIsModalOpen(!isModalOpen)
            }
            className="btn_primary maxMd:mt-3.5 maxMd:w-full"
          >
            Add Member
          </button>
        </div>
      </div>
      <Modal
        root={documentBodyRef}
        show={isModalOpen}
        className="modal_mobileview_center changepassword_modal"
        onClose={() => {
          setIsModalOpen(false);
          setFieldError("email", "");
        }}
        dismissible={true}
      >
        <Modal.Header
          className={clsx(modalStyle.modalHeader, "maxMd:px-4")}
          data-testid="add-member-header"
        >
          Add Member to <strong className="font-medium">{currentCompany?.name}</strong>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="dropdown_modal_overflow maxMd:p-4 mac13Inch:pb-4">
            <div className="space-y-6 ">
              <div className="items-center justify-between sm:flex">
                <TextInput
                  data-testid="add-member-email"
                  type="email"
                  disabled={areSeatsOver}
                  className={clsx(
                    {
                      [styles?.add_field]: !hasDowngradedPlan,
                      "w-full": hasDowngradedPlan,
                    },
                    "maxMd:mb-[18px]"
                  )}
                  id="email"
                  placeholder="Enter Email"
                  onChange={handleChange}
                  value={values.email}
                  color={errors.email ? "failure" : "gray"}
                />
                {!hasDowngradedPlan ? (
                  <>
                    {shouldDisableSelectMemberRole() ? (
                      <Select className="custom_select md:w-1/3" disabled>
                        <option>Owner</option>
                      </Select>
                    ) : (
                      <StaticDropdown
                        placeholder="No Option Selected"
                        mainClassname="md:w-1/3 custom_select"
                        handleChange={(data) => {
                          setFieldValue("is_admin", data || "");
                        }}
                        value={values.is_admin || ""}
                        data={[
                          { id: "Member", name: "Member" },
                          { id: "Owner", name: "Owner" },
                        ]}
                      />
                    )}
                  </>
                ) : null}
              </div>
              {areSeatsOver ? (
                <>
                  <div className={"mt-4 text-xs text-red-600"}>
                    You have reached the maximum number of users. Please contact our support team at{" "}
                    <a className={"underline"} href={"mailto:help@grand.io"}>
                      help@grand.io
                    </a>{" "}
                    to increase the number of available seats in your account.
                  </div>
                </>
              ) : null}
              {isError ? <ServerErrors className="mb-4" error={error} /> : ""}
            </div>
          </Modal.Body>
          <Modal.Footer className="dark:border-thunders">
            <div className={"flex w-full items-center justify-between"}>
              <span
                className={"inline-flex flex-row flex-wrap items-center justify-between gap-x-2"}
              >
                <h6 className="font-inter-medium text-base dark:text-white mac13Inch:text-sm">
                  {memberList.length} current {plural(memberList.length, "member", "members")}
                </h6>
                <h4 className="font-inter-regular text-base text-aurometalsaurus dark:text-greychateau mac13Inch:text-sm">
                  {remainingSeats} available {plural(remainingSeats, "seat", "seats")}
                </h4>
              </span>
              <button
                className="btn_primary flex items-center"
                disabled={areSeatsOver}
                type="submit"
              >
                {isLoading ? (
                  <div className="mr-3">
                    <Spinner size="sm" light={true} />
                  </div>
                ) : (
                  ""
                )}
                Submit
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default AddMember;
