import React, { memo, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { TextInput } from "flowbite-react";
import get from "lodash/get";
import { useOnClickOutside } from "usehooks-ts";

import { useCompany } from "shared/context/CompanyProvider";
import { SEARCH_SUGGESTION } from "shared/helpers/constant";
import { getItemFromLocalStorage, setItemToLocalStorage } from "shared/helpers/util";
import {
  CompanySuggestionsDataProps,
  useSearchSuggestion,
} from "shared/store/table-column-selection";

import Icon from "../icon/Icon";
import SearchSuggestionBox from "./SearchSuggestionBox";
import { SEARCH } from "./constants";
import { getSearchResponseData, getSearchSuggestionResponseData } from "./helpers";
import { SearchOptions, SearchProps } from "./types";

const Search = ({ onChange, searchText, name }: SearchProps) => {
  const [searchData, setSearchData] = useSearchSuggestion((state) => [
    state.searchData,
    state.setSearchData,
  ]);

  const { currentCompany } = useCompany();
  const [suggestions, setSuggestions] = useState<CompanySuggestionsDataProps>(
    getItemFromLocalStorage(SEARCH_SUGGESTION) || {}
  );

  const [filteredSuggestions, setFilteredSuggestions] = useState<SearchOptions[]>(
    get(suggestions, [currentCompany?.id, name], [])?.reverse() || []
  );

  const [text, setText] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const searchbarRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(searchbarRef, () => showSuggestion && setShowSuggestion(false));

  useEffect(() => {
    if (searchText) {
      setText(searchText);
    }
  }, [searchText]);

  const setFinalText = (str: string) => {
    const final = str.trim();

    const searchQuery = { field: "search", value: final, type: SEARCH };

    setText(final);
    setSearchData(
      getSearchResponseData({
        searchData: searchData,
        moduleName: name,
        data: searchQuery,
      })
    );
    onChange(final);
    if (final !== "") {
      // TODO: Saving these informations in the backend can be a future development
      setItemToLocalStorage(
        SEARCH_SUGGESTION,
        JSON.stringify(
          getSearchSuggestionResponseData({
            suggestionsData: suggestions,
            companyId: currentCompany?.id,
            key: name,
            data: searchQuery,
          })
        )
      );
      if (getItemFromLocalStorage(SEARCH_SUGGESTION)) {
        setSuggestions(getItemFromLocalStorage(SEARCH_SUGGESTION));
      }
    }

    setShowSuggestion(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setText(value);
    const existingSuggestions = get(suggestions, [currentCompany?.id, name], []);

    const filtered = existingSuggestions?.filter((suggestion: SearchOptions) =>
      suggestion.value?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filtered);
  };

  const canDelete = searchText && searchText.length > 0;

  const handleSuggestionClick = (suggestion: SearchOptions) => {
    setText(suggestion?.value);
    setSearchData(
      getSearchResponseData({
        searchData: searchData,
        moduleName: name,
        data: suggestion,
      })
    );
    onChange(suggestion?.value);
    setShowSuggestion(false);
  };

  return (
    <div
      ref={searchbarRef}
      className="relative flex w-full flex-col  transition-all md:max-w-[37.125rem] maxLg:mb-1.5"
    >
      <div className={"flex"}>
        <TextInput
          onClick={() => {
            setShowSuggestion(true);
          }}
          role={"input"}
          value={text}
          className={clsx("w-full flex-[100%]", {
            "[&_input]:pr-[36px]": canDelete,
          })}
          onChange={handleInputChange}
          autoComplete={"off"}
          placeholder={"Search..."}
          type={"input"}
          enterKeyHint={"search"}
          onKeyPress={(e): void => {
            const target = e.target as HTMLInputElement;
            if (e.key === "Enter") {
              // `as` is required to properly cast the event
              setFinalText(target.value);
              target.blur();
            }
          }}
        />

        {canDelete ? (
          <span
            className="absolute right-0 m-1 flex cursor-pointer items-center justify-center p-2"
            onClick={() => {
              setFinalText("");
              setFilteredSuggestions(get(suggestions, [currentCompany?.id, name], []));
            }}
          >
            <Icon type="close" fill={false} size="icon-xs" className="icon__color" />
          </span>
        ) : null}
      </div>
      {showSuggestion ? (
        <SearchSuggestionBox
          text={text}
          setFinalText={setFinalText}
          filteredSuggestions={filteredSuggestions}
          handleSuggestionClick={handleSuggestionClick}
        />
      ) : null}
    </div>
  );
};

export default memo(Search);
