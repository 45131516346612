/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Publisher } from './Publisher';
import {
    PublisherFromJSON,
    PublisherFromJSONTyped,
    PublisherToJSON,
} from './Publisher';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { ArticleStatusEnum } from './ArticleStatusEnum';
import {
    ArticleStatusEnumFromJSON,
    ArticleStatusEnumFromJSONTyped,
    ArticleStatusEnumToJSON,
} from './ArticleStatusEnum';

/**
 * 
 * @export
 * @interface ArticleList
 */
export interface ArticleList {
    /**
     * 
     * @type {number}
     * @memberof ArticleList
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleList
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleList
     */
    originalTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ArticleList
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleList
     */
    hasClassification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleList
     */
    readonly isRead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleList
     */
    readonly isSaved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArticleList
     */
    readonly isArchived: boolean;
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof ArticleList
     */
    readonly status: ArticleStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ArticleList
     */
    publicationDate?: Date | null;
    /**
     * 
     * @type {Publisher}
     * @memberof ArticleList
     */
    publisher: Publisher;
    /**
     * 
     * @type {Array<number>}
     * @memberof ArticleList
     */
    readonly assignees: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ArticleList
     */
    readonly commentsCount: number;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ArticleList
     */
    readonly tags: Array<Tag>;
}



/**
 * Check if a given object implements the ArticleList interface.
 */
export function instanceOfArticleList(value: object): value is ArticleList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('hasClassification' in value) || value['hasClassification'] === undefined) return false;
    if (!('isRead' in value) || value['isRead'] === undefined) return false;
    if (!('isSaved' in value) || value['isSaved'] === undefined) return false;
    if (!('isArchived' in value) || value['isArchived'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('publisher' in value) || value['publisher'] === undefined) return false;
    if (!('assignees' in value) || value['assignees'] === undefined) return false;
    if (!('commentsCount' in value) || value['commentsCount'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    return true;
}

export function ArticleListFromJSON(json: any): ArticleList {
    return ArticleListFromJSONTyped(json, false);
}

export function ArticleListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'originalTitle': json['original_title'] == null ? undefined : json['original_title'],
        'description': json['description'] == null ? undefined : json['description'],
        'hasClassification': json['has_classification'],
        'isRead': json['is_read'],
        'isSaved': json['is_saved'],
        'isArchived': json['is_archived'],
        'status': ArticleStatusEnumFromJSON(json['status']),
        'publicationDate': json['publication_date'] == null ? undefined : (new Date(json['publication_date'])),
        'publisher': PublisherFromJSON(json['publisher']),
        'assignees': json['assignees'],
        'commentsCount': json['comments_count'],
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function ArticleListToJSON(value?: Omit<ArticleList, 'id'|'is_read'|'is_saved'|'is_archived'|'status'|'assignees'|'comments_count'|'tags'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'original_title': value['originalTitle'],
        'description': value['description'],
        'has_classification': value['hasClassification'],
        'publication_date': value['publicationDate'] == null ? undefined : ((value['publicationDate'] as any).toISOString().substring(0,10)),
        'publisher': PublisherToJSON(value['publisher']),
    };
}

