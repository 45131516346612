import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { GetTaskResultType, TeamId } from "shared/types";

import { convertTaskData } from "../helpers";
import { GetAllTaskRequestParams, KanbanListType } from "../types";

export const getKanbanTasks = async (
  requestParams: GetAllTaskRequestParams,
  permissions: { team?: TeamId }
): Promise<AxiosResponse<KanbanListType[]>> => {
  let response = await api.get<KanbanListType[]>(`tasks/kanban/`, {
    params: { ...requestParams, ...permissions, ...hasNameOrdering(requestParams) },
  });

  if (response && response.data && response.data?.length > 0) {
    response = {
      ...response,
      data:
        response.data?.map((data) => {
          let tasksList: Array<GetTaskResultType> = [];
          if (data && data?.tasks && data?.tasks?.length > 0) {
            tasksList = data.tasks?.map((task) => convertTaskData(task)) || [];
          }
          return { ...data, tasks: tasksList || [] };
        }) || [],
    };
  }
  return response;
};
