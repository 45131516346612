import React from "react";

import { FormikProps } from "formik";

import { useInfiniteQuery } from "@tanstack/react-query";

import { useDropdownPaginationSearch } from "shared/store/dropdownPaginationSearch";

import { TaskResultType } from "modules/tasks/overview/types";
import { getStatus } from "modules/tasks/statuses/api";
import { StatusResultType } from "modules/tasks/statuses/types";

import CommonDropdownWrapper from "../common-dropdown";

export const TaskStatusField = ({
  selectedObject,
  teamId,
  companyId,
  isAllTeam,
  form,
}: {
  form: FormikProps<TaskResultType>;
  selectedObject: StatusResultType | null;
  teamId: number;
  companyId: number;
  isAllTeam?: boolean;
}) => {
  const { paginationSearchData } = useDropdownPaginationSearch();

  const {
    isLoading: statusLoading,
    data: taskStatusData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["getStatusForTask", companyId, teamId, paginationSearchData?.taskForm?.status],
    ({ pageParam = 1 }) => {
      if (!teamId) {
        return Promise.reject(new Error("Team is not selected"));
      }

      if (!companyId) {
        return Promise.reject(new Error("Company is not selected"));
      }

      const requestParams = {
        page: pageParam,
        name__icontains: paginationSearchData?.taskForm?.status,
      };

      return getStatus(
        paginationSearchData?.taskForm?.status ? requestParams : { page: pageParam },
        { team: { team: teamId, company: companyId, isAllTeam: isAllTeam } }
      );
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.next !== null) {
          const nextPageUrl = new URL(lastPage.data.next);
          const nextPage = nextPageUrl.searchParams.get("page");

          return Number(nextPage);
        }
      },
      cacheTime: 300000,
    }
  );

  return (
    <>
      <CommonDropdownWrapper
        hasPagination={true}
        isLoading={statusLoading}
        hasError={form.errors?.status ? true : false}
        handleChangeCallback={(data) => form.setFieldValue("status", data)}
        title=""
        fieldName="status"
        placeholder="Select Status"
        form={form}
        formName="taskForm"
        data={(taskStatusData?.pages?.map((result) => result?.data?.results) || [])?.flat()}
        selectedObject={selectedObject}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </>
  );
};
