import React from "react";
import { useNavigate } from "react-router-dom";

import Icon from "shared/components/icon/Icon";

import HeaderItem from "./HeaderItem";

const SettingsAndMembersItem = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderItem
        testId="headerItem_comp"
        onClick={() => {
          navigate("/settings/my-account");
        }}
        tooltip="Settings & Members"
        className="setting_member_module maxMd:!block"
      >
        <Icon type="setting" fill={true} size="icon-md" />
      </HeaderItem>
    </>
  );
};

export default SettingsAndMembersItem;
