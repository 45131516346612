import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import checkEnvVar from "shared/helpers/checkEnvVar";
import { TeamId } from "shared/types";

import convertAgreementData from "../helpers/convertAgreementData";
import { AddAgreementPayload, AgreementResultsType } from "../types";

export default async function addAgreement(
  data: AddAgreementPayload,
  permissions: { team: TeamId }
): Promise<AxiosResponse<AgreementResultsType>> {
  const params = new URLSearchParams();
  params.append("team", `${data.team}`);
  let response = await api.post<AgreementResultsType>(
    `agreements/`,
    { ...data },
    { params: { ...permissions, shared_with_team: !checkEnvVar("REACT_APP_ENABLE_OBJECT_MODAL") } }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertAgreementData(response.data),
    };
  }

  return response;
}
