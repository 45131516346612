import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { PaginationDropdownQueryParams, TeamParameter } from "shared/types";

import { ArticleFilterListResponse } from "../types";

export type GetFilterListFn = (
  team: TeamParameter
) => Promise<AxiosResponse<ArticleFilterListResponse>>;

export default function getFilterListFactory(
  filterUrl: string,
  team: TeamParameter,
  requestParams?: PaginationDropdownQueryParams
): Promise<AxiosResponse<ArticleFilterListResponse>> {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;

  return api.get<ArticleFilterListResponse>(filterUrl, {
    params: {
      team: teamParameter,
      company: team.company,
      ...hasNameOrdering(requestParams),
    },
  });
}
