import { client } from "client";

import { useInfiniteQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import {
  DropdownSortEnum,
  DropdownSortEnumValues,
  FeedFiltersOmitted,
} from "modules/horizonScanning/constants";

const ITEMS_PER_PAGE = 10;

const useFeedArticles = (params: {
  feedId: number;
  filters: FeedFiltersOmitted;
  sortBy?: DropdownSortEnumValues;
  enabled?: boolean;
}) => {
  const { feedId, filters, enabled = true, sortBy = DropdownSortEnum.date } = params;

  const { currentCompany } = useCompany();

  return useInfiniteQuery({
    queryKey: ["feed-articles", feedId, currentCompany.id, filters, sortBy],
    queryFn: ({ pageParam = 1 }) =>
      client.horizonScanning.horizonFeedsArticlesList({
        ...filters, // allow to override the default filters except for pages
        sort: sortBy,
        feedId: feedId,
        companyId: currentCompany.id,
        page: pageParam,
        pageSize: ITEMS_PER_PAGE,
      }),

    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnMount: false,
    enabled,
  });
};

export default useFeedArticles;
