import React, { useEffect, useRef } from "react";

import { Modal } from "flowbite-react";
import get from "lodash/get";
import set from "lodash/set";

import modalStyle from "assets/css/customModal.module.css";

import updateFilterById from "shared/components/common-table/api/updateFilterById";
import { FiltersProp, SelectProps } from "shared/components/common-table/types";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useTeam } from "shared/store/settings";
import { useFavoriteFilter, useTableFilter } from "shared/store/table-column-selection";

import { FAVORITE_TYPE, UNIT_VALUE_FILTER } from "../../helpers/constant";
import { FilterConditon, FilterModalProp } from "../common-table/types";
import FilterMenu from "./FilterMenu";

const FilterModal = ({ filter, filterOptions, filterApp, isOpen }: FilterModalProp) => {
  const bodyRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    bodyRef.current = document.body;
  }, []);
  const { documentBodyRef } = useDocumentBodyRef();

  const selectedFavoriteFilters = useFavoriteFilter((state) => state.selectedFilters);
  const setSelectedFiltersForModules = useTableFilter(
    (state) => state.setSelectedFiltersForModules
  );
  const currentTeam = useTeam((state) => state.currentTeam);
  const selectedFilters = useTableFilter((state) => state.selectedFilters);
  const setIsFavoriteModalOpen = useFavoriteFilter((state) => state.setIsModalOpen);

  const handleSubmit = () => {
    if (filter.id != undefined) {
      updateFilterById(
        filter.id,
        {
          ...selectedFavoriteFilters,
          conditions: selectedFavoriteFilters.conditions.filter((item) => item.value != ""),
        },
        currentTeam.id
      ).then(() => {
        const updatedFilter: SelectProps = {
          ...get(selectedFilters, filterApp).find(
            (item: SelectProps) =>
              item.type === FAVORITE_TYPE && parseInt(item.value || "") === filter.id
          ),
          conditions: selectedFavoriteFilters.conditions,
        };
        selectedFilters &&
          setSelectedFiltersForModules(set(selectedFilters, [filterApp], [updatedFilter]));
        setIsFavoriteModalOpen(() => false);
      });
    }
  };

  return (
    <Modal
      root={documentBodyRef}
      className="custom_modal"
      show={isOpen}
      onClose={() => {
        setIsFavoriteModalOpen(() => false);
      }}
    >
      <Modal.Header
        className={
          modalStyle.modalHeader +
          " !border-b !border-solid border-brightgray !p-4 dark:!border-iridium md:!p-6 "
        }
      >
        <span className="text-mirage dark:text-white">{filter?.name}</span>
      </Modal.Header>
      <Modal.Body className="h-full max-h-[26rem] overflow-y-scroll maxMd:p-4 maxMd:pb-0">
        {isOpen ? (
          <form id="update_filter" className="pb-1.5">
            {filterOptions
              ?.filter((filter) => filter.type !== UNIT_VALUE_FILTER)
              ?.map((filterOption: FiltersProp) => (
                <div className="mb-4 w-full md:mb-6" key={filterOption.label}>
                  <div className="flex items-center md:mb-2">
                    <label className="font-inter-medium text-sm maxMd:mb-2 maxMd:block">
                      {filterOption.label}
                    </label>
                  </div>
                  <FilterMenu
                    filterOption={filterOption}
                    filterConditions={filter.conditions.filter(
                      (condition: FilterConditon) => filterOption?.field === condition.field
                    )}
                  ></FilterMenu>
                </div>
              ))}
          </form>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="bo dark:!border-thunders maxMd:flex-col maxMd:border-none maxMd:p-4 mac13Inch:py-4">
        <button
          className="btn_secondary md:ml-auto maxMd:w-full"
          type="button"
          onClick={() => {
            setIsFavoriteModalOpen(() => false);
          }}
        >
          Cancel
        </button>
        <button
          className="btn_primary flex maxMd:mt-4 maxMd:w-full maxMd:justify-center"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
