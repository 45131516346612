/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TextArea } from './TextArea';
import {
    TextAreaFromJSON,
    TextAreaFromJSONTyped,
    TextAreaToJSON,
} from './TextArea';

/**
 * 
 * @export
 * @interface Obligation
 */
export interface Obligation {
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    readonly id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Obligation
     */
    categories: Array<string>;
    /**
     * 
     * @type {Array<TextArea>}
     * @memberof Obligation
     */
    textAreas: Array<TextArea>;
    /**
     * 
     * @type {Date}
     * @memberof Obligation
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Obligation
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    policyCoverage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    controls?: boolean;
    /**
     * Indicates whether the text was extracted using AI.
     * @type {boolean}
     * @memberof Obligation
     */
    readonly isAiExtracted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    indexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    authorityDocument: number;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    owner?: number | null;
}

/**
 * Check if a given object implements the Obligation interface.
 */
export function instanceOfObligation(value: object): value is Obligation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('textAreas' in value) || value['textAreas'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('isAiExtracted' in value) || value['isAiExtracted'] === undefined) return false;
    if (!('authorityDocument' in value) || value['authorityDocument'] === undefined) return false;
    return true;
}

export function ObligationFromJSON(json: any): Obligation {
    return ObligationFromJSONTyped(json, false);
}

export function ObligationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Obligation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'categories': json['categories'],
        'textAreas': ((json['text_areas'] as Array<any>).map(TextAreaFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'name': json['name'],
        'text': json['text'],
        'policyCoverage': json['policy_coverage'] == null ? undefined : json['policy_coverage'],
        'controls': json['controls'] == null ? undefined : json['controls'],
        'isAiExtracted': json['is_ai_extracted'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'authorityDocument': json['authority_document'],
        'owner': json['owner'] == null ? undefined : json['owner'],
    };
}

export function ObligationToJSON(value?: Omit<Obligation, 'id'|'created_at'|'updated_at'|'is_ai_extracted'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': value['categories'],
        'text_areas': ((value['textAreas'] as Array<any>).map(TextAreaToJSON)),
        'name': value['name'],
        'text': value['text'],
        'policy_coverage': value['policyCoverage'],
        'controls': value['controls'],
        'indexed': value['indexed'],
        'authority_document': value['authorityDocument'],
        'owner': value['owner'],
    };
}

