import { preOrderOperators, singleUnderscoreOperators } from "shared/helpers/constant";

import { FilterConditon } from "../types";

function isPreorderOperator(operator: string): boolean {
  return preOrderOperators?.includes(operator);
}

function isSingleUnderscoreOperator(operator: string): boolean {
  return singleUnderscoreOperators.includes(operator);
}

export default function getFieldForCondition(condition: FilterConditon): string {
  if (!condition.operator) {
    return condition.field;
  }

  if (isPreorderOperator(condition.operator)) {
    return `${condition.operator}_${condition.field}`;
  }

  if (isSingleUnderscoreOperator(condition.operator)) {
    return `${condition.field}_${condition.operator}`;
  }

  return `${condition.field}__${condition.operator}`;
}
