import React, { useCallback, useState } from "react";

import get from "lodash/get";

import { ReactComponent as Star } from "assets/images/icons/star.svg";

import FilterModal from "shared/components/filter-modal/FilterModal";
import Icon from "shared/components/icon/Icon";
import { FAVORITE_TYPE } from "shared/helpers/constant";
import {
  TableFilterTypeProps,
  useFavoriteFilter,
  useTableFilter,
} from "shared/store/table-column-selection";

import getFilterById from "../../api/getFilterById";
import { FilterProps, FiltersProp, SelectProps } from "../../types";
import FavoriteSlider from "./FavoriteSlider";
import FavoriteSubView from "./FavoriteSubView";

export default function FavoriteMobileView({
  type,
  displayFavorites,
  filterOptions,
  searchText,
}: {
  type: string;
  displayFavorites: boolean;
  filterOptions: Array<FiltersProp>;
  searchText: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [
    setSelectedFavoriteFilter,
    clickedFavoriteFilter,
    setIsFavoriteModalOpen,
    isFavoriteModalOpen,
  ] = useFavoriteFilter((state) => [
    state.setSelectedFilters,
    state.selectedFilters,
    state.setIsModalOpen,
    state.isModalOpen,
  ]);

  const [selectedFilters, setSelectedFiltersForModules, setSelectedFilters] = useTableFilter(
    (state) => [state.selectedFilters, state.setSelectedFiltersForModules, state.setSelectedFilters]
  );

  const clickEditFavoriteFilter = (filterId: number, teamId: number) => {
    getFilterById(filterId, teamId).then((response) => {
      setSelectedFavoriteFilter((): FilterProps => {
        return response.data;
      });
    });
    setIsFavoriteModalOpen(() => true);
  };

  const removeFilter = (filter: FilterProps) => {
    setSelectedFilters((prevFilters: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      const data = Object.assign({}, prevFilters, {
        [type]: get(prevFilters, type)?.filter((item: SelectProps) => {
          return !(item.value === filter.id && item.key === filter.name);
        }),
      });
      return data;
    });
  };

  const onFavoriteClick = useCallback(
    (filter: FilterProps) => {
      if (filter.id) {
        getFilterById(filter.id, filter.team).then((response) => {
          const data: TableFilterTypeProps = Object.assign({}, selectedFilters, {
            [type]: [
              {
                label: (
                  <>
                    <div className="flex items-center rounded-md bg-cyanblue !px-0 !py-0 !pr-2">
                      <span className="mr-2 inline-block rounded-l-[5px] bg-newcar !px-1.5 !py-1">
                        <Icon type="star" fill={true} size="icon-smd" className="themesvg_white" />
                      </span>
                      <span
                        className="mr-2 text-sm text-newcar"
                        onClick={() => {
                          if (response.data.id != undefined) {
                            clickEditFavoriteFilter(response.data.id, response.data.team);
                          }
                        }}
                      >
                        {response.data.name}
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          removeFilter({ ...response?.data });
                        }}
                      >
                        <Icon
                          type="close"
                          fill={true}
                          size="icon-xs"
                          className="close_filter_icon"
                        />
                      </span>
                    </div>
                  </>
                ),
                conditions: response.data.conditions,
                key: response.data.name,
                value: response.data.id,
                type: FAVORITE_TYPE,
              },
            ],
          });
          setSelectedFiltersForModules(data);
        });
      }
      setIsOpen(false);
      setIsSubMenuOpen(false);
    },
    [setSelectedFiltersForModules]
  );

  return (
    <>
      {displayFavorites ? (
        <>
          <div className="ml-2.5 flex items-center" onClick={() => setIsOpen(!isOpen)}>
            <Star className="si fill h-5 w-5" />
            <span
              data-testid="favorites-label"
              className="ml-2 text-sm font-semibold text-aurometalsaurus dark:text-greychateau"
            >
              Favorites
            </span>
          </div>
          {isOpen ? (
            <FavoriteSlider
              onClose={() => {
                setIsOpen(false);
                setIsSubMenuOpen(true);
              }}
              handleFilterClose={() => {
                setIsOpen(false);
                setIsSubMenuOpen(false);
              }}
              type={type}
              onFavoriteClick={onFavoriteClick}
              searchText={searchText}
            />
          ) : null}
          {isSubMenuOpen ? (
            <FavoriteSubView
              type={type}
              onFavoriteClick={onFavoriteClick}
              onClose={() => {
                setIsOpen(true);
                setIsSubMenuOpen(false);
              }}
              searchText={searchText}
            />
          ) : null}

          {clickedFavoriteFilter.id ? (
            <FilterModal
              filterOptions={filterOptions}
              filter={clickedFavoriteFilter}
              filterApp={type}
              isOpen={isFavoriteModalOpen}
              setIsOpen={setIsFavoriteModalOpen}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
