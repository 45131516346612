import { FileViewerProps } from "./types";

export default function PdfFileViewer({ document }: FileViewerProps) {
  return (
    <div
      data-testid="pdf-file-viewer"
      className="pdf-mobile-scroll flex h-full w-full flex-col items-center justify-center"
    >
      <iframe src={document.file} title={document.filename} className="h-full w-full" />
    </div>
  );
}
