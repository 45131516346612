import React, { useState } from "react";

import { Table } from "@tanstack/react-table";

import { ReactComponent as Setting } from "assets/images/icons/setting.svg";

import { ActionsType } from "../../types";
import ActionSlider from "./ActionSlider";

export default function ActionMobileView<TData>({
  actions,
  table,
}: {
  actions: ActionsType<TData>[];
  table: Table<TData>;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="ml-2.5 flex items-center" onClick={() => setIsOpen(!isOpen)}>
        <Setting className="theme_icon h-5 w-5" />
        <span
          data-testid="action-label"
          className="ml-2 font-inter-medium text-sm text-aurometalsaurus dark:text-greychateau"
        >
          Action
        </span>
      </div>
      {isOpen ? (
        <ActionSlider actions={actions} table={table} handleFilterClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}
