/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimilarObligation
 */
export interface SimilarObligation {
    /**
     * 
     * @type {number}
     * @memberof SimilarObligation
     */
    obligationId: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarObligation
     */
    authorityDocumentId: number;
}

/**
 * Check if a given object implements the SimilarObligation interface.
 */
export function instanceOfSimilarObligation(value: object): value is SimilarObligation {
    if (!('obligationId' in value) || value['obligationId'] === undefined) return false;
    if (!('authorityDocumentId' in value) || value['authorityDocumentId'] === undefined) return false;
    return true;
}

export function SimilarObligationFromJSON(json: any): SimilarObligation {
    return SimilarObligationFromJSONTyped(json, false);
}

export function SimilarObligationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarObligation {
    if (json == null) {
        return json;
    }
    return {
        
        'obligationId': json['obligation_id'],
        'authorityDocumentId': json['authority_document_id'],
    };
}

export function SimilarObligationToJSON(value?: SimilarObligation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'obligation_id': value['obligationId'],
        'authority_document_id': value['authorityDocumentId'],
    };
}

