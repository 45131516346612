import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import convertPaginatedData from "shared/helpers/convertPaginatedData";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { PaginatedResponse, PaginationDropdownQueryParams } from "shared/types";

export default async function getFilterDataByEndpoint<DataType = unknown>(
  url: string,
  requestParams?: PaginationDropdownQueryParams,
  defaultParams?: Record<string, unknown>
): Promise<AxiosResponse<PaginatedResponse<DataType>>> {
  let response = await api.get(url, {
    params: {
      ...defaultParams,
      ...hasNameOrdering(requestParams),
    },
  });

  if (response && response.data) {
    response = {
      ...response,
      data: convertPaginatedData(response.data),
    };
  }
  return response;
}
