import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { ADMIN_ROLE, OWNER_ROLE } from "shared/helpers/constant";
import { CompanyId } from "shared/types";

import { CompanyUserResponse, UserRole } from "modules/home/types";

export default function updateInvitation(
  id: number,
  role: UserRole,
  permissions: { company: CompanyId }
): Promise<AxiosResponse<CompanyUserResponse>> {
  const is_admin = [ADMIN_ROLE, OWNER_ROLE].includes(role);

  if (!role) {
    return Promise.reject(new Error("Role is required"));
  }

  return api.patch<CompanyUserResponse>(
    `/companies/invitations/${id}/`,
    {
      role,
      is_admin,
    },
    {
      params: { ...permissions },
    }
  );
}
