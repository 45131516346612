import React from "react";
import { useNavigate } from "react-router-dom";

import { Modal } from "flowbite-react";

import styles from "assets/css/sidebar.module.css";

import { setId } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";

import { SwitchTeamModalProps } from "./types";

const SwitchTeamModal = ({
  isSwitchTeamModalVisible,
  setIsSwitchTeamModalVisible,
  selectedTeam,
}: SwitchTeamModalProps) => {
  const { setCurrentTeam } = useTeam();
  const navigate = useNavigate();

  const onSwitchMoreClick = () => {
    setId("teamID", selectedTeam?.id?.toString());
    setCurrentTeam(selectedTeam);
    setIsSwitchTeamModalVisible(false);
    navigate("/");
  };

  return (
    <Modal
      show={isSwitchTeamModalVisible}
      size="2xl"
      popup={true}
      onClose={() => setIsSwitchTeamModalVisible(false)}
      dismissible={true}
      className={styles.switchCompanyModal}
    >
      <Modal.Header className="!p-2" />
      <Modal.Body>
        <div className="mt-6">
          <h2 className="font-inter-semibold text-mirage dark:!text-white">Oh snap!</h2>
          <h3 className="mt-3 text-aurometalsaurus dark:text-greychateau ">
            Article module is not available for Common team
            <br />
            Please select another one
          </h3>
          <div className="mt-6 flex justify-center gap-2">
            <button
              className="btn_secondary ml-auto"
              onClick={() => setIsSwitchTeamModalVisible(false)}
            >
              Dismiss
            </button>
            <button className="btn_primary" onClick={onSwitchMoreClick}>
              Switch Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchTeamModal;
