import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { get } from "lodash";
import groupBy from "lodash/groupBy";

import styles from "assets/css/emoji.module.css";

import emojiList from "shared/components/lexical-editor/plugins/EmojiPickerPlugin/emoji-list";

export type emojiType = {
  description: string;
  emoji: string;
  category?: string;
  aliases?: Array<string>;
  tags: Array<string>;
  unicode_version?: string;
  ios_version?: string;
  skin_tones?: boolean;
};

function EmojiComponent({ onEmojiSelect }: { onEmojiSelect: (emoji: emojiType) => void }) {
  const [emojiGroup, setEmojiGroup] = useState<Record<string, emojiType[]>>({});
  const [selectedEmojiGroup, setSelectedEmojiGroup] = useState<string>(
    emojiList[0]?.category || ""
  );

  useEffect(() => {
    const group: Record<string, emojiType[]> = groupBy(emojiList, "category");
    setEmojiGroup(group);
  }, []);

  return (
    <div>
      <ul className={styles.emojiHeader}>
        {Object.keys(emojiGroup)?.map((emoji: string) => (
          <li
            data-testid={emoji}
            key={emoji}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedEmojiGroup(emoji);
            }}
            className={clsx({ [styles.active]: emoji === selectedEmojiGroup })}
          >
            {get(emojiGroup, emoji)[0].emoji}
          </li>
        ))}
      </ul>
      <div className={styles.emojiBody} data-testid="emoji-group">
        {get(emojiGroup, selectedEmojiGroup)?.map((emoji: emojiType) => (
          <span
            data-testid={emoji.description}
            key={`emoji_${emoji.emoji}`}
            onDoubleClick={() => ""}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              onEmojiSelect(emoji);
            }}
          >
            {emoji.emoji}
          </span>
        ))}
      </div>
    </div>
  );
}

export default EmojiComponent;
