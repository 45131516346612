import { Todo, TodoRequest } from "openapi";

import { RelationShipOwnerType } from "../../shared/helpersV2/getRelationshipOwner";

export enum FeedTypes {
  Public = "public",
  Shared = "shared",
  Private = "private",
}

export type FeedGroupItem = {
  id: number;
  unread_articles: number;
  name: string;
  description?: string;
  object_owner: number;
  created_at: Date;
  members?: RelationShipOwnerType[];
  regulations: string[];
  tags: string[];
  section: string;
  is_archived: boolean;
  template_todos: Todo[];
};

export type FeedGroup = {
  type: FeedTypes;
  items: {
    count: number;
    results: FeedGroupItem[];
  };
};

export interface IClassificationData {
  id: string;
  name: string;
}

export enum ArticleStatus {
  pending = "Pending",
  in_progress = "In progress",
  completed = "Completed",
}

export const ArticleStatusColor: Record<keyof typeof ArticleStatus, string> = {
  completed: "bg-greenhaze",
  in_progress: "bg-yellow-300",
  pending: "bg-santagrey",
} as const;

export type ArticleAction = "save" | "read" | "archive" | "share";

export type AttachTaskParams = {
  companyId: number;
  feedId: number;
  articleId: string;
  taskIds: number[];
};

export enum DropdownFilterEnum {
  all = "all",
  unread = "unread",
  read = "read",
  assigned = "assigned",
  mentioned = "mentioned",
  comments = "comments", // same as mentioned
  saved = "saved",
  archived = "archived",
}

export type TodoRequestWithId = TodoRequest & { id: number };
