import React, { useEffect } from "react";

import clsx from "clsx";
import capitalize from "lodash/capitalize";
import moment from "moment";

import headerStyles from "assets/css/common-header.module.css";

import { FiltersProp } from "shared/components/common-table/types";
import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  FILTER_TYPE,
  PREDEFINED_DATE_FILTER,
  datePickerSetToTop,
} from "shared/helpers/constant";

import CommonDatePicker from "../common-date-picker";
import StaticDropdown from "../common-dropdown/static-dropdown";
import PredefinedDateFilterInterval from "./PredefinedDateFilterInterval";
import useAddPredefinedDateFilter from "./hooks/useAddPredefinedDateFilter";
import { FilterOptionType } from "./types";

function PredefinedDateFilter({
  onClick,
  item,
}: {
  onClick: (data: FilterOptionType) => void;
  item: FiltersProp;
}) {
  const { handleSubmit, values, errors, setFieldValue, resetForm } = useAddPredefinedDateFilter(
    () => {
      const from = moment(values.from).format("YYYY-MM-DD");
      const to = moment(values.to).format("YYYY-MM-DD");
      const key = `${values.from}_${values.to}`;

      if (values.interval.key == "custom") {
        onClick({
          key,
          label: (
            <span>{`${capitalize(item.label)} is between ${values?.from} and ${values?.to}`}</span>
          ),
          values: [from, to],
          field: item.field,
          type: FILTER_TYPE,
          operators: item.operators,
          operator: PREDEFINED_DATE_FILTER,
          conditions: [
            {
              field: item.field,
              operator: (item.operators && item.operators[0]) || BEFORE_OPERATOR,
              value: from,
            },
            {
              field: item.field,
              operator: (item.operators && item.operators[1]) || AFTER_OPERATOR,
              value: to,
            },
          ],
          single_option: item.single_option,
        });
      } else {
        onClick({
          key,
          label: <span>{values.interval.value}</span>,
          values: [from, to],
          field: item.field,
          type: FILTER_TYPE,
          operators: item.operators,
          operator: PREDEFINED_DATE_FILTER,
          conditions: [
            {
              field: item.field,
              operator: (item.operators && item.operators[0]) || BEFORE_OPERATOR,
              value: values.interval.key,
            },
            {
              field: item.field,
              operator: (item.operators && item.operators[1]) || AFTER_OPERATOR,
              value: values.interval.key,
            },
          ],
          single_option: item.single_option,
        });
      }
    }
  );

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [item.field]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div id="filter-datepicker" className="grid gap-2">
          <StaticDropdown
            placeholder="No Option Selected"
            handleChange={(data) => {
              setFieldValue(
                "interval",
                item.values?.find((v) => v.key == data || "")
              );
              setFieldValue(
                "from",
                PredefinedDateFilterInterval({ operator: AFTER_OPERATOR, type: data || "" })
              );
              setFieldValue(
                "to",
                PredefinedDateFilterInterval({ operator: BEFORE_OPERATOR, type: data || "" })
              );
            }}
            value={values?.interval?.key || ""}
            data={
              item.values?.map((item) => ({ id: `${item?.key}`, name: item?.value || "" })) || []
            }
            isClearable={false}
          />
          <span
            className={clsx({
              "publication-date": datePickerSetToTop.includes(item.field),
            })}
          >
            <CommonDatePicker
              placeholder={"Select Date From" + item.field}
              errClasssName={errors?.from && "!bg-red-50 !border-red-500"}
              date={values?.from ? moment(values?.from).toDate() : null}
              handleChange={(date: Date | null) => {
                setFieldValue("from", date ? moment(date)?.format("YYYY-MM-DD") : null);
              }}
              warningLabel=""
              disabled={values?.interval?.key != "custom"}
            />
          </span>
          <span
            className={clsx({
              "publication-datetwo": datePickerSetToTop.includes(item.field),
            })}
          >
            <CommonDatePicker
              placeholder={"Select Date To" + item.field}
              errClasssName={errors?.to && "!bg-red-50 !border-red-500"}
              date={values?.to ? moment(values?.to).toDate() : null}
              handleChange={(date: Date | null) => {
                setFieldValue("to", date ? moment(date)?.format("YYYY-MM-DD") : null);
              }}
              warningLabel=""
              disabled={values.interval.key != "custom"}
            />
          </span>
          <div className="!w-fit maxMd:!w-full">
            <button type="submit" className={headerStyles.btn_primary__imp}>
              Apply
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default PredefinedDateFilter;
