import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

import { convertNoteData } from "../helpers";
import { NoteResponse, UpdateNoteData } from "../types";

export default async function updateNoteById(
  noteId: number,
  data: UpdateNoteData,
  permissions: { team: TeamId }
): Promise<AxiosResponse<NoteResponse>> {
  let response = await api.patch<NoteResponse>(`notes/${noteId}/`, data, { params: permissions });

  if (response && response.data) {
    response = {
      ...response,
      data: convertNoteData(response.data),
    };
  }

  return response;
}
