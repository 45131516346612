import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Dropdown } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { getServerErrors } from "shared/helpers/util";
import { useAuthProvider } from "shared/store/settings";

import { deleteProvider } from "../api";
import { ProviderListComponentProps, ProviderResponseProps } from "../types";

const ProvidersList = ({ response, refetch }: ProviderListComponentProps) => {
  const { currentCompany } = useCompany();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const selectedProvider = useRef<number | null>(null);

  const setProvider = useAuthProvider((state) => state.setProvider);

  const setAddProviderModal = useAuthProvider((state) => state.setAddProviderModal);

  const { isLoading, mutate: deleteProviderFn } = useMutation(
    (id: number | null) => deleteProvider(currentCompany.id, id),
    {
      onSuccess: () => {
        refetch();
        toast("Provider Deleted Successfully", { type: "success" });
        setIsConfirmModalOpen(false);
        selectedProvider.current = null;
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const onDeleteClick = (id: number) => {
    setIsConfirmModalOpen(true);
    selectedProvider.current = id;
  };

  const onManageClick = (provider: ProviderResponseProps) => {
    setAddProviderModal(true);
    setProvider(provider);
  };

  return (
    <div>
      <ul>
        {response?.data.results.map((provider: ProviderResponseProps) => (
          <li
            className="border-lightGray border-b px-4 py-4 dark:border-thunders md:px-11"
            key={provider.id}
          >
            <div className="flex items-center">
              <div>
                <h3 className="dark:text-white">{provider.name}</h3>
                <p className="dark:text-greychateau">SAML</p>
              </div>
              <div className="ml-auto flex items-center">
                <span
                  className={clsx(
                    {
                      "bg-azureishWhite": provider.is_active,
                      "bg-brightgray": !provider.is_active,
                    },
                    "tag tag-ready mr-1 w-[4.773rem]"
                  )}
                >
                  <p
                    className={clsx(
                      {
                        "!text-greenhaze": provider.is_active,
                        "!text-aurometalsaurus": !provider.is_active,
                      },
                      "w-full text-center !text-xs"
                    )}
                  >
                    {provider.is_active ? "Enabled" : "Disabled"}
                  </p>
                </span>
                <div className="authentication_dropdown ml-10 text-aurometalsaurus  dark:text-greychateau">
                  <Dropdown
                    label="Actions"
                    inline={true}
                    className="dropdown_darkmode w-[9.625rem]"
                    placement="bottom"
                  >
                    <Dropdown.Item onClick={() => onManageClick(provider)}>Manage</Dropdown.Item>
                    <Dropdown.Item
                      className="!text-lavared"
                      onClick={() => onDeleteClick(provider.id)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <ConfirmModal
        isLoading={isLoading}
        setIsOpen={setIsConfirmModalOpen}
        isOpen={isConfirmModalOpen}
        action={() => deleteProviderFn(selectedProvider.current)}
      />
      {isLoading && <CentralSpinner />}
    </div>
  );
};

export default ProvidersList;
