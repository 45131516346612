import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AxiosResponse } from "axios";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import getPageFromNextPage from "shared/helpers/getPageFromNextPage";
import {
  AGREEMENT,
  ARTICLE,
  ARTICLETEAMACTION,
  COMPANY_INVITATION,
  DASHBOARD,
  DASHBOARD_COMMENT,
  DOCUMENT,
  INCIDENT,
  MITIGATION,
  NOTE,
  OTHER,
  POLICY,
  QUESTIONNAIRE,
  QUESTIONNAIRE_COMMENT,
  RISK,
  RISKV2,
  RISKV2_COMMENT,
  RISK_REGISTER,
  TASK,
  TEAM,
  VENDOR,
  VENDOR_COMMENT,
} from "shared/helpersV2/constant";
import { NotificationType } from "shared/store/header";
import { useRouteBlock } from "shared/storeV2/routeBlock";

import { PaginatedNotificationList } from "modules/notifications/types";

import useRiskQuery from "../../../modules/risksV2/risk-detail/hooks/useRiskQuery";
import Button from "../form-controls/button";
import NotificationItem from "./NotificationItem";
import { getNotifications } from "./api/getNotifications";
import { updateNotification } from "./api/updateNotification";

type ReminderDropdownProps = {
  shouldNotifyClickable: boolean;
};

const useConditionalRiskQuery = (objectType: string, objectId: number | null) => {
  const shouldFetch = objectType === RISKV2 && objectId !== null;
  return useRiskQuery(shouldFetch ? objectId : undefined);
};

const NotificationDropdown = ({ shouldNotifyClickable }: ReminderDropdownProps) => {
  const navigate = useNavigate();
  const { currentCompany, companies, updateCurrentCompany } = useCompany();

  const { isBlocking } = useRouteBlock();

  const [open, setOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const [riskObjectId, setRiskObjectId] = useState<number | null>(null);
  const [riskObjectType, setRiskObjectType] = useState<string>("");

  const { data: riskQueryData } = useConditionalRiskQuery(riskObjectType, riskObjectId);

  const getnotificationCount = (): number => {
    const unReadNotification = data?.pages
      ?.flatMap((page) => page.data?.results)
      .filter((item) => !item.is_read);
    return unReadNotification?.length || 0;
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ["getNotification"],
    ({ pageParam = 1 }) => {
      return getNotifications({ page: pageParam });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.next != null) {
          return getPageFromNextPage(lastPage.data.next);
        }
      },
    }
  );

  useEffect(() => {
    if (riskObjectType === RISKV2 && riskQueryData && riskQueryData.registers[0] && riskObjectId) {
      navigate(`${getURL(riskObjectId, RISKV2, riskQueryData.registers[0].id)}`, {
        state: { id: riskObjectId },
      });
    }
  }, [riskQueryData, navigate, riskObjectId, riskObjectType]);

  const { mutate: readNotification } = useMutation(
    (id: number) => {
      return updateNotification(id, true);
    },
    {
      onSuccess: ({ data }) => {
        const updatedNotification = data;
        queryClient.setQueryData(
          ["getNotification"],
          (old: InfiniteData<AxiosResponse<PaginatedNotificationList>> | undefined) => {
            if (!old || !old.pages || old.pages.length === 0) return old;
            old.pages = old.pages.map((page) => {
              page.data.results = page.data.results.map((notification) => {
                return notification.id === updatedNotification.id
                  ? updatedNotification
                  : notification;
              });

              return page;
            });
            return old;
          }
        );
      },
    }
  );

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (
      scrollTop + clientHeight >= scrollHeight * 0.9 &&
      hasNextPage &&
      !isFetchingNextPage &&
      fetchNextPage
    ) {
      await fetchNextPage();
    }
  };

  const getURL = (id: number, type: string, listId?: number): string => {
    switch (type) {
      case AGREEMENT:
        return `/agreements/overview/${id}/view`;
      case ARTICLE:
        return `/articles/overview/${id}`;
      case POLICY:
        return `/policies/overview/${id}/view`;
      case DOCUMENT:
        return `/document/${id}`;
      case NOTE:
        return `/notes/overview/${id}/view`;
      case RISK:
        return `/risks/register/${id}/view`;
      case MITIGATION:
        return `/risks/mitigations/${id}/view`;
      case INCIDENT:
        return `/risks/incidents/${id}/view`;
      case TASK:
        return `/tasks/overview`;
      case ARTICLETEAMACTION:
        return `/articles/overview/${id}`;
      case VENDOR:
        return `/workspace/${currentCompany?.id}/vendors/${id}`;
      case VENDOR_COMMENT:
        return `/workspace/${currentCompany?.id}/vendors/${id}`;
      case DASHBOARD:
        return `/workspace/${currentCompany?.id}/reports/${id}/`;
      case DASHBOARD_COMMENT:
        return `/workspace/${currentCompany?.id}/reports/${id}/`;
      case RISK_REGISTER:
        return `/workspace/${currentCompany?.id}/risks/registers/${id}/`;
      case RISKV2:
        return `/workspace/${currentCompany?.id}/risks/registers/${listId}/risks/${id}/edit`;
      case RISKV2_COMMENT:
        return `/workspace/${currentCompany?.id}/risks/registers/${listId}/risks/${id}/edit`;
      case QUESTIONNAIRE:
        return `/workspace/${currentCompany?.id}/questionnaire/${id}/`;
      case QUESTIONNAIRE_COMMENT:
        return `/workspace/${currentCompany?.id}/questionnaire/${id}/`;
      default:
        return `/`;
    }
  };

  const updateCompany = (companyId: number | null) => {
    const foundCompany = companies.find((company) => company.id === companyId);
    if (foundCompany) {
      updateCurrentCompany(foundCompany);
    }
  };

  const onNotificationClick = (item: NotificationType) => {
    if (isBlocking) {
      alert(
        "You have unsaved changes. Please save or discard them before opening your notifications."
      );
      return;
    }
    if (
      (item?.object_type === COMPANY_INVITATION && item?.company !== null) ||
      item?.object_type === TASK
    ) {
      updateCompany(item?.company);
      navigate(`${getURL(item?.object_id, item?.object_type)}`, {
        state: { id: item?.object_id },
      });
    }

    if (!item?.is_read) {
      readNotification(item?.id);
    }

    if (
      item?.object_type === TEAM ||
      item?.object_type === OTHER ||
      item?.object_type === VENDOR ||
      item?.object_type === VENDOR_COMMENT ||
      item?.object_id === null ||
      item?.company === null
    ) {
      return;
    }

    if (item?.object_type === RISKV2) {
      setRiskObjectId(item?.object_id);
      setRiskObjectType(RISKV2);
    } else {
      updateCompany(item?.company);
      navigate(`${getURL(item?.object_id, item?.object_type)}`, {
        state: { id: item?.object_id },
      });
    }
  };

  return (
    <>
      <DropdownMenu.Root open={open} onOpenChange={shouldNotifyClickable ? () => false : setOpen}>
        <DropdownMenu.Trigger
          asChild
          className={clsx("rounded-lg p-1", { "bg-antiflashwhite dark:bg-tricornblack": open })}
        >
          <div>
            <Tooltip content="Notification" className="metadata_tooltip" arrow={false}>
              <Button
                btnType="plain"
                btnSize="sm"
                className={clsx("relative rounded-sm !p-0 focus:!bg-transparent", {
                  "bg-antiflashwhite dark:bg-tricornblack": open,
                })}
                icon={
                  <>
                    {getnotificationCount() > 0 ? (
                      <>
                        <Icon
                          type="bell"
                          fill={true}
                          size="icon-sm"
                          className="fill_current_icon cursor-pointer rounded-sm p-0 text-davygrey dark:text-silvers"
                        />
                        <div className="absolute right-0 top-0 inline-flex h-2 w-2 items-center justify-center rounded-full">
                          <span className="h-2 w-2 rounded-full border border-white bg-valentinered dark:border-eerieblack" />
                        </div>
                      </>
                    ) : (
                      <Icon
                        type="bell"
                        fill={true}
                        size="icon-sm"
                        className="fill_current_icon cursor-pointer rounded-sm p-0 text-davygrey dark:text-silvers"
                      />
                    )}
                  </>
                }
              />
            </Tooltip>
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="DropdownMenuContent max-h-[28rem] w-96 overflow-y-auto !px-2 !py-4"
            sideOffset={5}
            side="bottom"
            align="end"
            onScroll={handleScroll}
          >
            {data?.pages?.flatMap((page) =>
              page.data?.results?.map((item, index) => (
                <Fragment key={item?.id}>
                  <NotificationItem
                    notification={item}
                    onClick={() => {
                      onNotificationClick(item);
                    }}
                  />

                  {index !== page.data?.results?.length - 1 ? (
                    <DropdownMenu.Separator className="DropdownMenuSeparator !m-0 min-h-[0.0625rem]" />
                  ) : null}
                </Fragment>
              ))
            )}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default NotificationDropdown;
