import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { GetTaskResultType } from "shared/types";

import { convertTaskData } from "../helpers";
import { TaskResultType } from "../types";

export const addTask = async (
  data: Partial<TaskResultType>
): Promise<AxiosResponse<GetTaskResultType>> => {
  const params = new URLSearchParams();
  if (data.company) params.append("company", data.company.toString());
  if (data.team) params.append("team", data.team.toString());
  let response = await api.post<GetTaskResultType>(`tasks/`, { ...data }, { params });

  if (response && response.data) {
    response = {
      ...response,
      data: convertTaskData(response.data),
    };
  }

  return response;
};
