import { object, ref, string } from "yup";

export const changePasswordSchema = object({
  new_password1: string().required(),
  new_password2: string().oneOf([ref("new_password1"), null], "Passwords must match"),
});

export const changeEmailSchema = object({
  email: string().required().email(),
  password: string().required(),
});

export const updateProfileSchema = object({
  name: string().required(),
});
