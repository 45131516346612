import { TeamId } from "shared/types";

import { deleteNoteById } from "../api";
import { NoteResponse } from "../types";

function deleteNotesBatch(notes: NoteResponse[], permissions: { team: TeamId }) {
  return Promise.all(notes.map((item) => deleteNoteById(item.id, permissions)));
}

export default deleteNotesBatch;
