import React, { useState } from "react";

import { AxiosResponse } from "axios";
import { Button } from "flowbite-react";

import Icon from "shared/components/icon/Icon";

import ArticleShare from "./ArticleShare";

const ArticleActionsUnauthorized = ({ response }: { response?: AxiosResponse }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);

  return (
    <div className="mb-4 mt-8 flex w-full max-w-[40rem]">
      <Button
        color="light"
        className="w-full max-w-[40rem] !text-aurometalsaurus dark:!border-iridium dark:bg-darkjunglegreenss dark:!text-greychateau dark:hover:!bg-iridium"
        onClick={() => setIsShareModalOpen(true)}
      >
        <Icon type="share" fill={false} size="icon-xs" className="share_icon mr-3" />
        Share
      </Button>
      <ArticleShare
        response={response}
        isShareModalOpen={isShareModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
      />
    </div>
  );
};

export default ArticleActionsUnauthorized;
