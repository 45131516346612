import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Dashboard = React.lazy(() => import("."));

export default function () {
  return (
    <Routes>
      <Route index element={<Navigate to="/dashboard/overview" />} />
      <Route path="/overview" element={<Dashboard />} />
    </Routes>
  );
}
