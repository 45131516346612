/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddAssigneesRequest
 */
export interface AddAssigneesRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AddAssigneesRequest
     */
    assignees?: Array<number>;
}

/**
 * Check if a given object implements the AddAssigneesRequest interface.
 */
export function instanceOfAddAssigneesRequest(value: object): value is AddAssigneesRequest {
    return true;
}

export function AddAssigneesRequestFromJSON(json: any): AddAssigneesRequest {
    return AddAssigneesRequestFromJSONTyped(json, false);
}

export function AddAssigneesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddAssigneesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'assignees': json['assignees'] == null ? undefined : json['assignees'],
    };
}

export function AddAssigneesRequestToJSON(value?: AddAssigneesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'assignees': value['assignees'],
    };
}

