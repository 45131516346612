/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddTagsRequest
 */
export interface AddTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddTagsRequest
     */
    tags?: Array<string>;
}

/**
 * Check if a given object implements the AddTagsRequest interface.
 */
export function instanceOfAddTagsRequest(value: object): value is AddTagsRequest {
    return true;
}

export function AddTagsRequestFromJSON(json: any): AddTagsRequest {
    return AddTagsRequestFromJSONTyped(json, false);
}

export function AddTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddTagsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

export function AddTagsRequestToJSON(value?: AddTagsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
    };
}

