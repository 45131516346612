import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDarkMode } from "usehooks-ts";

import { useQuery } from "@tanstack/react-query";

import { ReactComponent as CircleShape } from "assets/images/circle.svg";
import logo from "assets/images/logo.svg";
import logoWhite from "assets/images/logo_white.svg";
import smallLogo from "assets/images/logowhite.svg";
import logoText from "assets/images/sidebar/logotext.svg";

import NoDataAvailable from "shared/components/no-data-available";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { MOBILE_DEVICE } from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";
import { useTeam } from "shared/store/settings";

import { getPublicArticle } from "../api";
import ArticleActionsUnauthorized from "./ArticleActionsUnauthorized";
import ArticleInfo from "./ArticleInfo";

const ArticleDetailsUnauthorizedPage = () => {
  const { isDarkMode } = useDarkMode();
  const { articleId = 0 } = useParams();
  const currentTeam = useTeam((state) => state.currentTeam);
  const navigate = useNavigate();
  const deviceType = useScreen();

  const { isLoading: isArticleLoading, data: response } = useQuery(
    ["getArticlesById", articleId, currentTeam.id],
    () => getPublicArticle(+articleId),
    {
      enabled: !!articleId,
    }
  );

  if (deviceType === MOBILE_DEVICE) {
    document.querySelector("body")?.classList.add("overflow-y-auto");
  }

  return (
    <>
      {!isArticleLoading && response?.data ? (
        <div>
          <div className="ml-4 mt-[1rem] cursor-pointer md:ml-[3.75rem]">
            {deviceType === MOBILE_DEVICE ? (
              <img src={isDarkMode ? smallLogo : logo} alt="logo" onClick={() => navigate("/")} />
            ) : (
              <img
                src={isDarkMode ? logoWhite : logoText}
                alt="logo"
                onClick={() => navigate("/")}
              />
            )}
          </div>

          <div className="mt-5 flex flex-col items-center px-4 md:mt-[3.75rem]">
            <ArticleInfo
              response={response}
              unauthorized={true}
              articleActions={response ? <ArticleActionsUnauthorized response={response} /> : null}
            />
            <div className="mx-auto mb-[49px] mt-6 w-full rounded-2xl bg-mirage p-4 text-center text-white md:max-w-[40rem] md:p-[48px]">
              <h1 className="mb-3 font-[GrandSansDisplay] text-3xl leading-[38px] md:mb-6 md:text-[42px]">
                Let’s make <br /> compliance
                <span className="relative inline-block">
                  &nbsp;fun <CircleShape className="absolute left-[-10px] top-0 maxMd:hidden" />
                </span>
                &nbsp;again
              </h1>
              <p className="mb-6">
                We are reinventing GRC for the finance community. Combining AI-enabled solutions
                with human expertise in our next gen compliance platform.
              </p>
              <button onClick={() => navigate("/signup")} className="btn_secondary font-semibold">
                Sign up for free
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {!response?.data && !isArticleLoading ? (
        <div className="h-screen">
          <NoDataAvailable />
        </div>
      ) : null}
      {isArticleLoading ? <CentralSpinner /> : null}
    </>
  );
};

export default ArticleDetailsUnauthorizedPage;
