import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { DeleteInvitationByIdProps } from "../types";

export default function deleteInvitationById({
  invitationId,
  companyId,
}: DeleteInvitationByIdProps): Promise<AxiosResponse<"">> {
  const params = new URLSearchParams();
  params.append("company", `${companyId}`);
  return api.delete<"">(`companies/invitations/${invitationId}/`, { params });
}
