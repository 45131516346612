import React, { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Spinner, Table, TextInput } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyMember } from "shared/context/types/company";
import { GUEST_ROLE, MEMBER_ROLE } from "shared/helpers/constant";
import { getServerErrors } from "shared/helpers/util";

import { inviteTeammates } from "modules/auth/on-boarding/api";
import { UserRole } from "modules/home/types";

import RoleDropdown from "../components/RoleDropdown";
import useMemberForm from "../hooks/useMemberForm";

export default function CreateMember({
  item,
  setNewMembers,
  index,
  membersList,
  refetchMemberList,
  loggedInUserRole,
}: {
  item: { email: string; role: string };
  setNewMembers: (
    data: React.SetStateAction<
      {
        email: string;
        role: string;
      }[]
    >
  ) => void;
  index: number;
  membersList: Array<CompanyMember>;
  refetchMemberList: () => void;

  loggedInUserRole?: {
    level: number;
    name: string;
    value: UserRole;
  };
}) {
  const { currentCompany, refetch } = useCompany();

  const { handleSubmit, values, errors, setFieldValue, resetForm } = useMemberForm(() => {
    onSubmit({ email: values.email, role: values.role });
  });

  const companyMembers = [...membersList, ...currentCompany.organization_invites];

  const areSeatsOver = useMemo(
    () => companyMembers.length >= currentCompany.seats,
    [currentCompany]
  );

  const { isLoading, mutate: onSubmit } = useMutation(
    (data: { email: string; role: UserRole }) => {
      return inviteTeammates({
        invitee_identifier: data.email,
        organization: currentCompany?.id,
        role: data.role,
        is_admin: data.role === GUEST_ROLE || data.role === MEMBER_ROLE ? false : true,
      });
    },
    {
      onSuccess: () => {
        refetch();
        refetchMemberList();
        handleRemoveMember(index);
        toast("Invitation sent successfully!", { type: "success" });
      },
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e).map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const onChangeMember = useCallback((data: { role: string; email: string }, index: number) => {
    setFieldValue("email", data.email);
    setNewMembers((_newMembers) => _newMembers.map((item, i) => (i === index ? data : item)));
  }, []);

  const handleRemoveMember = useCallback((index: number) => {
    setNewMembers((_newMembers) => _newMembers.filter((item, i) => i !== index));
    resetForm();
  }, []);

  useEffect(() => {
    const table = document.getElementById("newMemberListing");
    if (table && table?.children?.length > 0) {
      table?.children[table?.children.length - 1]?.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    setFieldValue("email", item.email);
    setFieldValue("role", item.role);
  }, [item]);

  return (
    <Table.Row className="bg-white">
      <Table.Cell>
        <div className="flex items-center">
          <TextInput
            className={clsx(
              { [styles.email_input]: errors.email },
              "relative w-full",
              styles.email_input_gray
            )}
            autoFocus
            value={item.email}
            color={errors.email ? "failure" : "gray"}
            onChange={(event) => {
              onChangeMember({ role: item.role, email: event.target.value || "" }, index);
            }}
          />
          <Icon type="mail" className={styles.member_email_icon} />
        </div>
      </Table.Cell>
      <Table.Cell>
        <RoleDropdown
          loggedInUserRole={loggedInUserRole}
          isEdit={true}
          role={item.role || "Select Role"}
          isDisable={false}
          handleChangeRole={({ role }) => onChangeMember({ role: role, email: item.email }, index)}
        />
      </Table.Cell>
      <Table.Cell colSpan={3}>
        <div className="flex items-center justify-end gap-4">
          <button
            type="button"
            className="text-mirage dark:text-white"
            onClick={() => {
              handleRemoveMember(index);
            }}
          >
            Cancel
          </button>
          <button
            className="btn_primary flex gap-4"
            type="submit"
            onClick={() => {
              if (areSeatsOver) {
                toast("You have reached the maximum number of users", { type: "warning" });
                setNewMembers([]);
                return;
              }
              handleSubmit();
            }}
          >
            <Spinner hidden={!isLoading} size="sm" className="mr-3 !fill-mirage stroke-mirage" />
            Send Invitation
          </button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
