import React, { memo, useEffect, useRef, useState } from "react";
import { InputActionMeta } from "react-select";
import AsyncSelect from "react-select/async";

import { AxiosResponse } from "axios";
import clsx from "clsx";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/agreement.module.css";
import headerStyles from "assets/css/common-header.module.css";

import { ICONTAINS_OPERATOR } from "shared/helpers/constant";
import { selectControlStyle, selectErrorStyles } from "shared/helpers/selectStyle";
import {
  AgreementFormValues,
  PaginatedResponse,
  PaginationDropdownQueryParams,
} from "shared/types";

import { optionType } from "../metadata/types";

function SearchableDropdown<T extends object>({
  handleChangeSelectedOption,
  selectedValue,
  hasError,
  placeholder,
  refetch,
  className,
  isLoading,
  data,
  handleChange,
  mainClassname,
  nextPage,
}: {
  handleChangeSelectedOption?: (data: optionType | null) => void;
  selectedValue?: Pick<
    {
      id: number | string;
      name: string;
    },
    "id"
  > | null;
  hasError?: boolean;
  placeholder?: string;
  className?: string;
  isLoading?: boolean;
  mainClassname?: string;
  refetch: (
    requestParams: PaginationDropdownQueryParams
  ) => Promise<AxiosResponse<PaginatedResponse<unknown> | unknown[]>>;
  data: AgreementFormValues<Pick<{ id: number | string; name: string }, "id">>;
  value: string; // will remove later.
  handleChange: (data: string | null, options?: T) => void;
  nextPage?: string;
}) {
  const [selectedOption, setSelectedOption] = useState<optionType | null>(null);

  const { isDarkMode } = useDarkMode();

  const [options, setOptions] = useState<Array<optionType>>([]);
  const currentPage = useRef<number>(1);
  const [searchString, setSearchString] = useState<string>("");

  const getDynamicOptions = async (inputValue: string) => {
    const result = await refetch({
      ["name__" + ICONTAINS_OPERATOR]: inputValue,
      page: currentPage?.current,
    });

    return new Promise<optionType[]>((resolve) => {
      if (!isArray(result.data)) {
        let list: Array<optionType>;
        if (currentPage?.current === 1) {
          list = result.data?.results.map((item) => {
            return {
              id: Number(`${get(item, "id")}`),
              label: `${get(item, "name")}`,
              value: `${get(item, "id")}`,
              data: item,
            };
          });

          if (result?.data?.next != null) {
            currentPage.current += 1;
          }
          resolve(list);
        } else {
          list = [
            ...options,
            ...(result.data?.results.map((item) => {
              return {
                id: Number(`${get(item, "id")}`),
                label: `${get(item, "name")}`,
                value: `${get(item, "id")}`,
                data: item,
              };
            }) || []),
          ];

          if (result?.data?.next != null) {
            currentPage.current += 1;
          }
          resolve(list);
        }
      }
    });
  };

  const promiseOptions = async (inputValue: string) => {
    return getDynamicOptions(inputValue);
  };

  useEffect(() => {
    if (searchString?.length === 0) {
      if (currentPage?.current === 1) {
        setOptions(
          data?.map((item: Pick<{ id: number | string; name: string }, "id">) => {
            return {
              id: Number(item.id || "0"),
              label: `${get(item, "name")}`,
              value: `${item.id}`,
              data: item,
            };
          })
        );
      } else {
        setOptions((prevOptions) => [
          ...prevOptions,
          ...(data?.map((item: Pick<{ id: number | string; name: string }, "id">) => ({
            id: Number(item.id || "0"),
            label: `${get(item, "name")}`,
            value: `${item.id}`,
            data: item,
          })) || []),
        ]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(selectedValue)) {
      setSelectedOption({
        id: Number(get(selectedValue, "id")),
        label: `${get(selectedValue, "name")}`,
        value: `${get(selectedValue, "id")}`,
        data: selectedValue,
      });
    } else {
      setSelectedOption(null);
    }
  }, [selectedValue]);

  const lastAction = useRef<InputActionMeta | null>(null);

  const handleMenuScrollToBottom = async () => {
    if (nextPage && nextPage !== null && nextPage?.length > 0) {
      const nameMatch = nextPage?.match(/name__icontains=([^&]*)/);

      const name__icontains = nameMatch ? nameMatch[1] : "";

      if (name__icontains !== "") {
        await promiseOptions(name__icontains);
      } else {
        currentPage.current += 1;
        await refetch({ page: currentPage?.current });
      }
    }
  };

  return (
    <div className={className} data-testid="select_dropdown">
      <AsyncSelect
        onMenuOpen={() => {
          if (refetch) {
            refetch({});
          }
        }}
        onMenuClose={() => setOptions([])}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        isLoading={isLoading}
        menuPlacement="bottom"
        loadOptions={promiseOptions}
        isSearchable
        isClearable
        defaultOptions={options}
        options={options}
        placeholder={placeholder}
        classNamePrefix="multitag multitag_spacing searchable_dropdown"
        className={clsx(headerStyles?.searchField, styles.multiDropdownSelect, mainClassname)}
        value={[selectedOption]}
        styles={hasError ? selectErrorStyles(isDarkMode) : selectControlStyle(isDarkMode)}
        onInputChange={async (inputText, actionMeta) => {
          currentPage.current = 1;
          setSearchString(inputText);

          if (
            lastAction &&
            lastAction.current?.action === "set-value" &&
            actionMeta.action === "menu-close" &&
            actionMeta.prevInputValue !== ""
          ) {
            lastAction.current = null;
            return;
          }
          lastAction.current = actionMeta;

          if (
            (actionMeta.action === "menu-close" ||
              (inputText == "" && actionMeta.action === "input-change")) &&
            actionMeta.prevInputValue !== "" &&
            refetch
          ) {
            await refetch({});
          }
        }}
        onChange={async (valueOption, actionMeta) => {
          currentPage.current = 1;
          if (valueOption && !Array.isArray(valueOption)) {
            setSelectedOption(valueOption);
            handleChange(valueOption.value || null, valueOption);
            if (refetch && actionMeta.action === "select-option") {
              await refetch({});
            }
            if (handleChangeSelectedOption) {
              handleChangeSelectedOption(valueOption);
            }
            return;
          }
          if (valueOption === null) {
            setSelectedOption(null);
            handleChange(null, valueOption);
            if (refetch && actionMeta.action === "select-option") {
              await refetch({});
            }
            if (handleChangeSelectedOption) {
              handleChangeSelectedOption(null);
            }
          }
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#E5E7EB",
          },
        })}
      />
    </div>
  );
}

export default memo(SearchableDropdown);
