import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamId } from "shared/types";

export default function deleteNoteById(
  noteId: number,
  permissions: { team: TeamId }
): Promise<AxiosResponse<void>> {
  return api.delete(`notes/${noteId}/`, { params: permissions });
}
