import React from "react";

import Icon from "shared/components/icon/Icon";
import ModuleTitle from "shared/components/module-title/ModuleTitle";

import { useCompany } from "../../../../shared/context/CompanyProvider";
import { useWorkspaceModal } from "../../../../shared/store/trial";

export const TaskTitle = ({
  setIsModalOpen,
  callback,
}: {
  setIsModalOpen: (state: boolean) => void;
  callback?: () => void;
}) => {
  const { currentCompany } = useCompany();
  const setIsTrialModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  return (
    <ModuleTitle label="Tasks">
      <div className="maxMd:mt-1">
        <button
          className="btn_primary mt-auto flex h-10 items-center justify-center gap-x-1 maxMd:mt-2.5 maxMd:w-full maxMd:justify-center"
          type="button"
          onClick={() => {
            if (currentCompany?.trial_expired) {
              setIsTrialModalOpen(true);
            } else {
              setIsModalOpen(true);
              callback && callback();
            }
          }}
        >
          <Icon type="plus" fill={true} size="icon-smd" className="notification_icon" />
          Create Task
        </button>
      </div>
    </ModuleTitle>
  );
};
