import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "flowbite-react";

import { useQuery } from "@tanstack/react-query";

import { AuthHeader } from "shared/components/header";
import Spinner from "shared/components/spinner/Spinner";

import { verifyEmail } from "./api";

const VerifyEmail = () => {
  const { key } = useParams();

  const navigate = useNavigate();

  const { isLoading, isError } = useQuery(["verifyEmail"], () => verifyEmail(`${key}`), {
    onSuccess: () => {
      navigate("/signin", { replace: true });
    },
    onError: () => {
      toast("something went wrong", { type: "error" });
    },
  });

  return (
    <section className="relative overflow-x-hidden">
      <AuthHeader />
      <div className="flex h-[calc(100vh-50px)] flex-col items-center justify-center">
        {isLoading ? <Spinner /> : null}
        {isError ? (
          <Link to={"/signup"}>
            <Button>Back to signup</Button>
          </Link>
        ) : null}
      </div>
    </section>
  );
};

export default VerifyEmail;
