import React, { useEffect, useState } from "react";
import { OnChangeValue, OptionProps, components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

import { useDarkMode } from "usehooks-ts";

import Avatar from "shared/components/avatar";
import { TeamMemberOptionProps, TeamMembersProps } from "shared/components/metadata/types";
import { useCompany } from "shared/context/CompanyProvider";
import { selectControlStyleForCreate } from "shared/helpers/selectStyle";
import { getUsername } from "shared/helpers/util";
import { OptionDataProps } from "shared/types";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";
import { UserResponse } from "modules/home/types";

const CustomOptionMenu = ({ props }: { props: OptionProps<OptionDataProps> }) => {
  return (
    <>
      <components.Option {...props}>
        <div className=" flex !w-full items-center gap-2 font-inter-medium !font-medium">
          <span>
            <Avatar
              size="sm"
              name={getUsername(props?.data?.user)}
              avatarFor="user"
              avatarType="rounded"
              url={props?.data?.user?.profile_picture}
            />
          </span>
          {getUsername(props?.data?.user)}
        </div>
      </components.Option>
    </>
  );
};

const TeamMembers = ({ onChange, placeholder = "", members_object = [] }: TeamMembersProps) => {
  const { currentCompany } = useCompany();
  const [selectedMembers, setSelectedMembers] = useState<TeamMemberOptionProps[]>([]);

  const { isDarkMode } = useDarkMode();

  const onChangeFn = (selectedOptions: OnChangeValue<TeamMemberOptionProps, true>) => {
    setSelectedMembers([...selectedOptions]);
    onChange([...selectedOptions]);
  };

  const handleChangeMembers = (data_obj: UserResponse[]) => {
    setSelectedMembers(
      data_obj.map((item) => ({
        label: (
          <div className="flex items-center font-inter-medium dark:text-white">
            <div className="mr-1 flex">
              <Avatar
                size="sm"
                name={getUsername(item)}
                avatarFor="user"
                avatarType="rounded"
                url={item.profile_picture}
              />
            </div>
            {getUsername(item)}
          </div>
        ),
        value: item.id || 0,
      }))
    );
  };

  useEffect(() => {
    if (members_object.length && selectedMembers.length === 0) {
      handleChangeMembers(members_object);
    }
  }, [members_object]);

  return (
    <>
      <div className="select-member relative items-start">
        <AsyncPaginate
          isMulti
          isSearchable={true}
          value={selectedMembers}
          onChange={onChangeFn}
          components={{
            IndicatorSeparator: () => null,
            Option: (props) => <CustomOptionMenu props={props} />,
          }}
          styles={selectControlStyleForCreate(isDarkMode)}
          placeholder={
            <div className="flex w-full items-center">
              <span className="text-sm">{placeholder}</span>
            </div>
          }
          loadOptions={async (search: string, prevOptions, args = {}) => {
            const { page = 1 } = args as { page: number };

            if (!currentCompany?.id) {
              return {
                options: [],
                hasMore: false,
                additional: {
                  page,
                },
              };
            }

            const response = await getMembersByCompanyId(currentCompany.id, {
              page,
              search,
            });

            return {
              options: response.data.results.map((item) => ({
                value: item.user?.id,
                label: (
                  <div className=" flex !w-full items-center gap-2 font-inter-medium !font-medium">
                    <span className="font-inter-medium">
                      <Avatar
                        size="sm"
                        name={getUsername(item.user)}
                        avatarFor="user"
                        avatarType="rounded"
                        url={item.user?.profile_picture}
                      />
                    </span>
                    {getUsername(item.user)}
                  </div>
                ),
                user: item.user,
              })),
              hasMore: !!response.data.next,
              additional: {
                page: page + 1,
              },
            };
          }}
        />
      </div>
    </>
  );
};

export default TeamMembers;
