import { SPREADSHEET_SAVE_URL } from "../constants";
import isExcelResponseSpreadsheet from "./isExcelResponseSpreadsheet";

/**
 * Convert the response from the spreadsheet component to a file via spreadsheet server.
 * Raises error if warn data passed
 */
export default function convertToExcelFile(filename: string, data: unknown): Promise<File> {
  if (!isExcelResponseSpreadsheet(data)) {
    return Promise.reject(new Error("Invalid response from spreadsheet"));
  }

  const formData: FormData = new FormData();
  formData.append("JSONData", JSON.stringify(data.jsonObject.Workbook));
  formData.append(
    "PdfLayoutSettings",
    JSON.stringify({
      fitSheetOnOnePage: false,
      orientation: "Portrait",
    })
  );
  formData.append("FileName", filename);
  formData.append("SaveType", "xlsx");
  formData.append("VersionType", "Xlsx");
  formData.append("FileContentType", "Xlsx");
  return fetch(SPREADSHEET_SAVE_URL, {
    method: "POST",
    body: formData,
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => new File([blob], filename));
}
