import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Label, Modal, Spinner, TextInput, Textarea } from "flowbite-react";
import { usePostHog } from "posthog-js/react";

import { useMutation } from "@tanstack/react-query";

import memberStyles from "assets/css/members.module.css";
import styles from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";
import ServerErrors from "shared/components/server-errors";
import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useTeam } from "shared/store/settings";

import { useWorkspaceModal } from "../../../../shared/store/trial";
import { addTeam, addTeamMembers } from "../api/index";
import useCreateTeamForm from "../hooks/useCreateTeamForm";
import { TeamInputData } from "../types";

const AddTeam = () => {
  const posthog = usePostHog();

  const { currentCompany, refetch } = useCompany();
  const [isTeamModalOpen, setIsTeamModalOpen] = useState<boolean>(false);
  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const teams = useTeam((state) => state.teams);

  const setTeams = useTeam((state) => state.setTeams);

  const teamIconRef = useRef<HTMLInputElement>(null);

  const [teamIcon, setTeamIcon] = useState<File | null>(null);

  const setFilteredTeam = useTeam((state) => state.setFilteredTeam);

  const setIsFiltered = useTeam((state) => state.setIsFiltered);

  const { user } = useUser();

  const { documentBodyRef } = useDocumentBodyRef();

  const onTeamModalClose = () => {
    setIsTeamModalOpen(false);
  };

  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target.files ? target.files[0] : null;
    setTeamIcon(file);
  };

  const {
    isLoading,
    mutate: addTeamfn,
    isError,
    error,
  } = useMutation((data: TeamInputData) => addTeam(data, currentCompany.id, teamIcon), {
    onSuccess: (res) => {
      posthog.capture("TeamCreated");
      setTeams([...teams, { ...res.data, members: user?.id ? [user?.id] : [] }]);
      refetch();
      toast("Team Created Successfully", { type: "success" });
      addTeamMemberFn({
        teamId: res.data.id,
        members: user?.id ? [user?.id] : [],
      });
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const {
    isLoading: addTeamMemberLoader,
    mutate: addTeamMemberFn,
    isError: hasTeamMemberError,
    error: teamMemberError,
  } = useMutation(
    ({ teamId, members }: { teamId: number; members: number[] }) =>
      addTeamMembers(teamId, currentCompany.id, members),
    {
      onSuccess: () => {
        onTeamModalClose();
        handleReset({});
      },
      onError: (e: AxiosError): AxiosError => e,
    }
  );

  const { handleSubmit, values, errors, handleChange, handleReset } = useCreateTeamForm(() =>
    addTeamfn(values)
  );

  const getTeamIcon = () => {
    if (teamIcon) return URL.createObjectURL(teamIcon);
  };

  const onSearchTeam = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setIsFiltered(false);
      return;
    }
    setIsFiltered(true);
    const filterdTeamList = teams.filter((item) =>
      item.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setFilteredTeam(filterdTeamList);
  };

  return (
    <div className={styles.manage_teams} data-testid="create-team">
      <div className={memberStyles.account_content}>
        <div className="flex-wrap items-center md:flex">
          <div className="w-full md:w-[calc(100%-111px)]">
            <h3 className="font-inter-medium text-base dark:text-white mac13Inch:text-sm">
              Manage Teams
            </h3>
          </div>
        </div>
        <div className={clsx(memberStyles?.search_block, "maxMd:flex-col")}>
          <TextInput
            className={memberStyles?.search_field}
            placeholder="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onSearchTeam(e)}
          />
          <Icon type="search" fill={false} size="icon-md" className={memberStyles.search_img} />
          <button
            onClick={() =>
              currentCompany?.trial_expired ? setWorkspaceModalOpen(true) : setIsTeamModalOpen(true)
            }
            className="btn_primary maxMd:mt-3.5 maxMd:w-full"
          >
            Create Team
          </button>
        </div>
      </div>
      <Modal
        show={isTeamModalOpen}
        size="md"
        onClose={onTeamModalClose}
        dismissible={true}
        root={documentBodyRef}
      >
        <Modal.Header className={styles.team_add_header} />
        <Modal.Body className="overflow-y-auto pt-0 maxMd:p-4 mac13Inch:pb-2">
          <div className="h-full">
            <div className="text-center">
              <div className="m-px mb-3 font-sans-medium text-xl dark:text-white mac13Inch:text-base">
                Create a New Team
              </div>
              <p className="text-sm tracking-wide text-aurometalsaurus dark:text-greychateau">
                Teams are where your team organizes pages, permissions, and members
              </p>
            </div>
            <div
              className="mx-auto mt-4 h-20 w-20 cursor-pointer "
              onClick={() => teamIconRef?.current?.click()}
            >
              {!teamIcon && (
                <div className="h-full w-full rounded bg-lightSilver dark:bg-iridium" />
              )}
              {teamIcon && (
                <img className="h-full w-full rounded" src={getTeamIcon()} alt="team-icon" />
              )}
            </div>
            <div className="flex justify-center">
              <input
                data-testid="add-team-file"
                type="file"
                className="hidden"
                ref={teamIconRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onIconChange(e)}
              />
              <button
                className="mt-3 text-sm text-aurometalsaurus dark:text-greychateau"
                onClick={() => teamIconRef?.current?.click()}
              >
                Choose icon
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <Label value="Team Name*" className="mb-2 block font-inter-medium !text-mirage" />
                <TextInput
                  data-testid="add-team-name"
                  placeholder="Enter Team Name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  color={errors.name ? "failure" : "gray"}
                />
              </div>
              <div className="mb-4">
                <Label value="Description*" className="mb-2 block font-inter-medium !text-mirage" />
                <Textarea
                  data-testid="add-team-description"
                  rows={6}
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  color={errors.description ? "failure" : "gray"}
                />
              </div>
              {(isError || hasTeamMemberError) && (
                <ServerErrors className="mb-4" error={error || teamMemberError} />
              )}
              <div className="mb-4">
                <button
                  className="btn_primary w-full"
                  data-testid="add-team"
                  type="submit"
                  disabled={isLoading || addTeamMemberLoader}
                >
                  <Spinner
                    size="md"
                    light={true}
                    hidden={!(isLoading || addTeamMemberLoader)}
                    className="mr-3 fill-crayolasblue stroke-crayolasblue"
                  />
                  Create Team
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddTeam;
