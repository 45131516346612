import React, { useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Table } from "flowbite-react";
import get from "lodash/get";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { removeFromCompany } from "shared/components/permission/api";
import updateMemberRole from "shared/components/permission/api/UpdateMemberRole";
import updateInvitation from "shared/components/permission/api/updateInvitation";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";
import { CompanyMember } from "shared/context/types/company";
import { getServerErrors } from "shared/helpers/util";
import { useWorkspaceModal } from "shared/store/trial";

import deleteInvitationById from "modules/auth/on-boarding/api/deleteInvitationById";
import { UserRole } from "modules/home/types";
import MemberListingTable from "modules/settingsV2/shared/member-listing";

import CreateMember from "../create";

export default function MemberListing({
  loggedInUserRole,
  isEdit,
  membersList,
  newMembers,
  setNewMembers,
  refetchMemberList,
}: {
  loggedInUserRole?: {
    level: number;
    name: string;
    value: UserRole;
  };
  isEdit: boolean;
  membersList: Array<CompanyMember>;
  newMembers: Array<{ email: string; role: string }>;
  setNewMembers: (
    data: React.SetStateAction<
      {
        email: string;
        role: string;
      }[]
    >
  ) => void;
  refetchMemberList: () => void;
}) {
  const { currentCompany, refetch, removeCompany } = useCompany();

  const setWorkspaceModalOpen = useWorkspaceModal((state) => state.setIsModalOpen);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [invitationToBeDeleted, setInvitationToBeDeleted] = useState<number | null>(null);

  const [memberDetails, setMemberDetails] = useState<CompanyMember | undefined | null>(null);

  const { mutate: deleteInvitationFn, isLoading: isDeleteInvitation } = useMutation(
    (invitationId?: number) => {
      if (!invitationToBeDeleted) {
        Promise.reject(new Error("Member is not selected"));
      }

      return deleteInvitationById({
        invitationId,
        companyId: currentCompany.id,
      });
    },
    {
      onSuccess: () => {
        setInvitationToBeDeleted(null);
        setIsDeleteModalOpen(false);
        refetch();
        toast("Invitation deleted successfully.", { type: "success" });
      },
      onError: (error: AxiosError) => {
        {
          setInvitationToBeDeleted(null);
          setIsDeleteModalOpen(false);
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const { user } = useUser();

  const { isLoading: isDeleting, mutate: deleteFromCompany } = useMutation(
    (id: number) => removeFromCompany(id, { company: currentCompany.id }),
    {
      onSuccess: () => {
        if (get(memberDetails, "user.id") === user.id) {
          removeCompany(currentCompany, true);
          toast("Removed from Company.", { type: "success" });
        } else {
          removeCompany(currentCompany, false);
          refetchMemberList();

          toast("Member Removed Successfully.", { type: "success" });
          setMemberDetails(null);
        }
        setIsDeleteModalOpen(false);
      },
      onError: (e: AxiosError): AxiosError => {
        setIsDeleteModalOpen(false);
        setMemberDetails(null);
        getServerErrors(e).map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  const { isLoading: memberRoleLoading, mutate: updateMemberRoleFn } = useMutation({
    mutationFn: ({ role, memberId }: { role: UserRole; memberId: number }) => {
      return updateMemberRole(memberId, role, { company: currentCompany.id });
    },
    onSuccess: () => {
      refetchMemberList();
    },
    onError: (e: AxiosError): AxiosError => {
      getServerErrors(e).map((err: string) => toast(err, { type: "error" }));
      return e;
    },
  });

  const { isLoading: invitationRoleLoading, mutate: updateInvitationRoleFn } = useMutation({
    mutationFn: ({ role, id }: { role: UserRole; id: number }) => {
      return updateInvitation(id, role, { company: currentCompany.id });
    },
    onSuccess: () => {
      refetch();
    },
    onError: (e: AxiosError): AxiosError => {
      getServerErrors(e).map((err: string) => toast(err, { type: "error" }));
      return e;
    },
  });

  return (
    <div>
      {isDeleting || isDeleteInvitation || memberRoleLoading || invitationRoleLoading ? (
        <CentralSpinner />
      ) : null}

      <div className={clsx(styles.new_member_list)}>
        <MemberListingTable
          handleChangeRole={({ role, memberId, item }) => {
            if (get(item, "user")) {
              updateMemberRoleFn({ role, memberId });
            } else {
              updateInvitationRoleFn({ role, id: item.id });
            }
          }}
          loggedInUserRole={loggedInUserRole}
          isEdit={isEdit}
          membersList={membersList}
          handleDelete={(item) => {
            if (currentCompany?.trial_expired) {
              setWorkspaceModalOpen(true);
              return;
            }
            if (get(item, "user")) {
              setMemberDetails(item);
            } else {
              setInvitationToBeDeleted(item.id);
            }
            setIsDeleteModalOpen(true);
          }}
        >
          <>
            {isEdit && newMembers.length > 0 ? (
              <Table.Body
                className="divide-y border-t pl-4 dark:border-thunders"
                id="newMemberListing"
              >
                {newMembers?.map((item, index) => (
                  <CreateMember
                    membersList={membersList}
                    key={index}
                    setNewMembers={setNewMembers}
                    item={item}
                    index={index}
                    refetchMemberList={refetchMemberList}
                    loggedInUserRole={loggedInUserRole}
                  />
                ))}
              </Table.Body>
            ) : null}
          </>
        </MemberListingTable>
      </div>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        action={() => {
          if (invitationToBeDeleted) {
            deleteInvitationFn(invitationToBeDeleted);
            return;
          }
          if (memberDetails) {
            deleteFromCompany(memberDetails?.id);
          }
        }}
      />
    </div>
  );
}
