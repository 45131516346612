/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleStatusEnum } from './ArticleStatusEnum';
import {
    ArticleStatusEnumFromJSON,
    ArticleStatusEnumFromJSONTyped,
    ArticleStatusEnumToJSON,
} from './ArticleStatusEnum';

/**
 * 
 * @export
 * @interface AddStatusRequest
 */
export interface AddStatusRequest {
    /**
     * 
     * @type {ArticleStatusEnum}
     * @memberof AddStatusRequest
     */
    status?: ArticleStatusEnum;
}



/**
 * Check if a given object implements the AddStatusRequest interface.
 */
export function instanceOfAddStatusRequest(value: object): value is AddStatusRequest {
    return true;
}

export function AddStatusRequestFromJSON(json: any): AddStatusRequest {
    return AddStatusRequestFromJSONTyped(json, false);
}

export function AddStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddStatusRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : ArticleStatusEnumFromJSON(json['status']),
    };
}

export function AddStatusRequestToJSON(value?: AddStatusRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': ArticleStatusEnumToJSON(value['status']),
    };
}

