import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import getActivityLog from "shared/components/metadata/api/getActivityLog";
import {
  AGREEMENT_TABLE,
  ARTICLE_TABLE,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  MITIGATION_TABLE,
  NOTE_TABLE,
  POLICY_TABLE,
  RISK_TABLE,
  TASK_TABLE,
} from "shared/helpers/constant";

import { CreateCommentFunctionType, CreateCommentType, tagsType } from "../types";
import createAgreementComments from "./createAgreementComments";
import createArticlesComments from "./createArticlesComments";
import createDocumentsComments from "./createDocumentsComments";
import createIncidentsComments from "./createIncidentsComments";
import createMitigationsComments from "./createMitigationsComments";
import createNotesComments from "./createNotesComments";
import createPolicyComments from "./createPolicyComments";
import createRisksComments from "./createRisksComments";
import createTaskComments from "./createTaskComments";
import getComments from "./getComments";
import getTagsList from "./getTagsList";
import getTeamActions from "./getTeamActions";
import revertVersion from "./revertVersion";
import updateTeamActions from "./updateTeamActions";

export { default as getPermissionsList } from "./getPermissionsList";
export { default as createPermission } from "./createPermission";
export { default as updatePermission } from "./updatePermission";
export { default as deletePermission } from "./deletePermission";

// Post Comments API

export const createComment = (data: CreateCommentType): CreateCommentFunctionType => {
  const { comment, type, team, id, attachments, mentions, company } = data;

  const formData = new FormData();
  formData.append("team", `${team}`);
  formData.append("text", comment);
  formData.append(`${type}`, id || "");
  (attachments || []).forEach((file) => {
    formData.append("attachments", file);
  });
  (mentions || []).forEach((mention) => {
    formData.append("mentions", mention);
  });

  switch (type) {
    case AGREEMENT_TABLE:
      return createAgreementComments(+id, formData, { team, company });
    case ARTICLE_TABLE:
      return createArticlesComments(+id, formData, { team });
    case DOCUMENT_TABLE:
      return createDocumentsComments(+id, formData, { team });
    case POLICY_TABLE:
      return createPolicyComments(+id, formData, { team });
    case TASK_TABLE:
      return createTaskComments(+id, formData, { team });
    case NOTE_TABLE:
      return createNotesComments(+id, formData, { team });
    case MITIGATION_TABLE:
      return createMitigationsComments(+id, formData, { team });
    case RISK_TABLE:
      return createRisksComments(+id, formData, { team });
    case INCIDENTS_TABLE:
      return createIncidentsComments(+id, formData, { team });
  }
  throw "Invalid type";
};

export const deleteTagById = ({
  tagId,
  teamId,
}: {
  tagId: number;
  teamId: number;
}): Promise<AxiosResponse<tagsType>> => {
  return api.delete<tagsType>(`core/tags/${tagId}/`, { params: { team: teamId } });
};

export {
  getTagsList,
  getActivityLog,
  getTeamActions,
  revertVersion,
  getComments,
  updateTeamActions,
};
