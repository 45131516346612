import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import getFeedSharedUsers from "../api/getFeedSharedUsers";

interface FeedSharedUsersQueryProps {
  params: {
    feedId: number;
  };
  config: {
    enabled: boolean;
  };
}

const useFeedSharedUsers = ({ params, config }: FeedSharedUsersQueryProps) => {
  const { currentCompany } = useCompany();
  return useQuery(
    ["feed-shared-users", params.feedId],
    () => getFeedSharedUsers({ feedId: params.feedId, company: currentCompany.id }),
    {
      refetchOnWindowFocus: false,
      enabled: config.enabled,
    }
  );
};

export default useFeedSharedUsers;
