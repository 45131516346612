import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { getCountries, getIndustries } from "../../modules/auth/on-boarding/api";
import {
  CountryResponseParams,
  IndustryResponseParams,
} from "../../modules/auth/on-boarding/types";
import { SelectTemplateProps } from "../../modules/policies/overview/types";

type CompanyInfoProps = {
  countries: CountryResponseParams[] | undefined;
  industries: IndustryResponseParams[] | undefined;
  industriesOptions: SelectTemplateProps[] | undefined;
  countriesOptions: SelectTemplateProps[] | undefined;
};

const useCompanyInfo = ({
  industryKey,
  countryKey,
}: {
  industryKey: string;
  countryKey: string;
}): CompanyInfoProps => {
  const { data: countriesData } = useQuery(["fetch-countries", countryKey], () => getCountries());

  const countries = countriesData?.data.results;

  const { data: industries } = useQuery(["fetch-industries", industryKey], () => getIndustries());

  const industriesOptions: SelectTemplateProps[] | undefined = useMemo(() => {
    return industries?.data?.results?.map((industry: IndustryResponseParams) => {
      return { label: industry.name, value: `${industry.id}` };
    });
  }, [industries]);

  const countriesOptions: SelectTemplateProps[] | undefined = useMemo(() => {
    return countries?.map((country: CountryResponseParams) => {
      return { label: country.name, value: `${country.id}` };
    });
  }, [countries]);

  return {
    countries: countries,
    industries: industries?.data?.results,
    industriesOptions,
    countriesOptions,
  };
};

export default useCompanyInfo;
