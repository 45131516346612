import { useMemo } from "react";

import { useCompany } from "shared/context/CompanyProvider";
import { useUser } from "shared/context/UserProvider";

import { UserResponse } from "../../home/types";
import useFeed from "../shared/hooks/useFeed";
import useFeedSharedUsers from "../shared/hooks/useFeedSharedUsers";

export const useFeedMembers = ({ feedId }: { feedId: ID }) => {
  const { user } = useUser();
  const { currentCompany } = useCompany();

  const { data: sharedUsers } = useFeedSharedUsers({
    params: {
      feedId: Number(feedId),
    },
    config: {
      enabled: true,
    },
  });

  const feedQuery = useFeed({ feedId: feedId ? Number(feedId) : 0 });

  const groupUsers = useMemo(() => {
    const users =
      sharedUsers
        ?.filter((e) => !!e.shared_with_group)
        .flatMap((e) => e.shared_with_group?.users.map((e) => e.user)) || [];

    return Array.from(new Set(users.map((user) => user?.id)))
      .map((id) => users.find((user) => user?.id === id))
      .filter((user): user is UserResponse => user !== undefined);
  }, [sharedUsers]);

  const mentionableUsers = useMemo(() => {
    const feedOwner: UserResponse | undefined = currentCompany.organization_users.find(
      ({ user }) => user.id === feedQuery.data?.objectOwner
    )?.user;

    const users = [
      ...(sharedUsers?.filter((e) => !!e.shared_with_user).map((e) => e.shared_with_user) || []),
      ...(sharedUsers
        ?.filter((e) => !!e.shared_with_group)
        .flatMap((e) => e.shared_with_group?.users.map((e) => e.user)) || []),
      ...(feedOwner ? [feedOwner] : []),
      user,
    ].filter((e) => !!e);

    // return unique users
    return Array.from(new Set(users.map((user) => user?.id)))
      .map((id) => users.find((user) => user?.id === id))
      .filter((user): user is UserResponse => user !== undefined);
  }, [currentCompany.organization_users, sharedUsers, user, feedQuery.data?.objectOwner]);

  return { mentionableUsers, groupUsers };
};
