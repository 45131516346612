import React, { ChangeEvent } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import FirebaseNotification from "shared/components/browser-notification";
import ToggleSwitch from "shared/components/toggle-switch";
import { useHome } from "shared/store/home";

import { updateProfile } from "modules/settings/my-account/api";
import { MyAccountProps } from "modules/settings/my-account/types";

export const Notification = () => {
  const profile = useHome((state) => state.profile);
  const setProfile = useHome((state) => state.setProfile);

  const { mutate: updateProfileFn } = useMutation((data: MyAccountProps) => updateProfile(data), {
    onSuccess: (res) => {
      setProfile({ ...profile, ...res.data });
      toast("Setting saved successfully.", { type: "success" });
    },
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateProfileFn({ [e.target.name]: e.target.checked });
  };

  const onWebPushNotificationChange = async (
    e: ChangeEvent<HTMLInputElement>,
    callback: () => Promise<NotificationPermission | undefined>
  ) => {
    const { name, checked } = e.target;
    if (checked) {
      const permission = await callback();
      if (permission === "denied") {
        toast("Please unlock notifications for this site.", { type: "error" });
        return;
      }
    }
    updateProfileFn({ [name]: checked });
  };

  return (
    <>
      <section className={styles.main_content}>
        <div className="flex justify-between">
          <div>
            <h6 className="font-inter-medium text-mirage dark:text-white mac13Inch:text-sm">
              Email Notifications
            </h6>
            <p className="!mt-0 text-sm text-aurometalsaurus dark:text-greychateau">
              Receive email updates.
            </p>
          </div>
          <ToggleSwitch
            checked={profile?.receive_email_notifications || false}
            handleChange={onChange}
            name="receive_email_notifications"
          />
        </div>
      </section>
      <FirebaseNotification>
        {(props) => (
          <section className={clsx(styles.main_content, "!pt-0")}>
            <div className="flex justify-between">
              <div>
                <h6 className="font-inter-medium text-mirage dark:text-white mac13Inch:text-sm">
                  Browser Notifications
                </h6>
                <p className="mt-0 text-sm text-aurometalsaurus dark:text-greychateau">
                  Receive browser notification updates.
                </p>
              </div>
              <ToggleSwitch
                checked={profile?.receive_web_push_notifications || false}
                handleChange={(e) => onWebPushNotificationChange(e, props.requestPermission)}
                name="receive_web_push_notifications"
              />
            </div>
          </section>
        )}
      </FirebaseNotification>
    </>
  );
};
