import React, { memo, useEffect } from "react";

import { Modal } from "flowbite-react";
import { ModalBody } from "flowbite-react/lib/esm/components/Modal/ModalBody";
import { ModalHeader } from "flowbite-react/lib/esm/components/Modal/ModalHeader";

import { useCompany } from "shared/context/CompanyProvider";
import { useWorkspaceModal } from "shared/store/trial";

const TrialExpiredModal = () => {
  const { currentCompany } = useCompany();

  const [isTrialModalOpen, setIsTrialModalOpen] = useWorkspaceModal((state) => [
    state.isModalOpen,
    state.setIsModalOpen,
  ]);
  const Icon = () => {
    return (
      <>
        <svg
          width="154"
          height="91"
          viewBox="0 0 154 91"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M77 41.75C81.125 41.75 84.6563 40.2812 87.5938 37.3437C90.5313 34.4062 92 30.875 92 26.75V15.5H62V26.75C62 30.875 63.4688 34.4062 66.4063 37.3437C69.3438 40.2812 72.875 41.75 77 41.75ZM47 83V75.5H54.5V64.25C54.5 60.4375 55.3906 56.8593 57.1719 53.5156C58.9531 50.1718 61.4375 47.5 64.625 45.5C61.4375 43.5 58.9531 40.8281 57.1719 37.4844C55.3906 34.1406 54.5 30.5625 54.5 26.75V15.5H47V8H107V15.5H99.5V26.75C99.5 30.5625 98.6094 34.1406 96.8281 37.4844C95.0469 40.8281 92.5625 43.5 89.375 45.5C92.5625 47.5 95.0469 50.1718 96.8281 53.5156C98.6094 56.8593 99.5 60.4375 99.5 64.25V75.5H107V83H47Z"
            fill="#3A3A3C"
          />
        </svg>
      </>
    );
  };

  useEffect(() => {
    // Show trial modal when the component mounts
    if (currentCompany && currentCompany.trial_expired) {
      setIsTrialModalOpen(true);
    }
  }, [currentCompany?.trial_expired]);

  return (
    <>
      <Modal
        show={isTrialModalOpen}
        size="3xl"
        popup={true}
        onClose={() => setIsTrialModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center trial_container"
      >
        <ModalBody className="!flex !p-0">
          <div className="flex h-full gap-4">
            <div className="trial-modal-image">
              <span className="flex h-full items-center justify-center">
                <Icon />
              </span>
            </div>
            <div className="trial-modal-description">
              <p className="font-inter-medium text-xl dark:text-gray-300">
                Your Grand trial has finished{" "}
              </p>
              <p className="font-inter-regular text-base text-gray-500 dark:text-greychateau">
                We hope you enjoyed giving Grand a try!
              </p>
              <p className="font-inter-regular text-base text-gray-500 dark:text-greychateau">
                All data in your workspace is now read-only. We’ll keep your data for 30 days. If
                you’d like to keep access to your data and start using Grand again, please contact
                our sales team and schedule a 1:1 chat.
              </p>
              <p className="font-inter-regular text-base text-gray-500 dark:text-greychateau">
                If you have questions, please{" "}
                <span className="underline">
                  <a className="text-gray-800 dark:text-white" href={"mailto:hello@grand.io"}>
                    get in touch with us
                  </a>
                </span>{" "}
                and we’ll be happy to help!
              </p>
              <a
                href={"mailto:hello@grand.io"}
                className="btn_primary inline-flex w-1/2 justify-center"
              >
                Schedule a 1:1 chat
              </a>
            </div>
          </div>
          <ModalHeader></ModalHeader>
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(TrialExpiredModal);
