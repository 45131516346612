import flatten from "lodash/flatten";

import { RefetchOptions, SelectProps } from "../types";
import toRefetchSearchOption from "./toRefetchSearchOption";

export default function getRefetchOptionsFromSelectProps(
  selectProps: SelectProps[]
): RefetchOptions[] {
  return flatten(
    selectProps
      .map((selectProp) => selectProp.conditions || [])
      .map((conditions) => conditions.map((condition) => toRefetchSearchOption(condition)))
  );
}
