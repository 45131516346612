import React from "react";
import { createPortal } from "react-dom";

import { Spinner } from "flowbite-react";

import Modal from "../common-modal";
import Button from "../form-controls/button";

type ConfirmDeleteModalProps = {
  heading: string;
  show: boolean;
  question: string;
  description?: string;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
};

const ConfirmDeleteModal = ({
  heading,
  show,
  question,
  description,
  onClose,
  onDelete,
  isLoading = false,
}: ConfirmDeleteModalProps) => {
  const portalRoot = document.getElementById("modal-root");

  return (
    <>
      {createPortal(
        <Modal
          root={portalRoot as HTMLElement}
          onClose={() => onClose()}
          show={show}
          size="2xl"
          className="modal_mobileview_center confirm-delete-modal"
        >
          <Modal.Header className="items-center !px-4 !py-3 dark:!border-thunders ">
            {heading}
          </Modal.Header>
          <Modal.Body className="p-4">
            <h5 className="text-sm text-richblack dark:text-white">{question}</h5>
            {description ? (
              <p className="mt-1 text-sm  text-battleshipgray">{description}</p>
            ) : null}
          </Modal.Body>
          <Modal.Footer className="border-t-0 px-4 pb-4 pt-0">
            <div className="flex w-full justify-end gap-2">
              <Button btnTitle="Cancel" btnSize="sm" btnType="secondary" onClick={onClose} />
              <Button
                btnTitle="Delete"
                btnSize="sm"
                btnType="danger"
                onClick={onDelete}
                disabled={isLoading}
                icon={isLoading ? <Spinner size="sm" light={true} className="flex" /> : null}
              />
            </div>
          </Modal.Footer>
        </Modal>,
        portalRoot as Element
      )}
    </>
  );
};

export default ConfirmDeleteModal;
