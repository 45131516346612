import React from "react";

import clsx from "clsx";
import { Dropdown } from "flowbite-react";

import styles from "assets/css/members.module.css";

import Icon from "shared/components/icon/Icon";
import { CompanyMember } from "shared/context/types/company";

const MembersDropDown = ({
  member,
  onClick,
  index,
  onDelete,
}: {
  member: CompanyMember;
  onClick: (member: CompanyMember, key: string) => void;
  index: number;
  onDelete: (member: CompanyMember) => void;
}) => {
  return (
    <Dropdown
      label={member.is_admin ? "Company Owner" : "Member"}
      inline={true}
      className={clsx({
        [styles.memberDropDown]: index === 2,
        [styles.memberDropDownTop]: index === 2,
        [styles.memberDropDown]: index !== 2,
      })}
      placement="bottom"
    >
      <Dropdown.Item onClick={() => onClick(member, "owner")}>
        <div className="dropdown-list">
          <div
            data-testid="set-user-owner"
            className="flex font-inter-medium !text-aurometalsaurus dark:!text-greychateau"
          >
            Company Owner
            {member.is_admin && (
              <Icon type="checkmark" fill={true} size="icon-sm" className="sidebar_icon ml-auto" />
            )}
          </div>
          <p className="mt-1 !text-santagrey dark:!text-stormdust ">
            Can change company settings and invite new members to the workspace.
          </p>
        </div>
      </Dropdown.Item>
      <Dropdown.Item onClick={() => onClick(member, "member")}>
        <div>
          <div
            data-testid="set-user-member"
            className="flex font-inter-medium !text-aurometalsaurus dark:!text-greychateau"
          >
            Member
            {!member.is_admin && (
              <Icon type="checkmark" fill={true} size="icon-sm" className="sidebar_icon ml-auto" />
            )}
          </div>
          <p className="mt-1 !text-santagrey dark:!text-stormdust">
            Cannot change company settings or invite new members to the workspace
          </p>
        </div>
      </Dropdown.Item>
      <Dropdown.Item onClick={() => onDelete(member)}>
        <div className="text-lavared" data-testid="remove-user">
          Remove from Company
        </div>
      </Dropdown.Item>
    </Dropdown>
  );
};

export default MembersDropDown;
