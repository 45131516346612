import React, { FunctionComponent } from "react";

import clsx from "clsx";

import metadataStyles from "assets/css/metadata.module.css";
import styles from "assets/css/task.module.css";

import Comment from "shared/components/comments";
import { CommentType } from "shared/components/metadata/types";
import CentralSpinner from "shared/components/spinner/CentralSpinner";

interface CommentsListProps {
  comments: CommentType[];
  isLoading: boolean;
}

const CommentsList: FunctionComponent<CommentsListProps> = ({ comments, isLoading }) => {
  return (
    <>
      {isLoading ? <CentralSpinner /> : null}
      {comments.length > 0 ? (
        <>
          <ul
            className={clsx(metadataStyles.commentsList, styles.taskCommentList)}
            data-testid="comments-list"
          >
            {comments?.map((_comment: CommentType) => (
              <Comment comment={_comment} key={_comment.id} />
            ))}
          </ul>
        </>
      ) : null}
    </>
  );
};

export default CommentsList;
