import React from "react";

import clsx from "clsx";

import gridStyles from "assets/css/gridview.module.css";

import { getDateDifference } from "shared/helpers/util";

import Icon from "../icon/Icon";

function BeforeDate({ due_date }: { due_date: Date | string }) {
  return (
    <>
      <div
        data-testid="before-date"
        className={clsx(gridStyles.gridBeforeDate, gridStyles.gridDate)}
      >
        <Icon type="clock" fill={true} size="icon-xs" className="mr-1" />
        <span className="mr-1 text-aurometalsaurus dark:text-greychateau">
          {getDateDifference(due_date, new Date())}
        </span>
      </div>
    </>
  );
}

export default BeforeDate;
