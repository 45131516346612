import React from "react";

import { get } from "lodash";

import headerStyles from "assets/css/common-header.module.css";

import Icon from "shared/components/icon/Icon";
import { ICONTAINS_OPERATOR } from "shared/helpers/constant";
import useHideCopilot from "shared/hooks/useHideCopilot";
import useHideIntercom from "shared/hooks/useHideIntercom";
import { useTableFilter } from "shared/store/table-column-selection";

import InputFavorite from "../../InputFavorite";
import { FilterProps } from "../../types";
import FilterTitle from "../FilterTitle";

export default function FavoriteSubView({
  type,
  onFavoriteClick,
  onClose,
  searchText,
}: {
  type: string;
  onFavoriteClick: (filter: FilterProps) => void;
  onClose: () => void;
  searchText: string;
}) {
  useHideCopilot();
  useHideIntercom();

  const selectedFilters = useTableFilter((state) => state.selectedFilters);

  const favoriteSearchData = () => {
    if (searchText?.length > 0) {
      return [
        ...(get(selectedFilters, type) || []),
        {
          label: (
            <div className="flex items-center rounded-md bg-cyanblue !px-0 !py-0 !pr-2">
              <span className=" mr-2 inline-block rounded-l-[5px] bg-newcar !px-1.5 !py-1">
                <Icon type="filter" fill={true} size="icon-smd" className="themesvg_white" />
              </span>
              <span className="mr-2 text-sm text-newcar">Search</span>
            </div>
          ),
          value: `${searchText}`,
          field: "search",
          key: searchText,
          operator: ICONTAINS_OPERATOR,
          conditions: [
            {
              field: "search",
              operator: ICONTAINS_OPERATOR,
              value: searchText,
            },
          ],
        },
      ];
    } else {
      return get(selectedFilters, type);
    }
  };

  return (
    <div className="filter_view">
      <div>
        <FilterTitle handleClose={onClose} filterText="Save Current Search" />
        <div className={headerStyles.items}>
          <InputFavorite
            search={favoriteSearchData()}
            type={type}
            onFavoriteClick={onFavoriteClick}
          />
        </div>
      </div>
    </div>
  );
}
