/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `user` - User
 * * `system` - System
 * * `assistant` - Assistant
 * * `document` - Document
 * @export
 */
export const ChatMessageTypeEnum = {
    User: 'user',
    System: 'system',
    Assistant: 'assistant',
    Document: 'document'
} as const;
export type ChatMessageTypeEnum = typeof ChatMessageTypeEnum[keyof typeof ChatMessageTypeEnum];


export function instanceOfChatMessageTypeEnum(value: any): boolean {
    for (const key in ChatMessageTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ChatMessageTypeEnum, key)) {
            if (ChatMessageTypeEnum[key as keyof typeof ChatMessageTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ChatMessageTypeEnumFromJSON(json: any): ChatMessageTypeEnum {
    return ChatMessageTypeEnumFromJSONTyped(json, false);
}

export function ChatMessageTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageTypeEnum {
    return json as ChatMessageTypeEnum;
}

export function ChatMessageTypeEnumToJSON(value?: ChatMessageTypeEnum | null): any {
    return value as any;
}

