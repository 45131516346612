import { AxiosResponse } from "axios";

import { AGREEMENT_TYPE, NOTE_TYPE, POLICY_TYPE } from "shared/helpers/constant";

import { uploadAgreementFiles } from "modules/agreements/overview/api";
import { uploadNoteFile } from "modules/notes/api";
import { uploadPolicyFile } from "modules/policies/overview/api";
import { PolicyFileResponse } from "modules/policies/overview/types";
import { TeamResponse } from "modules/settings/teams/types";

import { FileUploader } from "../types";
import isUploadableModule from "./isUploadableModule";

const defaultFileUploader = () => Promise.resolve(null);

function policyFileUploader(
  data: FormData | null
): Promise<AxiosResponse<PolicyFileResponse> | null> {
  if (data === null) {
    console.warn("Can't upload file. Data is null.");
    return Promise.resolve(null);
  }
  return uploadPolicyFile(data, { team: Number(data.get("team")) });
}

function getAgreementFileUploader(team: TeamResponse): FileUploader {
  return (data: FormData | null) => {
    if (data === null) {
      console.warn("Can't upload file. Data is null.");
      return Promise.resolve(null);
    }
    return uploadAgreementFiles(
      {
        file: data.get("file") as File,
        teamId: team.id,
      },
      { team: team.id }
    );
  };
}

function getNoteFileUploader(team: TeamResponse): FileUploader {
  return (data: FormData | null) => {
    if (data === null) {
      console.warn("Can't upload file. Data is null.");
      return Promise.resolve(null);
    }
    return uploadNoteFile(data, { team: team.id });
  };
}

export default function getFileUploader(type: string, team: TeamResponse): FileUploader {
  if (!isUploadableModule(type)) {
    return defaultFileUploader;
  }

  switch (type) {
    case POLICY_TYPE: {
      return policyFileUploader;
    }
    case AGREEMENT_TYPE: {
      return getAgreementFileUploader(team);
    }
    case NOTE_TYPE: {
      return getNoteFileUploader(team);
    }
    default: {
      const _exhaustiveCheck: never = type;
      console.warn("Exhaustive check failed: ", _exhaustiveCheck);
      return defaultFileUploader;
    }
  }
}
