import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamParameter } from "shared/types";

import { convertArticleData } from "../helpers";
import { ArticlesQueryParams, PaginatedArticleList } from "../types";

export default async function getArticles(
  queryParams: ArticlesQueryParams,
  permissions: { team: TeamParameter }
): Promise<AxiosResponse<PaginatedArticleList>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;

  let response = await api.get<PaginatedArticleList>(`articles/`, {
    params: {
      ...hasNameOrdering(queryParams, "-publication_date"),
      ...{ team: teamParameter, company: permissions.team?.company },
      page_size: 32,
    },
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertArticleData(data);
          }) || [],
      },
    };
  }
  return response;
}
