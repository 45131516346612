import { truncate } from "lodash";
import { FeedView, HorizonFeedsArticlesListRequest } from "openapi";

import { formatDate } from "shared/componentsV2/utils";
import { DropdownItemProps } from "shared/typesV2";

import { DropdownFilterEnum } from "./types";

export type FeedFiltersOmitted = Omit<HorizonFeedsArticlesListRequest, "companyId" | "feedId">;
export const initialFilters: FeedFiltersOmitted = {
  smartFilter: "",
  keywordsOr: [],
  keywordsAnd: [],
  keywordsNot: [],
  jurisdictions: [],
  institutionTypes: [],
  publicationStartDate: undefined,
  publicationEndDate: undefined,
  regulations: [],
  publishers: [],
  tags: [],
};

export const feedViewToFilters = (feed?: FeedView): FeedFiltersOmitted => {
  if (!feed) return initialFilters;
  return {
    smartFilter: feed.smartFilter || undefined,
    keywordsAnd: feed.keywordsAnd,
    keywordsOr: feed.keywordsOr,
    keywordsNot: feed.keywordsNot,
    jurisdictions: feed.jurisdictions.map((jurisdiction) => jurisdiction.id),
    publicationStartDate: feed.publicationStartDate || undefined,
    publicationEndDate: feed.publicationEndDate || undefined,
    institutionTypes: feed.institutionTypes,
    regulations: feed.sections.map((section) => section.id),
    publishers: feed.publishers.map((publisher) => publisher.id),
    tags: feed.tags.map((tag) => tag.name),
  };
};

type Filter = { label: string; value: string; conditionLabel: string; iconName: string };

export const feedToFiltersArray = (feed?: FeedView): Filter[] => {
  const filters: Filter[] = [];
  if (feed?.smartFilter)
    filters.push({
      label: "Smart Filter",
      value: feed.smartFilter,
      conditionLabel: "is",
      iconName: "sparkles",
    });
  if (feed?.keywordsOr?.length)
    filters.push({
      label: "Keywords",
      value: feed.keywordsOr.join(", "),
      conditionLabel: "contains any of",
      iconName: "name",
    });
  if (feed?.keywordsAnd?.length)
    filters.push({
      label: "Keywords",
      value: feed.keywordsAnd.join(", "),
      conditionLabel: "contains all of",
      iconName: "name",
    });
  if (feed?.keywordsNot?.length)
    filters.push({
      label: "Keywords",
      value: feed.keywordsNot.join(", "),
      conditionLabel: "does not contain",
      iconName: "name",
    });

  if (feed?.jurisdictions?.length)
    filters.push({
      label: "Jurisdictions",
      value: feed.jurisdictions.map((e) => truncate(e.name, { length: 20 })).join(", "),
      conditionLabel: "in",
      iconName: "status",
    });
  // if (feed?.institutionTypes?.length)
  //   filters.push({
  //     label: "Institution Types",
  //     value: feed.institutionTypes.join(", "),
  //     conditionLabel: "is",
  //     iconName: "bars-3-bottom-left",
  //   });
  if (feed?.sections?.length)
    filters.push({
      label: "Regulations",
      value: feed.sections.map((e) => truncate(e.name, { length: 20 })).join(", "),
      conditionLabel: "is",
      iconName: "filter",
    });
  if (feed?.publishers?.length)
    filters.push({
      label: "Publishers",
      value: feed.publishers.map((e) => truncate(e.name, { length: 20 })).join(", "),
      conditionLabel: "in",
      iconName: "user",
    });
  if (feed?.tags?.length)
    filters.push({
      label: "Tags",
      value: feed.tags.map((e) => truncate(e.name, { length: 20 })).join(", "),
      conditionLabel: "in",
      iconName: "filter",
    });
  if (feed?.publicationStartDate) {
    filters.push({
      label: "Publication Date",
      value: feed?.publicationEndDate
        ? `FROM ${formatDate(feed.publicationStartDate)} \n TO ${formatDate(
            feed.publicationEndDate
          )}`
        : `FROM ${formatDate(feed.publicationStartDate)}`,
      conditionLabel: "is",
      iconName: "calendar",
    });
  }
  return filters;
};

export const dropdownFilters: DropdownItemProps[] = [
  { id: 0, title: "All", value: DropdownFilterEnum.all },
  { id: 1, title: "Only unread", value: DropdownFilterEnum.unread },
  { id: 2, title: "Only read", value: DropdownFilterEnum.read },
  { id: 3, title: "Assigned to me", value: DropdownFilterEnum.assigned },
  { id: 4, title: "Comments me", value: DropdownFilterEnum.mentioned },
  { id: 5, title: "Saved", value: DropdownFilterEnum.saved },
  { id: 6, title: "Archived", value: DropdownFilterEnum.archived },
];

export const DropdownSortEnum = {
  date: "publication_date:desc",
  relevance: "relevance",
} as const;

export type DropdownSortEnumValues = (typeof DropdownSortEnum)[keyof typeof DropdownSortEnum];

export const dropdownSortFilters: DropdownItemProps[] = [
  { id: 0, title: "Sort by date", value: DropdownSortEnum.date },
  { id: 1, title: "Sort by relevance", value: DropdownSortEnum.relevance },
];
