import { FormikProps } from "formik";
import { create } from "zustand";

import { LabeledValue } from "shared/types";

import {
  AgreementTypesProps,
  PaginatedAgreementTypeList,
} from "modules/agreements/agreements-types/types";
import {
  CategoriesResponse,
  PaginatedAgreementCategoryList,
} from "modules/agreements/categories/types";
import {
  AgreementEntityResultType,
  EntitiesProps,
  PaginatedAgreementEntityList,
} from "modules/agreements/entities/types";
import {
  AgreementFilesType,
  AgreementResultsType,
  GeographiesResultType,
  TemplateDataType,
} from "modules/agreements/overview/types";
import {
  CurrencyType,
  PaginatedAgreementList,
  SubContractorResultProps,
  ValidationType,
} from "modules/agreements/overview/types/index";
import { PaginatedAgreementSupplierList, SuppliersProps } from "modules/agreements/suppliers/types";
import {
  AgreementValidationResultType,
  PaginatedAgreementValidationList,
} from "modules/agreements/validations/types";

export const statusList: Array<LabeledValue> = [
  { id: "notSigned", name: "Not Signed" },
  { id: "signedByBothParties", name: "Signed By Both parties" },
  { id: "signedByInternalEntity", name: "Signed By InternalEntity" },
  { id: "signedBySupplier", name: "Signed By Supplier" },
  { id: "noticedByInternalEntity", name: "Noticed By InternalEntity" },
  { id: "noticedBySupplier", name: "Noticed By Supplier" },
  { id: "terminatedByInternalEntity", name: "Terminated By InternalEntity" },
  { id: "terminatedBySupplier", name: "Terminated By Supplier" },
  { id: "terminated", name: "terminated" },
];

export const supplierUnit: Array<LabeledValue> = [
  { id: "calendarDays", name: "Calendar Days" },
  { id: "businessDays", name: "Business Days" },
  { id: "months", name: "Months" },
];

export const role: Array<LabeledValue> = [
  { id: "dataController", name: "Data Controller" },
  { id: "jointController", name: "Joint Controller" },
  { id: "dataProcessor", name: "Data Processor" },
];

export const deploymentModel: Array<LabeledValue> = [
  { id: "public", name: "Public" },
  { id: "private", name: "Private" },
  { id: "hybrid", name: "Hybrid" },
  { id: "community", name: "Community" },
];
export const resolutionResilient: Array<LabeledValue> = [
  { id: "yes", name: "Yes" },
  { id: "no", name: "No" },
  { id: "partially", name: "Partially" },
];
export const degreeOfCriticality: Array<LabeledValue> = [
  { id: "low", name: "Low" },
  { id: "medium", name: "Medium" },
  { id: "high", name: "High" },
  { id: "toBeAssessed", name: "To be assessed" },
];

export const metaDataPriority: Array<LabeledValue> = [
  { id: "low", name: "Low" },
  { id: "medium", name: "Medium" },
  { id: "high", name: "High" },
];

export const riskAssessmentLevel: Array<LabeledValue> = [
  { id: "na", name: "N/A" },
  { id: "low", name: "Low" },
  { id: "limited", name: "Limited" },
  { id: "major", name: "Major" },
  { id: "severe", name: "Severe" },
  { id: "critical", name: "Critical" },
];

export const conditionsOfPayment: Array<LabeledValue> = [
  { id: "prepayment", name: "Prepayment" },
  { id: "postPayment", name: "Post payment" },
];
export const partiesTerminate: Array<LabeledValue> = [
  { id: "bothParties", name: "Both parties" },
  { id: "supplierOnly", name: "Supplier only" },
  { id: "internalEntityOnly", name: "Internal entity only" },
];
interface Agreement {
  templates: TemplateDataType;
  setTemplates: (templates: TemplateDataType) => void;

  categories: PaginatedAgreementCategoryList | null;
  setCategories: (categories: PaginatedAgreementCategoryList | null) => void;

  validations: PaginatedAgreementValidationList | null;
  setValidations: (validations: PaginatedAgreementValidationList | null) => void;

  types: PaginatedAgreementTypeList;
  setTypes: (templates: PaginatedAgreementTypeList) => void;

  currency: CurrencyType[];
  setCurrency: (currency: CurrencyType[]) => void;

  customValidation: AgreementValidationResultType[];
  setCustomValidation: (customValidation: AgreementValidationResultType[]) => void;

  internalEntities: PaginatedAgreementEntityList;
  setInternalEntities: (InternalEntities: PaginatedAgreementEntityList) => void;

  externalEntities: PaginatedAgreementSupplierList;
  setExternalEntities: (ExternalEntities: PaginatedAgreementSupplierList) => void;

  geographies: GeographiesResultType[];
  setGeographies: (geographies: GeographiesResultType[]) => void;

  agreementes: PaginatedAgreementList;
  setAgreementes: (Agreementes: PaginatedAgreementList) => void;

  currentAgreement: AgreementResultsType | null;
  setCurrentAgreement: (currentAgreement: AgreementResultsType | null) => void;

  currentSubContractor: SubContractorResultProps;
  setCurrentSubContractorAgreement: (currentSubContractor: SubContractorResultProps) => void;

  agreementFiles: AgreementFilesType[];
  setAgreementFiles: (agreementFiles: AgreementFilesType[]) => void;

  validationError: ValidationType[];
  setValidationError: (validationError: ValidationType[]) => void;

  currentSupplier: SuppliersProps;
  setCurrentSupplier: (currentSupplier: SuppliersProps) => void;

  currentEntity: AgreementEntityResultType | null;
  setCurrentEntity: (currentEntity: AgreementEntityResultType | null) => void;

  supplierFiles: AgreementFilesType[];
  setSupplierFiles: (supplierFiles: AgreementFilesType[]) => void;

  hasGridView: boolean;
  setHasGridView: (hasGridView: boolean) => void;
}

export const useAgreement = create<Agreement>((set) => ({
  templates: {} as TemplateDataType,
  setTemplates: (templates: TemplateDataType) => set({ templates }),

  categories: null,
  setCategories: (categories: PaginatedAgreementCategoryList | null) => set({ categories }),

  validations: null,
  setValidations: (validations: PaginatedAgreementValidationList | null) => set({ validations }),

  types: {} as PaginatedAgreementTypeList,
  setTypes: (types: PaginatedAgreementTypeList) => set({ types }),

  currency: [],
  setCurrency: (currency: CurrencyType[]) => set({ currency }),

  customValidation: [],
  setCustomValidation: (customValidation: AgreementValidationResultType[]) =>
    set({ customValidation }),

  internalEntities: {} as PaginatedAgreementEntityList,
  setInternalEntities: (internalEntities: PaginatedAgreementEntityList) =>
    set({ internalEntities }),

  externalEntities: {} as PaginatedAgreementSupplierList,
  setExternalEntities: (externalEntities: PaginatedAgreementSupplierList) =>
    set({ externalEntities }),

  geographies: [],
  setGeographies: (geographies: GeographiesResultType[]) => set({ geographies }),

  agreementes: {} as PaginatedAgreementList,
  setAgreementes: (agreementes: PaginatedAgreementList) => set({ agreementes }),

  currentAgreement: null,
  setCurrentAgreement: (currentAgreement: AgreementResultsType | null) => set({ currentAgreement }),

  currentSubContractor: {} as SubContractorResultProps,
  setCurrentSubContractorAgreement: (currentSubContractor: SubContractorResultProps) =>
    set({ currentSubContractor }),

  agreementFiles: [],
  setAgreementFiles: (agreementFiles: AgreementFilesType[]) => set({ agreementFiles }),

  validationError: [],
  setValidationError: (validationError: ValidationType[]) => set({ validationError }),

  currentSupplier: {} as SuppliersProps,
  setCurrentSupplier: (currentSupplier: SuppliersProps) => set({ currentSupplier }),

  currentEntity: {} as AgreementEntityResultType | null,
  setCurrentEntity: (currentEntity: AgreementEntityResultType | null) => set({ currentEntity }),

  supplierFiles: [],
  setSupplierFiles: (supplierFiles: AgreementFilesType[]) => set({ supplierFiles }),

  hasGridView: false,
  setHasGridView: (hasGridView: boolean) => set({ hasGridView }),
}));

interface CategoryEditFormStore {
  categoryEditForm: FormikProps<CategoriesResponse> | null;
  setCategoryEditForm: (categoryEditForm: FormikProps<CategoriesResponse> | null) => void;
}

export const useCategoryEditFormStore = create<CategoryEditFormStore>((set) => ({
  categoryEditForm: null,
  setCategoryEditForm: (categoryEditForm: FormikProps<CategoriesResponse> | null) =>
    set({ categoryEditForm }),
}));

interface TypesEditFormStore {
  typesEditForm: FormikProps<AgreementTypesProps> | null;
  setTypesEditForm: (typesEditForm: FormikProps<AgreementTypesProps> | null) => void;
}

export const useTypesEditFormStore = create<TypesEditFormStore>((set) => ({
  typesEditForm: null,
  setTypesEditForm: (typesEditForm: FormikProps<AgreementTypesProps> | null) =>
    set({ typesEditForm }),
}));

type SuppliersEditFormStore = {
  suppliersEditForm: FormikProps<SuppliersProps> | null;
  setSuppliersEditForm: (suppliersEditForm: FormikProps<SuppliersProps> | null) => void;
};

export const useSuppliersEditFormStore = create<SuppliersEditFormStore>((set) => ({
  suppliersEditForm: null,
  setSuppliersEditForm: (suppliersEditForm: FormikProps<SuppliersProps> | null) =>
    set({ suppliersEditForm }),
}));

type EntitiesEditFormStore = {
  entitiesEditForm: FormikProps<EntitiesProps> | null;
  setEntitiesEditForm: (entitiesEditForm: FormikProps<EntitiesProps> | null) => void;
};

export const useEntitiesEditFormStore = create<EntitiesEditFormStore>((set) => ({
  entitiesEditForm: null,
  setEntitiesEditForm: (entitiesEditForm: FormikProps<EntitiesProps> | null) =>
    set({ entitiesEditForm }),
}));
