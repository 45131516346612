import { AxiosResponse } from "axios";

import { KanbanOrderResultType, OrderUpdateTaskStatusRequestParams } from "../types";
import { createKanbanOrder } from "./createKanbanOrder";
import { updateKanbankOrder } from "./updateKanbankOrder";
import { updateTask } from "./updateTask";

export const orderUpdateTaskStatus = async (
  requestParams: OrderUpdateTaskStatusRequestParams
): Promise<AxiosResponse<KanbanOrderResultType>> => {
  const {
    team,
    statusFrom,
    taskIdsFrom,
    statusTo,
    taskIdsTo,
    requestFrom,
    requestTo,
    taskId,
    companyId,
    data,
  } = requestParams;

  const payloadFrom = {
    team,
    status: statusFrom,
    order: taskIdsFrom,
  };

  const payloadTo = {
    team,
    status: statusTo,
    order: taskIdsTo,
  };

  const params = new URLSearchParams();
  params.append("team", team.toString());

  return updateTask(
    {
      team: parseInt(`${team}`),
      id: taskId || 0,
      company: companyId,
      ...data,
    },
    {
      team: parseInt(`${team}`),
      company: companyId,
    }
  )
    .then(() => {
      if (requestFrom) return updateKanbankOrder(requestFrom?.id, payloadFrom);
      else return createKanbanOrder(payloadFrom);
    })
    .then(() => {
      if (requestTo) return updateKanbankOrder(requestTo?.id, payloadTo);
      else return createKanbanOrder(payloadTo);
    });
};
