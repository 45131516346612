import React from "react";

import { AuthHeader } from "shared/components/header";

const SignupThanks = () => {
  return (
    <>
      <AuthHeader />
      <section className="flex h-[calc(100vh-50px)] flex-col items-center justify-center">
        <h1 className="font-inter-semibold text-4xl dark:text-white">Thanks for signing up!</h1>
        <p className="mt-5 text-lg dark:text-greychateau">
          You will shortly receive a confirmation email with an activation link.
        </p>
      </section>
    </>
  );
};

export default SignupThanks;
