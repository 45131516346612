import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import {
  $createRangeSelection,
  $getRoot,
  $getSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
  LexicalEditor,
} from "lexical";
import { usePostHog } from "posthog-js/react";
import { useOnClickOutside } from "usehooks-ts";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/metadata.module.css";

import Icon from "shared/components/icon/Icon";
import { $isMentionNode } from "shared/components/lexical-editor/nodes/MentionNode";

import { createComment } from "../api";
import { CreateCommentType } from "../types";
import EmojiComponent, { emojiType } from "./EmojiComponent";
import FileCard from "./FileCard";
import ImageCard from "./ImageCard";

const SubmitContent = ({
  editor,
  refetch,
  type,
  id,
  teamId,
  companyId,
}: {
  editor: LexicalEditor;
  refetch: () => void;
  type: string;
  id: string;
  teamId: number;
  companyId: number;
}) => {
  const posthog = usePostHog();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const emojiRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeEmoji = () => {
    setIsOpen(false);
  };

  useOnClickOutside(emojiRef, closeEmoji);

  const { mutate: createCommentFn, isLoading } = useMutation(
    (data: CreateCommentType) => createComment(data),
    {
      onSuccess: () => {
        posthog.capture("commentCreated");
        toast("Comment added.", { type: "success" });
        refetch();
      },
      onError: () => {
        toast("Something went wrong.", { type: "error" });
      },
    }
  );

  // on enter submit comment
  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        if (event !== null && !event.shiftKey) {
          event.preventDefault();
          // bypass typeahead context menu
          if (document.querySelectorAll(".typeahead-popover").length > 0) {
            return false;
          }
          // submit on enter
          buttonRef.current?.click();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  const onSubmit = () => {
    editor.update(() => {
      const root = $getRoot();
      const textSize = root.getTextContentSize();
      if (textSize === 0 && files.length === 0) {
        return;
      }

      const mentions: string[] = [];
      editor.getEditorState()._nodeMap.forEach((node) => {
        if ($isMentionNode(node)) {
          mentions.push(`${node.__id}`);
        }
      });

      createCommentFn({
        team: teamId,
        comment: textSize === 0 ? "" : JSON.stringify(editor.getEditorState().toJSON()),
        type: type,
        id: id || "",
        attachments: files,
        mentions: mentions,
        company: companyId,
      });

      root.clear();
      setFiles([]);
      closeEmoji();
    });
  };

  const onEmojiSelect = (emoji: emojiType) => {
    editor.update(() => {
      const selection = $getSelection();
      if (!selection) {
        const rangeSelection = $createRangeSelection();
        $setSelection(rangeSelection);
      }
      const newSelection = $getSelection();
      newSelection?.insertText(emoji.emoji);
    });
  };

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles([...files, ...Array.from(e.target.files)]);
      if (fileRef.current) {
        fileRef.current.value = "";
      }
    }
  };

  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const isImageType = (file: File) => {
    return file.type.split("/")[0] === "image";
  };

  return (
    <>
      <div className="relative">
        <div className="editor-footer flex-col">
          <div className="">
            {files.map((file, index) => {
              if (isImageType(file)) {
                return (
                  <ImageCard
                    key={index}
                    filename={file.name}
                    url={URL.createObjectURL(file)}
                    onClose={() => removeFile(index)}
                  />
                );
              }

              return <FileCard name={file.name} key={index} onClose={() => removeFile(index)} />;
            })}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <input
                type="file"
                multiple
                name="files"
                className="hidden"
                ref={fileRef}
                onChange={onFileSelect}
              />
              <span
                className="cursor-pointer"
                onClick={() => {
                  fileRef.current?.click();
                }}
              >
                <Icon type="file-upload" fill={true} size="icon-xs" />
              </span>
              <div ref={emojiRef}>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <Icon type="emoji-happy" fill={true} size="icon-xs" />
                </span>
                {isOpen ? (
                  <div className={styles.emojiPicker}>
                    <EmojiComponent onEmojiSelect={onEmojiSelect} />
                  </div>
                ) : null}
              </div>
            </div>
            <button
              disabled={isLoading}
              ref={buttonRef}
              className={clsx("flex", {
                "cursor-not-allowed opacity-50": isLoading,
              })}
              onClick={() => {
                onSubmit();
              }}
            >
              <Icon type="airplane" fill={true} size="icon-smd" className="send_icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitContent;
