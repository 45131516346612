import clsx from "clsx";

import { ReactComponent as Filter } from "assets/images/icons/filter.svg";

export default function FilterDropDownLabel({ displayFilters }: { displayFilters: boolean }) {
  return (
    <div className={clsx(!displayFilters, "flex items-center")}>
      <Filter className="si fill mr-2 h-5 w-5" />
      <span className="font-inter-medium text-sm text-aurometalsaurus dark:text-greychateau">
        Filter
      </span>
    </div>
  );
}
