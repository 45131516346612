import axios, { AxiosError, AxiosRequestConfig } from "axios";

import {
  getRefreshToken,
  getToken,
  removeRefreshToken,
  removeToken,
  setToken,
} from "shared/helpers/util";

import serializeParams from "../helpers/serializeParams";

let retry = 0;

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v1`,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers = config.headers ?? {};
      config.headers[`Authorization`] = "Bearer " + token;
    }
    config.paramsSerializer = {
      serialize: serializeParams,
    };
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    retry = 0;
    return response;
  },
  async (err: AxiosError) => {
    const originalConfig = err.config as AxiosRequestConfig;

    if (retry >= 3) {
      removeToken();
      removeRefreshToken();
      retry = 0;
      try {
        window?.location?.replace("/signin");
      } catch (error) {
        return Promise.reject(error);
      }
      return;
    }

    if (err?.response?.status === 401) {
      retry++;
      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          removeToken();
          removeRefreshToken();
          retry = 0;
          try {
            window?.location?.replace("/signin");
          } catch (error) {
            return Promise.reject(error);
          }

          return;
        }

        const rs = await api.post("auth/token/refresh/", {
          refresh: getRefreshToken(),
        });

        if (!rs?.data) {
          throw new Error("Token refresh failed");
        }
        setToken(rs.data.access);
        return api(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }

    return Promise.reject(err);
  }
);

export default api;
