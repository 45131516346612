import { QueryParamsProps, TableFilterTypeProps } from "shared/store/table-column-selection";

import { TeamResponse } from "modules/settings/teams/types";

import { setId } from "./util";

export const resetFunctionForCommonTeam = (
  setSelectedTeam: (selectedTeam: TeamResponse) => void,
  team: TeamResponse,
  setIsSwitchTeamModalVisible: (state: boolean) => void
) => {
  setSelectedTeam(team);
  setIsSwitchTeamModalVisible(true);
};

export const resetQueryParamsForModules = (
  setCurrentTeam: (team: TeamResponse) => void,
  resetSelectedQueryParams: (params: QueryParamsProps) => void,
  team: TeamResponse
) => {
  setCurrentTeam(team);
  resetSelectedQueryParams({});
};

export const resetFiltersForModules = (
  setSelectedFilters: (fn: (params: TableFilterTypeProps | null) => TableFilterTypeProps) => void,
  setFirstFilterForModules: (
    fn: (params: TableFilterTypeProps | null) => TableFilterTypeProps
  ) => void
) => {
  setSelectedFilters(() => {
    return {} as TableFilterTypeProps;
  });
  setFirstFilterForModules(() => {
    return {} as TableFilterTypeProps;
  });
};

export const resetTeamToLocalStorage = (teamId: string, navigate: (path: string) => void) => {
  setId("teamID", teamId);
  navigate("/");
};
