import { AxiosResponse } from "axios";

import api from "shared/api/v1";

import { MyAccountProps, ProfileResponse } from "../types";

export default function updateProfile(
  data: MyAccountProps
): Promise<AxiosResponse<ProfileResponse>> {
  const formData = new FormData();
  if (data.name) {
    formData.append("name", data.name);
  }
  if (data.receive_email_notifications !== undefined) {
    formData.append("receive_email_notifications", `${data.receive_email_notifications}`);
  }
  if (data.receive_web_push_notifications !== undefined) {
    formData.append("receive_web_push_notifications", `${data.receive_web_push_notifications}`);
  }
  if (data.profile_picture) {
    formData.append("profile_picture", data.profile_picture);
  }
  if (data.show_welcome_message !== undefined) {
    formData.append("show_welcome_message", `${data.show_welcome_message}`);
  }
  return api.patch<ProfileResponse>("auth/profile/", formData);
}
