import { Tooltip } from "flowbite-react";

import { GRID_VIEW, TABLE_VIEW } from "shared/helpers/constant";
import { TableColumnSelectionProps, useTableViewType } from "shared/store/table-column-selection";

import Icon from "../icon/Icon";

const ToggleButton = ({
  viewType,
  setViewType,
  name,
}: {
  viewType: string;
  setViewType: (viewType: string) => void;
  name: string;
}) => {
  const selectedViewModules = useTableViewType((state) => state.selectedViewModules);

  const setSelectedViewForModules = useTableViewType((state) => state.setSelectedViewForModules);

  const onViewChange = () => {
    const nextView = viewType === TABLE_VIEW ? GRID_VIEW : TABLE_VIEW;
    setViewType(nextView);

    const data: TableColumnSelectionProps = Object.assign({}, selectedViewModules, {
      [name]: nextView,
    });

    setSelectedViewForModules(data);
  };

  return (
    <>
      <span className="view_icon">
        <Tooltip
          content={`Switch to ${viewType === GRID_VIEW ? "table" : "grid"} view`}
          placement="bottom"
          className="custom_tooltip w-[10rem] text-center"
        >
          <button className="maxMd:hidden" data-testid="table-toggle-icon" onClick={onViewChange}>
            {viewType === TABLE_VIEW ? (
              <Icon type="grid-view" fill={false} size="icon-md" className="tableview_icon" />
            ) : (
              <Icon type="table-view" fill={false} size="icon-md" className="table_view_icon" />
            )}
          </button>
        </Tooltip>
      </span>
    </>
  );
};

export default ToggleButton;
