import { SingleValueProps, components } from "react-select";

import Avatar from "shared/components/avatar";
import { getUsername } from "shared/helpers/util";

import { TeamUserOption } from "./types";

export default function TeamUserSingleValue(props: SingleValueProps<TeamUserOption>) {
  const { data } = props;
  const name = data.user ? getUsername(data.user) : data.team.name;
  const url = data.user ? data.user.profile_picture : data.team.team_picture;

  return (
    <components.SingleValue {...props}>
      <div className="center-align flex flex-row">
        <div className="flex flex-shrink-0 flex-row items-center">
          {data.user ? (
            <Avatar name={name} url={url} size="sm" avatarType="rounded" avatarFor="user" />
          ) : (
            <Avatar name={name} url={url} size="sm" avatarType="squared" avatarFor="team" />
          )}
        </div>
        <div className="ml-4 flex flex-row items-center">
          <div className="flex flex-row items-center text-sm font-medium">{name}</div>
        </div>
      </div>
    </components.SingleValue>
  );
}
