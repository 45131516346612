import convertToSfdt from "./convertToSfdt";
import getDocumentFileType from "./getDocumentFileType";

export default function fetchDocument(document: {
  file: string;
  filetype: string;
  filename: string;
}): Promise<string> {
  if (!document) {
    throw new Error("Document is not defined.");
  }
  const { file, filename, filetype } = document;
  if (getDocumentFileType({ filetype }) !== "document") {
    throw new Error("Passed document is not an Word document.");
  }
  return fetch(file || "", { mode: "cors" })
    .then((response) => response.blob())
    .then((blob) => new File([blob], filename || ""))
    .then((file) => convertToSfdt(file));
}
