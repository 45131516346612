import React, { ReactElement, useMemo } from "react";

import { AxiosResponse } from "axios";
import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import get from "lodash/get";

import { InfiniteQueryObserverResult } from "@tanstack/react-query";

import styles from "assets/css/agreement.module.css";

import WarningComponent from "shared/components/display-warning";
import Icon from "shared/components/icon/Icon";
import {
  AgreementFormValues,
  CommonFormType,
  PaginatedResponse,
  PaginationDropdownQueryParams,
} from "shared/types";

import SearchableDropdown from "../searchable-dropdown";
import SearchableDropdownPagination from "../searchable-dropdown-pagination";
import StaticDropdown from "./static-dropdown";

function CommonDropdownWrapper<T extends object>({
  selectedObject,
  className,
  hasPagination,
  form,
  fieldName,
  data,
  dropDownClassName,
  labelClassName,
  title,
  refetch,
  warningLabel,
  tooltip,
  placeholder,
  isLoading,
  handleChangeCallback,
  hasError,
  selectValue,
  nextPage,
  hasNextPage,
  fetchNextPage,
  formName,
  moduleName,
}: {
  selectedObject?: Pick<
    { id: number | string; name: string | ReactElement; email?: string },
    "id"
  > | null;
  hasPagination?: boolean;
  form?: CommonFormType<T>;
  fieldName?: string;
  formName?: string;
  moduleName?: string;
  data: AgreementFormValues<
    Pick<{ id: number | string; name: string | ReactElement }, "id" | "name">
  >;
  dropDownClassName?: string;
  labelClassName?: string;
  title: string | ReactElement;
  warningLabel?: string;
  tooltip?: string;
  placeholder?: string;
  isLoading?: boolean;
  className?: string;
  refetch?: (
    requestParams: PaginationDropdownQueryParams
  ) => Promise<AxiosResponse<PaginatedResponse<unknown> | unknown[]>>;
  handleChangeCallback?: (data: string | null, options?: object) => void;
  hasError?: boolean;
  selectValue?: string;
  nextPage?: string;
  setFilters?: (requestParams: PaginationDropdownQueryParams) => void;
  hasNextPage?: boolean;
  fetchNextPage?: () => Promise<
    InfiniteQueryObserverResult<AxiosResponse<unknown, unknown>, unknown>
  >;
}) {
  const value = useMemo(() => {
    return form && fieldName ? get(form?.values, fieldName?.toString()) : selectValue;
  }, [form, fieldName, selectValue]);

  return (
    <div className={clsx(styles.agreementsFormFields, "maxMd:flex-col")}>
      <div className={labelClassName}>
        <label htmlFor={fieldName?.toString()}>{title}</label>
        {typeof title === "string" && tooltip ? (
          <Tooltip content={tooltip} style="dark" className="custom_tooltip">
            <Icon type="question-outline" fill={false} size="icon-smd" />
          </Tooltip>
        ) : null}
      </div>
      <div className={clsx(styles.agreementsFormSelect)}>
        {hasPagination && refetch ? (
          <SearchableDropdown
            mainClassname={className}
            nextPage={nextPage}
            isLoading={isLoading}
            refetch={refetch}
            className={dropDownClassName}
            placeholder={placeholder}
            data={data}
            hasError={hasError}
            handleChangeSelectedOption={(option) => {
              form?.setFieldValue(`${fieldName?.toString()}_object`, option?.data || null);
            }}
            handleChange={(data, options) => {
              if (form && fieldName) {
                form?.setFieldValue(fieldName?.toString(), data);
              }
              if (handleChangeCallback) {
                handleChangeCallback(data, options);
              }
            }}
            selectedValue={selectedObject}
            value={value}
          />
        ) : hasPagination && !refetch ? (
          <SearchableDropdownPagination
            formName={formName}
            fieldName={fieldName}
            mainClassname={className}
            isLoading={isLoading}
            className={dropDownClassName}
            placeholder={placeholder}
            data={data}
            hasError={hasError}
            handleChangeSelectedOption={(option) => {
              if (form && fieldName) {
                form?.setFieldValue(`${fieldName?.toString()}_object`, option?.data || null);
              }
            }}
            handleChange={(data, options) => {
              if (form && fieldName) {
                form?.setFieldValue(fieldName?.toString(), data);
              }
              if (handleChangeCallback) {
                handleChangeCallback(data, options);
              }
            }}
            selectedValue={selectedObject}
            value={value}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            moduleName={moduleName}
          />
        ) : (
          <StaticDropdown
            isLoading={isLoading}
            mainClassname={className}
            placeholder={placeholder}
            hasError={hasError}
            handleChange={(data) => {
              if (form && fieldName) {
                form?.setFieldValue(fieldName?.toString(), data);
              }
              if (handleChangeCallback) {
                handleChangeCallback(data || null);
              }
            }}
            value={value}
            data={data}
          />
        )}
        {fieldName && !get(form?.values, fieldName?.toString()) && warningLabel ? (
          <WarningComponent className={labelClassName} warningLabel={warningLabel} />
        ) : null}
      </div>
    </div>
  );
}

export default CommonDropdownWrapper;
