import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useWizard } from "react-use-wizard";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Spinner } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import style from "assets/css/settings.module.css";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { getServerErrors } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";

import { PaginatedCompanyMember } from "modules/home/types";

import { groupInvitation } from "../api/groupInvitation";
import EmailWithRole from "../components/EmailWithRole";
import { CompanyGroupResponse, GroupInvitationInputData, GroupInvitationType } from "../types";

export default function MembersInvitation({
  group,
  groupMember,
  handleChangeMemberEmail,
  memberListing,
}: {
  group: CompanyGroupResponse | null;
  groupMember: Array<GroupInvitationType>;
  handleChangeMemberEmail: React.Dispatch<React.SetStateAction<GroupInvitationType[]>>;
  memberListing?: PaginatedCompanyMember;
}) {
  const navigate = useNavigate();

  const { previousStep } = useWizard();

  const { currentCompany } = useCompany();
  const currentTeam = useTeam((state) => state.currentTeam);

  const handleChange = (index: number, newData: GroupInvitationType) => {
    handleChangeMemberEmail((_data) => {
      return _data.map((item, i) => (i === index ? newData : item));
    });
  };

  const { isLoading, mutateAsync: inviteGroupMember } = useMutation(
    (data: GroupInvitationInputData) => {
      if (!group?.id) {
        return Promise.reject(new Error("Group is not defined"));
      }
      return groupInvitation(
        group?.id,
        { ...data },
        {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        }
      );
    },
    {
      onError: (e: AxiosError): AxiosError => {
        getServerErrors(e)?.map((err: string) => toast(err, { type: "error" }));
        return e;
      },
    }
  );

  return (
    <div className={style.group_invitation_container}>
      <h3 className="py-4 text-lg font-semibold dark:text-white">Invite to {group?.name}</h3>
      <h3 className="dark:text-white">Email Addresses</h3>
      <div className={style.email_container}>
        {groupMember.map((item, index) => (
          <EmailWithRole
            className={clsx(
              style.email_role,
              "group_select_control select-member select-team-member"
            )}
            selectedMember={groupMember
              .filter((data) => data.email.value)
              .map((data) => data.email.value)}
            data={item}
            handleChange={(data) => handleChange(index, { ...data })}
          />
        ))}
      </div>
      <button
        className={clsx("flex cursor-pointer items-center gap-2 dark:text-white", {
          "cursor-not-allowed": memberListing?.count === groupMember.length,
        })}
        disabled={memberListing?.count === groupMember.length}
        onClick={() =>
          handleChangeMemberEmail([...groupMember, { email: { label: "", value: 0 } }])
        }
      >
        <Icon type="plus" size="icon-smd" className={style.add_more_icon} />
        Add more
      </button>
      <div className={style.group_invitation_footer}>
        <button className="btn_secondary text-sm" onClick={previousStep}>
          Back
        </button>
        <div className="flex items-center gap-4">
          <Link className="dark:text-white" to={`/settings/${currentCompany.id}/groups/`}>
            Send invites later
          </Link>
          <button
            className="btn_primary"
            disabled={isLoading}
            onClick={async () => {
              const invites = groupMember
                .filter((member) => member.email.label !== "")
                .map(async (member) => {
                  if (!member.email.email) {
                    return;
                  }

                  await inviteGroupMember({
                    email: member.email.email,
                  });
                });

              await Promise.all(invites);

              if (invites.length > 0) {
                toast("Member Invite successfully.", { type: "success" });
              }
              navigate(`/settings/${currentCompany.id}/groups/`);
            }}
          >
            <Spinner
              size="md"
              light={true}
              hidden={!isLoading}
              className="mr-3 fill-crayolasblue stroke-crayolasblue"
            />
            Send Invitation
          </button>
        </div>
      </div>
    </div>
  );
}
