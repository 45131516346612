import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { useMutation } from "@tanstack/react-query";

import CommonTable from "shared/components/common-table";
import SimpleTable from "shared/components/common-table/SimpleTable";
import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { useCompany } from "shared/context/CompanyProvider";
import { tasksDownloadFields } from "shared/data/downloadFieldList";
import { GRID_VIEW, TABLE_VIEW, TASK_TABLE } from "shared/helpers/constant";
import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";
import { GetTaskResultType } from "shared/types";

import { deleteTaskById, downloadFile } from "./api";
import { TaskTitle } from "./components/TaskTitle";
import TaskFormModal from "./create/TaskFormModal";
import EditTaskModal from "./edit";
import useGetTaskById from "./hooks/useGetTaskById";
import useTaskCommonTable from "./hooks/useTaskCommonTable";
import CurrentTaskModal from "./view/CurrentTaskModal";

const Task = () => {
  const { currentTeam } = useTeam();
  const { currentCompany } = useCompany();

  const { getTaskByIdFn } = useGetTaskById();

  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  const [setCurrentTask, currentTask, tasks] = useTask((state) => [
    state.setCurrentTask,
    state.currentTask,
    state.tasks,
  ]);

  const [toBeEdited, setToBeEdited] = useState<GetTaskResultType | null>(null);

  const {
    statuses,
    statusId,
    setStatusId,
    isLoading: statusLoading,
    columns,
    filters,
    actions,
    searchFields,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    toBeDeleted,
    refetchTasks,
    refetchTasksData,
  } = useTaskCommonTable({
    onEdit: (task) => {
      setToBeEdited({
        ...task,
        tags: task?.tags_object?.map((tag) => tag.name) || task?.tags,
      });
    },
  });

  const { isLoading, mutate: downloadDocuments } = useMutation(
    ({ fields, output_format }: { fields: string; output_format: string }) =>
      downloadFile({
        fields,
        output_format,
        teamId: currentTeam?.id,
      }),
    {
      onSuccess: (res, validators) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `tasks.${validators?.output_format.toLowerCase() === "csv" ? "csv" : "xls"}`
        );
        document.body.appendChild(link);
        link.click();
      },
      onError: (error: AxiosError) => {
        toast(error?.message || "Something went wrong", {
          type: "error",
        });
      },
    }
  );

  const { isLoading: isDeleting, mutateAsync: deleteTasks } = useMutation(
    ({ taskId, teamId }: { taskId: number; teamId: number }) =>
      deleteTaskById({ taskId }, { team: teamId }),
    {
      onError: (error: AxiosError) => {
        toast(error?.message || "Something went wrong", {
          type: "error",
        });
      },
    }
  );

  return (
    <>
      <div>
        {!currentTeam.is_all_team && <TaskTitle setIsModalOpen={setIsModalOpen} />}
        <CommonTable<GetTaskResultType>
          columns={columns}
          data={tasks || null}
          filters={filters}
          actions={actions}
          refetch={refetchTasks}
          searchFields={searchFields}
          downloadFn={(fields: string, output_format: string) => {
            downloadDocuments({ fields, output_format });
          }}
          downloadFields={tasksDownloadFields}
          name={TASK_TABLE}
          displayActions={!currentTeam.is_all_team}
          displayDownload={!currentTeam.is_all_team}
          displayFavorites={!currentTeam.is_all_team}
          displayToggleButton={!currentTeam.is_all_team}
        >
          {(table, viewType) => (
            <>
              {currentTeam.is_all_team || viewType === TABLE_VIEW ? (
                <SimpleTable
                  table={table}
                  onRowClick={(id, data) => {
                    getTaskByIdFn({
                      taskId: "id" in data ? Number(data?.id) : 0,
                      team: currentTeam.id,
                      company: currentCompany.id,
                      isAllTeam: currentTeam.is_all_team,
                    }).then((response) => {
                      setCurrentTask(response?.data);
                      setIsViewModalOpen(true);
                    });
                  }}
                  isLoading={statusLoading || isDeleting || isLoading}
                />
              ) : null}
              {!currentTeam.is_all_team && viewType === GRID_VIEW ? (
                <Navigate to="/tasks/gridview" />
              ) : null}

              <ConfirmModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setIsDeleteModalOpen}
                action={async () => {
                  const promises = toBeDeleted.map((task) => {
                    return deleteTasks({
                      taskId: task?.id || 0,
                      teamId: task?.team || 0,
                    });
                  });
                  await Promise.all(promises).then(() => {
                    refetchTasksData();
                    table.toggleAllRowsSelected(false);
                    toast("Task Deleted Successfully.", { type: "success" });
                  });
                  setIsDeleteModalOpen(false);
                }}
              />
            </>
          )}
        </CommonTable>
      </div>
      {isModalOpen ? (
        <TaskFormModal
          isModalOpen={isModalOpen}
          statusId={statusId}
          statuses={statuses}
          teamId={currentTeam?.id}
          onCreate={() => {
            setIsModalOpen(false);
            refetchTasksData();
            setStatusId(0);
          }}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      ) : null}
      {isViewModalOpen ? (
        <CurrentTaskModal
          isViewModalOpen={isViewModalOpen}
          onClose={() => {
            setIsViewModalOpen(false);
          }}
          onEdit={() => {
            setIsViewModalOpen(false);
            if (currentTask) {
              setToBeEdited({
                ...currentTask,
                tags: currentTask?.tags_object?.map((tag) => tag.name) || currentTask?.tags,
              });
            } else {
              setToBeEdited(null);
            }
          }}
        />
      ) : null}
      {toBeEdited ? (
        <EditTaskModal
          isOpen={true}
          task={toBeEdited}
          teamId={currentTeam.id}
          onClose={() => {
            setIsViewModalOpen(false);
            setToBeEdited(null);
          }}
          onUpdated={() => {
            setToBeEdited(null);
            setIsViewModalOpen(false);
            refetchTasksData();
          }}
        />
      ) : null}
    </>
  );
};

export default Task;
