import React, { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { Checkbox, Modal } from "flowbite-react";
import { sortBy } from "lodash";

import styles from "assets/css/task.module.css";

import { DownloadModalProps } from "shared/types";

const DownloadModal = ({ action, isOpen, setIsOpen, column }: DownloadModalProps) => {
  const [hasToggleValue, setHasToggleValue] = useState<"xlsx" | "csv">("xlsx");
  const fieldsRef = useRef<{ label: string; id: number }[]>([]);

  const handleExportClick = () => {
    if (fieldsRef.current) {
      const fields = sortBy(fieldsRef.current, "id")
        .map((item) => item.label)
        .join(",");
      action(fields, hasToggleValue);
      setHasToggleValue("xlsx");
    }
  };

  return (
    <Modal
      show={isOpen}
      size="md"
      popup={true}
      onClose={() => {
        setIsOpen(false);
        setHasToggleValue("xlsx");
      }}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header className={clsx(styles?.downloadModalHeader, "dark:!border-thunders")}>
        <span className="font-inter-medium text-lg">Export Data</span>
      </Modal.Header>
      <Modal.Body className="!overflow-y-hidden p-4 !pb-0 !pr-0 md:!pl-[1.375rem] md:!pt-[1.375rem]">
        <div className="flex gap-4">
          <h3 className="mb-5 text-sm font-normal  text-gray-500 dark:text-greychateau">
            Export Data
          </h3>
          <div
            className="flex h-fit cursor-pointer items-center"
            onClick={() => setHasToggleValue(hasToggleValue === "csv" ? "xlsx" : "csv")}
          >
            <input
              checked={hasToggleValue === "xlsx"}
              onChange={() => ""}
              type="radio"
              value=""
              name="default-radio1"
              className="h-4 w-4 cursor-pointer border-gray-300 bg-gray-100 text-mirage focus:ring-2 focus:ring-mirage"
            />
            <label className="ml-2 cursor-pointer text-sm font-medium text-mirage dark:text-gray-300">
              EXCEL
            </label>
          </div>
          <div
            className="flex h-fit items-center"
            onClick={() => setHasToggleValue(hasToggleValue === "csv" ? "xlsx" : "csv")}
          >
            <input
              checked={hasToggleValue === "csv"}
              onChange={() => ""}
              type="radio"
              value=""
              name="default-radio"
              className="h-4 w-4 cursor-pointer border-gray-300 bg-gray-100 text-mirage focus:ring-2 focus:ring-mirage"
            />
            <label className="ml-2 cursor-pointer text-sm font-medium text-mirage dark:text-gray-300">
              CSV
            </label>
          </div>
        </div>
        <ListRenderer column={column} isOpen={isOpen} fields={fieldsRef} />
      </Modal.Body>
      <Modal.Footer className="maxMd:p-4">
        <div className="ml-auto flex gap-4 maxMd:w-full maxMd:flex-col-reverse">
          <button
            className="btn_secondary maxMd:w-full"
            onClick={() => {
              setIsOpen(false);
              setHasToggleValue("xlsx");
            }}
          >
            Close
          </button>
          <button
            className="btn_primary maxMd:w-full"
            data-testid="export_btn"
            onClick={handleExportClick}
          >
            Export
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const ListRenderer = ({
  column,
  isOpen,
  fields,
}: {
  column: { id?: number; label: string; value: string }[];
  isOpen: boolean;
  fields: React.MutableRefObject<{ label: string; id: number }[]>;
}) => {
  const [selectedColumns, setSelectedColumns] = useState<{ label: string; id: number }[]>([]);

  const handleChangeField = (name: string, id: number) => {
    const existingIndex = selectedColumns.findIndex((col) => col.id === id);
    if (existingIndex !== -1) {
      setSelectedColumns((prevSelectedColumns) => {
        const updatedColumns = [...prevSelectedColumns];
        updatedColumns.splice(existingIndex, 1);
        return updatedColumns;
      });
    } else {
      setSelectedColumns((prevSelectedColumns) => [...prevSelectedColumns, { label: name, id }]);
    }
  };
  const hasFieldValue = (name: string) => {
    return selectedColumns.some((col) => col.label === name);
  };

  fields.current = selectedColumns;

  useEffect(() => {
    if (!isOpen) {
      setSelectedColumns([]);
      fields.current = [];
    }
  }, [isOpen]);

  return (
    <div className="max-h-[16.375rem] overflow-y-auto">
      <ul>
        {column?.map(
          (
            columnName: {
              value: string;
              label: string;
            },
            index: number
          ) => {
            return (
              <li
                key={`${index}-${columnName.label}`}
                className="mb-4 flex cursor-pointer items-center gap-4"
                onClick={() => {
                  handleChangeField(columnName?.value, index);
                }}
              >
                <Checkbox className="cursor-pointer" checked={hasFieldValue(columnName?.value)} />
                <span className="text-sm text-gray-500 dark:text-greychateau">
                  {columnName?.label}
                </span>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default DownloadModal;
