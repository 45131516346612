import React from "react";

import clsx from "clsx";

const DisplayLabel = ({
  label,
  testId,
  labelClass,
}: {
  label: string;
  labelClass?: string;
  testId?: string;
}) => {
  return (
    <span
      className={clsx(labelClass, "font-inter-medium text-base text-mirage dark:text-white")}
      data-testid={testId}
    >
      {label}
    </span>
  );
};

export const ToggleSwitch = ({
  toggleIconClass,
  leftLabel,
  rightLabel,
  name,
  testId,
  checked,
  className,
  handleChange,
}: {
  toggleIconClass?: string;
  leftLabel?: string;
  rightLabel?: string;
  name: string;
  testId?: string;
  checked: boolean;
  className?: string;
  handleChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="relative flex items-start">
      {leftLabel ? <DisplayLabel label={leftLabel} testId="left_label" labelClass="mr-4" /> : null}
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          onChange={handleChange}
          checked={checked}
          type="checkbox"
          data-testid={testId}
          name={name}
          className="peer sr-only"
        />
        {toggleIconClass ? (
          <div data-testid="custom_toggle" className={toggleIconClass} />
        ) : (
          <div
            className={clsx(
              "dark:bg- peer h-6 w-11 rounded-full bg-gray-300 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none dark:border-gray-600 dark:peer-checked:bg-iridium",
              className
            )}
          />
        )}
      </label>
      {rightLabel ? (
        <DisplayLabel label={rightLabel} testId="right_label" labelClass="ml-2" />
      ) : null}
    </div>
  );
};
