import React, { useEffect } from "react";

import clsx from "clsx";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/settings.module.css";

import ToggleSwitch from "shared/components/toggle-switch";
import { IS_DARK_MODE } from "shared/helpers/constant";
import { getItemFromLocalStorage, setItemToLocalStorage } from "shared/helpers/util";

const Appearance = () => {
  const { isDarkMode, toggle } = useDarkMode();

  const onChangeMode = () => {
    toggle();
    setItemToLocalStorage(IS_DARK_MODE, isDarkMode ? "false" : "true");
  };

  useEffect(() => {
    if (getItemFromLocalStorage(IS_DARK_MODE)) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);

  return (
    <>
      <div className="flex justify-between px-4 py-4 md:px-6">
        <div>
          <h3 className="font-inter-medium text-base font-medium dark:text-white">Dark Mode</h3>
          <h3 className="text-sm text-aurometalsaurus dark:text-greychateau">
            Turn your app to light-on-dark color scheme
          </h3>
        </div>
        <ToggleSwitch
          checked={false}
          handleChange={onChangeMode}
          name="receive_email_notifications"
          toggleIconClass={clsx(styles.themeToggle, isDarkMode && styles.themeSwitchToggle)}
        />
      </div>
    </>
  );
};

export default Appearance;
