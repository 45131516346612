import { useState } from "react";

import { useAppliedFeedFilters } from "../AppliedFeedFiltersContext";
import { FeedFiltersOmitted, feedViewToFilters, initialFilters } from "../constants";
import useFeedArticles from "../hooks/useFeedArticles";
import useFeed from "../shared/hooks/useFeed";
import { DropdownFilterEnum } from "../types";

export const useFeedArticlesWithFeed = ({ feedId }: { feedId: number }) => {
  const [areFiltersReady, setAreFiltersReady] = useState(false);
  const [filters, setFilters] = useState<FeedFiltersOmitted>(initialFilters);

  const {
    appliedFilters,
    dropdownFilter,
    debouncedSearch,
    setAppliedFilters,
    setSidebarFilters,
    sortBy,
  } = useAppliedFeedFilters();
  const feedQuery = useFeed({
    feedId,
    onSuccess: (feed) => {
      const feedFilters = feedViewToFilters(feed);
      setFilters(feedFilters);
      setSidebarFilters(feedFilters);
      setAppliedFilters(feedFilters);
      setAreFiltersReady(true);
    },
  });

  const feedArticlesQuery = useFeedArticles({
    feedId,
    sortBy,
    filters: {
      ...appliedFilters,
      smartFilter: appliedFilters?.smartFilter || feedQuery?.data?.smartFilter || undefined,
      query: debouncedSearch ?? undefined,
      showOnly: dropdownFilter === DropdownFilterEnum.comments ? "mentioned" : dropdownFilter,
    },
    enabled: areFiltersReady,
  });

  return { filters, feedArticlesQuery, feedQuery };
};
