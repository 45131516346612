import React from "react";

const PendingRequest = () => {
  return (
    <span className="rounded-md border border-lightSilver px-2.5 py-1	text-santagrey">
      Pending request
    </span>
  );
};

export default PendingRequest;
