import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { useDebounce } from "usehooks-ts";

import { DropdownFilterEnum } from "modules/horizonScanning/types";

import {
  DropdownSortEnum,
  DropdownSortEnumValues,
  FeedFiltersOmitted,
  initialFilters,
} from "./constants";

type CAppliedFeedFilters = {
  appliedFilters: FeedFiltersOmitted;
  sidebarFilters: FeedFiltersOmitted;
  search: string | null;
  debouncedSearch: string | null;
  dropdownFilter: DropdownFilterEnum;
  sortBy: DropdownSortEnumValues;
  setAppliedFilters: (filters: FeedFiltersOmitted) => void;
  setSidebarFilters: (filters: FeedFiltersOmitted) => void;
  setSearch: Dispatch<SetStateAction<string | null>>;
  setDropdownFilter: (dropdownFilter: DropdownFilterEnum) => void;
  setSortBy: (sortBy: DropdownSortEnumValues) => void;
};

const AppliedFeedFiltersContext = createContext<CAppliedFeedFilters>({
  appliedFilters: {},
  sidebarFilters: initialFilters,
  search: null,
  debouncedSearch: null,
  sortBy: DropdownSortEnum.date,
  dropdownFilter: DropdownFilterEnum.all,
  setAppliedFilters: () => console.error("Missing AppliedFeedFiltersProvider"),
  setSearch: () => console.error("Missing AppliedFeedFiltersProvider"),
  setDropdownFilter: () => console.error("Missing AppliedFeedFiltersProvider"),
  setSidebarFilters: () => console.error("Missing AppliedFeedFiltersProvider"),
  setSortBy: () => console.error("Missing AppliedFeedFiltersProvider"),
});

export const AppliedFeedFiltersProvider = ({ children }: PropsWithChildren) => {
  const [sidebarFilters, setSidebarFilters] = useState<FeedFiltersOmitted>(initialFilters);
  const [appliedFilters, setAppliedFilters] = useState<FeedFiltersOmitted>({});
  const [dropdownFilter, setDropdownFilter] = useState<DropdownFilterEnum>(DropdownFilterEnum.all);
  const [sortBy, setSortBy] = useState<DropdownSortEnumValues>(DropdownSortEnum.date);
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 500);

  return (
    <AppliedFeedFiltersContext.Provider
      value={{
        appliedFilters,
        sidebarFilters,
        search,
        debouncedSearch,
        dropdownFilter,
        sortBy,
        setAppliedFilters,
        setSidebarFilters,
        setSearch,
        setDropdownFilter,
        setSortBy,
      }}
    >
      {children}
    </AppliedFeedFiltersContext.Provider>
  );
};

export const useAppliedFeedFilters = () => useContext(AppliedFeedFiltersContext);
