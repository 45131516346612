/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BlankEnum = {
    Empty: ''
} as const;
export type BlankEnum = typeof BlankEnum[keyof typeof BlankEnum];


export function instanceOfBlankEnum(value: any): boolean {
    for (const key in BlankEnum) {
        if (Object.prototype.hasOwnProperty.call(BlankEnum, key)) {
            if (BlankEnum[key as keyof typeof BlankEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function BlankEnumFromJSON(json: any): BlankEnum {
    return BlankEnumFromJSONTyped(json, false);
}

export function BlankEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlankEnum {
    return json as BlankEnum;
}

export function BlankEnumToJSON(value?: BlankEnum | null): any {
    return value as any;
}

