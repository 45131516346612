import React, { useCallback } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Modal, Spinner, Tabs } from "flowbite-react";
import toString from "lodash/toString";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import PermissionListing from "shared/components/permission";
import ServerErrors from "shared/components/server-errors";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { getCompanyUserResponseData } from "shared/helpers/getCompanyUserResponseData";
import { getServerErrors, getUsername } from "shared/helpers/util";
import useKeyPress from "shared/hooks/useKeyPress";
import { useTeam, useTeamMemberPermission } from "shared/store/settings";

import { CompanyUserResponse } from "modules/home/types";

import { updateTeamMemberPermission } from "../api";
import useGetMembers from "../hooks/useGetMembers";
import { TeamPermissionModalProps, TeamResponse } from "../types";

const TeamMemberPermission = ({ isLoading, member, memberList }: TeamPermissionModalProps) => {
  const { currentCompany } = useCompany();

  const selectedTeam = useTeam((store) => store.selectedTeam);

  const { teamMemberPermissionModal, setTeamMemberPermissionModal } = useTeamMemberPermission();

  const memberPermission = useTeamMemberPermission((state) => state.memberPermission);

  const { fetchMemberPermissionFn } = useGetMembers();

  const {
    isLoading: isUpdatePermission,
    mutate: updateTeamMemberPermissionFn,
    isError,
    error,
  } = useMutation(
    (data: TeamResponse | CompanyUserResponse) => {
      if (!selectedTeam?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }

      return updateTeamMemberPermission(selectedTeam.id, data, currentCompany.id, member?.id);
    },
    {
      onSuccess: () => {
        if (selectedTeam) {
          fetchMemberPermissionFn({ teamId: selectedTeam?.id, memberId: member?.id || 0 });
        }
        toast("Members Permission Updated Successfully", { type: "success" });
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
          return error;
        }
      },
    }
  );

  const getCurrentMember = useCallback((): CompanyUserResponse | null => {
    if (memberList) {
      const currentTeamMember: CompanyUserResponse | null =
        [...memberList].find((ele) => ele.user?.id === member?.id) || null;
      if (currentTeamMember)
        return {
          ...currentTeamMember,
          custom_permissions: memberPermission,
        };
      return null;
    }
    return null;
  }, [memberPermission, member, isUpdatePermission]);

  useKeyPress(() => setTeamMemberPermissionModal(false));

  return (
    <>
      <Modal
        show={teamMemberPermissionModal && !isLoading}
        size="xl"
        className="modal_mobileview_center"
        dismissible={true}
        onClose={() => setTeamMemberPermissionModal(false)}
      >
        <Modal.Header className={clsx(styles.manage_teams_header, "maxMd:px-4 maxMd:py-3")}>
          <Avatar
            name={getUsername(getCurrentMember()?.user)}
            avatarFor="user"
            avatarType="squared"
            size="regular"
            url={toString(getCurrentMember()?.user?.profile_picture)}
          />
          <span className="width_calc ml-3 text-lg text-mirage dark:text-white maxMd:text-sm">
            {getUsername(getCurrentMember()?.user)}
          </span>
        </Modal.Header>
        <Modal.Body className="maxMd:px-4 maxMd:py-5">
          {isUpdatePermission ? <CentralSpinner /> : null}
          <Tabs.Group
            aria-label=""
            style="underline"
            defaultValue="permission"
            className={clsx(styles.manage_teams_tabs, "permission_tabs")}
          >
            <Tabs.Item title="Permissions" id="permission">
              <div className="flex justify-center">
                <Spinner hidden={!isLoading} size="xl" />
              </div>
              {!isLoading && !isUpdatePermission ? (
                <div className="-mt-4">
                  <PermissionListing
                    data={getCompanyUserResponseData(getCurrentMember())}
                    updatePermissionFn={updateTeamMemberPermissionFn}
                  />
                  {isError && (
                    <div className="px-8">
                      <ServerErrors className="mt-4" error={error} />
                    </div>
                  )}
                </div>
              ) : null}
            </Tabs.Item>
          </Tabs.Group>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TeamMemberPermission;
