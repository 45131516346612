import { useState } from "react";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";

import { useMutation, useQuery } from "@tanstack/react-query";

import ConfirmModal from "shared/components/confirm-modal/ConfirmModal";
import { useTeam } from "shared/store/settings";
import { useTask } from "shared/store/tasks";
import { GetTaskResultType } from "shared/types";

import { deleteTaskById, getTasks } from "modules/tasks/overview/api";
import TaskFormModal from "modules/tasks/overview/create";
import EditTaskModal from "modules/tasks/overview/edit";
import { RelatedObjectType } from "modules/tasks/overview/types";
import CurrentTaskModal from "modules/tasks/overview/view/CurrentTaskModal";
import { getStatus } from "modules/tasks/statuses/api";

import { MetaTable } from "../types";
import ScheduledTasks from "./ScheduledTasks";

export type ScheduledTasksContainerProps = {
  type: MetaTable;
  relatedObject: RelatedObjectType;
};

function ScheduledTasksContainer({ type, relatedObject }: ScheduledTasksContainerProps) {
  const { currentTask, setCurrentTask } = useTask();
  const { currentTeam } = useTeam();

  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState<boolean>(false);
  const [isViewTaskModalOpen, setIsViewTaskModalOpen] = useState<boolean>(false);
  const [toBeEditedTask, setToBeEditedTask] = useState<GetTaskResultType | null>(null);
  const [toBeDeletedTask, setToBeDeletedTask] = useState<GetTaskResultType | null>(null);

  const {
    isFetching: statusFetching,
    isLoading: statusLoading,
    data: statuses,
  } = useQuery(
    ["getStatus", currentTeam?.company, currentTeam?.id],
    () =>
      getStatus(
        {},
        {
          team: {
            team: currentTeam?.id,
            company: currentTeam.company,
            isAllTeam: currentTeam.is_all_team,
          },
        }
      ),
    {
      enabled: !!currentTeam?.id,
    }
  );

  const {
    data,
    refetch: refetchTasksData,
    isFetching,
  } = useQuery(["getScheduledTasks", type, relatedObject?.id], () =>
    getTasks(
      {
        [type]: relatedObject?.id,
      },
      {
        team: {
          team: currentTeam.id,
          company: currentTeam.company,
          isAllTeam: currentTeam.is_all_team,
        },
      }
    )
  );
  const { mutate: deleteTaskFn } = useMutation(
    ({ taskId }: { taskId: number }) => deleteTaskById({ taskId }, { team: currentTeam.id }),
    {
      onSuccess: () => {
        toast("Scheduled Task deleted successfully", { type: "success" });
        refetchTasksData();
        setToBeDeletedTask(null);
      },
      onError: () => {
        toast("Something went wrong!", { type: "error" });
      },
    }
  );

  return (
    <>
      {isCreateTaskModalOpen
        ? createPortal(
            <TaskFormModal
              isModalOpen={isCreateTaskModalOpen}
              relatedObject={relatedObject}
              relatedObjectType={type}
              teamId={currentTeam.id}
              statuses={statuses?.data.results}
              onCreate={() => {
                refetchTasksData();
                setIsCreateTaskModalOpen(false);
              }}
              onClose={() => {
                setIsCreateTaskModalOpen(false);
              }}
              statusId={statuses?.data.results?.[0]?.id}
            />,
            document.body
          )
        : null}
      {isViewTaskModalOpen
        ? createPortal(
            <CurrentTaskModal
              isViewModalOpen={isViewTaskModalOpen}
              relatedObject={relatedObject}
              onClose={() => {
                refetchTasksData();
                setIsViewTaskModalOpen(false);
                setCurrentTask(null);
              }}
              onEdit={() => {
                setIsViewTaskModalOpen(false);
                if (currentTask) {
                  setToBeEditedTask({
                    ...currentTask,
                    tags: currentTask.tags_object?.map((tag) => tag.name) || currentTask.tags,
                  });
                } else {
                  setToBeEditedTask(null);
                }
              }}
            />,
            document.body
          )
        : null}
      {toBeEditedTask
        ? createPortal(
            <EditTaskModal
              isOpen={!!toBeEditedTask}
              task={toBeEditedTask}
              relatedObject={relatedObject}
              relatedObjectType={type}
              teamId={currentTeam.id}
              onClose={() => {
                refetchTasksData();
                setIsViewTaskModalOpen(false);
                setToBeEditedTask(null);
              }}
              onUpdated={() => {
                refetchTasksData();
                setIsViewTaskModalOpen(false);
                setCurrentTask(null);
                setToBeEditedTask(null);
              }}
            />,
            document.body
          )
        : null}
      {toBeDeletedTask
        ? createPortal(
            <ConfirmModal
              isOpen={!!toBeDeletedTask}
              text="Are you sure you want to delete this task?"
              setIsOpen={(isOpen) => {
                if (!isOpen) {
                  setToBeDeletedTask(null);
                }
              }}
              action={() =>
                deleteTaskFn({
                  taskId: toBeDeletedTask.id,
                })
              }
            />,
            document.body
          )
        : null}
      <ScheduledTasks
        type={type}
        isLoading={isFetching || statusLoading || statusFetching}
        taskList={data?.data}
        relatedObject={relatedObject}
        onCreateTask={() => setIsCreateTaskModalOpen(true)}
        onTaskClick={(task) => {
          setCurrentTask(task);
          setIsViewTaskModalOpen(true);
        }}
        onTaskEdit={(task) => {
          if (task) {
            setToBeEditedTask({
              ...task,
              tags: task.tags_object?.map((tag) => tag.name) || task.tags,
            });
          } else {
            setToBeEditedTask(null);
          }
        }}
        onTaskDelete={setToBeDeletedTask}
      />
    </>
  );
}

export default ScheduledTasksContainer;
