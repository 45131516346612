import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import clsx from "clsx";

import styles from "assets/css/sidebar.module.css";

import { RISK_MODULE, RISK_MODULE_V2 } from "shared/helpers/ability";
import { MOBILE_DEVICE, subMenuOverview } from "shared/helpers/constant";
import useModuleEnabled from "shared/hooks/useModuleEnabled";
import useScreen from "shared/hooks/useScreen";
import { useSidebar } from "shared/store/sidebar";

import { getSidebarItemIcon } from "../icons/Icons";
import { RenderListProps, itemTyps } from "./types";

export default function RenderList({ ele, index, onClick, isSelected }: RenderListProps) {
  const deviceType = useScreen();

  const { checkEnabled } = useModuleEnabled();

  const { pathname } = useLocation();

  const setIsVisible = useSidebar((state) => state.setIsVisible);

  const onLinkClick = () => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    }
    return;
  };

  const module =
    ele?.subject === RISK_MODULE && ele?.path?.startsWith("/workspace/")
      ? RISK_MODULE_V2
      : ele?.subject;

  if (module && !checkEnabled(module)) {
    return null;
  }

  return (
    <li key={index}>
      {/* Dashboard Item */}
      <Link
        to={ele?.path || pathname}
        onClick={() => {
          onClick(index);
        }}
        className={clsx(styles.sidebarMenu, {
          nav_active:
            (pathname?.includes(ele?.path || "") && !!ele?.path?.slice(1)) ||
            (ele?.label === "Dashboard" && pathname === "/dashboard/overview"),
        })}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex w-full items-center">
            <div className="flex min-w-[1.5rem] flex-shrink-0 items-center justify-center">
              <span className="inline-flex">{getSidebarItemIcon(ele.label)}</span>
            </div>
            <span className={clsx(styles.sidebarMenuItem, "dark:text-greychateau")}>
              {ele.label}
            </span>
          </div>
          {ele?.isBeta ? (
            <div
              className={clsx(
                styles.sidebarMenuItem,
                "flex items-center rounded-md bg-transparent px-1.5 py-0.5 outline outline-1 outline-santagrey dark:outline-gray_400"
              )}
            >
              <h4 className="font-inter-regular text-xs font-normal !text-aurometalsaurus dark:!text-battleshipgray">
                Beta
              </h4>
            </div>
          ) : null}
        </div>

        {ele.items ? (
          <svg
            sidebar-toggle-item=""
            className={clsx(styles.sidebarMenuArrow, {
              "rotate-180": ele.isOpen || isSelected,
            })}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        ) : null}
      </Link>

      {/* Dashboard Item */}
      {ele.items ? (
        <ul
          id="dropdown-example"
          className={clsx(
            { hidden: !(ele.isOpen || isSelected), block: ele.isOpen || isSelected },
            "md:space-y-1.5 md:py-2"
          )}
        >
          {ele.items.map((item: itemTyps, itemIndex: number) => (
            <li key={itemIndex}>
              <Link
                to={item?.path || pathname}
                className={clsx(
                  "light:text-white light:hover:bg-gray-700 group flex w-full items-center border-antiflashwhite p-1.5 py-3 pl-12 text-base font-normal text-aurometalsaurus transition duration-75 hover:bg-gray-100 dark:text-greychateau dark:hover:bg-balticsea dark:hover:text-white md:rounded-lg md:py-1 md:pl-[38px] maxMd:border-b maxMd:dark:border-thunders",
                  {
                    nav_active:
                      pathname?.includes(item?.path as string) ||
                      (subMenuOverview?.includes(pathname?.split("/")[2]) &&
                        subMenuOverview?.includes(item?.path?.split("/")[2])),
                  }
                )}
                onClick={onLinkClick}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
}
