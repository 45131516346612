import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AxiosError } from "axios";
import { Label } from "flowbite-react";
import { Spinner, TextInput } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import { AuthHeader } from "shared/components/header";
import ServerErrors from "shared/components/server-errors";

import { resetPassword } from "./api";
import useResetPassword from "./hooks/useResetPassword";
import { ResetPasswordRequestProps } from "./types";

const ResetPassword = () => {
  const { uid, token } = useParams();

  const navigate = useNavigate();

  const {
    mutate: resetPasswordFn,
    isError,
    error,
  } = useMutation((data: ResetPasswordRequestProps) => resetPassword(data), {
    onSuccess: () => {
      navigate("/", { replace: true });
    },
    onError: (e: AxiosError): AxiosError => e,
  });

  const { handleSubmit, values, errors, handleChange } = useResetPassword(() =>
    resetPasswordFn({ ...values, uid, token })
  );

  return (
    <>
      <AuthHeader />
      <section className="auth-container">
        <div className="card card-auth">
          <h2 className="md:title-lg mb-4 font-inter-bold text-3xl md:mb-10">
            Reset Your Password
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Label value="New Password" className="mb-2 block font-inter-medium !text-mirage" />
              <TextInput
                type="password"
                placeholder="Enter password"
                value={values.new_password1}
                name="new_password1"
                onChange={handleChange}
                color={errors.new_password1 ? "failure" : "gray"}
              />
            </div>
            <div className="mb-4">
              <Label
                value="Confirm New Password"
                className="mb-2 block font-inter-medium !text-mirage"
              />
              <TextInput
                type="password"
                placeholder="Enter confirm password"
                value={values.new_password2}
                name="new_password2"
                onChange={handleChange}
                color={errors.new_password2 ? "failure" : "gray"}
              />
            </div>

            {isError ? <ServerErrors className="mb-4" error={error} /> : null}
            <button className="btn_primary w-full font-inter-medium text-base md:py-3.5">
              <Spinner
                size="md"
                light={true}
                hidden={true}
                className="mr-3 fill-crayolasblue stroke-crayolasblue"
              />
              Reset Password
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
