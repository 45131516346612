import { useArticleContext } from "../../contexts/ArticleContext";
import ArticleSection from "./ArticleSection";

enum ArticleClassificationsLabels {
  relevantRegulations = "Relevant Regulations",
  mitigatingEfforts = "Mitigating Efforts",
  potentialImpact = "Potential Impact",
  timeline = "Timeline",
  institutionTypes = "Institution Types",
  regulatoryJurisdiction = "Regulatory Jurisdictions",
}

const ArticleClassifications = () => {
  const {
    articleQuery: { data: article },
    isPublic,
  } = useArticleContext();

  const addSeparator = (data?: string | null | string[]) => {
    if (Array.isArray(data)) return data.join(" | ");
    return data?.split(" ").join(" | ");
  };

  if (!article) return null;

  const classifications = [
    {
      title: ArticleClassificationsLabels.relevantRegulations,
      classification: addSeparator(article.sections.map((section) => section.name)),
    },
    {
      title: ArticleClassificationsLabels.potentialImpact,
      classification: article.potentialImpact,
    },
    {
      title: ArticleClassificationsLabels.mitigatingEfforts,
      classification: article.mitigatingEfforts,
    },
    {
      title: ArticleClassificationsLabels.timeline,
      classification: article.timeline,
    },
    {
      title: ArticleClassificationsLabels.institutionTypes,
      classification: addSeparator(article.institutionTypes),
    },
    {
      title: ArticleClassificationsLabels.regulatoryJurisdiction,
      classification: addSeparator(article.regions.map((region) => region.name)),
    },
  ];

  return (
    <ArticleSection title={isPublic ? undefined : "Classifications"} icon="squares-check">
      <div className="grid gap-2">
        {classifications.map(({ classification, title }) =>
          classification ? (
            <div
              key={title}
              className="grid gap-2 rounded-lg border border-antiflashwhite bg-ghostwhite p-4 font-medium dark:border-jet dark:bg-darkjunglegreenss"
            >
              <span className="text-xs text-aurometalsaurus dark:text-gray-400">{title}</span>
              <span className="text-sm text-richblack dark:text-gray-500">{classification}</span>
            </div>
          ) : null
        )}
      </div>
    </ArticleSection>
  );
};

export default ArticleClassifications;
