import React, { useState } from "react";

import clsx from "clsx";

import truncateArr from "shared/helpers/truncateArr";

export type ExpandableTagsProps = {
  tags: string[];
  limit: number;
  tagsClassName?: string;
};

function ExpandableTags({ tags, limit, tagsClassName }: ExpandableTagsProps) {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {truncateArr(tags, expanded ? tags.length : limit).map((tag) => (
        <span
          key={tag}
          className={clsx(
            "mt mr-1 rounded-md bg-brightgrey px-2.5 py-1 text-xs text-white dark:bg-thunders",
            tagsClassName
          )}
        >
          {tag}
        </span>
      ))}
      {tags.length > limit ? (
        <button
          type="button"
          className="text-sm font-medium text-santagrey dark:text-stormdust"
          onClick={handleExpand}
        >
          {expanded ? "Show less" : `+${tags.length - limit} more`}
        </button>
      ) : null}
    </div>
  );
}

export default ExpandableTags;
