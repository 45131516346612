import { create } from "zustand";
import { persist } from "zustand/middleware";

import { VendorListResponse } from "modules/vendors/listing/types";
import { VendorResultsType } from "modules/vendors/types";

interface Vendor {
  currentVendor: VendorResultsType | null;
  setCurrentVendor: (currentVendor: VendorResultsType | null) => void;
  currentVendorList: VendorListResponse | null;
  setCurrentVendorList: (currentVendorList: VendorListResponse | null) => void;
  resetCurrentVendorList: (currentVendorList: VendorListResponse | null) => void;
}

export const useVendor = create<Vendor>()(
  persist(
    (set) => ({
      currentVendor: null,
      setCurrentVendor: (currentVendor: VendorResultsType | null) => set({ currentVendor }),

      currentVendorList: null,
      setCurrentVendorList: (currentVendorList: VendorListResponse | null) =>
        set({ currentVendorList }),
      resetCurrentVendorList: (currentVendorList: VendorListResponse | null) =>
        set({ currentVendorList }),
    }),
    {
      name: "vendor-list-storage",
      partialize: (state) => ({ currentVendorList: state.currentVendorList }),
    }
  )
);
