import React from "react";

import Spinner from "./Spinner";

const CentralSpinner = () => {
  return (
    <div
      data-testid="central-spinner"
      className="fixed inset-0 z-10 flex items-center justify-center "
    >
      <Spinner />
    </div>
  );
};

export default CentralSpinner;
