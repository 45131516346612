import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import clsx from "clsx";
import get from "lodash/get";
import has from "lodash/has";
import moment from "moment";
import { useUpdateEffect } from "usehooks-ts";

import {
  PaginationState,
  SortingState,
  Table,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import headerStyles from "assets/css/common-header.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  DEFAULT_PAGE_SIZE,
  GRID_VIEW,
  MOBILE_DEVICE,
  TABLE_VIEW,
} from "shared/helpers/constant";
import useScreen from "shared/hooks/useScreen";
import useColumnStore from "shared/store/columnStore";
import { useDocuments } from "shared/store/documents";
import {
  SelectedColumnModulesProps,
  useQueryParams,
  useSearchSuggestion,
  useTableColumnSelection,
  useTableFilter,
  useTableViewType,
} from "shared/store/table-column-selection";

import Actions from "./Actions";
import ColumnSelection from "./ColumnSelection";
import DisplayFilters from "./DisplayFilters";
import Download from "./Download";
import Favorites from "./Favorites";
import Filters from "./Filters";
import Pagination from "./Pagination";
import PredefinedDateFilterInterval from "./PredefinedDateFilterInterval";
import Search from "./Search";
import ToggleButton from "./ToggelButton";
import { PAGINATION, SEARCH, SORTING } from "./constants";
import { getRefetchOptionsFromSelectProps } from "./helpers";
import ActionMobileView from "./mobile-views/actions/ActionMobileView";
import FavoriteMobileView from "./mobile-views/favorites/FavoriteMobileView";
import FiltersMobileView from "./mobile-views/filters/FiltersMobileView";
import { CommonTableProps, RefetchOptions, SelectProps } from "./types";

const genericMemo: <T>(component: T) => T = memo;

function CommonTable<TData>({
  columns,
  data,
  filters,
  actions,
  refetch,
  displayHeader = true,
  displayPagination = true,
  displaySearch = true,
  displayFilters = true,
  displayActions = true,
  displayFavorites = true,
  displayDownload = true,
  displayColumnSelection = true,
  displayInnerBlock = true,
  downloadFn,
  name,
  displayToggleButton = true,
  children,
  downloadFields,
  isLoading,
  customTitle,
  magicWandProps,
}: CommonTableProps<TData>) {
  const deviceType = useScreen();

  const selectedFilters = useTableFilter((state) => state.selectedFilters);

  const { pathname } = useLocation();
  const selectedDocuments = useDocuments((state) => state.selectedDocuments);
  const [selectedColumnModules, setSelectedColumnForModules] = useTableColumnSelection((state) => [
    state.selectedColumnModules,
    state.setSelectedColumnForModules,
  ]);

  const [selectedQueryParams, setSelectedQueryParams] = useQueryParams((state) => [
    state.selectedQueryParams,
    state.setSelectedQueryParams,
  ]);

  const searchData = useSearchSuggestion((state) => state.searchData);

  const [searchText, setSearchText] = useState<string>(get(searchData, name)?.value || "");

  const changeSearchText = useCallback((value: string) => setSearchText(value), []);

  const handleSearchChanged = (searchedData: string) => {
    let allSearchQueryParams: RefetchOptions[] = get(selectedQueryParams, name) || [];
    allSearchQueryParams = allSearchQueryParams?.map((item: RefetchOptions) =>
      item?.field === "page" ? { ...item, value: "1" } : item
    );

    setSelectedQueryParams({ [name]: allSearchQueryParams });
    changeSearchText(searchedData);
  };

  const selectedViewModules = useTableViewType((state) => state.selectedViewModules);

  const { currentCompany } = useCompany();

  const [columnVisibility, setColumnVisibility] = React.useState(
    get(selectedColumnModules, name, {})
  );

  const [viewType, setViewType] = useState<string>(
    get(
      selectedViewModules,
      name,
      ["risk", "incident", "mitigation", "agreement"].includes(name) ? TABLE_VIEW : GRID_VIEW
    )
  );

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex:
      parseInt(
        get(selectedQueryParams, name)?.find((item: RefetchOptions) => item?.field === "page")
          ?.value || "1"
      ) - 1 || 0,
    pageSize: data?.page_limit || DEFAULT_PAGE_SIZE,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex:
        (parseInt(
          get(selectedQueryParams, name)?.find((item: RefetchOptions) => item?.field === "page")
            ?.value || "1"
        ) - 1 || 0) + 1,
      pageSize,
    }),
    [pageIndex, pageSize, selectedQueryParams]
  );

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const defaultData = React.useMemo(() => [], []);

  const table: Table<TData> = useReactTable({
    data: data?.results || defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      pagination,
      sorting,
      columnVisibility,
    },
    pageCount: data ? Math.ceil(data?.count / (data.page_limit || DEFAULT_PAGE_SIZE)) : 0,
    onSortingChange: setSorting,
  });

  useEffect(() => {
    prepareQuery({
      field: "page",
      value: `${pageIndex + 1}`,
      type: PAGINATION,
    });
  }, [pageIndex]);

  useEffect(() => {
    if (sorting?.length) {
      const first = sorting[0];
      const fieldName: string = first.id.split("_hide")[0] || "";

      prepareQuery({
        field: "ordering",
        value: `${first.desc ? "-" : ""}${fieldName}`,
        type: SORTING,
      });
    }
  }, [sorting]);

  useUpdateEffect(() => {
    const filtersData: SelectProps[] = get(selectedFilters, name, []);
    const moduleFilters: SelectProps[] = filtersData?.map((item) => {
      return {
        ...item,
        conditions: (item.conditions || []).map((condition) => {
          if (
            (condition.operator == AFTER_OPERATOR || condition.operator == BEFORE_OPERATOR) &&
            ["this_week", "this_month", "last_7_days", "last_30_days"].includes(condition.value)
          ) {
            const value = PredefinedDateFilterInterval({
              operator: condition.operator,
              type: condition.value,
            });
            return {
              ...condition,
              value: value ? moment(value).format("YYYY-MM-DD") : "",
            };
          } else return condition;
        }),
      };
    });
    const searchQueries = getRefetchOptionsFromSelectProps(moduleFilters);
    prepareQueries(searchQueries);
  }, [get(selectedFilters, name)]);

  const prepareQuery = (refetchOption: RefetchOptions) => {
    const _filters: Array<RefetchOptions> =
      get(selectedQueryParams, name)?.filter(
        (_filter: RefetchOptions) => _filter.field !== refetchOption.field
      ) ||
      get(selectedQueryParams, name) ||
      [];
    setSelectedQueryParams({ [name]: [..._filters, refetchOption] });
  };

  const prepareQueries = (refetchOptions: RefetchOptions[]) => {
    const _filters =
      get(selectedQueryParams, name)
        ?.filter((item: RefetchOptions) => item.field !== "page")
        .filter((_filter: RefetchOptions) => _filter.type !== SEARCH) ||
      get(selectedQueryParams, name) ||
      [];

    setSelectedQueryParams({
      [name]: [
        ..._filters,
        ...refetchOptions,
        {
          field: "page",
          value: "1",
          type: PAGINATION,
        },
      ],
    });
  };

  // refetch data
  useEffect(() => {
    let allSearchQueryParams: RefetchOptions[] = get(selectedQueryParams, name) || [];

    const searchItem = allSearchQueryParams.findIndex(
      (item: RefetchOptions) => item?.field === "search"
    );
    if (searchText.length > 0) {
      if (searchItem === -1) {
        const searchQueryParam = [{ field: "search", value: searchText, type: SEARCH }];
        allSearchQueryParams = [...allSearchQueryParams, ...searchQueryParam];
      } else {
        allSearchQueryParams[searchItem] = { field: "search", value: searchText, type: SEARCH };
      }
    } else if (allSearchQueryParams?.length > 0) {
      allSearchQueryParams = allSearchQueryParams?.filter(
        (item: RefetchOptions) => item?.field !== "search"
      );
    }

    setPagination({
      pageIndex:
        parseInt(
          allSearchQueryParams?.find((item: RefetchOptions) => item?.field === "page")?.value || "1"
        ) - 1 || 0,
      pageSize: data?.page_limit || DEFAULT_PAGE_SIZE,
    });

    refetch && refetch(allSearchQueryParams);
  }, [get(selectedQueryParams, name), searchText]);

  // function to save the preference for column at localstorage

  const [companyColumnPreferences, setCompanyColumnPreferences] = useColumnStore((state) => [
    state.companyColumnPreferences,
    state.setCompanyColumnPreferences,
  ]);

  useEffect(() => {
    // Save column preferences for the current company
    const currentPreferences = get(companyColumnPreferences, currentCompany?.id, {});
    const updatedPreferences = {
      ...currentPreferences,
      [name]: columnVisibility,
    };
    if (updatedPreferences) {
      setCompanyColumnPreferences(currentCompany?.id, updatedPreferences);
    }
  }, [columnVisibility]);

  useEffect(() => {
    const companyPreferences = get(companyColumnPreferences, currentCompany?.id);
    // Apply column preferences for the current company
    table.getAllColumns().forEach((column) => {
      if (has(companyPreferences, [name, column.id])) {
        column.toggleVisibility(get(companyPreferences, [name, column.id], false));
      } else if (column?.id.includes("_hide")) {
        column.toggleVisibility(false);
      } else {
        column.toggleVisibility(true);
      }
    });
  }, []);

  // set column selection in store
  useEffect(() => {
    const data: SelectedColumnModulesProps = Object.assign({}, selectedColumnModules, {
      [name]: columnVisibility,
    });
    setSelectedColumnForModules(data);
  }, [columnVisibility]);

  // documents module has diff table so need to handle selection of row.
  const isDocumentSelected = selectedDocuments.length > 0 && pathname.includes("documents");

  return (
    <>
      {customTitle ? customTitle(table) : null}

      {displayHeader ? (
        <div className={clsx(headerStyles.subscriptionHeader)}>
          {displayInnerBlock ? (
            <>
              <div className={headerStyles.subscriptionBody}>
                <div className={headerStyles.innerBlock}>
                  {displaySearch ? (
                    <Search onChange={handleSearchChanged} searchText={searchText} name={name} />
                  ) : null}

                  {deviceType === MOBILE_DEVICE ? (
                    <FiltersMobileView
                      name={name}
                      filters={filters}
                      displayFilters={displayFilters}
                    />
                  ) : (
                    <Filters
                      magicWandProps={magicWandProps}
                      name={name}
                      filters={filters}
                      displayFilters={displayFilters}
                    />
                  )}

                  {displayActions &&
                  deviceType !== MOBILE_DEVICE &&
                  (table.getIsSomeRowsSelected() ||
                    table.getIsAllRowsSelected() ||
                    isDocumentSelected) ? (
                    <Actions<TData> actions={actions} table={table} />
                  ) : null}

                  {displayActions &&
                  deviceType === MOBILE_DEVICE &&
                  (table.getIsSomeRowsSelected() ||
                    table.getIsAllRowsSelected() ||
                    isDocumentSelected) ? (
                    <ActionMobileView actions={actions} table={table} />
                  ) : null}

                  {deviceType === MOBILE_DEVICE ? (
                    <FavoriteMobileView
                      type={name}
                      filterOptions={filters}
                      displayFavorites={displayFavorites}
                      searchText={searchText}
                    />
                  ) : (
                    <Favorites
                      type={name}
                      filterOptions={filters}
                      displayFavorites={displayFavorites}
                      searchText={searchText}
                    />
                  )}
                </div>
                <div className="header_iconfix flex items-center gap-3  md:mt-1.5 maxMd:gap-0">
                  {displayDownload && downloadFn && downloadFields ? (
                    <Download downloadFn={downloadFn} downloadFields={downloadFields} />
                  ) : null}

                  {displayPagination && !isLoading && data && data?.results?.length > 0 ? (
                    <Pagination table={table} className="pagination-btn block maxMd:!hidden" />
                  ) : null}

                  {displayToggleButton ? (
                    <ToggleButton name={name} viewType={viewType} setViewType={setViewType} />
                  ) : null}

                  {displayColumnSelection && viewType === TABLE_VIEW ? (
                    <ColumnSelection table={table} />
                  ) : null}
                </div>
              </div>
              <DisplayFilters name={name} />
            </>
          ) : null}
        </div>
      ) : null}
      {children ? children(table, viewType) : null}
    </>
  );
}

export default genericMemo(CommonTable);
