import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { show_all_objects } from "shared/helpersV2/constant";

import { DownloadAgreementsProps, GetAllAgreementsRequestParams } from "../types";

export default function downloadAgreements(
  requestParams: GetAllAgreementsRequestParams,
  params: DownloadAgreementsProps
): Promise<AxiosResponse<Blob>> {
  return api.get<Blob>(`/agreements/export/`, {
    params: {
      ...params,
      ...hasNameOrdering(requestParams),
      show_all_objects,
    },
    responseType: "blob",
  });
}
