import csv from "assets/images/documents/csv-icon.svg";
import docx from "assets/images/documents/docx-icon.svg";
import folder from "assets/images/documents/folder.svg";
import htm from "assets/images/documents/htm-icon.svg";
import html from "assets/images/documents/html-icon.svg";
import jpg from "assets/images/documents/jpg-icon.svg";
import json from "assets/images/documents/json-icon.svg";
import pdf from "assets/images/documents/pdf-icon.svg";
import png from "assets/images/documents/png-icon.svg";
import ppt from "assets/images/documents/ppt-icon.svg";
import rar from "assets/images/documents/rar-icon.svg";
import txt from "assets/images/documents/txt-icon.svg";
import xbrl from "assets/images/documents/xbrl-icon.svg";
import xhtml from "assets/images/documents/xhtml-icon.svg";
import xlsm from "assets/images/documents/xlsm-icon.svg";
import xlsx from "assets/images/documents/xlsx-icon.svg";
import xml from "assets/images/documents/xml-icon.svg";
import zip from "assets/images/documents/zip-icon.svg";

import { BreadCrumbTreeProps } from "shared/components/bread-crumb/types";
import { ActionsType } from "shared/components/common-table/types";
import { DirectoryTreeProps } from "shared/store/documents";

import { FilterMenuType } from "modules/articles/subscription/types";

export const getDocumentIcon = (extension: string | undefined) => {
  switch (extension) {
    case "pdf":
      return pdf;
    case "docx":
      return docx;
    case "doc":
      return docx;
    case "png":
      return png;
    case "xls":
      return xlsx;
    case "xlsx":
      return xlsx;
    case "ppt":
      return ppt;
    case "txt":
      return txt;
    case "jpg":
      return jpg;
    case "jpeg":
      return jpg;
    case "csv":
      return csv;
    case "xlsm":
      return xlsm;
    case "xlsb":
      return xlsx;
    case "xltx":
      return xlsx;
    case "xltm":
      return xlsx;
    case "pptx":
      return ppt;
    case "zip":
      return zip;
    case "rar":
      return rar;
    case "xml":
      return xml;
    case "json":
      return json;
    case "html":
      return html;
    case "htm":
      return htm;
    case "xbrl":
      return xbrl;
    case "xhtml":
      return xhtml;
    default:
      break;
  }
};

const filterSubMenu = ["Commented", "Assigned", "Attachments", "Due date", "importance", "Tagged"];
export const filterMenu: FilterMenuType[] = [
  {
    key: "Action type",
    value: filterSubMenu,
  },
  {
    key: "Team",
    value: filterSubMenu,
  },
  {
    key: "Team Tag",
    value: filterSubMenu,
  },
  {
    key: "Assigned to",
    value: filterSubMenu,
  },
  {
    key: "Commented by",
    value: filterSubMenu,
  },
  {
    key: "Attached by",
    value: filterSubMenu,
  },
  {
    key: "Importance",
    value: filterSubMenu,
  },
  {
    key: "Due date",
    value: filterSubMenu,
  },
  {
    key: "Action date",
    value: filterSubMenu,
  },
  {
    key: "Publication date",
    value: filterSubMenu,
  },
];

export const documentActions: ActionsType<{ key: string; icon: string }>[] = [
  { key: "Move to diffrent location", icon: folder },
];

const validExtensions = [
  "pdf",
  "doc",
  "docx",
  "jpg",
  "png",
  "xlsx",
  "xls",
  "txt",
  "jpeg",
  "csv",
  "xlsm",
  "xlsb",
  "xltx",
  "xltm",
  "ppt",
  "pptx",
  "zip",
  "rar",
  "xml",
  "json",
  "html",
  "htm",
  "xbrl",
  "xhtml",
];

export const isFileValid = (filename: string) => {
  const fileExt = filename?.split(".").pop() || "";

  if (validExtensions.includes(fileExt)) {
    return true;
  }
};

export const findNodeWithParents = (
  nodes: DirectoryTreeProps[],
  id: number | null,
  parents: DirectoryTreeProps[] = []
): BreadCrumbTreeProps[] => {
  for (const node of nodes) {
    if (node.id === id) {
      return [...parents, node]?.map((res) => {
        return { ...res, link: `/documents/${res.id}` };
      }); // return an array of all parent nodes and the matching node
    } else if (node.children && node.children.length > 0) {
      const result = findNodeWithParents(node.children, id, [...parents, node]); // recursively search the child nodes
      if (result.length > 0) {
        return result?.map((res) => {
          return { ...res, link: `/documents/${res.id}` };
        });
      }
    }
  }
  return []; // return [] if the node is not found
};

export const removeFileExtension = (fileName?: string) => {
  return fileName?.split(".").slice(0, -1).join(".");
};

export const checkFileExtension = (fileName?: string) => {
  const fileExt = fileName?.split(".").pop() || "";
  switch (fileExt) {
    case "pdf":
    case "png":
    case "txt":
    case "jpg":
    case "jpeg":
      return "fileUploaded";
    case "xls":
    case "xlsx":
      return "spreadsheetCreated";
    case "docx":
    case "doc":
      return "documentCreated";
    default:
      return "";
  }
};
