import api from "shared/api/v1";
import { CompanyId } from "shared/types";

import updatePermission from "./updatePermission";

export const removeFromCompany = (id: number, permissions: { company: CompanyId }) => {
  return api.delete(`/companies/members/${id}/`, { params: permissions });
};

export { updatePermission };
