import React from "react";

import clsx from "clsx";
import toString from "lodash/toString";

import taskStyles from "assets/css/task.module.css";

import Avatar from "shared/components/avatar";
import { User } from "shared/context/types/company";
import { getUserAbbreviation, getUsername } from "shared/helpers/util";

type UserAvatar = {
  user?: User | null;
  className?: string;
  size?: "xs" | "smd" | "sm" | "regular" | "md" | "lg" | "xl";
};
function UserAvatar({ className, user, size }: UserAvatar) {
  return (
    <div className="flex" data-testid="user-avatar">
      <Avatar
        name={`${getUserAbbreviation(user)}`}
        avatarFor="user"
        avatarType="rounded"
        size={size || "sm"}
        url={toString(user?.profile_picture)}
      />
      <h3 className={clsx(taskStyles.fieldValue, className, "flex items-center")}>
        {getUsername(user)}
      </h3>
    </div>
  );
}
export default UserAvatar;
