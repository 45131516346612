/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleImpactEnum } from './ArticleImpactEnum';
import {
    ArticleImpactEnumFromJSON,
    ArticleImpactEnumFromJSONTyped,
    ArticleImpactEnumToJSON,
} from './ArticleImpactEnum';

/**
 * 
 * @export
 * @interface AddImpactRequest
 */
export interface AddImpactRequest {
    /**
     * 
     * @type {ArticleImpactEnum}
     * @memberof AddImpactRequest
     */
    impact?: ArticleImpactEnum;
}



/**
 * Check if a given object implements the AddImpactRequest interface.
 */
export function instanceOfAddImpactRequest(value: object): value is AddImpactRequest {
    return true;
}

export function AddImpactRequestFromJSON(json: any): AddImpactRequest {
    return AddImpactRequestFromJSONTyped(json, false);
}

export function AddImpactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddImpactRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'impact': json['impact'] == null ? undefined : ArticleImpactEnumFromJSON(json['impact']),
    };
}

export function AddImpactRequestToJSON(value?: AddImpactRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'impact': ArticleImpactEnumToJSON(value['impact']),
    };
}

