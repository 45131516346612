/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Todo } from './Todo';
import {
    TodoFromJSON,
    TodoFromJSONTyped,
    TodoToJSON,
} from './Todo';
import type { VisibilityEnum } from './VisibilityEnum';
import {
    VisibilityEnumFromJSON,
    VisibilityEnumFromJSONTyped,
    VisibilityEnumToJSON,
} from './VisibilityEnum';
import type { Region } from './Region';
import {
    RegionFromJSON,
    RegionFromJSONTyped,
    RegionToJSON,
} from './Region';
import type { Publisher } from './Publisher';
import {
    PublisherFromJSON,
    PublisherFromJSONTyped,
    PublisherToJSON,
} from './Publisher';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { Section } from './Section';
import {
    SectionFromJSON,
    SectionFromJSONTyped,
    SectionToJSON,
} from './Section';

/**
 * 
 * @export
 * @interface FeedView
 */
export interface FeedView {
    /**
     * 
     * @type {number}
     * @memberof FeedView
     */
    readonly id: number;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof FeedView
     */
    readonly tags: Array<Tag>;
    /**
     * 
     * @type {Array<Todo>}
     * @memberof FeedView
     */
    readonly todos: Array<Todo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedView
     */
    institutionTypes?: Array<number>;
    /**
     * 
     * @type {Array<Region>}
     * @memberof FeedView
     */
    readonly jurisdictions: Array<Region>;
    /**
     * 
     * @type {Array<Section>}
     * @memberof FeedView
     */
    readonly sections: Array<Section>;
    /**
     * 
     * @type {Array<Publisher>}
     * @memberof FeedView
     */
    readonly publishers: Array<Publisher>;
    /**
     * 
     * @type {number}
     * @memberof FeedView
     */
    readonly readCount: number;
    /**
     * 
     * @type {VisibilityEnum}
     * @memberof FeedView
     */
    readonly visibility: VisibilityEnum;
    /**
     * 
     * @type {Date}
     * @memberof FeedView
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FeedView
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof FeedView
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeedView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FeedView
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeedView
     */
    isArchived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeedView
     */
    smartFilter?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedView
     */
    keywordsOr?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedView
     */
    keywordsAnd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedView
     */
    keywordsNot?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof FeedView
     */
    publicationStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FeedView
     */
    publicationEndDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof FeedView
     */
    totalArticlesCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeedView
     */
    objectOwner?: number | null;
}



/**
 * Check if a given object implements the FeedView interface.
 */
export function instanceOfFeedView(value: object): value is FeedView {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('todos' in value) || value['todos'] === undefined) return false;
    if (!('jurisdictions' in value) || value['jurisdictions'] === undefined) return false;
    if (!('sections' in value) || value['sections'] === undefined) return false;
    if (!('publishers' in value) || value['publishers'] === undefined) return false;
    if (!('readCount' in value) || value['readCount'] === undefined) return false;
    if (!('visibility' in value) || value['visibility'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function FeedViewFromJSON(json: any): FeedView {
    return FeedViewFromJSONTyped(json, false);
}

export function FeedViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedView {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'todos': ((json['todos'] as Array<any>).map(TodoFromJSON)),
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'jurisdictions': ((json['jurisdictions'] as Array<any>).map(RegionFromJSON)),
        'sections': ((json['sections'] as Array<any>).map(SectionFromJSON)),
        'publishers': ((json['publishers'] as Array<any>).map(PublisherFromJSON)),
        'readCount': json['read_count'],
        'visibility': VisibilityEnumFromJSON(json['visibility']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'isArchived': json['is_archived'] == null ? undefined : json['is_archived'],
        'smartFilter': json['smart_filter'] == null ? undefined : json['smart_filter'],
        'keywordsOr': json['keywords_or'] == null ? undefined : json['keywords_or'],
        'keywordsAnd': json['keywords_and'] == null ? undefined : json['keywords_and'],
        'keywordsNot': json['keywords_not'] == null ? undefined : json['keywords_not'],
        'publicationStartDate': json['publication_start_date'] == null ? undefined : (new Date(json['publication_start_date'])),
        'publicationEndDate': json['publication_end_date'] == null ? undefined : (new Date(json['publication_end_date'])),
        'totalArticlesCount': json['total_articles_count'] == null ? undefined : json['total_articles_count'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
    };
}

export function FeedViewToJSON(value?: Omit<FeedView, 'id'|'tags'|'todos'|'jurisdictions'|'sections'|'publishers'|'read_count'|'visibility'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'institution_types': value['institutionTypes'],
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'is_archived': value['isArchived'],
        'smart_filter': value['smartFilter'],
        'keywords_or': value['keywordsOr'],
        'keywords_and': value['keywordsAnd'],
        'keywords_not': value['keywordsNot'],
        'publication_start_date': value['publicationStartDate'] == null ? undefined : ((value['publicationStartDate'] as any).toISOString().substring(0,10)),
        'publication_end_date': value['publicationEndDate'] == null ? undefined : ((value['publicationEndDate'] as any).toISOString().substring(0,10)),
        'total_articles_count': value['totalArticlesCount'],
        'object_owner': value['objectOwner'],
    };
}

