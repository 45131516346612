import { RefetchOptions } from "shared/components/common-table/types";

import { UserResponse } from "modules/home/types";

const getUserID = (
  usersList: {
    memberId: number;
    user: UserResponse;
  }[],
  memberId: string
) => {
  const userId = usersList.find((record) => `${memberId}` === `${record.memberId}`)?.user.id;

  return `${userId}`;
};

export default function convertMemberIdToUserId({
  usersList,
  options,
}: {
  usersList: {
    memberId: number;
    user: UserResponse;
  }[];
  options: RefetchOptions[];
}) {
  const filteredOptions = options.map((option) =>
    ["owner", "commented_by", "assignees"].find((field) => option.field.includes(field))
      ? { ...option, value: getUserID(usersList, option.value) }
      : option
  );
  return filteredOptions;
}
