import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import teamToQueryParams from "shared/helpers/teamToQueryParams";
import { TeamParameter } from "shared/types";

import { getParamName, getUrlName } from "../helpers";
import { Permission, PermissionModule, PermissionUpdateData } from "../types";

export default function updatePermission(
  module: PermissionModule,
  objectId: number,
  permissionId: number,
  data: PermissionUpdateData,
  team: TeamParameter
): Promise<AxiosResponse<Permission>> {
  return api.patch<Permission>(`/${getUrlName(module)}/permissions/${permissionId}/`, data, {
    params: {
      [getParamName(module)]: objectId,
      ...teamToQueryParams(team),
    },
  });
}
