import React from "react";

import { useTeam } from "shared/store/settings";
import { GetTaskResultType } from "shared/types";

import useLoadRelatedObject from "../hooks/useLoadRelatedObject";
import { RelatedObjectType } from "../types";

type TaskWithRelatedObjectProps = {
  task: GetTaskResultType;
  relatedObject?: RelatedObjectType;
};

export default function withRelatedObject<Props extends TaskWithRelatedObjectProps>(
  Component: React.FC<Props>
): React.FC<Props> {
  return function WithRelatedObjectComponent({ ...props }: Props) {
    const { task, relatedObject } = props;
    const { currentTeam } = useTeam();
    const loadObject = useLoadRelatedObject(
      task,
      {
        team: currentTeam?.id,
        company: currentTeam?.company,
        isAllTeam: currentTeam?.is_all_team,
      },
      relatedObject
    );
    return <Component {...props} task={task} relatedObject={loadObject} />;
  };
}
