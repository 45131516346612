import { MetaTable } from "shared/components/metadata/types";
import { MetadataUrlType } from "shared/types";

import { RISK_MODULE } from "./ability";
import {
  AGREEMENT_METADATA,
  AGREEMENT_TABLE,
  ARTICLE_METADATA,
  ARTICLE_TABLE,
  DIRECTORIES,
  DIRECTORY_METADATA,
  DOCUMENT_METADATA,
  DOCUMENT_TABLE,
  INCIDENTS_TABLE,
  INCIDENT_METADATA,
  MITIGATION_METADATA,
  MITIGATION_TABLE,
  NOTE_METADATA,
  NOTE_TABLE,
  POLICY_METADATA,
  POLICY_TABLE,
  RISK_METADATA,
  TASK_METADATA,
  TASK_TABLE,
} from "./constant";

export const getUrlFromType = (type: MetaTable): MetadataUrlType => {
  switch (type) {
    case AGREEMENT_TABLE:
      return AGREEMENT_METADATA;
    case ARTICLE_TABLE:
      return ARTICLE_METADATA;
    case DOCUMENT_TABLE:
      return DOCUMENT_METADATA;
    case POLICY_TABLE:
      return POLICY_METADATA;
    case TASK_TABLE:
      return TASK_METADATA;
    case NOTE_TABLE:
      return NOTE_METADATA;
    case MITIGATION_TABLE:
      return MITIGATION_METADATA;
    case RISK_MODULE:
      return RISK_METADATA;
    case INCIDENTS_TABLE:
      return INCIDENT_METADATA;
    case DIRECTORIES:
      return DIRECTORY_METADATA;
    default:
      return "default";
  }
};
