import api from "shared/api/v1";

import { SharedObjectRole } from "../../../types";

export const getSharedObjectsFromBaseUrl = (
  baseUrl: string,
  companyId: number,
  paramProps?: Record<string, unknown>
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }

  return api.get(`${baseUrl}`, {
    params: { company: companyId, show_all_objects: true, ...paramProps },
  });
};

export const createSharedObjectForUser = (
  baseUrl: string,
  shared_with_user: number,
  role: SharedObjectRole,
  companyId: number,
  paramProps?: Record<string, unknown>
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }

  return api.post(
    baseUrl,
    {
      shared_with_user,
      role,
    },
    { params: { company: companyId, show_all_objects: true, ...paramProps } }
  );
};

export const createSharedObjectForGroup = (
  baseUrl: string,
  shared_with_group: number,
  role: SharedObjectRole,
  companyId: number,
  paramProps?: Record<string, unknown>
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }

  return api.post(
    baseUrl,
    {
      shared_with_group,
      role,
    },
    { params: { company: companyId, show_all_objects: true, ...paramProps } }
  );
};

export const updateSharedObjectRole = (
  companyId: number,
  sharedObjectId: number,
  role: SharedObjectRole,
  paramProps?: Record<string, unknown>
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }

  return api.patch(
    `core/shared-objects/${sharedObjectId}/`,
    {
      role,
    },
    { params: { company: companyId, ...paramProps } }
  );
};

export const deleteSharedObject = (
  companyId: number,
  sharedObjectId: number,
  paramProps?: Record<string, unknown>
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }

  return api.delete(`core/shared-objects/${sharedObjectId}/`, {
    params: { company: companyId, ...paramProps },
  });
};

export const updateSharedObjectOwner = (
  transferOwnerUrl: string,
  companyId: number,
  owner: { object_owner: number }
) => {
  if (!companyId) {
    return Promise.reject("Company ID is required");
  }
  return api.patch(`${transferOwnerUrl}`, owner);
};
