import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  GroupHeadingProps,
  MenuListProps,
  NoticeProps,
  OptionProps,
  SingleValueProps,
  components,
} from "react-select";
import { AsyncPaginate, reduceGroupedOptions, wrapMenuList } from "react-select-async-paginate";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { useDarkMode } from "usehooks-ts";

import { useMutation } from "@tanstack/react-query";

import { getServerErrors } from "shared/helpers/util";
import { DEBOUNCE_DELAY } from "shared/helpersV2/constant";
import getConfirmModalV2 from "shared/helpersV2/getConfirmModalV2";
import { getModuleName } from "shared/helpersV2/getModuleName";
import { selectControlStyleForCreateV2, selectErrorStylesV2 } from "shared/helpersV2/selectStyleV2";
import { useCacheCounter } from "shared/storeV2/cacheUnique";
import { DropdownOptionType } from "shared/types";
import { ApiDetail } from "shared/typesV2";

import ConfirmModal from "../../components/confirm-modal/ConfirmModal";
import Icon from "../../components/icon/Icon";
import ConfirmDeleteModal from "../confirm-delete-modal";
import { deleteDataByEndpoint, getDataByEndpoint } from "./api";
import CreateModal from "./components/CreateModal";
import CustomizedCreateModal, {
  TModalComponents,
  TModalComponentsProps,
} from "./components/CustomizedCreateModal";

const genericMemo: <T>(component: T) => T = memo;

function CommonDropdownV2<DataType = unknown>({
  selectedValue,
  onChange,
  placeholder,
  isMultiSelect = false,
  isClearable = false,
  apiDetails,
  searchKey,
  defaultOrderingField,
  handleDeleteCleanUpFn,
  optionField,
  constantOptions,
  defaultOptions,
  createDetails,
  CreateModalBody,
  isDeletable = false,
  OptionComponent,
  menuListPosition,
  isDisabled = false,
  classNamePrefix,
  menuIsOpen = false,
  className,
  MenuListComponent,
  hasError,
  excludeOptionIds,
  CustomSingleValueComponent,
  CustomGroupHeadingComponent,
  CustomizedModalComponents,
  CustomizedModalProps,
  moduleName,
  menuPlacement = "auto",
  fetchData,
  id,
}: {
  id?: string;
  placeholder?: string;
  isClearable?: boolean;
  // This is used for the search, pagination, create and delete functionality to get data dynamically.
  apiDetails?: ApiDetail | ApiDetail[];
  // If any API does not have name field for searching then we can modify from here.
  searchKey?: string;
  // If any API does not have name field for ordering then we can modify from here.
  defaultOrderingField?: string;
  // If we have different key for displaying data then we can set dynamic field from here.
  optionField?: { label?: string; value?: string; data?: string };
  // This is used for the constant data dropdown
  constantOptions?:
    | Array<DropdownOptionType<DataType>>
    | Array<{
        label: string;
        options: Array<DropdownOptionType<DataType>>;
        icon?: string;
        data?: unknown;
      }>;
  // default options to be displayed on top of api results
  defaultOptions?: { name: string; value: number | null }[];
  // This is used for the create functionality
  createDetails?: { createTitle: string; modalTitle: string };
  CreateModalBody?: React.ForwardRefExoticComponent<
    React.RefAttributes<unknown> & { onSuccess: (data: DataType) => void }
  >;
  // It enables the menu funcationality of the Async Paginate
  menuIsOpen?: boolean;
  // It enables the delete functionality
  isDeletable?: boolean;
  // This allow us to modify the options which are displaying
  OptionComponent?: FC<unknown>;
  // A position prop to define where the menu list should open inside the web structure and this allows the select options to be displayed as an overlay.
  menuListPosition?: { left: number; top: number; width: string };
  // It helps to styling outside this component
  classNamePrefix?: string;
  // It helps to styling outside this component
  className?: string;
  isDisabled?: boolean;
  // It helps to display validation for the field
  // Custom component for filtering/mapping dropdown options
  MenuListComponent?: FC<{ props: unknown }>;
  // Highlights the border with red color. Defaults to `false`
  hasError?: boolean;
  // Used to filter options manually
  excludeOptionIds?: Array<number | string>;
  // Used to Customized modal for create functionality
  CustomizedModalProps?: TModalComponentsProps<DataType>;
  CustomizedModalComponents?: TModalComponents<DataType>;
  isMultiSelect?: boolean;
  selectedValue: Array<DropdownOptionType<DataType>>;
  onChange: (selectedOption: Array<DropdownOptionType<DataType>>) => void;
  // This allows us to modify value which we selected
  CustomSingleValueComponent?: FC<unknown>;
  // This allows us to modify the group heading which we are displaying
  CustomGroupHeadingComponent?: FC<unknown>;
  menuPlacement?: "auto" | "top" | "bottom";
  handleDeleteCleanUpFn?: CallableFunction;
  moduleName?: string;

  fetchData?: (args: { page: number; search?: string }) => Promise<{
    data: DataType[];
    hasMore: boolean;
  }>;
}) {
  const { isDarkMode } = useDarkMode();

  const [search, setSearch] = useState<string>("");
  const [cacheId, incCacheId] = useCacheCounter((state) => [state.cacheId, state.incCacheId]);

  const { createTitle = "", modalTitle = "" } = createDetails || {};

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCustomizedModalOpen, setIsCustomizedModalOpen] = useState<boolean>(false);

  const [deleteValue, setDeleteValue] = useState<DropdownOptionType<DataType> | null>(null);

  const constantOptionsCopy = useRef<string>("");

  const getValueFromDynamicField = (item: unknown, field: "label" | "value" | "data") => {
    if (optionField) {
      let filteredValue = "";
      optionField[field]?.split(",").forEach((field: string) => {
        if (filteredValue) {
          return;
        }
        filteredValue = get(item, field, "");
      });
      return filteredValue;
    }
    return false;
  };

  const MenuList = wrapMenuList((props) =>
    menuListPosition ? (
      createPortal(
        <div
          style={{
            position: "absolute",
            top: menuListPosition.top,
            left: menuListPosition.left,
            zIndex: 50,
            maxWidth: "71rem",
            width: "100%",
          }}
        >
          <MemorisedMenuComponent
            props={props}
            title={createTitle}
            isVisible={!isEmpty(createDetails)}
            handleCreate={() => {
              if (CustomizedModalComponents) {
                setIsCustomizedModalOpen(true);
              } else {
                setIsModalOpen(true);
              }
            }}
            MenuListComponent={MenuListComponent}
          />
        </div>,
        document.body
      )
    ) : (
      <MemorisedMenuComponent
        props={props}
        title={createTitle}
        isVisible={!isEmpty(createDetails)}
        handleCreate={() => {
          if (CustomizedModalComponents) {
            setIsCustomizedModalOpen(true);
          } else {
            setIsModalOpen(true);
          }
        }}
        MenuListComponent={MenuListComponent}
      />
    )
  );

  const getObject = (
    data: DataType,
    apiDetail?: ApiDetail
  ): DropdownOptionType<
    DataType,
    {
      deleteApiDetail?: ApiDetail;
    }
  > => {
    return {
      label: getValueFromDynamicField(data, "label") || (get(data, "name", "") as string),
      value:
        getValueFromDynamicField(data, "value") ||
        (get(data, optionField?.value || "id", "") as string),
      data: data,
      deleteApiDetail: apiDetail,
    };
  };

  const { mutate: deleteValueFn, isLoading: isDeleting } = useMutation(
    () => {
      if (
        !deleteValue ||
        !(
          deleteValue as unknown as {
            deleteApiDetail: ApiDetail;
          }
        ).deleteApiDetail
      ) {
        return Promise.reject(new Error("Option is not selected"));
      }

      const { url, props } = (
        deleteValue as unknown as {
          deleteApiDetail: ApiDetail;
        }
      ).deleteApiDetail;

      return deleteDataByEndpoint(url, (deleteValue.data as { id: number })?.id, props || {});
    },
    {
      onSuccess: () => {
        toast.success("Deleted Successfully!", {
          className: "toast_v2",
          bodyClassName: "toast_v2_body",
          theme: "dark",
        });

        if (
          !isMultiSelect &&
          selectedValue?.length > 0 &&
          selectedValue[0]?.value?.toString() === `${deleteValue}`?.toString()
        ) {
          onChange([]);
        }
        setDeleteValue(null);
        handleDeleteCleanUpFn ? handleDeleteCleanUpFn() : null;
        incCacheId();
      },
      onError: (error: AxiosError) => {
        getServerErrors(error).forEach((err: string) =>
          toast.error(err, {
            className: "toast_v2",
            bodyClassName: "toast_v2_body",
            theme: "dark",
          })
        );
        setDeleteValue(null);
      },
    }
  );

  const handledeleteOption = useCallback((value: DropdownOptionType<DataType>) => {
    setDeleteValue(value);
  }, []);

  const isMenuOpen = useMemo(() => {
    return menuIsOpen ? { menuIsOpen } : {};
  }, [menuIsOpen]);

  useEffect(() => {
    try {
      if (
        constantOptions?.length &&
        JSON.stringify(constantOptions) !== constantOptionsCopy.current
      ) {
        if (constantOptionsCopy.current) {
          incCacheId();
        }

        constantOptionsCopy.current = JSON.stringify(constantOptions);
      }
    } catch (e) {
      /* Empty */
    }
  }, [constantOptions]);

  return (
    <>
      <AsyncPaginate
        {...isMenuOpen}
        key={id}
        id={id}
        maxMenuHeight={250}
        isDisabled={isDisabled}
        menuPlacement={menuPlacement}
        debounceTimeout={DEBOUNCE_DELAY}
        cacheUniqs={[cacheId]}
        isSearchable={true}
        inputValue={search}
        onInputChange={(value, event) => {
          if (
            event.action === "input-change" ||
            event.action === "set-value" ||
            (event.action === "menu-close" && !menuIsOpen)
          ) {
            setSearch(value);
          }
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          // Check if the pressed key is a first space
          if (event.key === " " && search.length === 0) {
            event.preventDefault();
            setSearch(" ");
          }
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        reduceOptions={reduceGroupedOptions}
        loadOptions={async (_, prevOptions, args) => {
          const page = (args as { page: number })?.page || 1;

          if (Array.isArray(apiDetails)) {
            if (!apiDetails.length) {
              return {
                options: [],
                hasMore: false,
              };
            }

            const groupId = (args as { groupId: number })?.groupId || 0;

            const { url, props } = apiDetails[groupId];

            const response = await getDataByEndpoint<DataType>(
              url || "",
              {
                [searchKey || "name_icontains"]: search,
                ordering: defaultOrderingField || "name",
                page: page,
              },
              props
            ).catch(() => null);

            if (!response) {
              const nextGroupId = Math.min(groupId + 1, apiDetails.length - 1);

              return {
                options: [],
                hasMore: nextGroupId < apiDetails.length,
                additional: {
                  page: 0,
                  groupId: nextGroupId,
                },
              };
            }

            const { data } = response;

            const options = data?.results.map((item) => getObject(item, apiDetails[groupId]));

            const hasMore = !!data?.next || groupId < apiDetails.length - 1;
            const nextGroupId = data?.next ? groupId : groupId + 1;

            return {
              options: options || [],
              hasMore,
              additional: {
                page: nextGroupId == groupId ? page + 1 : 0,
                groupId: nextGroupId,
              },
            };
          } else if (apiDetails) {
            const { url, props } = apiDetails;

            const response = await getDataByEndpoint<DataType>(
              url || "",
              {
                [searchKey || "name__icontains"]: search,
                ordering: defaultOrderingField || "name",
                page: page,
              },
              props
            ).catch(() => null);

            if (!response) {
              return {
                options: [],
                hasMore: false,
              };
            }

            const { data } = response;

            const mergedData = [...((defaultOptions as DataType[]) ?? []), ...data.results];
            const options =
              apiDetails?.key === "saved_filter"
                ? mergedData
                    .map((item) => getObject(item, apiDetails))
                    .filter(
                      (item) => get(item, "data.object_type") === getModuleName(moduleName || "")
                    )
                : mergedData.map((item) => getObject(item, apiDetails));

            return {
              options: options || [],
              hasMore: !!data?.next,
              additional: {
                page: page + 1,
              },
            };
          } else if (fetchData) {
            const response = await fetchData({ page, search }).catch(() => null);

            if (!response) {
              return {
                options: [],
                hasMore: false,
              };
            }

            const mergedData = [...((defaultOptions as DataType[]) ?? []), ...response.data];

            const options = mergedData.map((item) => getObject(item));

            return {
              options: options || [],
              hasMore: response.hasMore,
              additional: {
                page: page + 1,
              },
            };
          }

          let filteredOptions;
          if (!search) {
            filteredOptions = constantOptions || [];
          } else {
            const searchLower = search.toLowerCase();
            filteredOptions =
              constantOptions?.flatMap((option: unknown) => {
                if (has(option, "options")) {
                  const filteredOptions = get(option, "options", []).filter(
                    (option) =>
                      get(option, "label", "").toLowerCase().includes(searchLower) ||
                      String(get(option, "value", "")).toLowerCase().includes(searchLower)
                  );
                  return {
                    ...(option as unknown as object),
                    options: filteredOptions,
                  };
                }
                return get(option, "label", "").toLowerCase().includes(searchLower) ||
                  String(get(option, "value", "")).toLowerCase().includes(searchLower)
                  ? [option]
                  : [];
              }) || [];
          }
          return {
            options: filteredOptions || [],
            hasMore: false,
          };
        }}
        classNamePrefix={clsx("multitag multitag_spacing", classNamePrefix)}
        isClearable={isClearable}
        className={className}
        isMulti={isMultiSelect}
        placeholder={placeholder || "Select..."}
        styles={
          hasError ? selectErrorStylesV2(isDarkMode) : selectControlStyleForCreateV2(isDarkMode)
        }
        value={selectedValue}
        onChange={(newValue) => {
          if (Array.isArray(newValue)) {
            onChange(newValue);
            return;
          }

          onChange(newValue ? [newValue] : []);
        }}
        backspaceRemovesValue={true}
        filterOption={(options) => {
          if (
            (excludeOptionIds && excludeOptionIds?.includes(options.value)) ||
            excludeOptionIds?.includes(options.data.data.id)
          ) {
            return false;
          }
          return true;
        }}
        components={{
          NoOptionsMessage: (props) => (
            <MemorisedNoOptionComponent
              props={props}
              toggleModal={() => setIsModalOpen(!isModalOpen)}
              title="No Data Available"
            />
          ),
          Option: (props) => (
            <MemorisedOptionMenu
              props={props}
              handleDeleteValue={() => {
                handledeleteOption(props.data);
              }}
              showDelete={isDeletable}
              OptionComponent={OptionComponent}
            />
          ),
          MenuList,
          SingleValue: (props) => (
            <MemorisedCustomSingleValue
              props={props}
              CustomSingleValueComponent={CustomSingleValueComponent}
            />
          ),
          GroupHeading: (props) => (
            <MemorisedGroupHeadingComponent
              props={props}
              CustomGroupHeadingComponent={CustomGroupHeadingComponent}
            />
          ),
        }}
      />
      {isModalOpen ? (
        <CreateModal<DataType>
          show={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          onSuccess={(data) => {
            onChange(isMultiSelect ? [...selectedValue, getObject(data)] : [getObject(data)]);
            incCacheId();
            setIsModalOpen(false);
          }}
          title={modalTitle}
          CreateModalBody={CreateModalBody}
        />
      ) : null}

      {(isCustomizedModalOpen || CustomizedModalProps?.Root?.show) && CustomizedModalComponents ? (
        <CustomizedCreateModal<DataType>
          ModalComponentsProps={CustomizedModalProps}
          ModalComponents={CustomizedModalComponents}
          handleSubmit={(data) => {
            onChange(isMultiSelect ? [...selectedValue, getObject(data)] : [getObject(data)]);

            incCacheId();

            setIsCustomizedModalOpen(false);
          }}
          show={isCustomizedModalOpen || CustomizedModalProps?.Root?.show ? true : false}
          handleClose={() => setIsCustomizedModalOpen(false)}
        />
      ) : null}
      {deleteValue && getConfirmModalV2(moduleName || "") ? (
        <ConfirmDeleteModal
          heading={"Delete filter"}
          question={`Are you sure you want to delete ${deleteValue?.label}?`}
          show={!!deleteValue}
          onClose={() => setDeleteValue(null)}
          onDelete={() => {
            deleteValueFn();
          }}
          isLoading={isDeleting}
        />
      ) : (
        <ConfirmModal
          isOpen={!!deleteValue}
          setIsOpen={() => setDeleteValue(null)}
          action={() => {
            deleteValueFn();
          }}
        />
      )}
    </>
  );
}

const CustomSingleValue = ({
  props,
  CustomSingleValueComponent,
}: {
  props: SingleValueProps;
  CustomSingleValueComponent?: FC<{ data: unknown }>;
}) => {
  return (
    <>
      <components.SingleValue {...props}>
        <div className="!w-full">
          {CustomSingleValueComponent ? (
            <CustomSingleValueComponent data={props?.data} />
          ) : (
            get(props.data, "label", "")
          )}
        </div>
      </components.SingleValue>
    </>
  );
};

const MemorisedCustomSingleValue = memo(CustomSingleValue);

const GroupHeadingComponent = ({
  props,
  CustomGroupHeadingComponent,
}: {
  props: GroupHeadingProps;
  CustomGroupHeadingComponent?: FC<{ data: unknown }>;
}) => {
  return (
    <>
      <components.GroupHeading {...props}>
        <div className="!w-full">
          {CustomGroupHeadingComponent ? (
            <CustomGroupHeadingComponent data={props?.data} />
          ) : (
            get(props.data, "label", "")
          )}
        </div>
      </components.GroupHeading>
    </>
  );
};

const MemorisedGroupHeadingComponent = memo(GroupHeadingComponent);

const CustomOptionMenu = ({
  props,
  handleDeleteValue,
  showDelete,
  OptionComponent,
}: {
  props: OptionProps<DropdownOptionType>;
  handleDeleteValue: (value: number | string) => void;
  showDelete?: boolean;
  OptionComponent?: FC<{ data: unknown }>;
}) => {
  return (
    <>
      <components.Option {...props}>
        <div className="flex !w-full justify-between font-inter-medium font-medium">
          {OptionComponent ? <OptionComponent data={props.data} /> : get(props.data, "label", "")}
          {showDelete ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteValue(props.data.value);
              }}
            >
              <Icon
                type="trash-outline"
                fill={false}
                size="icon-xs"
                className="global_hover_icon"
              />
            </button>
          ) : null}
        </div>
      </components.Option>
    </>
  );
};

const MemorisedOptionMenu = memo(CustomOptionMenu);

const CustomNoOptionComponent = ({
  props,
  title,
}: {
  props: NoticeProps;
  toggleModal: () => void;
  title: string;
}) => {
  return (
    <>
      <components.NoOptionsMessage {...props}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="!w-full text-sm"
        >
          {title}
        </div>
      </components.NoOptionsMessage>
    </>
  );
};

const MemorisedNoOptionComponent = memo(CustomNoOptionComponent);

const CustomMenuComponent = ({
  props,
  title,
  isVisible,
  handleCreate,
  MenuListComponent,
}: {
  props: MenuListProps;
  title: string;
  isVisible: boolean;
  handleCreate: () => void;
  MenuListComponent?: FC<{ props: unknown }>;
}) => {
  return (
    <>
      <components.MenuList {...props}>
        <div className="!w-full">
          {MenuListComponent ? <MenuListComponent props={props} /> : props.children}
        </div>
        {isVisible ? (
          <div className="sticky bottom-0 !border-b-0 border-t border-t-brightgray bg-white px-1 pb-1 dark:!bg-mirage">
            <button
              className="common_create_dropdown_addIcon cursor-pointer"
              onClick={() => {
                handleCreate();
              }}
            >
              <span>
                <Icon type="plus-btn" fill={false} size="icon-xs" className="global_hover_icon" />
              </span>
              {title}
            </button>
          </div>
        ) : null}
      </components.MenuList>
    </>
  );
};

const MemorisedMenuComponent = memo(CustomMenuComponent);

export default genericMemo(CommonDropdownV2);
