import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { useQuery } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import { useCompany } from "shared/context/CompanyProvider";
import { UPGRADE } from "shared/helpers/constant";
import { usePlans, useSettings, useUpdateCard } from "shared/store/settings";

import UpdateAddress from "./UpdateAddress";
import UpdateCard from "./UpdateCard";
import UpdateEmail from "./UpdateEmail";
import UpdateVATorGST from "./UpdateVATorGST";
import { getInvoices, getPaymentMethod } from "./api";
import { InvoiceResponseProps } from "./types";

const Billing = () => {
  const { currentCompany } = useCompany();

  const setScreen = useSettings((state) => state.setScreen);

  const setSidebarTitle = useSettings((state) => state.setSidebarTitle);

  const plans = usePlans((state) => state.plans);

  const setIsUpdateCardModalOpen = useUpdateCard((state) => state.setIsUpdateCardModalOpen);

  const setIsUpdateEmailModalOpen = useUpdateCard((state) => state.setIsUpdateEmailModalOpen);

  const setIsUpdateAddressModalOpen = useUpdateCard((state) => state.setIsUpdateAddressModalOpen);

  const setIsUpdateVATorGSTModalOpen = useUpdateCard((state) => state.setIsUpdateVATorGSTModalOpen);

  const { data: response } = useQuery(["getInvoices"], () => getInvoices(currentCompany.id));

  const { data: billingDetails, refetch: refetchBillingDetails } = useQuery(
    ["getPaymentMethod"],
    () => getPaymentMethod(currentCompany.id)
  );

  const billings = !isEmpty(response?.data) ? response?.data : [];

  const onClick = () => {
    setScreen(UPGRADE);
    setSidebarTitle("Upgrade");
  };

  return (
    <>
      {billingDetails?.data && !isEmpty(currentCompany.customer) && (
        <div className="flex-wrap items-center justify-between border-b border-brightgray px-11 py-3 dark:border-thunders md:flex maxMd:px-4 mac13Inch:justify-start mac13Inch:px-6">
          <div className="md:w-[calc(100%-142px)]">
            <p className="!mt-0 text-sm !text-mirage dark:!text-greychateau">
              This company team plan is set to{" "}
              {`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(2)} `}
              per month and will renew on{" "}
              <b className="font-bold">
                {moment(billingDetails?.data?.created).add(1, "months").format("MMMM DD, YYYY.")}{" "}
              </b>
            </p>
          </div>
          <div className="md:text-right maxMd:mt-2">
            <button type="button" onClick={onClick} className="btn_secondary">
              Change Plan
            </button>
          </div>
        </div>
      )}
      <div className="overflow-x-auto maxMd:pb-4">
        <table className={clsx(styles.billingTable, "w-full maxMd:min-w-[500px]")}>
          {billingDetails?.data && !isEmpty(currentCompany.customer) && (
            <tbody>
              <tr className="flex items-center border-b border-brightgray px-11 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>Payment Method</td>
                {billingDetails?.data?.card && (
                  <td>
                    {`${billingDetails?.data?.card.brand} ending with ${billingDetails?.data?.card?.last4}`}
                  </td>
                )}
                <td>
                  <button onClick={() => setIsUpdateCardModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-11 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>Billing Email</td>
                <td>{billingDetails?.data?.billing_details.email || "-"}</td>
                <td>
                  <button onClick={() => setIsUpdateEmailModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-11 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>Address</td>
                <td>
                  {!isEmpty(billingDetails?.data?.billing_details)
                    ? `${billingDetails?.data?.billing_details.address.line1 || ""}, ${
                        billingDetails?.data?.billing_details.address.line2 || ""
                      } ${billingDetails?.data?.billing_details.address.city || ""}, ${
                        billingDetails?.data?.billing_details.address.state || ""
                      },${billingDetails?.data?.billing_details.address.postal_code || ""}, ${
                        billingDetails?.data?.billing_details.address.country || ""
                      }`
                    : "-"}
                </td>
                <td>
                  <button onClick={() => setIsUpdateAddressModalOpen(true)}>Update</button>
                </td>
              </tr>
              <tr className="flex items-center border-b border-brightgray px-11 py-3 dark:border-thunders maxMd:px-4 maxMd:text-sm">
                <td>VAT/GST Number</td>
                <td>{billingDetails?.data?.billing_details.eu_vat || "-"}</td>
                <td>
                  <button onClick={() => setIsUpdateVATorGSTModalOpen(true)}>Update</button>
                </td>
              </tr>
            </tbody>
          )}
          {!currentCompany.customer && (
            <div>
              <div className="border-brightgray px-11 py-3 dark:border-thunders md:border-b maxMd:px-4 maxMd:text-sm mac13Inch:px-6">
                <div className="flex items-center dark:text-white">
                  Workspace Balance{" "}
                  <p className="ml-auto text-aurometalsaurus dark:text-white">$0</p>
                </div>
                <div className="mt-12 dark:text-white mac13Inch:mt-4">Invoices</div>
              </div>
              <p className="px-11 pt-4 text-aurometalsaurus dark:text-greychateau maxMd:px-4 mac13Inch:px-6">
                This workspace has no payments yet.
              </p>
            </div>
          )}
        </table>
      </div>
      <h4 className="px-4 py-3 font-inter-medium text-sm dark:text-white md:pl-11">Invoices</h4>
      <div className="min-w-[310px] overflow-x-auto md:min-w-[500px]">
        {billings?.map((_invoice: InvoiceResponseProps, _index: number) => (
          <div
            className="flex overflow-x-auto px-4 py-3 text-sm md:px-11 maxMd:min-w-[460px] mac13Inch:px-7"
            key={_index}
          >
            <span className="w-[33.5%] text-davygrey dark:text-greychateau">
              {moment(_invoice.created).format("MMMM DD, YYYY")}
            </span>
            <a href={_invoice.invoice_pdf} className="w-[26%] text-davygrey dark:text-greychateau">
              View invoice
            </a>
            <span className="w-[20%] text-mirage dark:text-greychateau">
              {_invoice.amount_paid} kr
            </span>
            <span className="w-[20%] text-center font-inter-medium text-mirage dark:text-greychateau">
              {_invoice.paid ? "Paid" : "Pending"}
            </span>
          </div>
        ))}
      </div>
      <UpdateCard billingDetails={billingDetails} refetchBillingDetails={refetchBillingDetails} />
      <UpdateEmail billingDetails={billingDetails} refetchBillingDetails={refetchBillingDetails} />
      <UpdateAddress
        billingDetails={billingDetails}
        refetchBillingDetails={refetchBillingDetails}
      />
      <UpdateVATorGST
        billingDetails={billingDetails}
        refetchBillingDetails={refetchBillingDetails}
      />
    </>
  );
};

export default Billing;
