import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Button, Modal, Spinner, TextInput, Textarea } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import Avatar from "shared/components/avatar";
import { useCompany } from "shared/context/CompanyProvider";
import { getServerErrors } from "shared/helpers/util";
import { useTeam } from "shared/store/settings";

import { deleteTeam, updateTeamProfile } from "../api";
import useUpdateTeamForm from "../deafult-teams/useUpdateTeamForm";
import { TeamInputData } from "../types";

const General = () => {
  const memberIconRef = useRef<HTMLInputElement>(null);

  const selectedTeam = useTeam((store) => store.selectedTeam);

  const [teamPicture, setTeamPicture] = useState<File | null>(null);

  const { currentCompany, refetch } = useCompany();

  const isManageModalOpen = useTeam((state) => state.isManageModalOpen);

  const setIsManageModalOpen = useTeam((state) => state.setIsManageModalOpen);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const onIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const file: File | null = target.files ? target.files[0] : null;
    if (file && file["type"].split("/")[0] !== "image") {
      toast("Upload a valid image", { type: "error" });
      return;
    }
    setTeamPicture(file);
  };

  const { isLoading: isUpdating, mutate: updateTeamFn } = useMutation(
    (data: TeamInputData) => {
      if (!selectedTeam?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }
      return updateTeamProfile(selectedTeam?.id, currentCompany?.id, data, teamPicture);
    },
    {
      onSuccess: () => {
        refetch();
        toast("Team Updated Successfully", { type: "success" });
        setIsManageModalOpen(false);
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const { isLoading: isDeleting, mutate: deleteTeamFn } = useMutation(
    () => {
      if (!selectedTeam?.id) {
        return Promise.reject(new Error("Team is not selected"));
      }
      return deleteTeam(selectedTeam?.id, currentCompany?.id);
    },
    {
      onSuccess: () => {
        refetch();
        toast("Team Deleted Successfully", { type: "success" });
        setIsDeleteModalOpen(false);
        setIsManageModalOpen(false);
      },
      onError: (error: AxiosError) => {
        {
          getServerErrors(error).map((err: string) => toast(err, { type: "error" }));
        }
      },
    }
  );

  const getProfilePicture = () => {
    if (teamPicture) return URL.createObjectURL(teamPicture);
    if (selectedTeam?.team_picture) return selectedTeam?.team_picture;
  };

  const { handleSubmit, values, errors, handleChange, resetForm } = useUpdateTeamForm(() =>
    updateTeamFn(values)
  );

  useEffect(() => {
    return () => {
      setTeamPicture(null);
      resetForm();
    };
  }, [isManageModalOpen]);

  return (
    <>
      <div className={styles.general_body}>
        <div>
          <h4 className="font-inter-medium dark:text-white">Icon</h4>
          <div className="add-iconbox flex flex-wrap items-center border-b border-brightgray py-4 dark:border-thunders">
            <Avatar
              name={selectedTeam?.name || ""}
              avatarFor="user"
              avatarType="squared"
              size="lg"
              url={getProfilePicture()}
              onClick={() => memberIconRef?.current?.click()}
            />
            <input
              type="file"
              className="hidden"
              ref={memberIconRef}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onIconChange(e)}
            />
            <p className="ml-[18px] w-[calc(100%-98px)]">
              Upload an image or pick an emoji. It will show up in your sidebar and notifications.
            </p>
          </div>
        </div>
        <form className="border-brightgray" onSubmit={handleSubmit}>
          <label className="mb-2 mt-4 inline-block font-inter-medium text-sm">Name*</label>
          <TextInput
            placeholder="You can use your name or the name of your team. Keep it simple.  "
            name="name"
            value={values.name}
            onChange={handleChange}
            color={errors.name ? "failure" : "gray"}
          />
          <label className="mb-2 mt-4 block font-inter-medium text-sm">Description*</label>
          <Textarea
            placeholder="No description"
            name="description"
            value={values.description}
            onChange={handleChange}
            color={errors.description ? "failure" : "gray"}
            className="text-sm"
          />

          <div className="border-b border-brightgray pb-4 dark:border-thunders">
            <label className="mb-4 mt-4 inline-block font-inter-medium text-sm">Danger Zone</label>
            <button
              type="button"
              className="!ml-0 block rounded-full !border border-lavared !py-2.5 px-5 !text-sm !text-lavared hover:bg-lavared hover:!text-white focus:!ring-0 mac13Inch:!text-xs"
              onClick={() => {
                setIsDeleteModalOpen(true);
              }}
            >
              Delete Team
            </button>
          </div>
          <div className="justify-end pt-4 md:flex">
            <button
              className="btn_secondary mr-4 border !border-r !border-t maxMd:mb-[18px] maxMd:w-full mac13Inch:!text-xs"
              type="button"
              onClick={() => setIsManageModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="btn_primary !ml-0 maxMd:w-full mac13Inch:!text-xs"
              type="submit"
              disabled={isUpdating}
            >
              <Spinner
                size="md"
                hidden={!isUpdating}
                light={true}
                className="mr-3 !fill-mirage !stroke-mirage"
              />
              Update
            </button>
          </div>
        </form>
      </div>
      <Modal
        show={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        dismissible={true}
        className="modal_mobileview_center"
      >
        <Modal.Header
          className={clsx(styles.general_header, "dark:!border-thunders mac13Inch:items-center")}
        >
          Delete Team
        </Modal.Header>
        <Modal.Body className="maxMd:p-4">
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-greychateau mac13Inch:text-sm">
              Are you sure you want to delete this team? It cannot be reverted.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-wrap justify-end dark:!border-thunders maxMd:flex-col-reverse maxMd:p-4">
          <button
            className="btn_secondary font-inter-medium maxMd:mt-[18px] maxMd:w-full"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            No, cancel
          </button>
          <Button
            className="font-xs-mac13 !rounded-full !bg-[#E02424] font-inter-medium hover:!bg-red-700 maxMd:!ml-0 maxMd:w-full"
            onClick={() => deleteTeamFn()}
            disabled={isDeleting}
          >
            <Spinner
              size="md"
              light={true}
              hidden={!isDeleting}
              className="mr-3 !fill-lavared !stroke-lavared"
            />
            Yes, I’m sure
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default General;
