import { boolean, object, ref, string } from "yup";

export const signupSchema = object({
  agreeTerms: boolean().oneOf([true], "agreeTerms must accept"),
  email: string().required().email(),
  password1: string()
    .required()
    .min(8)
    .matches(/^(?=.*[a-z])/, "")
    .matches(/^(?=.*[A-Z])/, "")
    .matches(/^(?=.*[0-9])/, "")
    .matches(/^(?=.*[^a-zA-Z0-9])/, ""),
  password2: string()
    .required()
    .oneOf([ref("password1")], "Your passwords do not match."),
});
