import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { ARTICLE_MODULE } from "shared/helpers/ability";
import { AGREEMENT_TABLE } from "shared/helpers/constant";
import { getUrlFromType } from "shared/helpers/getUrlFromType";
import { show_all_objects } from "shared/helpersV2/constant";
import { CompanyId, TeamId } from "shared/types";

import convertMetadataResponse from "../helpers/convertMetadataResponse";
import { MetaTable, TeamActionPayload } from "../types";

export default async function updateTeamActions(
  type: MetaTable,
  data: TeamActionPayload,
  { teamId, companyId, isAllTeam }: { teamId: TeamId; companyId: CompanyId; isAllTeam: boolean }
): Promise<AxiosResponse<TeamActionPayload>> {
  const { id } = data;

  const typeUrl = getUrlFromType(type);

  if (typeUrl === "default") {
    return Promise.reject(new Error("Module is not selected"));
  }

  const teamPermissionPayload = { team: teamId, company: companyId, isAllTeam: isAllTeam };

  const teamParameter = teamPermissionPayload?.isAllTeam ? "all" : teamPermissionPayload?.team;

  const URL = type === ARTICLE_MODULE ? `${typeUrl}/${id}/team-actions/` : `${typeUrl}/${id}/`;

  let response = await api.patch<TeamActionPayload>(
    URL,
    { ...data },
    {
      params: {
        ...{ team: teamParameter, company: teamPermissionPayload.company },
        show_all_objects: type === AGREEMENT_TABLE ? show_all_objects : undefined,
      },
    }
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertMetadataResponse(response.data),
    };
  }

  return response;
}
