import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamParameter } from "shared/types";

import convertDocumentData from "../helpers/convertDocumentData";
import { Directory, DirectoryProps, Document, DocumentsQueryParams } from "../type";

export type UpdateDocumentProps = {
  id: number | undefined;
  file?: File;
  name?: string;
  team: number;
  directory?: number;
};

export type AddDocumentProps = {
  directory: string | undefined;
  file?: File;
  team: number;
  name: string;
};

export const getDocument = async (team: TeamParameter, requestParams?: { ordering: "name" }) => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;
  let response = await api.get(
    `documents/directories/root/?team=${teamParameter}&company=${team.company}`,
    { params: { ...hasNameOrdering(requestParams) } }
  );

  if (response && response.data && response.data?.documents?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        documents:
          response.data?.documents?.map((data: object) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const getDirectoriesTree = (team: TeamParameter, requestParams?: { ordering: "name" }) => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;
  return api.get(`documents/directories/tree/?team=${teamParameter}&company=${team.company}`, {
    params: { ...hasNameOrdering(requestParams) },
  });
};

export const addDocument = async (data: AddDocumentProps) => {
  if (data.file) {
    const formData = new FormData();
    formData.append("file", data?.file);
    formData.append("name", data?.file.name);
    formData.append("team", `${data.team}`);
    formData.append("name", `${data.name}`);
    if (data.directory) {
      formData.append("directory", `${data.directory}`);
    }
    return api.post(`documents/?team=${data.team}`, formData);
  }
  const payload = { team: data.team, name: data.name };

  if (data.directory) {
    Object.assign(payload, { directory: data.directory });
  }
  let response = await api.post(`documents/?team=${data.team}`, { ...payload });

  if (response && response.data) {
    response = {
      ...response,
      data: convertDocumentData(response.data),
    };
  }

  return response;
};

export const updateDocument = async (data: UpdateDocumentProps) => {
  const formData = new FormData();
  if (data.name) {
    formData.append("name", `${data.name}`);
  } else if (!data.name && data.file) {
    formData.append("name", `${data.file.name}`);
  } else {
    new Error("Name is required");
  }

  if (data.file) {
    formData.append("file", data.file);
  }
  if (data.directory) {
    formData.append("directory", `${data.directory}`);
  }
  formData.append("team", `${data.team}`);

  let response = await api.patch(`documents/${data.id}/?team=${data.team}`, formData);

  if (response && response.data) {
    response = {
      ...response,
      data: convertDocumentData(response.data),
    };
  }

  return response;
};

export const deleteDocument = (id: number | undefined | null, team: number) => {
  return api.delete(`documents/${id}/?team=${team}`);
};

export const getDirectory = async (team: number, requestParams?: { ordering?: string }) => {
  let response = await api.get(`documents/directories/root/?team=${team}`, {
    params: { ...hasNameOrdering(requestParams) },
  });

  if (response && response.data && response.data?.documents?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        documents:
          response.data?.documents?.map((data: object) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const addDirectory = async (data: DirectoryProps) => {
  let response = await api.post(`documents/directories/?team=${data.team}`, {
    ...data,
    team: data.team,
  });

  if (response && response.data) {
    response = {
      ...response,
      data: {
        ...response.data,
        documents:
          response.data?.documents?.map((data: object) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const deleteDirectory = (id: number | undefined | null, team: number) => {
  return api.delete(`documents/directories/${id}/?team=${team}`);
};

export const updateDirectory = async (data: DirectoryProps | Document) => {
  let response = await api.patch(`documents/directories/${data.id}/?team=${data.team}`, data);

  if (response && response.data) {
    response = {
      ...response,
      data: {
        ...response.data,
        documents:
          response.data?.documents?.map((data: object) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const getDirectoryByID = async (
  id: string | undefined,
  team: TeamParameter,
  requestParams?: { ordering?: string }
) => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;
  let response = await api.get(
    `documents/directories/${id}/?team=${teamParameter}&company=${team.company}`,
    {
      params: { ...hasNameOrdering(requestParams) },
    }
  );

  if (response && response.data && response.data?.documents?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        documents:
          response.data?.documents?.map((data: object) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const manageSearchData = (
  team: TeamParameter,
  options?: DocumentsQueryParams,
  parent?: string | undefined,
  parents: string[] = [],
  id?: string | undefined,
  type?: string
): { permission: Record<string, string | number | Array<string>> } => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team.toString();
  const permission: Record<string, string | number> = {
    team: teamParameter,
    company: team.company.toString(),
  };

  if (parent && id != undefined) {
    if (type === "document") {
      permission["directory"] = parent;
    } else {
      permission["parent"] = parent;
    }
  } else {
    const useParents = parents.length > 0 ? parents?.toString() : ",";
    if (type === "document") {
      permission["directory__in"] = useParents;
    } else {
      permission["parent__in"] = useParents;
    }
  }

  return { permission: { ...permission, ...options } };
};

export const searchDirectories = async (
  team: TeamParameter,
  options: DocumentsQueryParams,
  parent?: string | undefined,
  parents: string[] = [],
  id?: string | undefined,
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<{ results: Directory[] }>> => {
  let response = await api.get<{ results: Directory[] }>(`documents/directories/`, {
    params: manageSearchData(team, options, parent, parents, id, "directory").permission,
    ...hasNameOrdering(requestParams),
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            const documentList =
              data.documents.map((document) => convertDocumentData(document)) || [];
            return { ...data, documents: documentList };
          }) || [],
      },
    };
  }
  return response;
};

export const searchDocuments = async (
  team: TeamParameter,
  options: DocumentsQueryParams,
  parent?: string | undefined,
  parents: string[] = [],
  id?: string | undefined,
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<{ results: Document[] }>> => {
  let response = await api.get<{ results: Document[] }>(`documents/`, {
    params: manageSearchData(team, options, parent, parents, id, "document").permission,
    ...hasNameOrdering(requestParams),
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertDocumentData(data);
          }) || [],
      },
    };
  }
  return response;
};

export const getSingleDocument = async (documentId: string, team: TeamParameter) => {
  const teamParameter = team?.isAllTeam ? "all" : team?.team;
  let response = await api.get(
    `documents/${documentId}/?team=${teamParameter}&company=${team.company}`
  );

  if (response && response.data) {
    response = {
      ...response,
      data: convertDocumentData(response.data),
    };
  }

  return response;
};
