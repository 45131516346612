import React, { useEffect } from "react";

import clsx from "clsx";
import capitalize from "lodash/capitalize";
import moment from "moment";

import headerStyles from "assets/css/common-header.module.css";

import {
  AFTER_OPERATOR,
  BEFORE_OPERATOR,
  DATETIME_FILTER,
  FILTER_TYPE,
  datePickerSetToTop,
} from "shared/helpers/constant";

import CommonDatePicker from "../common-date-picker";
import useAddDateFilter from "./hooks/useAddDateFilter";
import { FilterOptionType, FiltersProp } from "./types";

function DateFilter({
  onClick,
  item,
  type,
}: {
  onClick: (data: FilterOptionType) => void;
  item: FiltersProp;
  type: string;
}) {
  const { handleSubmit, values, errors, setFieldValue, resetForm } = useAddDateFilter(() => {
    const from =
      type === DATETIME_FILTER ? moment(values.from).format("YYYY-MM-DD HH:mm:ss.S") : values.from;
    const to =
      type === DATETIME_FILTER ? moment(values.to).format("YYYY-MM-DD HH:mm:ss.S") : values.to;
    const key = `${values.from}_${values.to}`;

    onClick({
      key,
      label: (
        <span>
          {capitalize(item.label)} {" is between "}
          {values?.from} {" and "}
          {values?.to}
        </span>
      ),
      values: [from, to],
      field: item.field,
      type: FILTER_TYPE,
      operators: item.operators,
      conditions: [
        {
          field: item.field,
          operator: (item.operators && item.operators[0]) || BEFORE_OPERATOR,
          value: from,
        },
        {
          field: item.field,
          operator: (item.operators && item.operators[1]) || AFTER_OPERATOR,
          value: to,
        },
      ],
      single_option: item.single_option,
    });
  });

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [item.field]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div id="filter-datepicker" className="grid gap-2">
          <span
            className={clsx({
              "publication-datetwo": datePickerSetToTop.includes(item.field),
            })}
          >
            <CommonDatePicker
              placeholder="Select Date From"
              errClasssName={errors?.from && "!bg-red-50 !border-red-500"}
              date={values?.from ? moment(values?.from).toDate() : null}
              handleChange={(date: Date | null) => {
                setFieldValue("from", date ? moment(date)?.format("YYYY-MM-DD") : null);
              }}
              warningLabel=""
            />
          </span>
          <span
            className={clsx({
              "publication-datetwo": datePickerSetToTop.includes(item.field),
            })}
          >
            <CommonDatePicker
              placeholder="Select Date To"
              errClasssName={errors?.to && "!bg-red-50 !border-red-500"}
              date={values?.to ? moment(values?.to).toDate() : null}
              handleChange={(date: Date | null) => {
                setFieldValue("to", date ? moment(date)?.format("YYYY-MM-DD") : null);
              }}
              warningLabel=""
            />
          </span>

          <div className="!w-fit maxMd:!w-full">
            <button
              type="submit"
              data-testid="date_filter_submit"
              className={headerStyles.btn_primary__imp}
            >
              Apply
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default DateFilter;
