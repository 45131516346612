interface IMetadataFieldProps {
  label: string;
  meta: JSX.Element | string;
}

const MetadataField = ({ label, meta }: IMetadataFieldProps) => {
  return (
    <div className="flex items-center gap-3 text-sm">
      <label className="min-w-[180px] font-normal text-aurometalsaurus dark:text-gray-400">
        {label}
      </label>
      {meta}
    </div>
  );
};

export default MetadataField;
