import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import clsx from "clsx";
import { Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { useDarkMode, useOnClickOutside } from "usehooks-ts";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import { useMutation, useQuery } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";
import amex from "assets/images/icons/amex.svg";
import credit from "assets/images/icons/credit.svg";
import debit from "assets/images/icons/debit.svg";
import visa from "assets/images/icons/visa.svg";
import logo from "assets/images/logo.svg";
import logoDark from "assets/images/logowhite.svg";

import StaticDropdown from "shared/components/common-dropdown/static-dropdown";
import ServerErrors from "shared/components/server-errors";
import CentralSpinner from "shared/components/spinner/CentralSpinner";
import { useCompany } from "shared/context/CompanyProvider";
import { countries } from "shared/helpers/settings";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { usePlans } from "shared/store/settings";

import getMembersByCompanyId from "modules/home/api/getMembersByCompanyId";

import { addSubscription } from "./api";
import usePolling from "./hooks/usePolling";
import useUpgradeForm from "./hooks/useUpgradeForm";
import { SubscriptionProps } from "./types";

interface UpgardeModdalProps {
  isUpgradePaymentModal: boolean;
  setIsUpgradePaymentModal: (isUpgradePaymentModal: boolean) => void;
}

const UpgradeModal = ({ isUpgradePaymentModal, setIsUpgradePaymentModal }: UpgardeModdalProps) => {
  const upgradeModalRef = useRef<HTMLInputElement>(null);

  const stripe = useStripe();
  const elements = useElements();
  const { isDarkMode } = useDarkMode();

  const { currentCompany } = useCompany();
  const plans = usePlans((state) => state.plans);

  const [stripeErrors, setStripeErrors] = useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });

  const { data: companyMemberList, isLoading: isMemberLoading } = useQuery(
    ["getMemberByCompany", "settingUpgrade", currentCompany?.id],
    () => getMembersByCompanyId(currentCompany?.id),
    {
      enabled: !!currentCompany?.id,
    }
  );

  const [isSubmited, setIsSubmited] = useState(false);

  const { documentBodyRef } = useDocumentBodyRef();

  const {
    isSuccess,
    isLoading,
    mutate: addSubscribtionFn,
    isError,
    error,
  } = useMutation((data: SubscriptionProps) => addSubscription(data, currentCompany?.id), {
    onSuccess: async (res) => {
      const confirm = await stripe?.confirmCardPayment(res.data?.clientSecret);
      if (confirm?.error) {
        setIsPolling(false);
        return toast("Payment Failed. Try again later.", { type: "error" });
      }
    },
    onError: (e: AxiosError): AxiosError => {
      toast("Subscription Failed. Try again later.", {
        type: "error",
      });
      return e;
    },
  });

  const { isPolling, setIsPolling } = usePolling(isSuccess, setIsUpgradePaymentModal);

  const { handleSubmit, values, errors, handleChange, setFieldValue } = useUpgradeForm(() =>
    createSubscription()
  );

  const createSubscription = async () => {
    setIsSubmited(true);
    if (stripeErrors.cardExpiry || stripeErrors.cardNumber || stripeErrors.cardCvc) return;

    const cardElement = elements?.getElement("cardNumber");
    if (cardElement) {
      try {
        const paymentMethod = await stripe?.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: values.name,
            email: values.email,
            phone: values.phone_number,
            address: {
              line1: values.address?.line1,
              line2: values.address?.line2,
              city: values.address?.city,
              postal_code: values.address?.postal_code,
              state: values.address?.state,
              country: values.address?.country,
            },
          },
        });

        addSubscribtionFn({
          ...values,
          plan_id: plans[0].id,
          payment_method: paymentMethod?.paymentMethod?.id,
        });
      } catch (error) {
        toast("Subscription Failed. Try again later.", {
          type: "error",
        });
      }
    }
  };

  const onChange = (
    event:
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
  ) => {
    if (!event.complete) {
      const filedError = { ...stripeErrors, [event.elementType]: true };
      setStripeErrors(filedError);
    } else {
      const filedError = { ...stripeErrors, [event.elementType]: false };
      setStripeErrors(filedError);
    }
  };

  const isCountrySelected = () => {
    return !!values.address?.country;
  };

  useOnClickOutside(upgradeModalRef, () => setIsUpgradePaymentModal(false));

  const getMonthlyAmount = () => {
    if (companyMemberList?.data?.results?.length) {
      return `EUR ${(
        (plans[0]?.tiers[0]?.unit_amount / 100) *
        companyMemberList?.data?.results?.length
      ).toFixed(2)}`;
    }
  };

  const shouldLoaderRender = () => {
    if (isLoading) {
      return true;
    } else if (isSuccess && isPolling) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Modal
        root={documentBodyRef}
        show={isUpgradePaymentModal}
        className={clsx(styles.upgrade_payment)}
        dismissible={true}
        size={"4xl"}
        onClose={() => {
          setIsUpgradePaymentModal(false);
        }}
      >
        <div className="md:hidden">
          <Modal.Header className="p-4">
            <span className="font-inter-medium text-lg text-mirage dark:text-white">
              Upgrade to Business
            </span>
          </Modal.Header>
        </div>

        <Modal.Body className={styles.upgrade_payment_modal} ref={upgradeModalRef}>
          <div className={styles.upgrade_modal}>
            {isMemberLoading ? <CentralSpinner /> : null}

            <div className="maxMd:hidden">
              <img
                src={isDarkMode ? logoDark : logo}
                alt="logo-img"
                className="h-6 w-6 mac13Inch:h-4 mac13Inch:w-4"
              />
              <h4 className="mt-2 font-sans-medium text-[1.75rem] text-mirage dark:text-white maxMd:text-2xl mac13Inch:text-[1.5rem]">
                Upgrade to Business
              </h4>
            </div>
            <p className="!mt-2 text-sm text-aurometalsaurus dark:text-greychateau md:!mt-1">
              Write, plan, and work together in one spot.
            </p>
            <div id="select" className="mt-4">
              <div className="mb-2 block">
                <Label
                  htmlFor="countries"
                  value="Payment Information"
                  className="!font-semibold !text-mirage"
                />
              </div>
              <Select className="custom_select" id="countries" disabled>
                <option>{`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(
                  2
                )} per month`}</option>
              </Select>
            </div>
            <div className="my-4">
              <Label value="Payment Method" className="!font-semibold !text-mirage" />
              <div className={styles.paymentIcon}>
                <p className="!mt-0 mr-1 text-davygrey dark:text-greychateau mac13Inch:text-sm">
                  Credit or Debit Card
                </p>
                <img src={debit} alt="debit-icon" />
                <img src={credit} alt="credit-icon" />
                <img src={visa} alt="visa-icon" />
                <img src={amex} alt="amex-icon" />
              </div>
            </div>
            <Label
              value="Billing Information"
              className="!font-semibold tracking-wide !text-mirage"
            />
            <div className="mb-4 md:mb-8 md:mt-4">
              <div className="mt-2 md:mb-8">
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Name"
                  value={values.name}
                  name="name"
                  color={errors.name ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Email"
                  value={values.email}
                  name="email"
                  color={errors.email ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-3"}
                  placeholder="Phone Number"
                  value={values.phone_number}
                  name="phone_number"
                  color={errors.phone_number ? "failure" : "gray"}
                />
                <StaticDropdown
                  placeholder="Select Country"
                  mainClassname={clsx("mb-2", {
                    [styles.selected]: isCountrySelected(),
                    [styles.selectCountry]: !isCountrySelected(),
                  })}
                  handleChange={(data) => {
                    setFieldValue("address.country", data || "");
                  }}
                  value={values.address?.country}
                  data={countries?.map((item) => ({ id: item.code, name: item.name || "" }))}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Address Line 1 (Street Address)"
                  value={values.address?.line1}
                  name="address.line1"
                  color={errors.address?.line1 ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Address Line 2  (Apartment, Suite, etc)"
                  value={values.address?.line2}
                  name="address.line2"
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="City"
                  value={values.address?.city}
                  name="address.city"
                  color={errors.address?.city ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="Zip code"
                  value={values.address?.postal_code}
                  name="address.postal_code"
                  color={errors.address?.postal_code ? "failure" : "gray"}
                />
                <TextInput
                  onChange={handleChange}
                  className={"mb-2"}
                  placeholder="State/province"
                  value={values.address?.state}
                  name="address.state"
                  color={errors.address?.state ? "failure" : "gray"}
                />
              </div>
            </div>
            {/* Card Component */}
            <Label value="Card Number" className="mb-2 !font-semibold !text-mirage" />
            <CardNumberElement
              onChange={onChange}
              id="card_number"
              options={{
                showIcon: true,
                style: {
                  base: {
                    color: isDarkMode ? "#A6A6A7" : "",
                  },
                },
              }}
              className={clsx(
                { error: isSubmited && stripeErrors.cardNumber },
                "card-number mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm !text-mirage dark:border-iridium dark:bg-balticsea"
              )}
            />
            <div className="my-4 grid gap-4 md:mb-6 md:grid-cols-2 md:gap-6">
              <div>
                <Label value="Expiry" className="!font-semibold !text-mirage" />
                <CardExpiryElement
                  id="card_expiry"
                  options={{
                    style: {
                      base: {
                        color: isDarkMode ? "#A6A6A7" : "",
                      },
                    },
                  }}
                  className={clsx(
                    { error: isSubmited && stripeErrors.cardExpiry },
                    "mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 dark:border-iridium dark:bg-balticsea dark:text-greychateau"
                  )}
                  onChange={onChange}
                />
              </div>
              <div>
                <Label value="CVC" className="!font-semibold !text-mirage" />
                <CardCvcElement
                  id="card_cvc"
                  options={{
                    style: {
                      base: {
                        color: isDarkMode ? "#A6A6A7" : "",
                      },
                    },
                  }}
                  onChange={onChange}
                  className={clsx(
                    { error: isSubmited && stripeErrors.cardCvc },
                    "mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-iridium dark:bg-balticsea dark:text-greychateau md:mb-11"
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.upgrade_summary}>
            <Label
              htmlFor="countries"
              value="Order Summary"
              className="!font-inter-medium text-lg !text-mirage"
            />
            <ul className="md:mt-3.5">
              <li>
                <div className="flex items-center justify-between">
                  <Label
                    htmlFor="countries"
                    value={`Business Plan (${companyMemberList?.data?.results?.length} member)`}
                    className="text-base !text-mirage"
                  />
                  <h4>{getMonthlyAmount()}</h4>
                </div>
                <div className="flex md:mt-3">
                  <p>
                    {`EUR ${(plans[0]?.tiers[0]?.unit_amount / 100).toFixed(2)} / user / month`}
                  </p>
                </div>
              </li>
              <li>
                <div className="flex justify-between">
                  <p>Subtotal</p>
                  <h4>{getMonthlyAmount()}</h4>
                </div>
              </li>
              <li>
                <div className="mt-4 flex justify-between">
                  <p>Total</p>
                  <h4>{getMonthlyAmount()}</h4>
                </div>
              </li>
            </ul>
            <div className="mt-4 flex justify-between maxMd:flex-col">
              <button className="btn_secondary mb-4 w-[135px] py-2.5 maxMd:w-full">
                Contact sales
              </button>
              <button
                className="btn_primary w-[155px] py-2.5 md:mb-4 maxMd:w-full"
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                <Spinner
                  size="md"
                  light={true}
                  hidden={!shouldLoaderRender()}
                  className="mr-3 !fill-mirage stroke-mirage"
                />
                {shouldLoaderRender() ? "Upgrading" : "Upgrade"}
              </button>
            </div>
            {isError && <ServerErrors className="mt-4" error={error || errors} />}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpgradeModal;
