import React from "react";
import { NumericFormat } from "react-number-format";

import { GTE_OPERATOR, LTE_OPERATOR } from "shared/helpers/constant";

import StaticDropdown from "../common-dropdown/static-dropdown";
import { FilterValues } from "./types";

export default function UnitValueInput({
  field,
  handleChangeUnitValue,
  selectedUnit,
  selectedOperator,
  unitValues,
  value,
}: {
  field: string;
  handleChangeUnitValue: ({
    value,
    unit,
    operator,
  }: {
    value: number | string | null;
    unit: string;
    operator: string;
  }) => void;
  selectedUnit: string;
  selectedOperator: string;
  unitValues?: FilterValues[];
  value: string | number | null;
}) {
  const [, valueField] = field.split(" ");

  return (
    <>
      <StaticDropdown
        placeholder="No Option Selected"
        handleChange={(data) => {
          handleChangeUnitValue({
            unit: data || "",
            value,
            operator: selectedOperator || GTE_OPERATOR,
          });
        }}
        value={selectedUnit}
        data={unitValues?.map((item) => ({ id: item.value, name: item.name || "" })) || []}
      />
      <div className="grid grid-cols-2 gap-3 ">
        <StaticDropdown
          placeholder="No Option Selected"
          handleChange={(data) => {
            handleChangeUnitValue({ operator: data || "", value, unit: selectedUnit });
          }}
          value={selectedOperator}
          data={[
            { id: GTE_OPERATOR, name: "Greater then equal to" },
            { id: LTE_OPERATOR, name: "Less then equal to" },
          ]}
        />

        <NumericFormat
          id={valueField}
          className="custom_input form-control"
          value={value}
          placeholder="Enter Value"
          onValueChange={(values) => {
            handleChangeUnitValue({
              value: values.floatValue || null,
              unit: selectedUnit,
              operator: selectedOperator || GTE_OPERATOR,
            });
          }}
          allowNegative={false}
        />
      </div>
    </>
  );
}
