import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import teamToQueryParams from "shared/helpers/teamToQueryParams";
import { TeamParameter } from "shared/types";

import { getParamName, getUrlName } from "../helpers";
import { PaginatedPermissionList, PermissionModule } from "../types";

export default function getPermissionsList(
  module: PermissionModule,
  objectId: number,
  team: TeamParameter,
  page = 1
): Promise<AxiosResponse<PaginatedPermissionList>> {
  return api.get<PaginatedPermissionList>(`/${getUrlName(module)}/permissions/`, {
    params: {
      [getParamName(module)]: objectId,
      ...teamToQueryParams(team),
      page,
    },
  });
}
