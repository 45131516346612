import { getRefreshToken, removeRefreshToken, removeToken, setToken } from "shared/helpers/util";

import { Middleware } from "../openapi";

export const refreshTokenMiddleware = (baseUrl: string): Middleware => ({
  pre: async (context) => {
    if (context.init.refreshAttempted === undefined) context.init.refreshAttempted = false;
    return context;
  },

  post: async (context) => {
    const { refreshAttempted = false } = context.init;

    if (context.response.ok) {
      return context.response;
    }

    if (context.response.status !== 401) {
      try {
        const errorMessage = await context.response.json();
        return Promise.reject(errorMessage?.detail || "Something went wrong");
      } catch (error) {
        return Promise.reject("Something went wrong");
      }
    }

    if (refreshAttempted) {
      return context.response;
    }

    // Attempt to refresh the token first before redirecting
    context.init.refreshAttempted = true;

    try {
      const refreshResponse = await context.fetch(`${baseUrl}/api/v1/auth/token/refresh/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refresh: getRefreshToken(),
        }),
      });

      if (!refreshResponse.ok) {
        removeTokenAndRedirectToSignIn();
      }

      const { access: token } = await refreshResponse.json();
      setToken(token);

      return context.fetch(context.url, {
        ...context.init,
        headers: {
          ...context.init.headers,
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      removeTokenAndRedirectToSignIn();
    }
  },
});

function removeTokenAndRedirectToSignIn() {
  removeToken();
  removeRefreshToken();
  window?.location?.replace("/signin");
}
