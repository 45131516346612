import React from "react";

import clsx from "clsx";

const WarningComponent = ({
  warningLabel,
  className,
}: {
  warningLabel?: string;
  className?: string;
}) => {
  return (
    <>
      {warningLabel ? (
        <label
          className={clsx(
            "label-font w-full font-normal !text-gamboge dark:!text-gamboge maxMd:block",
            className
          )}
        >
          {warningLabel}
        </label>
      ) : null}
    </>
  );
};

export default WarningComponent;
