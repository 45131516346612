import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamParameter } from "shared/types";

import { convertTaskData } from "../helpers";
import { GetAllTaskRequestParams, PaginatedTaskListResponse } from "../types";

export const getTasks = async (
  requestParams: GetAllTaskRequestParams,
  permissions: { team: TeamParameter } | null,
  options?: { sort?: string | null }
): Promise<AxiosResponse<PaginatedTaskListResponse>> => {
  if (!permissions) {
    return Promise.reject("Team is not defined");
  }

  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  let response = await api.get<PaginatedTaskListResponse>(`tasks/`, {
    params: {
      ...hasNameOrdering(requestParams),
      ...{ team: teamParameter, company: permissions.team?.company },
      ...(options?.sort ? { ordering: options.sort } : {}),
    },
  });

  if (response && response.data && response.data?.results?.length > 0) {
    response = {
      ...response,
      data: {
        ...response.data,
        results:
          response.data?.results?.map((data) => {
            return convertTaskData(data);
          }) || [],
      },
    };
  }
  return response;
};
