import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { TeamParameter } from "shared/types";

import { CompanyGroupResponse } from "../types";

export const getGroupById = (
  groupId: number,
  permissions: TeamParameter
): Promise<AxiosResponse<CompanyGroupResponse>> => {
  return api.get<CompanyGroupResponse>(`companies/groups/${groupId}/`, {
    params: { company: permissions.company },
  });
};
