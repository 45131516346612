import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Modal, Spinner, TextInput } from "flowbite-react";
import { FormikProps, useFormik } from "formik";

import { useMutation } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";

import { deleteCompany } from "modules/settings/company/api";
import { CompanyDeleteProps, CompanyProps } from "modules/settings/company/types";

export default function DeleteCompanyModal({
  isDeleteModalOpen,
  handleClose,
}: {
  handleClose: () => void;
  isDeleteModalOpen: boolean;
}) {
  const navigate = useNavigate();

  const { currentCompany, refetch, updateCurrentCompany, companies } = useCompany();

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading, mutate: deleteCompanyFn } = useMutation(
    () => deleteCompany(currentCompany?.id),
    {
      onSuccess: () => {
        handleClose();
        // update current company to avoid API falling with the wrong id or data in other modules.
        updateCurrentCompany(companies.filter((company) => company.id !== currentCompany.id)[0]);
        toast("Company Deleted Successfully.", { type: "success" });
        refetch();
        navigate("/");
      },
    }
  );

  const validate = (values: CompanyProps) => {
    const errors: CompanyDeleteProps = {};

    if (!values.name) {
      errors.name = "invalid name";
    } else if (values.name !== currentCompany?.name) {
      errors.name = "invalid name";
    }
    return errors;
  };

  const formik: FormikProps<CompanyProps> = useFormik<CompanyProps>({
    initialValues: { name: "" },
    onSubmit: () => deleteCompanyFn(),
    validateOnChange: false,
    validate,
  });

  return (
    <Modal
      root={documentBodyRef}
      show={isDeleteModalOpen}
      onClose={() => handleClose()}
      dismissible={true}
      className="modal_mobileview_center"
    >
      <Modal.Header className="header-font-xs-mac13 font-sans-medium dark:!border-thunders maxMd:px-4 maxMd:py-2.5 mac13Inch:items-center mac13Inch:px-6 mac13Inch:py-4">
        <span className="text-mirage dark:text-white">Please confirm company deletion</span>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="maxMd:px-4 maxMd:py-5">
          <div className="space-y-6">
            <p className="mb-3 text-aurometalsaurus dark:text-greychateau mac13Inch:text-sm">
              Are you sure you want to delete this company? It cannot be reverted.
            </p>
            <TextInput
              data-testid="delete-company-name"
              type="text"
              placeholder="Enter company name"
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              color={formik.errors.name ? "failure" : "gray"}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="flex-wrap justify-end !space-x-0 !p-4 dark:!border-thunders md:!px-6">
          <button
            type="button"
            className="btn_secondary md:mr-3 maxMd:mb-[18px] maxMd:w-full"
            onClick={() => handleClose()}
          >
            Cancel
          </button>
          <Button type="submit" color="failure" pill={true} className="maxMd:w-full">
            <Spinner size="sm" hidden={!isLoading} className="mr-3 !fill-red-700 !stroke-red-700" />
            Continue
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
