import React from "react";
import { toast } from "react-toastify";

import clsx from "clsx";
import { Modal, Spinner, TextInput } from "flowbite-react";

import { useMutation } from "@tanstack/react-query";

import styles from "assets/css/settings.module.css";

import StaticDropdown from "shared/components/common-dropdown/static-dropdown";
import { useCompany } from "shared/context/CompanyProvider";
import { countries } from "shared/helpers/settings";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { useUpdateCard } from "shared/store/settings";

import { updatePaymentMethod } from "../api";
import useUpdateAddressForm from "../hooks/useUpdateAddressForm";
import { BillingDetailsModalProps, UpdateBillingData } from "../types";

const UpdateAddress = ({ billingDetails, refetchBillingDetails }: BillingDetailsModalProps) => {
  const { currentCompany } = useCompany();

  const isUpdateAddressModalOpen = useUpdateCard((state) => state.isUpdateAddressModalOpen);

  const setIsUpdateAddressModalOpen = useUpdateCard((state) => state.setIsUpdateAddressModalOpen);

  const { documentBodyRef } = useDocumentBodyRef();

  const { isLoading, mutate: updatePaymentMethodFn } = useMutation(
    (data: UpdateBillingData) => {
      if (!billingDetails?.id) {
        return Promise.reject(new Error("Billing Details are not valid."));
      }
      return updatePaymentMethod({ company: currentCompany?.id, data, id: billingDetails?.id });
    },
    {
      onSuccess: () => {
        refetchBillingDetails();
        toast("Address Updated Successfully.", { type: "success" });
        setIsUpdateAddressModalOpen(false);
      },
    }
  );

  const { values, handleChange, setFieldValue, errors, handleSubmit, resetForm } =
    useUpdateAddressForm(
      () =>
        updatePaymentMethodFn({
          billing_details: {
            ...billingDetails?.billing_details,
            address: values.address,
          },
        }),
      billingDetails
    );

  const isCountrySelected = () => {
    return !!values.address?.country;
  };

  const onCloseModal = () => {
    resetForm();
    setIsUpdateAddressModalOpen(false);
  };

  return (
    <Modal
      root={documentBodyRef}
      dismissible={true}
      show={isUpdateAddressModalOpen}
      onClose={onCloseModal}
      className={clsx(styles.upgrade_card_details, "modal_mobileview_center")}
    >
      <Modal.Header className="text-lg dark:!border-thunders maxMd:p-4">
        Change Your Address
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body className="maxMd:p-4">
          <TextInput
            data-testid="name"
            placeholder="Name"
            value={values.name}
            name="name"
            onChange={handleChange}
            color={errors.name ? "failure" : "gray"}
          />

          <StaticDropdown
            placeholder="Select Country"
            mainClassname={clsx("mt-4", {
              [styles.selected]: isCountrySelected(),
              [styles.selectCountry]: !isCountrySelected(),
            })}
            handleChange={(data) => {
              setFieldValue("address.country", data || "");
            }}
            value={values.address?.country}
            data={countries?.map((item) => ({ id: item.code, name: item.name || "" }))}
          />

          <TextInput
            data-testid="line1"
            placeholder="Address Line 1 (Street Address)"
            value={values.address?.line1}
            name="address.line1"
            onChange={handleChange}
            color={errors.address?.line1 ? "failure" : "gray"}
            className="mt-4"
          />
          <TextInput
            data-testid="line2"
            placeholder="Address Line 2  (Apartment, Suite, etc)"
            value={values.address?.line2}
            name="address.line2"
            onChange={handleChange}
            className="mt-4"
          />
          <TextInput
            data-testid="city"
            placeholder="City"
            value={values.address?.city}
            name="address.city"
            onChange={handleChange}
            color={errors.address?.city ? "failure" : "gray"}
            className="mt-4"
          />
          <TextInput
            placeholder="Zip Code"
            value={values.address?.postal_code}
            name="address.postal_code"
            onChange={handleChange}
            color={errors.address?.postal_code ? "failure" : "gray"}
            className="mt-4"
          />
          <TextInput
            data-testid="state"
            placeholder="State/Province"
            value={values.address?.state}
            name="address.state"
            onChange={handleChange}
            color={errors.address?.state ? "failure" : "gray"}
            className="mt-4"
          />
        </Modal.Body>
        <Modal.Footer className="dark:!border-thunders maxMd:flex-wrap maxMd:p-4">
          <button
            className="btn_secondary ml-auto maxMd:w-full"
            onClick={onCloseModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn_primary item-center md:flex maxMd:!ml-0 maxMd:mt-2 maxMd:w-full"
            type="submit"
          >
            <Spinner hidden={!isLoading} size="sm" className="mr-2 !fill-mirage stroke-mirage" />
            {isLoading ? "Updating" : "Update"}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateAddress;
