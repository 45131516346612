import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";

import ArticleSection from "./ArticleSection";

const ArticleSummary = () => {
  const {
    articleQuery: { data: article },
  } = useArticleContext();
  if (!article) return null;
  return (
    <ArticleSection title="Summary" icon="document-check">
      <span className="ml-6 border-l-2 border-brightgray pl-6 text-base font-medium leading-relaxed text-davygrey dark:border-jet dark:text-gray-500">
        {article.description}
      </span>
    </ArticleSection>
  );
};

export default ArticleSummary;
