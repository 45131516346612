import { CompanyResponse } from "modules/home/types";
import { TeamResponse } from "modules/settings/teams/types";

import { RelatedObjectType } from "../../../../../modules/tasks/overview/types";

/**
 * Get the teams that are available for sharing a related object
 */
export default function getAvailableTeams(
  company: CompanyResponse,
  relatedObject: RelatedObjectType,
  excludedTeams?: number[]
): TeamResponse[] {
  const { team } = relatedObject;
  const excludedTeamSet = new Set(excludedTeams);

  return company.teams
    .filter((t) => t.id !== team)
    .filter((t) => !t.is_all_team)
    .filter((t) => !t.is_general_team)
    .filter((t) => !t.is_common_team)
    .filter((t) => !excludedTeamSet.has(t.id));
}
