import { toast } from "react-toastify";

import { AxiosError } from "axios";
import get from "lodash/get";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useTeam } from "shared/store/settings";

import { RelatedObjectType } from "modules/tasks/overview/types";

import { updatePermission } from "../api";
import { PERMISSION_LABEL } from "../constants";
import isPermissionModule from "../helpers/isPermissionModule";
import { MetaTable, Permission, PermissionLevel } from "../types";

export default function usePermissionUpdateMutation({
  type,
  relatedObject,
}: {
  type: MetaTable;
  relatedObject: RelatedObjectType;
}) {
  const queryClient = useQueryClient();

  const { currentTeam } = useTeam();

  return useMutation<
    Permission,
    AxiosError,
    { permission: Permission; level: PermissionLevel },
    { previousPermission?: Permission }
  >({
    mutationFn: async ({ permission, level }) => {
      if (!isPermissionModule(type)) {
        return Promise.reject("Invalid permission module");
      }

      if (!relatedObject.id) {
        return Promise.reject("Invalid related object id");
      }

      const response = await updatePermission(
        type,
        relatedObject.id,
        permission.id,
        { level },
        {
          team: currentTeam?.id,
          isAllTeam: currentTeam.is_all_team,
          company: currentTeam?.company,
        }
      );
      return response.data;
    },
    onMutate: async ({ permission, level }) => {
      await queryClient.cancelQueries(["permissions", type, relatedObject.id]);

      const permissionQueryKey = ["permissions", type, relatedObject.id, permission.id];

      const previousPermission = queryClient.getQueryData<Permission>(permissionQueryKey);

      queryClient.setQueryData<Permission>(permissionQueryKey, {
        ...permission,
        level,
        level_label: get(PERMISSION_LABEL, level),
      });

      return { previousPermission };
    },
    onError: (err, data, context) => {
      if (context && context.previousPermission) {
        const { previousPermission } = context;
        queryClient.setQueryData(
          ["permissions", type, relatedObject.id, previousPermission.id],
          previousPermission
        );
      }
      toast.error("Something went wrong! Please try again.");
    },
    onSuccess: () => {
      toast.success("Permission updated successfully.");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["permissions", type, relatedObject.id]);
    },
  });
}
