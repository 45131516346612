import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import hasNameOrdering from "shared/helpers/hasNameOrdering";
import { TeamParameter } from "shared/types";

import { NoteFileResponse } from "../types";

export default function getNoteFile(
  id: number,
  permissions: { team: TeamParameter },
  requestParams?: { ordering?: string }
): Promise<AxiosResponse<NoteFileResponse>> {
  const teamParameter = permissions.team?.isAllTeam ? "all" : permissions.team?.team;
  return api.get<NoteFileResponse>(`notes/files/${id}/`, {
    params: {
      team: teamParameter,
      company: permissions.team?.company,
      ...hasNameOrdering(requestParams),
    },
  });
}
