import React, { SyntheticEvent, useEffect, useState } from "react";

import { COMMAND_PRIORITY_LOW, LexicalCommand, LexicalEditor, createCommand } from "lexical";

import { URL_MATCHER } from "@lexical/react/LexicalAutoEmbedPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import { useMutation } from "@tanstack/react-query";

import { getURLMetadata } from "../../api";
import { $createWebBookmarkNode, WebBookmarkNode } from "../../nodes/WebBookmarkNode";
import { WebBookmarkPayload } from "../../types";
import { DialogActions } from "../../ui/Dialog";

export const INSERT_WEB_BOOKMARK_COMMAND: LexicalCommand<WebBookmarkPayload> = createCommand(
  "INSERT_WEB_BOOKMARK_COMMAND"
);

export default function WebBookmark(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([WebBookmarkNode])) {
      throw new Error("WebBookmarkPlugin: WebBookmmarkNode not registered on editor");
    }

    return editor.registerCommand<WebBookmarkPayload>(
      INSERT_WEB_BOOKMARK_COMMAND,
      (payload) => {
        const webBookmarkNode = $createWebBookmarkNode(
          payload.title,
          payload.description,
          payload.image,
          payload.link
        );
        $insertNodeToNearestRoot(webBookmarkNode);
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  return null;
}

export function InsertWebBookmarkDialog({
  activeEditor,
  onClose,
}: {
  activeEditor: LexicalEditor;
  onClose: () => void;
}) {
  const [src, setSrc] = useState("");
  const textRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus();
      }
    });
  }, []);

  const { mutate, isLoading } = useMutation((url: string) => getURLMetadata({ url }), {
    onSuccess: (res) => {
      const payload = {
        title: res.data.title,
        description: res.data.description,
        image: res.data.image,
        link: res.data.link,
      };
      processResponse(payload);
    },
    onError: (error, vars) => {
      let host = null;
      try {
        const domain = new URL(vars);
        host = domain.host;
      } catch (e) {
        host = vars;
      }

      const payload = {
        title: host,
        description: vars,
        image: "",
        link: vars,
      };
      processResponse(payload);
    },
  });

  const processResponse = (payload: WebBookmarkPayload) => {
    activeEditor.dispatchCommand(INSERT_WEB_BOOKMARK_COMMAND, payload);
    onClose();
  };

  const match = URL_MATCHER.test(src);

  const isDisabled = src === "" || !match;

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDisabled) {
      return;
    }
    mutate(src);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="Input__wrapper">
          <input
            ref={textRef}
            type="text"
            className="Input__input"
            placeholder={"https://www.grand.io"}
            value={src}
            data-test-id={`web-bookmark-modal-url`}
            onChange={(e) => {
              const { value } = e.target;
              setSrc(value);
            }}
          />
        </div>
        <DialogActions>
          <button
            type="submit"
            data-test-id="web-bookmark-modal-submit-btn"
            disabled={isDisabled}
            className="btn_primary maxMd:w-full"
          >
            {isLoading ? "Processing ..." : "Create Bookmark"}
          </button>
        </DialogActions>
      </form>
    </div>
  );
}
