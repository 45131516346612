import { useState } from "react";
import { MultiValue } from "react-select";
import { toast } from "react-toastify";

import { AxiosResponse, isAxiosError } from "axios";
import clsx from "clsx";
import { Label, Spinner } from "flowbite-react";
import { omit } from "lodash";
import moment from "moment";

import { useMutation, useQuery } from "@tanstack/react-query";

import headerStyles from "assets/css/common-header.module.css";

import CommonDatePicker from "shared/components/common-date-picker";
import StaticDropdown from "shared/components/common-dropdown/static-dropdown";
import {
  CreateTeamAction,
  TeamActionPayload,
  TeamMemberOptionProps,
  commentProps,
  optionType,
} from "shared/components/metadata/types";
import CentralSpinner from "shared/components/spinner/Spinner";
import TagsField from "shared/components/tags-field";
import { useCompany } from "shared/context/CompanyProvider";
import { getOptionType } from "shared/helpers/getOptionTypeData";
import { convertDate, initialDataForList } from "shared/helpers/util";
import { metaDataPriority } from "shared/store/agreements";
import { useTeam } from "shared/store/settings";

import { getTeamActions, updateTeamActions } from "../api";
import TeamMembers from "./TeamMembers";
import TeamOwner from "./TeamOwner";

const TeamActions = ({ type, id, teamId, companyId, isAllTeam }: commentProps) => {
  const initialValues = {
    priority: "",
    tags: [],
    assignees: [],
    due_date: null,
    owner: "",
    owner_object: null,
    created_by: { id: 0, email: "", name: "", profile_picture: "" },
  };

  const { currentCompany } = useCompany();
  const currentTeam = useTeam((state) => state.currentTeam);

  const [teamAction, setTeamAction] = useState<CreateTeamAction>(initialValues);

  const {
    data: respTeamAction,
    refetch,
    isLoading: isLoadTeamActions,
  } = useQuery(
    ["getTeamActions", [], teamId, id],
    () =>
      getTeamActions({
        type,
        typeId: id,
        permission: { teamId, companyId: companyId, isAllTeam: isAllTeam },
      }),
    {
      initialData: () => ({ data: { ...initialDataForList } } as AxiosResponse),
      onSuccess: (res) => {
        if (res) {
          const tagsOption: Array<optionType> = [];
          res?.data?.tags.forEach((item: string) => {
            tagsOption.push({ value: item, label: item });
          });
          setTeamAction({
            ...res?.data,
            tags: [...tagsOption],
          });
        }
      },
    }
  );

  const handleChange = (
    newValue: number | string[] | string | MultiValue<optionType> | null,
    type: string
  ) => {
    setTeamAction({ ...teamAction, [type]: newValue });
  };

  const { mutate: teamActionFn, isLoading: isActionUpdateLoader } = useMutation(
    (data: TeamActionPayload) =>
      updateTeamActions(type, data, {
        teamId: Number(data?.teamId),
        companyId: currentCompany?.id,
        isAllTeam: currentTeam?.is_all_team || false,
      }),
    {
      onSuccess: () => {
        toast("Team Action updated successfully.", { type: "success" });
        setTeamAction(initialValues);
      },
      onError: (error: unknown) => {
        if (isAxiosError(error) && error.response?.status === 403) {
          toast("You are not authorized to perform this action", { type: "error" });
        } else {
          toast("Something went wrong", { type: "error" });
        }
      },
      onSettled: () => {
        refetch();
      },
    }
  );

  const onSubmit = (data: CreateTeamAction) => {
    const teamActionData: TeamActionPayload = { ...initialValues };
    teamActionData.tags = data.tags.map((item: optionType) => item.value);
    teamActionData.teamId = teamId;
    teamActionData.owner = data?.owner || "";
    teamActionData.priority = data.priority;
    teamActionData.due_date = data.due_date;
    teamActionData.assignees = data.assignees;
    teamActionData.type = type;
    teamActionData.id = id;
    omit(data, "created_by");
    teamActionFn(teamActionData);
  };

  return (
    <div className="metadata-tabs">
      {isLoadTeamActions || isActionUpdateLoader ? <CentralSpinner /> : null}
      <h1 className={clsx(headerStyles.subscriptionTitle, "metadata-tabs__header")}>
        Object Properties
      </h1>
      <form className="metadata_height flex flex-col gap-4 mac13Inch:gap-3">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Priority" className="!text-mirage" />
          </div>
          <StaticDropdown
            placeholder="No Option Selected"
            handleChange={(data) => {
              handleChange(data, "priority");
            }}
            value={teamAction.priority || ""}
            data={metaDataPriority}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="tags" value="Tags" className="!text-mirage" />
          </div>
          <TagsField
            teamId={teamId}
            values={teamAction.tags}
            onChange={(value) => handleChange(value, "tags")}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="text" value="Assignee" className="!text-mirage" />
          </div>
          <TeamMembers
            members={teamAction.assignees}
            members_object={respTeamAction.data?.assignees_object || []}
            onChange={(
              values:
                | string
                | number
                | string[]
                | MultiValue<optionType>
                | MultiValue<TeamMemberOptionProps>
                | null
                | undefined
            ) => {
              handleChange(
                getOptionType(values || []).map((item: optionType) => item.value),
                "assignees"
              );
            }}
          />
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <div>
            <Label className="mb-2 block !text-mirage" htmlFor="text" value="Due date" />
            <CommonDatePicker
              placeholder="yyyy-mm-dd"
              date={teamAction.due_date ? moment(teamAction.due_date).toDate() : null}
              handleChange={(date: Date | null) => {
                handleChange(date ? convertDate(date) : null, "due_date");
              }}
              defaultDate={
                respTeamAction?.data.due_date
                  ? moment(respTeamAction?.data.due_date).toDate()
                  : null
              }
            />
          </div>
          <div>
            <Label className="mb-2 block !text-mirage " htmlFor="created_by" value="Owner" />
            <TeamOwner
              user_object={teamAction?.owner_object}
              onChange={(
                id:
                  | string
                  | number
                  | string[]
                  | MultiValue<optionType>
                  | MultiValue<TeamMemberOptionProps>
                  | null
                  | undefined
              ) => {
                handleChange(Number(id), "owner");
              }}
            />
          </div>
        </div>
        <div className="pb-4 md:ml-auto md:mt-4 md:flex">
          <button
            type="button"
            className="btn_secondary md:mr-3 maxMd:mb-4 maxMd:w-full"
            onClick={() =>
              setTeamAction({
                ...initialValues,
                owner: "",
              })
            }
          >
            Clear
          </button>
          <button
            type="button"
            className="btn_primary gap-2 md:flex maxMd:w-full"
            onClick={() => onSubmit(teamAction)}
          >
            {isActionUpdateLoader ? <Spinner size="sm" /> : null}
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TeamActions;
