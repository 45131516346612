import React from "react";
import Select from "react-select";

import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/common-header.module.css";
import chevronDown from "assets/images/chevron-down.svg";

import { useCompany } from "shared/context/CompanyProvider";
import { selectControlStyleForCreate, selectErrorStyles } from "shared/helpers/selectStyle";

import { GroupChildrenComponentProps } from "../types";

const TeamPermissionGroupMapping = ({ groupMappingForm }: GroupChildrenComponentProps) => {
  const { isDarkMode } = useDarkMode();

  const { currentCompany } = useCompany();

  const placeholderForTeamSelector = (
    <div className="text-sm text-aurometalsaurus">Select team/s</div>
  );

  const componentsForTeamSelector = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => <img src={chevronDown} alt="chevron-down-icon" className="mr-4" />,
  };

  const teamSelectorClass = groupMappingForm.errors.teams
    ? selectErrorStyles(isDarkMode)
    : selectControlStyleForCreate(isDarkMode);

  return (
    <Select
      className={styles?.searchField}
      classNamePrefix="multitag mapping_tag"
      isClearable={false}
      isMulti={true}
      styles={teamSelectorClass}
      value={groupMappingForm.values.teams}
      placeholder={placeholderForTeamSelector}
      onChange={(teams) => groupMappingForm.setFieldValue("teams", [...teams])}
      noOptionsMessage={() => null}
      getOptionLabel={(team) => team.name}
      getOptionValue={(team) => team.id}
      options={currentCompany.teams}
      components={componentsForTeamSelector}
    />
  );
};

export default TeamPermissionGroupMapping;
