/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `INTERNAL` - Internal
 * * `EXTERNAL` - External
 * @export
 */
export const OriginEnum = {
    Internal: 'INTERNAL',
    External: 'EXTERNAL'
} as const;
export type OriginEnum = typeof OriginEnum[keyof typeof OriginEnum];


export function instanceOfOriginEnum(value: any): boolean {
    for (const key in OriginEnum) {
        if (Object.prototype.hasOwnProperty.call(OriginEnum, key)) {
            if (OriginEnum[key as keyof typeof OriginEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OriginEnumFromJSON(json: any): OriginEnum {
    return OriginEnumFromJSONTyped(json, false);
}

export function OriginEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OriginEnum {
    return json as OriginEnum;
}

export function OriginEnumToJSON(value?: OriginEnum | null): any {
    return value as any;
}

