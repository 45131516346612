import React, { useEffect, useRef } from "react";

import { Label, Tabs, TextInput } from "flowbite-react";
import _ from "lodash";
import { useWindowSize } from "usehooks-ts";

import warning from "assets/images/icon/warning.svg";

import Icon from "shared/components/icon/Icon";
import NotCompliant from "shared/components/not-compliant";
import { getElementOffSetTop } from "shared/helpers/util";
import { useStatusEditFormStore } from "shared/store/tasks";

import useStatusEditForm from "../hooks/useStatusEditForm";
import { StatusResultType } from "../types";
import TasksByType from "./TasksByStatus";

const EditForm = ({ status }: { status: StatusResultType }) => {
  const { height } = useWindowSize();

  const statusViewRef = useRef<HTMLDivElement | null>(null);

  const setStatusEditForm = useStatusEditFormStore((state) => state.setStatusEditForm);

  const formik = useStatusEditForm(status);

  useEffect(() => {
    setStatusEditForm(formik);
    return () => {
      return setStatusEditForm(null);
    };
  }, [formik.values]);

  return (
    <form>
      <div
        className="w-full"
        ref={statusViewRef}
        style={{
          height: height - getElementOffSetTop(statusViewRef.current),
        }}
      >
        <div className="details_sub_container">
          <div className="flex">
            <h3 className="details_heading">Details</h3>
            {!_.isEmpty(formik.errors) ? <NotCompliant count={_.size(formik.errors)} /> : null}
          </div>
          <div className="mt-2.5 grid md:grid-cols-3 md:gap-6">
            <div>
              <div className="flex">
                <Label value="Name*" className="mb-2 mr-2 block !text-mirage" />
                <Icon type="question-outline" fill={false} size="icon-smd" />
                {formik.errors.name ? (
                  <img src={warning} alt="warning-icon" className="ml-auto mt-1 h-3.5" />
                ) : null}
              </div>
              <TextInput
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                color={formik.errors.name ? "failure" : "gray"}
              />
            </div>
          </div>
        </div>
        <div className="tabs_details_container">
          <Tabs.Group className="tabs_details">
            <Tabs.Item title="Tasks" active={true}>
              <TasksByType />
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </div>
    </form>
  );
};

export default EditForm;
