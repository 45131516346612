import { FormikProps } from "formik";

import styles from "assets/css/task.module.css";

import TaskStatusField from "shared/components/task-status-field";

import { StatusResultType } from "modules/tasks/statuses/types";

import TaskLabel from "../shared/TaskLabel";
import { TaskResultType } from "../types";

export const TaskStatus = ({
  form,
  selectedObject,
  teamId,
  companyId,
}: {
  form: FormikProps<TaskResultType>;
  selectedObject: StatusResultType | null;
  teamId: number;
  companyId: number;
}) => {
  return (
    <div>
      <TaskLabel title="Status" />

      <div className={styles.statusDropdown}>
        <TaskStatusField
          form={form}
          selectedObject={selectedObject}
          teamId={teamId}
          companyId={companyId}
        />
      </div>
    </div>
  );
};
