import React from "react";

import clsx from "clsx";

import styles from "assets/css/task.module.css";

import { TaskLabelType } from "../types";

function TaskDisplayValue({ className, title, onClick }: TaskLabelType) {
  return (
    <h3
      className={clsx(styles.fieldValue, className, { "cursor-pointer": onClick && title })}
      onClick={onClick}
    >
      {title ? <span className="task_underline">{title}</span> : <span>N/A</span>}
    </h3>
  );
}

export default TaskDisplayValue;
