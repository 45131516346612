import {
  AppearanceIcon,
  BelllIcon,
  CreditCardIcon,
  GroupsIcon,
  InstitutionTypeIcon,
  MemberIcon,
  MyAccountIcon,
  RiskSettingIcon,
  SettingIcon,
  TeamsIcon,
  UpgradeIcon,
} from "shared/components/icons/Icons";
import { useCompany } from "shared/context/CompanyProvider";
import checkEnvVar from "shared/helpers/checkEnvVar";
import {
  AGREEMENTS,
  AGREEMENT_REMINDERS,
  APPEARANCE,
  BILLING,
  COMPANY,
  GROUPS,
  INSTITUTION_TYPES,
  MEMBERS,
  MY_ACCOUNT,
  NOTIFICATION,
  TEAMS,
  UPGRADE,
} from "shared/helpers/constant";

import TeamsSettings from "modules/settings/teams/TeamsSettings";

import Appearance from "../appearance";
import Billing from "../billing";
import Groups from "../groups";
import Members from "../members";
import MyAccount from "../my-account";
import { Notification } from "../notification/Notification";
import { SettingTypes } from "../types";
import Upgrade from "../upgrade";
import WorkspaceSettings from "../workspace-settings";

const useGetSidebarItems = () => {
  const { currentCompany } = useCompany();

  const accountSettings: Array<SettingTypes> = [
    {
      title: "My Account",
      icon: <MyAccountIcon />,
      slug: MY_ACCOUNT,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "my-account",
      element: <MyAccount />,
    },
    {
      title: "Notification Settings",
      icon: <BelllIcon />,
      slug: NOTIFICATION,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "notification",
      element: <Notification />,
    },
    {
      title: "Appearance",
      icon: <AppearanceIcon />,
      slug: APPEARANCE,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: "appearance",
      element: <Appearance />,
    },
  ];

  const companySettings: Array<SettingTypes> = [
    {
      title: "Workspace Settings",
      icon: <SettingIcon />,
      slug: COMPANY,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/workspace-settings`,
      element: <WorkspaceSettings />,
    },
    {
      title: "Members",
      icon: <MemberIcon />,
      slug: MEMBERS,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/members`,
      element: <Members />,
    },
    {
      title: "Groups",
      icon: <GroupsIcon />,
      slug: GROUPS,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/groups`,
      element: <Groups />,
    },
    {
      title: "Teams",
      icon: <TeamsIcon />,
      slug: TEAMS,
      stripeIncluded: false,
      isVisible: () => true,
      redirectTo: `${currentCompany.id}/teams`,
      element: <TeamsSettings />,
      deprecated: true,
    },
    {
      title: "Agreements",
      icon: <RiskSettingIcon />,
      slug: AGREEMENTS,
      stripeIncluded: false,
      isVisible: () => checkEnvVar("REACT_APP_AGREEMENTS_REMINDERS_ENABLE"),
      children: [
        {
          title: "Reminders",
          slug: AGREEMENT_REMINDERS,
          stripeIncluded: false,
          isVisible: () => checkEnvVar("REACT_APP_AGREEMENTS_REMINDERS_ENABLE"),
          redirectTo: `${currentCompany.id}/agreement/reminders`,
          element: <div className="dark:text-white">{AGREEMENT_REMINDERS}</div>,
        },
      ],
      element: <div className="dark:text-white">{AGREEMENTS}</div>,
    },
    {
      title: "Institution Types",
      icon: <InstitutionTypeIcon />,
      slug: INSTITUTION_TYPES,
      stripeIncluded: false,
      isVisible: () => checkEnvVar("REACT_APP_INSTITUTION_TYPES_ENABLE"),
      redirectTo: "institutation_types",
      element: <div className="dark:text-white">{INSTITUTION_TYPES}</div>,
    },
    {
      title: "Upgrade",
      icon: <UpgradeIcon />,
      slug: UPGRADE,
      stripeIncluded: true,
      isVisible: () => !currentCompany.is_enterprise && checkEnvVar("REACT_APP_STRIPE_ENABLE"),
      redirectTo: "upgrade",
      element: <Upgrade />,
    },
    {
      title: "Billing",
      icon: <CreditCardIcon />,
      slug: BILLING,
      stripeIncluded: true,
      isVisible: () => !currentCompany.is_enterprise && checkEnvVar("REACT_APP_STRIPE_ENABLE"),
      redirectTo: "billing",
      element: <Billing />,
    },
  ];
  return { companySettings, accountSettings };
};

export default useGetSidebarItems;
